import * as React from 'react';
import * as URLBuilder from '../../routes/url-builder';
import {
    ModuleNavLink,
    ModuleRedirect,
    ModuleRoot,
    ModuleRoute,
    ModuleSwitch,
    useAppNavigator,
} from '@poolware/react-app-navigator';
import * as Staff from './index';
import { ListPageStaffTeams } from './StaffTeams/List';
import { NewPageStaffTeam } from './StaffTeams/New';
import { ViewPageStaffTeam } from './StaffTeams/View';
import { useViewer } from '@poolware/app-shell';
import { Icon, MenuBar } from '@ez/components';

const SectionsNavbar = () => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess } = useViewer();
    if (AppNavigator.isModal || !modulesAccess.Staff?.teams) {
        return null;
    }

    return (
        <div className={'p-2'}>
            <MenuBar.Root>
                <MenuBar.Section>
                    <ModuleNavLink to={'/all'} exact={false}>
                        <Icon name={'spy'} /> Staff
                    </ModuleNavLink>
                    <ModuleNavLink to={'/teams'} exact={false}>
                        <Icon name={'users'} /> Teams
                    </ModuleNavLink>
                </MenuBar.Section>
            </MenuBar.Root>
        </div>
    );
};

export const Router = () => {
    const { paramName } = URLBuilder.Staff();

    let pathId = `:${paramName}`;

    return (
        <ModuleRoot>
            <>
                <ModuleRoute path={'/teams'} exact={true} component={SectionsNavbar} />
                <ModuleRoute path={'/all'} exact={true} component={SectionsNavbar} />

                <ModuleSwitch>
                    <ModuleRedirect path={'/'} exact to={'/all'} />
                    <ModuleRoute path={'/all'} exact component={Staff.List} />
                    <ModuleRoute path="/teams/new" component={NewPageStaffTeam} />
                    <ModuleRoute path="/teams/:id" component={ViewPageStaffTeam} />
                    <ModuleRoute path="/teams" component={ListPageStaffTeams} />
                    <ModuleRoute path="/new" component={Staff.New} />
                    <ModuleRoute path={`/${pathId}/edit`} component={Staff.Edit} />
                    <ModuleRoute path={`/${pathId}`} component={Staff.View} />
                </ModuleSwitch>
            </>
        </ModuleRoot>
    );
};
