import * as React from 'react';
import { StickyMenuContainer } from './StickyMenuContainer';
import { MenuBar } from './MenuBar';

export const StickyMenuBar: React.FC<{ topOffset?: number; className?: string }> = ({
    children,
    topOffset,
    className,
}) => {
    return (
        <StickyMenuContainer topOffset={topOffset}>
            <MenuBar.Root className={className}>{children}</MenuBar.Root>
        </StickyMenuContainer>
    );
};
