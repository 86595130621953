import * as React from 'react';
import { compose } from '@ez/tools';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import {
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    MenuBarSection,
    StickyMenuBar,
    toastError,
    VStack,
} from '@ez/components';
import ViewBrand from '../ViewBrand';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';

const BrandViewPageComp: React.FC<IAppNavigatorProps & IProductCatalogMutators & { companyId: string }> = (props) => {
    const companyId = props.AppNavigator.params.companyId;
    const handleOnDelete = async () => {
        try {
            const { brandId } = props.AppNavigator.params;
            await props.mutateBrand.delete({ id: brandId });
            props.AppNavigator.replace(`/${companyId}`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    const goBack = () => {
        props.AppNavigator.navigate(`/${companyId}`, { relativeToModule: true });
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'To Company'} />
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarDropdown icon={'bars'} color={'red'}>
                        <MenuBarDropdownItemWithConfirm
                            icon={'trash'}
                            color="orange"
                            content="Delete Brand"
                            onClick={handleOnDelete}
                            popup={{ content: 'Will be deleted' }}
                            confirm={{
                                confirmMessage: {
                                    header: 'Delete?',
                                    content: 'Will be deleted forever!',
                                },
                                negative: true,
                                confirmButton: {
                                    content: 'Delete',
                                    icon: 'trash',
                                },
                            }}
                        />
                    </MenuBarDropdown>
                </MenuBarSection>
            </StickyMenuBar>

            <ViewBrand />
        </VStack>
    );
};

export const ViewCompanyBrandDetails = compose(
    withAppNavigator(),
    withProductCatalogMutators(['BrandsList', 'QueryBrandNode'])
)(BrandViewPageComp);
