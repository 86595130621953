import * as React from 'react';
import { useMemo } from 'react';
import { ConnectionTableDef, DefaultConnectionTable, Display, Icon, Panel } from '@ez/components';
import { fromEdges, NodeType, SortDirection } from '@poolware/api';
import {
    DueDate,
    IconServiceJobRecurStatus,
    IconServiceJobStage,
    ServiceJobModuleRouterID,
    useQueryServiceJobConnection,
    useServiceJobActions,
} from '@poolware/app-service-jobs';
import _truncate from 'lodash/truncate';
import { AppColorNames, AppIconNames, IconRecurServiceCall, IconServiceCall } from '../../../../components/AppIcons';
import styled from 'styled-components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { EZCDevicesLinkWithState } from '../../common';

//TODO: DUPE: This is a duplicate of the one in /CustomerManagement/common-panels/ServiceJobHistoryPanel/PanelServiceCasesHistory.tsx
const StatusDiv = styled.div<{ isOpen?: boolean }>`
    color: ${(props) => (props.isOpen ? '#2179bd' : undefined)};
    // font-weight: ${(props) => (props.isOpen ? 'bold' : undefined)};
`;

const useTableDefRecur = (): ConnectionTableDef<NodeType.ServiceJob> => {
    return useMemo(
        () => [
            {
                header: 'ID',
                width: 1,
                cell: (serviceJob) => {
                    return (
                        <div>
                            <IconServiceJobRecurStatus serviceJob={serviceJob} />
                            {serviceJob.serviceJobNumber}
                        </div>
                    );
                },
            },
            {
                header: 'Service',
                cell: (serviceJob) => {
                    const title = _truncate(serviceJob.title || '--', { length: 60 });
                    return (
                        <div>
                            <Icon name={AppIconNames.ServiceJob.RecurringService} /> {title}
                        </div>
                    );
                },
            },
            {
                header: 'Opened',
                width: 2,
                cell: (v) => <Display.Date format={'ll'} value={v.createdAt} />,
            },
            {
                header: 'Last Appt.',
                width: 2,
                cell: (v) => {
                    return (
                        <span>
                            <Display.Date format={'ll'} value={v.lastAppointmentDate} defaultString={<i>no end</i>} />
                        </span>
                    );
                },
            },
        ],
        []
    );
};

const useTableDefSingle = (): ConnectionTableDef<NodeType.ServiceJob> => {
    return useMemo(
        () => [
            {
                sortKey: 'serviceJobNumber',
                header: 'ID',
                width: 1,
                cell: (serviceJob) => {
                    const isOpen = serviceJob.stage?.status === NodeType.ServiceJobStageStatus.Active;
                    return <StatusDiv isOpen={isOpen}>{serviceJob.serviceJobNumber}</StatusDiv>;
                },
            },
            {
                header: 'Date/Time Opened',
                sortKey: 'createdAt',
                cellProps: { verticalAlign: 'top' },
                width: 2,
                cell: (serviceJob) => {
                    const isOpen = serviceJob.stage?.status === NodeType.ServiceJobStageStatus.Active;
                    return (
                        <StatusDiv isOpen={isOpen}>
                            <Display.Date value={serviceJob.createdAt} format={'lll'} />
                        </StatusDiv>
                    );
                },
            },

            {
                header: 'Case',
                cell: (serviceJob) => {
                    const isOpen = serviceJob.stage?.status === NodeType.ServiceJobStageStatus.Active;
                    const wos = fromEdges(serviceJob.workOrders);
                    const title = _truncate(serviceJob.title || '--', { length: 60 });
                    const isRecurring = serviceJob.isRecurring;
                    const IconComp = isRecurring ? IconRecurServiceCall : IconServiceCall;
                    return (
                        <div>
                            <StatusDiv
                                isOpen={isOpen}
                                style={{
                                    marginBottom: '0.5em',
                                }}
                            >
                                <IconServiceJobStage stage={serviceJob?.stage} /> <IconComp /> {title}
                            </StatusDiv>
                            <div style={{ marginBottom: '1em' }}>
                                <div className={'grid grid-2'}>
                                    {wos.map((wo) => {
                                        const isOpen = wo.stage?.status === NodeType.ServiceJobStageStatus.Active;
                                        return (
                                            <div className={'pl-6'} key={wo.id}>
                                                <StatusDiv isOpen={isOpen}>
                                                    <IconServiceJobStage stage={wo?.stage} />
                                                    <Icon name={AppIconNames.ServiceJob.WorkOrder} />
                                                    {wo.workOrderNumber} - {_truncate(wo.title, { length: 60 })}{' '}
                                                    {wo.appointmentItem && <Icon name={'calendar alternate outline'} />}
                                                </StatusDiv>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                },
            },
            {
                header: 'Due Date',
                sortKey: 'dueDate',
                width: 2,
                cell: (sj) => {
                    return (
                        <DueDate
                            format={'LL'}
                            dueDate={sj.dueDate}
                            warnIfOverdue={sj.stage?.status === NodeType.ServiceJobStageStatus.Active}
                        />
                    );
                },
            },
        ],
        []
    );
};

export interface TabServiceCasesProps {
    device: EZCDevicesLinkWithState;
    onView?: (serviceJob: NodeType.ServiceJob) => any;
}

export const TabServiceCases: React.FC<TabServiceCasesProps> = ({ device }) => {
    const tableDef = useTableDefSingle();
    const { AppNavigator, location } = useAppNavigator();
    const { ServiceJobAction } = useServiceJobActions();

    const customer = device?.owner;
    const { connectionState, connectionData } = useQueryServiceJobConnection({
        // query: QL,
        ezcDeviceLink: device?.id,
        // relatedCustomer: customer?.id,
        // relatedPool: pool?.id,
        isRecurring: false,
        isImplicit: false,
        skip: !customer,
        defaultSortDirection: SortDirection.DES,
        defaultSortKey: 'serviceJobNumber',
    });

    const onView = (serviceJob: NodeType.ServiceJob) => {
        AppNavigator.navigate(`/sj/${serviceJob.id}`, {
            setOrigin: true,
            moduleId: ServiceJobModuleRouterID,
        });
    };

    const onNewServiceJob = () => {
        ServiceJobAction.newServiceJob({
            customer: device?.owner,
            pool: device?.pool,
            ezcDeviceLink: device,
            returnTo: location,
        });
    };

    return (
        <Panel>
            <Panel.Header
                basic={true}
                content={'Service Services'}
                button={{ content: 'New Case', icon: 'plus', color: 'green', onClick: onNewServiceJob }}
            />
            <Panel.Body>
                <DefaultConnectionTable
                    showHeader={true}
                    tableProps={{
                        color: AppColorNames.ServiceJob.ServiceCall,
                    }}
                    onRowClick={onView}
                    tableDef={tableDef}
                    connectionData={connectionData}
                    connectionState={connectionState}
                />
            </Panel.Body>
        </Panel>
    );
};
