import * as React from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMobileAppointmentCtx } from '../Root';
import { Button, PageLayout } from '@ez/components';
import { Frame, SectionHeader } from '../components';
import { calcChecklistCount } from '../PageCheckList/utils';

const ActivateJobButton: React.FC = () => {
    const { startWorkOrder } = useMobileAppointmentCtx();

    return (
        <Button
            className={'w-full my-4'}
            variant={'primary'}
            // color={'green'}
            size={'lg'}
            onClick={startWorkOrder}
            icon={'checkmark'}
        >
            Activate Job
        </Button>
    );
};

export const PageSectionJobDetails: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { workOrder, isWorkOrderPending, appointmentItem, startWorkOrder } = useMobileAppointmentCtx();

    const onViewChecklist = () => {
        AppNavigator.navigateRelative('/checklist');
    };

    const checklistCount = calcChecklistCount(workOrder);

    return (
        <>
            <SectionHeader>Job Details</SectionHeader>
            <PageLayout.BodySection vStack={true}>
                <Frame bordered={true}>
                    <div className={'test-strong font-bold'}>Job Description Notes</div>
                    <div className={'text-sm'}>{appointmentItem?.note || '--'}</div>
                </Frame>
                {isWorkOrderPending ? (
                    <ActivateJobButton />
                ) : (
                    <>
                        {checklistCount.total > 0 ? (
                            <Button
                                className={'my-4'}
                                fluid={true}
                                color={'primary'}
                                size={'lg'}
                                onClick={onViewChecklist}
                            >
                                View Checklist ({checklistCount.completed}/{checklistCount.total})
                            </Button>
                        ) : (
                            <Button className={'my-4'} fluid={true} color={'primary'} size={'lg'} disabled={true}>
                                No Checklist
                            </Button>
                        )}
                    </>
                )}
            </PageLayout.BodySection>
        </>
    );
};
