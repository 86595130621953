import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Checkbox as CheckboxSUI, CheckboxProps, SemanticIcon } from './semantic-ui';
import { CheckboxRadix } from './radix';
import { clsxm, useId } from './utils';

export const AsyncCheckbox: React.FC<CheckboxProps & { loading?: boolean; onToggle: (checked: boolean) => any }> = ({
    onToggle,
    loading,
    ...rest
}) => {
    const [isChanging, setChanging] = useState(false);
    const ref = useRef<'ref'>();
    useEffect(() => {
        ref.current = 'ref';
        return () => {
            ref.current = null;
        };
    });

    const handleChange = async (event, data) => {
        event.preventDefault();
        event.stopPropagation();
        setChanging(true);
        await onToggle?.(data.checked);
        if (ref.current) {
            setChanging(false);
        }
    };

    if (isChanging || loading) {
        return <SemanticIcon loading={true} name={'spinner'} />;
    }

    return <CheckboxSUI {...rest} onChange={handleChange} />;
};

export const Checkbox_New: React.FC<{
    label?: React.ReactNode;
    checked?: boolean;
    onCheckedChange?: (checked) => any;
    className?: string;
}> = ({ label, checked, onCheckedChange, className }) => {
    const id = useId();
    return (
        <div className={clsxm('flex flex-row items-center gap-2', className)}>
            <CheckboxRadix
                id={id}
                // className={'size-[1.5rem] mt-0.5'}
                checked={checked}
                onCheckedChange={onCheckedChange}
            />
            {label && <label htmlFor={id}>{label}</label>}
        </div>
    );
};
