import 'core-js/es';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppRootProvider, setHistoryBasename } from '@poolware/app-shell';
import { store } from './redux/store';
import App from './App';
import { WaterlinkProvider } from '@poolware/waterlink';
import { MapProvider } from './Pages/Devices/MapProvider';
import '@ez/components/css/semantic.css';
import '@poolware/react-datepicker/dist/react-datepicker.min.css';
import '@poolware/react-big-calendar/dist/css/react-big-calendar.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import './styles/index.scss';

if (process.env.REACT_APP_ROUTER_BASENAME) {
    setHistoryBasename(process.env.REACT_APP_ROUTER_BASENAME);
}

ReactDOM.render(
    <AppRootProvider reduxStore={store}>
        <MapProvider>
            <WaterlinkProvider>
                <App />
            </WaterlinkProvider>
        </MapProvider>
    </AppRootProvider>,
    document.getElementById('root')
);
