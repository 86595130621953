import { createThemeByHue } from '@ez/components';

let _t = undefined;

export const getTheme = (publicPrefix: string) => {
    if (_t) {
        return _t;
    }

    const huePrim = 232;
    const hueSec = 183;
    const primDavey = '#FFD200';
    const accentDavey = '#00747A';
    _t = createThemeByHue({
        publicPrefix,
        huePrim: huePrim,
        primSat: 10,
        hueSec: hueSec,
        customisation: {
            themeName: 'davey',
            iconPath: `${publicPrefix}/icon.png`,
            logoPath: `${publicPrefix}/logo.jpg`,
            text: {
                color: {
                    primary: `hsl(${huePrim}, 10%, 20%)`,
                },
            },
            button: {
                color: {
                    primary: `hsl(${huePrim}, 10%, 25%)`,
                    primaryHover: `hsl(${huePrim}, 18%, 75%)`,
                    secondary: `hsl(${hueSec}, 25%, 50%)`,
                    tertiary: `hsl(${huePrim}, 10%, 85%)`,
                },
            },
            sidebar: {
                backgroundColor: '#000000',
                menuItem: {
                    backgroundColor: '#44475a',
                    backgroundColorHover: '#423f30',
                    backgroundColorActive: '#423f30',
                    textColor: '#f8f8e3',
                    content: {
                        ascentColorActive: 'hsl(49,100%,50%)',
                    },
                },
                menuGroup: {
                    separatorColor: 'hsl(49,100%,10%)',
                },
            },
            navbar: {
                backgroundColor: '#000000',
                separatorColor: 'hsl(49,100%,50%)',
                menuItem: {
                    backgroundColor: '#000000',
                    backgroundColorHover: '#6272a4',
                    backgroundColorActive: '#44475a',
                    textColor: '#f8f8f2',
                },
                logo: {
                    backgroundColor: '#000000',
                },
            },
            // Disabled for now
            border: {
                radiusSm: '0.375rem',
                radius: '0.5rem',
                radiusMd: '0.75rem',
                radiusLg: '1.0rem',
                radiusXl: '1.25rem',
                radius2xl: '1.5rem',
                radius3xl: '2.0rem',
                color: `hsl(${huePrim}, 10%, 85%)`,
                colorStrong: `hsl(${huePrim}, 40%, 80%)`,
            },
            main: {
                backgroundColor: `hsl(210, 0%, 90%)`,
                // backgroundColor: '#cc4747',
            },
        },
    });

    return _t;
};
