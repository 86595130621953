import * as React from 'react';
import { ServiceJobsTable } from './ServiceJobsTable';
import { NodeType } from '@poolware/api';
import {
    MenuBar,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    PageLayout,
    PopupProps,
    ScrollableLayout,
    useStateSidebar,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { FilterBar } from './FilterBar';
import { ToolbarQuickFilters } from './ToolbarQuickFilters';

const popupSingleService: PopupProps = {
    header: 'One-off Service Case',
    content: 'Non periodic, single time service',
    position: 'right center',
};

const Page: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { showSidebar, setShowSidebar } = useStateSidebar('service-jobs.sj-list.sidebar');

    const onView = (serviceJob: NodeType.ServiceJob) => {
        AppNavigator.navigate(`/sj/single/${serviceJob.id}`, {
            relativeToModule: true,
        });
    };

    const onNewSingle = () => {
        AppNavigator.navigateRelative('/sj/single/new', {
            setOrigin: true,
            modal: false,
        });
    };

    const barGroupItemConf: MenuBarGroupProps = {
        menu: { menuMode: MenuBarGroupMODE.DROPDOWN_ON_MOBILE, icon: 'plus', color: 'green' },
        items: [
            {
                color: 'green',
                icon: 'plus',
                onClick: onNewSingle,
                content: 'New Case',
                popup: popupSingleService,
            },
        ],
    };

    const LS = (
        <ScrollableLayout>
            <ScrollableLayout.MenuBar>
                <MenuBar.Section position={'left'}>
                    <MenuBar.HeaderItem color={'grey'} icon={'filter'}>
                        Filters
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item onClick={() => setShowSidebar(false)} icon={'cancel'} color={'grey'} />
                </MenuBar.Section>
            </ScrollableLayout.MenuBar>
            <ScrollableLayout.BodyScroll>
                <div className={'px-2 py-4'}>
                    <FilterBar />
                </div>
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );

    const Main = (
        <ScrollableLayout block={false}>
            <div className={'z-10'}>
                <MenuBar.Root>
                    <MenuBar.Section>
                        {/*{!showSidebar && <MenuBar.Item icon={'filter'} onClick={() => setShowSidebar(true)} />}*/}
                        <MenuBar.HeaderItem color={ModuleColorNames.ServiceCall} icon={ModuleIconNames.ServiceCall}>
                            Service Cases
                        </MenuBar.HeaderItem>
                    </MenuBar.Section>
                    <MenuBar.Section position={'right'}>
                        <MenuBar.Group {...barGroupItemConf} />
                    </MenuBar.Section>
                </MenuBar.Root>
            </div>

            <ToolbarQuickFilters setShowSidebar={setShowSidebar} showSidebar={showSidebar} />

            <ScrollableLayout.BodyScroll>
                <ServiceJobsTable onView={onView} />
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );

    return (
        <PageLayout.ThreeColumnManaged
            leftSidebar={LS}
            main={Main}
            showLeft={showSidebar}
            onCloseLeft={() => setShowSidebar(false)}
        />
    );
};

export default Page;
