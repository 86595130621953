import * as React from 'react';
import { NodeType } from '@poolware/api';
import { withFormikSuggestInput } from '@ez/components';
import { createSuggestInputWithQuery } from './create-connected-suggest-input';
import gql from 'graphql-tag';

const QL = gql`
    query FranchisesList_Filter($first: Int, $page: Int, $search: FranchiseViewerFranchisesSearch) {
        viewer {
            franchises(first: $first, page: $page, search: $search) {
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        id
                        name
                        types
                        organisationType {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const SuggestInputFranchise = createSuggestInputWithQuery<
    NodeType.Franchise,
    NodeType.FranchiseViewerFranchisesSearch
>({
    gqlQuery: QL,
    gqlQueryConnectionPath: 'viewer.franchises',
    formatOptionLabel: (item) => {
        return (
            <span className={'text-sm'}>
                {item.name} (<span className={'text-sm text-tertiary'}>{item.organisationType?.name}</span>)
            </span>
        );
    },
    getOptionLabel: (item) => item.name,
    mapQueryToSearch: (query) => {
        return {
            anyString: [query],
        };
    },
});

export const FormikSuggestInputFranchise = withFormikSuggestInput(SuggestInputFranchise);
