import * as React from 'react';
import { useMemo } from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { FormFieldSuggestInput, FormFieldSuggestInputProps, withFormikSuggestInput } from '@ez/components';
import { useQueryServiceJobGroupConnection } from '../queries/use-query-service-job-group-connection';

export const ServiceJobGroupSuggestInput: React.FC<FormFieldSuggestInputProps<NodeType.ServiceJobGroup>> = ({
    onChange,
    ...rest
}) => {
    const { connectionState, connectionData } = useQueryServiceJobGroupConnection();

    const getOptionLabel = (item: NodeType.ServiceJobGroup): string => {
        if (item?.parent) {
            return `${item?.parent?.title} > ${item?.title}`;
        }
        return item?.title;
    };

    const flattenedGroups = useMemo(() => {
        return connectionData
            .reduce((acc, g) => {
                acc.push(g);
                const ch = fromEdges(g?.children);
                acc.push(...ch);
                return acc;
            }, [])
            .map((g) => ({ value: g, text: getOptionLabel(g) }));
    }, [connectionData]);

    const onItemSelect = (item: NodeType.ServiceJobGroup) => {
        onChange?.(item);
    };

    const formatOptionLabel = (item: NodeType.ServiceJobGroup) => {
        if (item.parent) {
            return (
                <span>
                    <i>{item?.parent?.title}</i> {'>'} {item?.title}
                </span>
            );
        }
        // else {
        //     return <span className={'pl-6'}> - {item?.title}</span>;
        // }

        return <>{item?.title}</>;
    };

    return (
        <FormFieldSuggestInput
            formatOptionLabel={formatOptionLabel}
            options={flattenedGroups}
            loading={connectionState.loading}
            onItemSelect={onItemSelect}
            getOptionValue={(item) => item?.id}
            {...rest}
        />
    );
};

export const FormikServiceJobGroupSuggestInput = withFormikSuggestInput(ServiceJobGroupSuggestInput);
