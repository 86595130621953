import { css } from 'twin.macro';
import { ThemeInterface } from './theme.interface';

export const generateCssVarsMap = (obj: ThemeInterface) => {
    // console.log('Generating CSS vars map for theme', obj.themeName);

    const fieldExceptions = {
        primary_25: 'primary-25',
    };

    const camelToSnakeCase = (str) => {
        if (fieldExceptions[str]) {
            return fieldExceptions[str];
        }
        return str.replace(/[A-Z0-9]/g, (letter) => `-${letter.toLowerCase()}`);
    };

    function flattenObj(obj, parent, res = {}) {
        for (let key in obj) {
            let propName = parent ? parent + '-' + camelToSnakeCase(key) : key;
            if (typeof obj[key] == 'object') {
                flattenObj(obj[key], propName, res);
            } else {
                res[propName] = obj[key];
            }
        }
        return res;
    }

    const varMap = flattenObj(obj, '--ez');
    // Custom variable. Used in semantic css.
    varMap['--ez-font-size-base'] = obj?.text?.size?.base;
    varMap['--ez-border-radius-default'] = obj?.border?.radius;
    return varMap;
};

// These variables are used in Fomantic CSS, Tailwind css and EZ Components
export const getThemeCssVars = () => {
    return css`
        :root {
            ${(props) => {
                const vars = generateCssVarsMap(props.theme);
                let cssVars = '';
                for (const key in vars) {
                    cssVars += `${key}: ${vars[key]};\n`;
                }
                return cssVars;
            }}
`;
};
