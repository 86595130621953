import { CalendarPickerButton, Optionable, SegmentedSelectButtons } from '@ez/components';
import moment from 'moment';
import * as React from 'react';

export enum DisplayRangeEnum {
    RANGE_12h = '12h',
    RANGE_1D = '1d',
    RANGE_3D = '3d',
    RANGE_1W = '1w',
    RANGE_1M = '1M',
    RANGE_3M = '3M',
    RANGE_ALL = 'all',
}

interface DateRangePickerProps {
    rangeType: DisplayRangeEnum;
    date: Date;
    onChange?: (rangeType: DisplayRangeEnum, date: Date) => void;
}

enum JumpDirection {
    LEFT = '_JUMP_LEFT_',
    RIGHT = '_JUMP_RIGHT_',
}

const rangeOptions: Optionable<DisplayRangeEnum | JumpDirection>[] = [
    {
        text: '',
        icon: 'chevron left',
        value: JumpDirection.LEFT,
    },
    {
        text: '12h',
        value: DisplayRangeEnum.RANGE_12h,
    },
    {
        text: '1d',
        value: DisplayRangeEnum.RANGE_1D,
    },
    {
        text: '3d',
        value: DisplayRangeEnum.RANGE_3D,
    },
    {
        text: '1w',
        value: DisplayRangeEnum.RANGE_1W,
    },
    {
        text: '1M',
        value: DisplayRangeEnum.RANGE_1M,
    },
    {
        text: '',
        icon: 'chevron right',
        value: JumpDirection.RIGHT,
    },
];

export const DateRangePickerButton: React.FC<DateRangePickerProps> = ({ rangeType, date, onChange }) => {
    const handleArrowClick = (direction: JumpDirection) => {
        let newDate = moment(date);
        switch (rangeType) {
            case DisplayRangeEnum.RANGE_12h:
                newDate = moment(date).add(direction === JumpDirection.LEFT ? -12 : 12, 'h');
                break;
            case DisplayRangeEnum.RANGE_1D:
                newDate = moment(date).add(direction === JumpDirection.LEFT ? -1 : 1, 'd');
                break;
            case DisplayRangeEnum.RANGE_3D:
                newDate = moment(date).add(direction === JumpDirection.LEFT ? -3 : 3, 'd');
                break;
            case DisplayRangeEnum.RANGE_1W:
                newDate = moment(date).add(direction === JumpDirection.LEFT ? -1 : 1, 'w');
                break;
            case DisplayRangeEnum.RANGE_1M:
                newDate = moment(date).add(direction === JumpDirection.LEFT ? -1 : 1, 'M');
                break;
        }

        // check if new date is in the future
        if (newDate.isAfter(moment())) {
            newDate = moment();
        }

        onChange?.(rangeType, newDate.toDate());
    };

    return (
        <div className={'flex flex-row gap-1'}>
            <SegmentedSelectButtons
                options={rangeOptions}
                value={rangeType}
                onChange={(o) => {
                    if (o.value === '_JUMP_LEFT_') {
                        return handleArrowClick(JumpDirection.LEFT);
                    } else if (o.value === '_JUMP_RIGHT_') {
                        return handleArrowClick(JumpDirection.RIGHT);
                    } else {
                        onChange(o.value, date);
                    }
                }}
            />
            <CalendarPickerButton
                selected={date}
                calendarProps={{
                    disabled: {
                        after: moment().toDate(),
                    },
                }}
                onSelect={(newDate) => {
                    onChange?.(rangeType, newDate);
                }}
            />
        </div>
    );
};
