import styled, { createGlobalStyle } from 'styled-components';
import { LayoutState } from './AppLayoutManager';

export interface LayoutConf {
    layoutState: LayoutState;
    sidebar: {
        width: string;
        widthMini: string;
        widthMobile: string;
    };
    navbar: {
        height: string;
    };
    layoutMask: {
        backgroundColor: string;
    };
    desktopBreakpoint: number;
}

const transition = (transitionString: string) => (props: LayoutConf) => {
    if (!props.layoutState.animationEnabled) return null;
    return `
        -moz-transition: ${transitionString};
        -o-transition: ${transitionString};
        -webkit-transition: ${transitionString};
        transition: ${transitionString};
    `;
};
const transitionDesktopOnly = (transitionString: string) => (props: LayoutConf) => {
    if (!props.layoutState.isDesktop) return null;
    return transition(transitionString)(props);
};

const transitionMobileOnly = (transitionString: string) => (props: LayoutConf) => {
    if (props.layoutState.isDesktop) return null;
    return transition(transitionString)(props);
};

const getSidebarWidth = (props: LayoutConf) => {
    if (props.layoutState.mini) {
        return props.sidebar.widthMini;
    } else {
        return props.sidebar.width;
    }
};

const getSideBarVarValue = (props: LayoutConf) => {
    if (!props.layoutState.isDesktop) {
        return 0;
    } else {
        return getSidebarWidth(props);
    }
};

export const LayoutWrapperGlobalStyle = createGlobalStyle`
    :root  {
        --sideBar: ${getSideBarVarValue};
        --topBar: 46px;
    }
`;

export const LayoutWrapper = styled.div<LayoutConf>`
    padding: 0;
    min-height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    #sidebar {
        top: 0;
        bottom: 0;
        position: fixed;
        height: 100%;
        z-index: 899;
        user-select: none;
        width: var(--sideBar, 180px);
        left: 0;
    }

    // #header {
    //     height: ${(props) => props.navbar.height};
    //     position: fixed;
    //     z-index: 897;
    //     right: 0;
    //     top: 0;
    //     ${transitionDesktopOnly('left 0.25s')};
    //     .hamburger {
    //         display: block;
    //     }
    // }

    //#header,
    #content,
    #footer {
        left: var(--sideBar, 180px);
    }

    #content {
        z-index: unset; /// DON'T SET Z-INDEX. It will clip all children to #content's boundaries.
        padding-top: ${(props) => props.navbar.height};
        ${transitionDesktopOnly('margin-left 0.25s')};
        //flex-grow: 1;
        //display: flex;
    }

    .layout-mask {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 898;
        background-color: ${(props) => props.layoutMask.backgroundColor};
        user-select: none;
        overflow: hidden;
        opacity: ${(props) => (!props.layoutState.mobileCollapsed ? '0.4' : '0')};
        visibility: hidden;
        ${transitionMobileOnly('opacity 0.25s')};
    }

    /* desktop */
    @media (min-width: ${(props) => props.desktopBreakpoint + 1}px) {
        #sidebar {
            width: var(--sideBar, 180px);
        }
        #content,
        #footer {
            margin-left: var(--sideBar, 180px);
        }
        #header {
            left: var(--sideBar, 180px);
        }
    }

    /* mobile */
    @media (max-width: ${(props) => props.desktopBreakpoint}px) {
        #sidebar {
            ${transitionMobileOnly('left 0.25s')};
            left: ${(props) =>
                !props.layoutState.mobileCollapsed ? 0 : `calc(-100px - ${props.sidebar.widthMobile})`};
            width: ${(props) => props.sidebar.widthMobile};
            box-shadow: ${(props) => !props.layoutState.mobileCollapsed && '10px 0 20px 8px rgba(0, 0, 0, 0.2)'};
        }

        #header,
        #content,
        #footer {
            left: 0;
            user-select: ${(props) => !props.layoutState.mobileCollapsed && 'none'};
        }

        .layout-mask {
            visibility: ${(props) => !props.layoutState.mobileCollapsed && 'visible'};
        }
    }

    @media print {
        .layout-mask,
        #header,
        #footer,
        #sidebar {
            display: none !important;
        }
        #content {
            margin: 0;
            padding: 0;
        }
    }
`;
