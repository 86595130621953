import * as React from 'react';
import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { FormikFormFieldLabel } from './FormikFormFieldLabel';
import { useDebouncedCallback } from '@ez/tools';
import { FormField, FormFieldLabel } from '../form-components';
import { Input, InputFile, InputProps } from '../radix';
import { Icon } from '../Icon';
import { FormikFieldProps } from './FormikTypes';
import { useId } from '../utils';

export type FormikFieldOptionType = {
    key: string | number;
    value: any;
    text: string;
};

export interface FormikInputFieldProps extends Omit<InputProps, 'name'>, FormikFieldProps {
    datalist?: FormikFieldOptionType[];
    options?: FormikFieldOptionType[]; // Same as datalist
    subLabel?: React.ReactNode | string;
    subLabelStyle?: any;
    debounceMs?: number;
    actionButton?: React.ReactNode;
    onDidChange?: (newValue: any, fieldName: string) => any;

    actionRight?: React.ReactNode;

    //  HACK:
    // labelPosition?: any;
    size?: any;
    fluid?: boolean;
}

export const FormikInputField = React.forwardRef<HTMLInputElement, FormikInputFieldProps>(
    (
        {
            dataTestId,
            datalist,
            options,
            label,
            name,
            required,
            className,
            subLabel,
            debounceMs = 0,
            subLabelStyle,
            actionButton,
            onDidChange,
            disabled,
            readOnly,
            actionRight,
            id,
            ...rest
        },
        ref
    ) => {
        const fieldId = useId(id);
        const opts = datalist || options;
        const datalistname = opts?.length ? `datalist-${name}` : undefined;
        const [field, meta, helpers] = useField({ name: name });

        let value = field?.value;
        if (value === null || value === undefined) {
            value = '';
        }
        const [presVal, setPresValue] = useState(value);

        useEffect(() => setPresValue(value), [value]);

        const setValue = async (newValue) => {
            await helpers.setValue(newValue);
            onDidChange?.(newValue, name);
        };

        const handleChange = (e) => {
            setPresValue(e.target.value);
            if (debounceMs > 1) {
                debouncedHandleChange(e.target.value);
            } else {
                setValue(e.target.value);
            }
        };

        const [debouncedHandleChange] = useDebouncedCallback((value) => {
            setValue(value);
        }, debounceMs);

        const touched = meta.touched;
        const error = meta.error;
        let hasError = touched && !!error;

        const isDisabled = disabled || readOnly;
        return (
            <FormField className={className}>
                <FormFieldLabel
                    readOnly={readOnly}
                    disabled={disabled}
                    required={required}
                    label={label}
                    errorLabel={hasError && error}
                    htmlFor={fieldId}
                />
                <div className={'flex flex-row w-full'}>
                    <Input
                        autoComplete="off"
                        {...rest}
                        ref={ref}
                        list={datalistname}
                        onBlur={field.onBlur}
                        aria-invalid={hasError}
                        id={fieldId}
                        // name={name}
                        value={presVal}
                        onChange={handleChange}
                        data-testid={dataTestId}
                        disabled={isDisabled}
                        readOnly={readOnly}
                        className={actionRight && 'border-r-0 rounded-r-none'}
                    />
                    {actionRight}
                </div>
                {opts?.length > 0 && (
                    <datalist id={datalistname}>
                        {opts.map((d, index) => (
                            <option key={d.key || index} value={d.value} />
                        ))}
                    </datalist>
                )}
            </FormField>
        );
    }
);

export const FormikFileInput: React.FC<FormikFieldProps> = ({ id, label, name, required, ...props }) => {
    const fieldId = useId(id);
    const [field, meta, helpers] = useField(name);
    const touched = meta.touched;
    const error = meta.error;
    let hasError = touched && !!error;
    return (
        <FormField>
            <FormikFormFieldLabel htmlFor={fieldId} label={label} name={name} required={required} />
            <InputFile
                className={'p-0'}
                id={fieldId}
                aria-invalid={hasError}
                {...props}
                onChange={(event) => {
                    helpers.setValue(event.currentTarget.files[0]);
                }}
            />
        </FormField>
    );
};

// password form input filed with toggleable password visibility
export const FormikFieldPasswordInput: React.FC<FormikInputFieldProps> = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);

    return (
        <FormikInputField
            type={showPassword ? 'text' : 'password'}
            iconRight={
                <Icon
                    className={'hover:bg-accent rounded-full cursor-pointer text-tertiary'}
                    name={showPassword ? 'eye slash' : 'eye'}
                    onClick={togglePassword}
                />
            }
            {...props}
        />
    );
};
