import * as React from 'react';
import { Checkbox, Table } from '../semantic-ui';
import { TableRowClickable } from '../connection-table';

export interface FormTableRowWithRadioboxProps {
    label?: string | React.ReactNode;
    onClick: () => any;
    isSelected?: boolean;
    readOnly?: boolean;
}
export const FormTableRowWithRadiobox: React.FC<FormTableRowWithRadioboxProps> = ({
    label,
    onClick,
    isSelected,
    children,
    readOnly,
}) => {
    const rowStyle = {
        backgroundColor: isSelected ? '#dee4e7' : undefined,
    };
    const handleClick = () => {
        onClick?.();
    };

    return (
        <TableRowClickable onClick={handleClick} style={rowStyle} isSelectable={!readOnly}>
            <Table.Cell>
                <Checkbox radio checked={isSelected} readOnly={readOnly} disabled={readOnly} />
                <span className={'pl-2'}>{label}</span>
            </Table.Cell>
            {children}
        </TableRowClickable>
    );
};
