import * as React from 'react';
import { useField } from 'formik';
import { FormikFormFieldLabel } from './FormikFormFieldLabel';
import { FormField } from '../form-components';
import { Textarea, TextareaProps } from '../radix';
import { useId } from '../utils';
import { FormikFieldProps } from './FormikTypes';

export const FormikTextareaField: React.FC<TextareaProps & FormikFieldProps> = ({
    label,
    name,
    required,
    dataTestId,
    disabled,
    readOnly,
    iconLeft = null,
    id,
    ...rest
}) => {
    const fieldId = useId(id);
    const [field, meta, helpers] = useField(name);

    let hasError = meta.touched && !!meta.error;
    const isDisabled = disabled || readOnly;

    return (
        <FormField>
            <FormikFormFieldLabel htmlFor={fieldId} label={label} name={name} required={required} />
            <Textarea
                aria-invalid={hasError}
                data-testid={dataTestId}
                disabled={isDisabled}
                id={fieldId}
                iconLeft={iconLeft /*<StickyNote />*/}
                {...field}
                {...rest}
            />
        </FormField>
    );
};
