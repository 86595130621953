import gql from 'graphql-tag';
import { NodeType } from '@poolware/api';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { OperationVariables } from '@apollo/react-common';
import { useMemo } from 'react';

const QL = gql`
    query ($ids: [ID!]!) {
        viewer {
            resolveEZCDeviceStates(devices: $ids) {
                state
                definitionId
                device {
                    id
                    deviceId
                    ezcId
                }
            }
        }
    }
`;

export function useQueryEzcDeviceStates<TVariables = OperationVariables>(
    deviceLinks: NodeType.EZCDeviceLink[],
    options?: QueryHookOptions<any, TVariables>
) {
    const ids = useMemo(() => deviceLinks.map((deviceLink) => deviceLink.device?.id).filter(Boolean), [deviceLinks]);

    const variables = {
        ids: ids,
    };

    const { data, ...rest } = useQuery<NodeType.Query>(QL, {
        variables: variables,
        ...options,
    });

    const deviceStates: NodeType.EZCDeviceState[] = data?.viewer?.resolveEZCDeviceStates;

    return {
        deviceStates,
        ...rest,
    };
}
