import * as React from 'react';
import { ButtonWithPopup, ButtonWithPopupProps } from './ButtonWithPopup';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../radix';
import { Icon } from '../Icon';

export interface DropdownMenuOptionType {
    key?: number | string;
    icon?: string;
    content?: React.ReactNode;
    value?: boolean | number | string;
    locked?: boolean | string;
    disabled?: boolean;
    onClick?:
        | ((option: Omit<DropdownMenuOptionType, 'onClick'>) => void | Promise<void> | undefined)
        | undefined
        | null;
}

export interface ButtonWithDropdownMenuProps extends ButtonWithPopupProps {
    options: DropdownMenuOptionType[];
}

export const ButtonWithDropdownMenu: React.FC<ButtonWithDropdownMenuProps> = ({
    options,
    iconRight,
    showLoaderOnClick,
    disabled,
    ...props
}) => {
    const defaultIcon = iconRight ?? 'chevron down';
    const [isUpdating, setIsUpdating] = React.useState(false);

    const handleOptionClick = async (option: DropdownMenuOptionType) => {
        try {
            if (showLoaderOnClick) {
                setIsUpdating(true);
            }
            await option.onClick?.(option);
            setIsUpdating(false);
        } catch (e) {
            setIsUpdating(false);
            throw e;
        }
    };

    const _rightIcon = isUpdating ? <Icon name={'spinner'} loading={true} /> : defaultIcon;

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <ButtonWithPopup iconRight={_rightIcon} disabled={disabled || isUpdating} {...props} />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                {options.map((option, index) => {
                    return (
                        <DropdownMenuItem
                            key={option.key ?? index}
                            onClick={(e) => handleOptionClick(option)}
                            disabled={!!option.locked || option.disabled}
                            className={'gap-2 min-w-[4rem] px-4'}
                        >
                            {option.locked && <Icon name={'lock'} />}
                            {option.icon && <Icon name={option.icon} />}
                            {option.content}
                        </DropdownMenuItem>
                    );
                })}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
