import * as React from 'react';
import { JSONView, Modal, Panel, useModalCtrl } from '@ez/components';
import { DisplayLGTimer } from './DisplayLGTimer';
import { DeviceStateType, LifeGuardShadowType } from '../../../../common';
import { DeviceContextProps } from '../types';
import { getComponentAttributesModel } from '../AttributeRenderer/common';
import { FromEditSchedules } from './FromEditSchedules';

const mapDeviceScheduleToAttributesSet = (state: DeviceStateType<LifeGuardShadowType>) => {
    const timer0 = getComponentAttributesModel(state, 'timer0', { type: 'TIMER', readOnly: false });
    const timer1 = getComponentAttributesModel(state, 'timer1', { type: 'TIMER', readOnly: false });
    return { timer0, timer1 };
};

export const PanelSchedules: React.FC<DeviceContextProps> = ({ device, refetchQueries }) => {
    const modalCtrl = useModalCtrl(false);
    const attributes = mapDeviceScheduleToAttributesSet(device.deviceState?.state?.lifeguard);
    return (
        <>
            <Panel>
                <Panel.Header
                    content={'Schedules'}
                    button={{
                        content: 'Change',
                        onClick: () => modalCtrl.onOpen(),
                    }}
                />
                <Panel.Body>
                    <Panel.Item label={'Timer 1'}>
                        <DisplayLGTimer timerAttr={attributes?.timer0} />
                    </Panel.Item>
                    <Panel.Item label={'Timer 2'}>
                        <DisplayLGTimer timerAttr={attributes?.timer1} />
                    </Panel.Item>
                </Panel.Body>

                <Modal {...modalCtrl} centered={true} size={'small'}>
                    <FromEditSchedules
                        device={device}
                        refetchQueries={refetchQueries}
                        timerAttrs={attributes}
                        // timerAttrs={{} as any}
                        onDone={modalCtrl.onClose}
                    />
                </Modal>
                {false && <JSONView data={attributes} />}
            </Panel>
        </>
    );
};
