import * as React from 'react';
import {
    DebugJsonButton,
    JSONView,
    MenuBar,
    Optionable,
    PageLayout,
    SegmentedSelectButtons,
    StickyMenuBar,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType } from '@poolware/api';
import { useViewer } from '@poolware/app-shell';
import * as URLBuilder from '../../../routes/url-builder';
import { usePersistedState } from '@ez/tools';
import { LayoutTable } from './LayoutTable';
import { LayoutCards } from './LayoutCards';
import { LayoutMap } from './LayoutMap';
import { resolveCoordinates } from './LayoutMap/data';
import { EZCDevicesLinkWithState, useQueryDevices } from '../common';
import { LayoutViewProps } from './types';
import { useEffect } from 'react';

export interface PageProps {}

type ViewMode = 'cards' | 'table' | 'map';

const layoutOptions: Optionable<ViewMode>[] = [
    {
        value: 'table',
        icon: 'list layout',
        text: '',
    },
    {
        value: 'cards',
        icon: 'grid layout',
        text: '',
    },
    {
        value: 'map',
        icon: 'map',
        text: '',
    },
];

export const ListPage: React.FC<PageProps> = () => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess } = useViewer();
    const [favoriteDevices, setFavoriteDevices] = usePersistedState<string[]>('pw.devices.favorites', []);
    const [viewMode, setViewMode] = usePersistedState<ViewMode>('pw.devices.list-mode', 'table');

    const { connectionData, connectionState, refetchQuery } = useQueryDevices({
        sortFirst: favoriteDevices,
        pageSize: 30,
    });

    const onView = (deviceLink: NodeType.EZCDeviceLink) => {
        AppNavigator.navigateRelative(`/${deviceLink.id}`);
    };

    const handleViewModeChange = (value: ViewMode) => {
        setViewMode(value);
    };

    const onNew = () => AppNavigator.navigate(URLBuilder.Devices().new);

    const data = connectionData.map((device) => {
        return {
            ...device,
            coordinates: resolveCoordinates(device),
        };
    });

    const onFavoriteChange = async (device: EZCDevicesLinkWithState, isFav: boolean) => {
        console.log('onFavoriteChange', device.id, isFav);

        if (isFav) {
            setFavoriteDevices([...favoriteDevices, device.id]);
        } else {
            setFavoriteDevices(favoriteDevices.filter((id) => id !== device.id));
        }
    };

    const layoutViewProps: LayoutViewProps = {
        onView,
        onNew,
        connectionData: data,
        connectionState,
        favoriteDevices: favoriteDevices,
        onFavoriteChange,
    };

    return (
        <PageLayout width={'full'}>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.HeaderItem>Managed Devices</MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <DebugJsonButton data={connectionData} />
                    <MenuBar.Item
                        icon={'plus'}
                        content={'Add Device'}
                        color={'green'}
                        locked={!modulesAccess?.EZConnectIntegration?.accessDeviceRegistration}
                        onClick={onNew}
                    />
                    <MenuBar.Item asChild={true}>
                        <SegmentedSelectButtons
                            fluid={false}
                            color={'tertiary'}
                            size={'sm'}
                            onChange={(o) => handleViewModeChange(o.value)}
                            value={viewMode}
                            options={layoutOptions}
                        />
                    </MenuBar.Item>
                </MenuBar.Section>
            </StickyMenuBar>

            {viewMode === 'table' && <LayoutTable {...layoutViewProps} />}
            {viewMode === 'cards' && <LayoutCards {...layoutViewProps} />}
            {viewMode === 'map' && <LayoutMap {...layoutViewProps} />}

            {/*<JSONView data={favoriteDevices} />*/}
        </PageLayout>
    );
};
