import { REST } from './types';

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

function status(response) {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(new Error(response.statusText));
    }
}

function json(response) {
    return response.json();
}

const fetchForJson = async (endpoint, data: RequestInit = {}, payload?: any) => {
    if (payload) {
        data = {
            ...data,
            body: JSON.stringify(payload),
        };
    }

    const response = await fetch(endpoint, {
        ...defaultFetchProps,
        ...data,
    });
    handleErrors(response);
    await status(response);
    return (await response.json()).data;
};

// const delay = (ms) =>
//     new Promise(resolve => setTimeout(resolve, ms));

const defaultFetchProps: RequestInit = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
    credentials: 'include',
    mode: 'cors',
};

export interface FileNode {
    /**
     * File key, for HTTP requests
     */
    fileKey: string;
    /**
     * Full url location if provided
     */
    location?: string;
    /**
     * GraphQL ID
     */
    id: string;
}

export class PoolwareAPIClient {
    apiBaseUrl = null;

    constructor(apiEndpoint) {
        this.apiBaseUrl = apiEndpoint;
    }

    public login = async (username, password) => {
        const endpoint = this.apiBaseUrl + '/auth/login';

        let data = new FormData();
        const payload = {
            username: username,
            password: password,
        };
        data.append('json', JSON.stringify(payload));

        return fetch(endpoint, {
            ...defaultFetchProps,
            method: 'POST',
            body: JSON.stringify(payload),
        })
            .then(handleErrors)
            .then(status)
            .then(json)
            .then(function (resJson) {
                return resJson.data;
            });
    };

    public logout = async () => {
        const endpoint = this.apiBaseUrl + '/auth/logout';
        return fetch(endpoint, {
            ...defaultFetchProps,
            method: 'POST',
        })
            .then(handleErrors)
            .then(status)
            .then(json)
            .then(function (resJson) {
                return resJson.data;
            });
    };

    public fetchMyProfile = async (): Promise<REST.Resp<REST.UserProfile>> => {
        const endpoint = this.apiBaseUrl + '/resource/user/me';
        return fetch(endpoint, defaultFetchProps)
            .then(handleErrors)
            .then(status)
            .then(json)
            .then(function (resJson) {
                return resJson;
            });
    };

    public resetPassword = async (email: string): Promise<REST.Resp<any>> => {
        const endpoint = this.apiBaseUrl + '/auth/password_reset';

        let data = new FormData();
        const payload = {
            email: email,
        };
        data.append('json', JSON.stringify(payload));

        return fetch(endpoint, {
            ...defaultFetchProps,
            method: 'PUT',
            body: JSON.stringify(payload),
        })
            .then(function (response) {
                if (response.status >= 500) {
                    return Promise.reject(new Error(response.statusText));
                } else {
                    return Promise.resolve(response);
                }
            })
            .then(json)
            .then(function (resJson) {
                if (resJson?.code != 0) {
                    return Promise.reject(new Error(resJson?.message));
                }
                return resJson;
            });
    };

    public uploadFile = async (
        file: File,
        fields: { note?: string; beforeAfterStatus?: 'AFTER' | 'BEFORE' }
    ): Promise<FileNode> => {
        const endpoint = this.apiBaseUrl + '/upload';
        const formData = new FormData();
        if (fields?.note) {
            formData.append('note', fields?.note);
        }
        if (fields?.beforeAfterStatus) {
            formData.append('beforeAfterStatus', fields?.beforeAfterStatus);
        }

        // MUST BE THE LAST FIELD IN THE FormData
        formData.append('file', file, file.name);

        const result = await fetch(endpoint, {
            ...defaultFetchProps,
            method: 'POST',
            body: formData,
            headers: {
                Accept: 'application/json',
                // "Content-Type": 'multipart/form-data'
            },
        })
            .then(handleErrors)
            .then(status)
            .then(json);
        if (result.data) {
            return result.data;
        }
        throw new Error('Upload failed');
    };

    public uploadProductDocumentFile = async (doc: {
        file: File;
        type: any;
        isPublic?: string;
        note?: string;
        organisationType?: string;
        franchise?: string;
        public?: string;
        brand?: string;
        company?: string;
        title?: string;
    }): Promise<{ fileKey: string; id: string; location: string }> => {
        const endpoint = this.apiBaseUrl + '/upload-product-document';
        const formData = new FormData();

        const { file } = doc;
        if (doc.title) {
            formData.append('title', doc.title);
        }
        if (doc.brand) {
            formData.append('title', doc.brand);
        }
        if (doc.type) {
            formData.append('type', doc.type);
        }
        if (doc.organisationType) {
            formData.append('organisationType', doc.organisationType);
        }
        if (doc.franchise) {
            formData.append('franchise', doc.franchise);
        }

        // MUST BE THE LAST FIELD IN THE FormData
        formData.append('file', file, file.name);
        const result = await fetch(endpoint, {
            ...defaultFetchProps,
            method: 'POST',
            body: formData,
            headers: {
                Accept: 'application/json',
                // "Content-Type": 'multipart/form-data'
            },
        })
            // .then(handleErrors)
            .then(status)
            .then(json);
        if (result.data) {
            return result.data;
        }
        throw new Error('Upload failed');
    };

    public signupFranchise = async (payload: REST.SignupRequest): Promise<REST.SignupResponse> => {
        const endpoint = this.apiBaseUrl + '/auth/franchise/register';

        return fetchForJson(endpoint, { method: 'POST' }, payload);
    };
}
