import * as React from 'react';
import { FormikDefaultForm, FormikInputField, FormikTextareaField, Modal, Panel, useModalCtrl } from '@ez/components';
import { AdminOnly } from '@poolware/app-shell';
import * as _ from 'lodash';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { NodeType } from '@poolware/api';
import { useWorkOrderViewContext } from './WorkOrderPageContext';

export const WorkOrderDetailsViewPanel: React.FC<{
    workOrder: NodeType.WorkOrder;
    onEdit?: () => any;
}> = ({ workOrder, onEdit }) => {
    const title = (
        <>
            <b>Work Order</b> - {workOrder.workOrderNumber}
        </>
    );

    return (
        <Panel>
            <Panel.Header
                color={ModuleColorNames.WorkOrder}
                icon={ModuleIconNames.WorkOrder}
                content={title}
                button={onEdit && { icon: 'edit', content: 'Edit', onClick: onEdit }}
            />
            <Panel.Body>
                <AdminOnly>
                    <Panel.Item color={'red'} label="Franchise">
                        {workOrder?.serviceJob?.entity?.franchise?.name}
                    </Panel.Item>
                    <Panel.Divider />
                </AdminOnly>
                <Panel.Item wrap={true} label={'Subject'} content={workOrder.title} />
                <Panel.ItemText
                    maxHeightPx={620}
                    label={'Job Description'}
                    wrap={true}
                    content={workOrder.description || '--'}
                />
            </Panel.Body>
        </Panel>
    );
};

export const WorkOrderDetailsForm: React.FC<{
    workOrder: NodeType.WorkOrder;
    workOrderMutators;
    onDone: () => any;
    title?: any;
}> = ({ workOrder, workOrderMutators, onDone, title }) => {
    const initialValues = { ...workOrder };

    const onSubmit = async (values: typeof initialValues) => {
        const conf = {
            title: values.title,
            description: values.description,
            // assignedTo: values.assignedTo?.id || null,
        };

        await workOrderMutators.update({
            id: workOrder.id,
            ...conf,
        });
        onDone?.();
    };

    return (
        <FormikDefaultForm
            debug={true}
            color={'blue'}
            header={title}
            icon={ModuleIconNames.WorkOrder}
            onSubmit={onSubmit}
            initialValues={initialValues}
            onCancel={onDone}
        >
            <Panel.Item labelWidth={'200px'} label={'Parent Service Case ID'}>
                {workOrder.serviceJob?.serviceJobNumber}
                {' - '}
                {_.truncate(workOrder.serviceJob?.title, { length: 60 })}
            </Panel.Item>
            <Panel.Item labelWidth={'200px'} label={'Work Order ID'}>
                {workOrder.workOrderNumber}
            </Panel.Item>
            <Panel.Divider />
            <FormikInputField name={'title'} label={'Subject'} />
            {/*<FormikSuggestInputStaff name={'assignedTo'} label={'Assigned To'} />*/}
            <FormikTextareaField name={'description'} rows={12} label={'Work Order Description'} />
        </FormikDefaultForm>
    );
};

export const WorkOrderDetailsPanelForm: React.FC<{
    readonly?: boolean;
}> = ({ readonly }) => {
    const modalCtrl = useModalCtrl();

    const { workOrder, workOrderMutators } = useWorkOrderViewContext();

    const title = (
        <>
            <b>Work Order</b> - {workOrder.workOrderNumber}
        </>
    );

    return (
        <>
            <WorkOrderDetailsViewPanel workOrder={workOrder} onEdit={!readonly && modalCtrl.onOpen} />
            <Modal closeOnDimmerClick={false} {...modalCtrl}>
                <WorkOrderDetailsForm
                    workOrderMutators={workOrderMutators}
                    workOrder={workOrder}
                    onDone={modalCtrl.onClose}
                    title={title}
                />
            </Modal>
        </>
    );
};
