import { NodeType, useQueryConnection } from '@poolware/api';
import { useDefaultConnectionState } from '@ez/api-core';
import gql from 'graphql-tag';
import { createSuggestInputWithQueryConnectionHook } from '@poolware/app-shell';

const query = gql`
    query getEmailTypes {
        viewer {
            emailTypes {
                edges {
                    node {
                        id
                        defaultBody
                        name
                    }
                }
            }
        }
    }
`;

const useQueryEmailTypeConnection = (props?) => {
    const { variables, connectionState } = useDefaultConnectionState(props);
    return useQueryConnection<NodeType.EmailType>({
        query: query,
        connectionPath: 'viewer.emailTypes',
        connectionConfig: connectionState,
        variables: variables,
    });
};

export const SuggestInputEmailType = createSuggestInputWithQueryConnectionHook<NodeType.EmailType>({
    useQueryConnectionHook: useQueryEmailTypeConnection,
    getOptionLabel: (item) => item?.name,
});
