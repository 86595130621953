import * as React from 'react';
import { DeviceContextProps } from './types';
import {
    FormikCheckboxField,
    FormikDefaultForm,
    FormikDefaultFormProps,
    Modal,
    Panel,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { DeviceStateType, LifeGuardMode, LifeGuardShadowType } from '../../../common';
import { useMutationDeviceState } from './use-mutation-device-state';

const ModesOrder = [
    { key: LifeGuardMode.MANUAL, enabled: true },
    { key: LifeGuardMode.BOOST, enabled: true },
    { key: LifeGuardMode.SCHEDULE, enabled: true },
    { key: LifeGuardMode.SPA, enabled: true },
    { key: LifeGuardMode.COVER, enabled: true },
    { key: LifeGuardMode.WINTER, enabled: true },
    { key: LifeGuardMode.MAINTENANCE, enabled: false },
    { key: LifeGuardMode.SERVICE, enabled: false },
    { key: LifeGuardMode.BACKWASH, enabled: false },
];

const modesArrayToObject = (modes: string[]) => {
    let allModes = {};
    ModesOrder.forEach((item) => {
        allModes[item.key] = modes?.includes(item.key);
    });
    return allModes;
};

const modesObjectToArray = (modesObj: Object) => {
    return Object.keys(modesObj).filter((key) => !!modesObj[key]);
};

const FormActiveModes: React.FC<FormikDefaultFormProps> = (props) => {
    return (
        <FormikDefaultForm debug={true} header={'Active Modes'} submitButton={{ content: 'Update' }} {...props}>
            {ModesOrder.map((item) => (
                <FormikCheckboxField
                    key={item.key}
                    name={`modes.${item.key}`}
                    label={item.key}
                    disabled={!item.enabled}
                />
            ))}
        </FormikDefaultForm>
    );
};

export const PanelActiveModes: React.FC<DeviceContextProps> = ({ device, refetchQueries }) => {
    const modalCtrl = useModalCtrl(false);
    const deviceState = device.deviceState;
    const state: DeviceStateType<LifeGuardShadowType> = deviceState.state?.lifeguard || {};
    const stateReported = state.reported;

    const { update } = useMutationDeviceState({
        refetchQueries: refetchQueries,
    });

    const initialValues = {
        modes: modesArrayToObject(stateReported?.modes || []),
    };

    const onSubmit = async (values: typeof initialValues) => {
        const modes = modesObjectToArray(values.modes);
        try {
            const res = await update({
                device: device.device.id,
                scope: 'lifeguard',
                input: {
                    modes: modes,
                },
            });
            modalCtrl.onClose();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <Panel>
            <Panel.Header content={'Active Modes'} button={{ content: 'Change', onClick: modalCtrl.onOpen }} />
            <Panel.Body>
                <div className={'flex flex-row flex-wrap gap-1'}>
                    {stateReported?.modes?.map((mode, index) => (
                        <div className={'rounded px-2 bg-accent text-accent-foreground'} key={index}>
                            {mode}
                        </div>
                    ))}
                </div>
            </Panel.Body>
            <Modal {...modalCtrl} size={'small'}>
                <FormActiveModes initialValues={initialValues} onCancel={modalCtrl.onClose} onSubmit={onSubmit} />
            </Modal>
        </Panel>
    );
};
