import * as React from 'react';
import { JSONView, Segment } from '@ez/components';
import { EZCDevicesLinkWithState } from '../../../common';

export const DeviceStateGeneric: React.FC<{ device: EZCDevicesLinkWithState; refetchQueries?: any[] }> = ({
    device,
}) => {
    return (
        <>
            <Segment>TODO: Implement Generic Device state renderer</Segment>
            <Segment>
                <JSONView data={device?.deviceState} />
            </Segment>
        </>
    );
};
