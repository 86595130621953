import gql from 'graphql-tag';
import { addressFragment, NodeType, siteFragment } from '@poolware/api';
import { useQuery } from 'react-apollo';
import { fromEdges } from '@ez/api-core';

const SiteQuery = gql`
    query SiteQuery($siteId: ID!) {
        node(id: $siteId) {
            ... on Site {
                id
                ...SiteFragment
            }
        }
        viewer {
            poolTypes(first: 1) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            poolSanitisers(first: 1) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
    ${siteFragment}
    ${addressFragment}
`;

export const useQuerySiteDetails = (siteId: string) => {
    const res = useQuery<NodeType.Query>(SiteQuery, {
        variables: { siteId: siteId },
    });

    return {
        loading: res.loading,
        error: res.error,
        data: res.data,
        site: res.data?.node as NodeType.Site,
        defaultPoolType: fromEdges(res.data?.viewer.poolTypes)[0],
        defaultPoolSanitiser: fromEdges(res.data?.viewer.poolSanitisers)[0],
    };
};
