import { AttributeErrorType, DeviceStateType, LifeGuardShadowType } from './index';
import { AttributeModel } from '../View/TabDeviceState/Lifeguard/AttributeRenderer';
import { getComponentAttributesModel } from '../View/TabDeviceState/Lifeguard/AttributeRenderer/common';
import { ShadowAttrStatus } from './get-shadow-attribute';

export type AttributesSetType = {
    name: string;
    value?: AttributeModel;
    setpoint?: AttributeModel;
    isConnected?: AttributeModel;
    error?: AttributeErrorType;
};

const valueToLabelIsConnected = (value: any, status?: ShadowAttrStatus) => {
    switch (status) {
        default:
        case ShadowAttrStatus.Unknown:
        case ShadowAttrStatus.Settled:
            return value ? 'Connected' : 'Not Connected';
        case ShadowAttrStatus.Pending:
            return value ? 'Connecting...' : 'Disconnecting...';
    }
};

export const mapDeviceStateToAttributesSet = (state: DeviceStateType<LifeGuardShadowType>): AttributesSetType[] => {
    const isNipperCellOuputReadOnly = state.reported?.orpSensorConnected === true;

    return [
        {
            name: 'pH',
            value: getComponentAttributesModel(state, 'phSensorValue', {
                type: 'FLOAT',
                readOnly: true,
            }),
            setpoint: getComponentAttributesModel(state, 'phSetpoint', {
                type: 'FLOAT',
                readOnly: false,
                min: 7.0,
                max: 7.8,
                step: 0.1,
                label: 'pH Setpoint',
            }),
            isConnected: getComponentAttributesModel(state, 'phSensorConnected', {
                type: 'BOOL',
                readOnly: false,
                valueToString: valueToLabelIsConnected,
            }),
            error: state.reported?.phError,
        },
        {
            name: 'ORP',
            value: getComponentAttributesModel(state, 'orpSensorValue', {
                type: 'FLOAT',
                readOnly: true,
            }),
            setpoint: getComponentAttributesModel(state, 'orpSetpoint', {
                type: 'FLOAT',
                readOnly: false,
                label: 'ORP Setpoint',
                min: 350,
                max: 980,
                step: 5,
            }),
            isConnected: getComponentAttributesModel(state, 'orpSensorConnected', {
                type: 'BOOL',
                readOnly: false,
                valueToString: valueToLabelIsConnected,
            }),
            error: state.reported?.orpError,
        },
        {
            name: 'Salinity',
            value: getComponentAttributesModel(state, 'salinitySensorValue', {
                type: 'FLOAT',
                readOnly: true,
            }),
            setpoint: getComponentAttributesModel(state, 'salinitySetpoint', {
                type: 'FLOAT',
                readOnly: true,
                valueToString: (value) => '--',
            }),
            isConnected: getComponentAttributesModel(state, 'salinitySensorConnected', {
                type: 'BOOL',
                readOnly: false,
                valueToString: valueToLabelIsConnected,
            }),
            error: state.reported?.salinityError,
        },
        {
            name: 'Temperature',
            value: getComponentAttributesModel(state, 'temperatureSensorValue', {
                type: 'FLOAT',
                readOnly: true,
            }),
            setpoint: getComponentAttributesModel(state, 'temperatureSetpoint', {
                type: 'FLOAT',
                readOnly: false,
                label: 'Temperature Setpoint',
                min: 15,
                max: 39,
            }),
            isConnected: getComponentAttributesModel(state, 'temperatureSensorConnected', {
                type: 'BOOL',
                readOnly: false,
                valueToString: valueToLabelIsConnected,
            }),
            error: state.reported?.temperatureError,
        },
        {
            name: 'VSD',
            value: getComponentAttributesModel(state, 'vsdPumpSpeed', {
                type: 'ENUM',
                readOnly: true,
            }),
            setpoint: getComponentAttributesModel(state, 'vsdPumpSpeed', {
                type: 'ENUM',
                readOnly: false,
                options: [2, 4, 6, 8, 10],
                label: 'VSD Pump Speed',
            }),
            isConnected: getComponentAttributesModel(state, 'vsdPumpConnected', {
                type: 'BOOL',
                readOnly: false,
                valueToString: valueToLabelIsConnected,
            }),
            error: state.reported?.vsdError,
        },

        {
            name: 'Boost Duration',
            value: getComponentAttributesModel(state, 'boostDuration', {
                type: 'FLOAT',
                readOnly: true,
                valueToString: (value) => `${value} hrs`,
            }),
            setpoint: getComponentAttributesModel(state, 'boostDuration', {
                type: 'ENUM',
                readOnly: false,
                options: [6, 12, 18, 24],
                label: 'Boost Duration',
                valueToString: (value) => `${value} hrs`,
            }),
            // isConnected: getComponentAttributesModel(state, 'boostDuration', { type: 'BOOL', readOnly: true }),
        },
        {
            name: 'Backwash Duration',
            value: getComponentAttributesModel(state, 'backwashDuration', {
                type: 'FLOAT',
                readOnly: true,
                valueToString: (value) => `${value} sec`,
            }),
            setpoint: getComponentAttributesModel(state, 'backwashDuration', {
                type: 'ENUM',
                readOnly: false,
                options: [120, 180, 240, 300, 360],
                label: 'Boost Duration',
                valueToString: (value) => `${value} sec`,
            }),
            // isConnected: getComponentAttributesModel(state, 'backwashDuration', { type: 'BOOL', readOnly: true }),
        },

        {
            name: 'Nipper Cell Output',
            value: getComponentAttributesModel(state, 'nipperCellOutputValue', {
                type: 'FLOAT',
                readOnly: true,
            }),
            setpoint: getComponentAttributesModel(state, 'nipperCellOutputSetpoint', {
                type: 'FLOAT',
                readOnly: isNipperCellOuputReadOnly,
                min: 0,
                max: 100,
                label: 'Nipper Cell Output Setpoint',
            }),
            // isConnected: getComponentAttributesModel(state, 'cellOutput', { type: 'BOOL', readOnly: true }),
            error: state.reported?.nipperError,
        },
    ];
};
