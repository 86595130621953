import * as React from 'react';
import { useField } from 'formik';
import { FormFieldSuggestInput, FormFieldSuggestInputProps } from '../form-components';
import { FormikFieldProps } from './FormikTypes';

export type FormikSuggestInputFieldNewProps<
    V,
    Base extends FormFieldSuggestInputProps<V> = FormFieldSuggestInputProps<V>
> = Omit<Base, 'value' | 'onChange'> &
    FormikFieldProps & {
        onDidChange?: (newValue: V, fieldName: string) => any;
    };

export function withFormikSuggestInput<
    V = any,
    Base extends FormFieldSuggestInputProps<V> = FormFieldSuggestInputProps<V>
>(InputComp: React.ComponentType<Base>) {
    const FormikSuggestInputField: React.FC<FormikSuggestInputFieldNewProps<V, Base>> = ({
        name,
        onDidChange,
        ...props
    }) => {
        const [field, meta, helpers] = useField({ name: name });

        let value = field?.value;
        const touched = meta.touched;
        const error = meta.error;
        let hasError = touched && error;

        const setValue = async (newValue: V) => {
            await helpers.setValue(newValue);
            onDidChange?.(newValue, name);
        };

        const handleChange = (value: V) => {
            setValue(value);
        };

        //  TODO: fix types
        return (
            // @ts-ignore
            <InputComp isClearable={true} error={hasError} value={value} onChange={handleChange} {...props} />
        );
    };
    FormikSuggestInputField.displayName = `FormikSuggestInputField(${InputComp.displayName})`;

    return FormikSuggestInputField;
}

export const FormikSuggestInputField = withFormikSuggestInput(FormFieldSuggestInput);
