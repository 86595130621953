import { createThemeByHue } from '@ez/components';
import { hslParams } from '@ez/components/dist/app-theme/utils';

export const getTheme = (publicPrefix) => {
    const huePrim = 160;
    const theme = createThemeByHue({
        publicPrefix: publicPrefix,
        huePrim: huePrim,
        hueSec: 28,
        primSat: 25,
        primLum: 30,
        customisation: {
            logoPath: `${publicPrefix}/admin-logo.png`,
            iconPath: `${publicPrefix}/admin-icon.png`,
            themeName: 'aquatest-admin',
            main: {
                backgroundColor: hslParams(huePrim, 10, 85),
            },
            navbar: {
                logo: {
                    backgroundColor: hslParams(huePrim, 25, 20),
                },
            },
        },
    });

    return theme;
};
