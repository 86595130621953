import * as React from 'react';
import { MenuBarHeaderItem } from './MenuBarHeaderItem';
import { MenuBarGroup } from './MenuBarGroup';
import { MenuBarItem, MenuBarItemLink, MenuBarItemWithConfirm } from './MenuBarItem';
import { MenuBarDropdown, MenuBarDropdownItem, MenuBarDropdownItemWithConfirm } from './MenuBarDropdown';
import * as MenubarPrimitive from '@radix-ui/react-menubar';
import { clsxm } from '../utils';

export interface MenuBarProps extends React.ComponentPropsWithoutRef<typeof MenubarPrimitive.Root> {
    // HACK: This is a workaround to allow the MenuBar to accept size prop.
    // TODO: Remove this later
    size?: 'tiny' | 'small' | 'medium' | 'large';
}

const MenubarRoot = React.forwardRef<React.ElementRef<typeof MenubarPrimitive.Root>, MenuBarProps>(
    ({ className, ...props }, ref) => (
        <MenubarPrimitive.Root
            ref={ref}
            className={clsxm(
                'w-full flex flex-row h-12 items-center gap-1 rounded border bg-menubar p-1 shadow',
                className
            )}
            {...props}
        />
    )
);

MenubarRoot.displayName = MenubarPrimitive.Root.displayName;

export const MenuBarSection: React.FC<{
    position?: 'left' | 'right';
    className?: string;
}> = ({ className, position, children }) => {
    return (
        <>
            <div
                className={clsxm([
                    'ez-menubar-section',
                    position === 'left' && 'justify-start',
                    position === 'right' && 'justify-end',
                    className,
                ])}
            >
                {children}
            </div>
        </>
    );
};

export const MenuBar = {
    Section: MenuBarSection,
    HeaderItem: MenuBarHeaderItem,
    Item: MenuBarItem,
    ItemLink: MenuBarItemLink,
    ItemWithConfirm: MenuBarItemWithConfirm,
    Group: MenuBarGroup,
    Dropdown: MenuBarDropdown,
    DropdownItem: MenuBarDropdownItem,
    DropdownItemWithConfirm: MenuBarDropdownItemWithConfirm,
    Root: MenubarRoot,
};
