import {
    FormikDefaultForm,
    FormikDefaultFormProps,
    FormikInputField,
    Segment,
    FormFieldSuggestInput,
} from '@ez/components';
import { FormikSuggestInputFranchise, SuggestInputFranchise } from '@poolware/app-shell';
import * as React from 'react';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    name: Yup.string().trim().min(2, 'Too Short!').max(250, 'Too Long!').required('Required'),
    email: Yup.string().required('Required').trim().email().nullable(),
});

export const WarranterForm: React.FC<FormikDefaultFormProps> = ({ children, ...props }) => {
    return (
        <FormikDefaultForm validationSchema={validationSchema} {...props}>
            <FormikInputField name={'name'} label={'Name'} required />
            <FormikInputField name={'email'} label={'Email'} required />
            <FormikSuggestInputFranchise
                search={{ isWarranter: true }}
                name={'actingFranchise'}
                label={'Acting Franchise'}
            />

            <Segment>
                Only <b>Warranter</b>-type franchises can be assigned as the destination office for warranty requests
            </Segment>
            {children}
        </FormikDefaultForm>
    );
};
