import * as React from 'react';
import { SemanticCOLORS } from 'semantic-ui-react';
import { Form, Formik, FormikConfig, FormikHelpers as FormikActions, FormikValues } from 'formik';
import { toastError } from '../toast';
import { FormikFormButtons } from './FormikFormButtons';
import { isFunction } from '../utils';
import { HeaderButtonType, Panel, PanelHeaderProps } from '../panel';
import { FormikFormDebug } from './FormikFormDebug';
import { useIsMounted, usePersistedToggle } from '@ez/tools';
import { SemanticICONS } from '../Icon';
import { ButtonWithPopupProps } from '../buttons';

let isDevelopment = false;
try {
    isDevelopment = process.env.NODE_ENV === 'development' || window?.localStorage?.debugUI;
} catch (e) {
    console.error(e);
}

export interface FormikDefaultFormBaseProps<Values extends FormikValues = FormikValues> extends FormikConfig<Values> {
    debug?: boolean;
}

export const FormikDefaultFormBase: React.FC<FormikDefaultFormBaseProps> = (props) => {
    const { children, debug, onSubmit, initialValues, ...rest } = props;

    const isMounted = useIsMounted();

    const handleOnSubmit = async (values: any, actions: FormikActions<any>) => {
        actions.setSubmitting(true);
        try {
            await props.onSubmit(values, actions);
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e });
            toastError({ title: 'Error', description: e.message });
        }
        if (isMounted()) {
            actions.setSubmitting(false);
        }
    };

    return (
        <Formik {...rest} initialValues={initialValues || {}} onSubmit={handleOnSubmit}>
            {(formikBag) => {
                return (
                    <Form autoComplete={'off'} className={'w-full'}>
                        {isFunction(children) ? children(formikBag) : children}
                        <FormikFormDebug hidden={!debug} />
                    </Form>
                );
            }}
        </Formik>
    );
};

export interface FormikDefaultFormProps<Values extends FormikValues = FormikValues>
    extends FormikDefaultFormBaseProps<Values> {
    onCancel?: () => any;
    color?: SemanticCOLORS;
    icon?: SemanticICONS;
    header?: string | React.ReactNode;
    debug?: boolean;
    headerColor?: SemanticCOLORS;
    headerButton?: PanelHeaderProps['button'];
    submitOnEnter?: boolean;
    submitButton?: ButtonWithPopupProps;
    cancelButton?: ButtonWithPopupProps;
    activateOnDirty?: boolean;
    size?: string;
}

export const FormikDefaultForm: React.FC<FormikDefaultFormProps> = (props) => {
    const {
        children,
        debug,
        header,
        headerColor,
        onSubmit,
        submitOnEnter = true,
        submitButton,
        cancelButton,
        color,
        icon,
        activateOnDirty,
        headerButton,
        initialValues,
        size,
        ...rest
    } = props;

    const [_debugLocal, _setDebug] = usePersistedToggle('dev.form-debug', false);
    const isMounted = useIsMounted();

    const handleOnSubmit = async (values: any, actions: FormikActions<any>) => {
        actions.setSubmitting(true);
        try {
            await props.onSubmit(values, actions);
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e });
            toastError({ title: 'Failed', description: e.message });
        }
        if (isMounted()) {
            actions.setSubmitting(false);
        }
    };

    const _debug = debug !== undefined ? debug : _debugLocal;
    let debugToggleButton: HeaderButtonType = { icon: 'bug', onClick: () => _setDebug(!_debugLocal) };
    let _headerButton;
    if (isDevelopment) {
        _headerButton = [] as HeaderButtonType[];
        _headerButton.push(debugToggleButton);
        if (headerButton) {
            if (headerButton instanceof Array) {
                _headerButton = _headerButton.concat(headerButton);
            } else {
                _headerButton.push(headerButton);
            }
        }
    } else {
        _headerButton = headerButton;
    }

    return (
        <FormikDefaultFormBase {...rest} debug={_debug} initialValues={initialValues || {}} onSubmit={handleOnSubmit}>
            {(formikBag) => {
                return (
                    <Panel>
                        {(header || _headerButton) && (
                            <Panel.Header icon={icon} color={headerColor || color} button={_headerButton}>
                                {header}
                            </Panel.Header>
                        )}

                        <Panel.Body>{isFunction(children) ? children(formikBag) : children}</Panel.Body>

                        <Panel.Footer>
                            <FormikFormButtons
                                activateOnDirty={activateOnDirty}
                                cancelButton={cancelButton}
                                submitButton={submitButton}
                                submitOnEnter={submitOnEnter}
                                handleCancel={props.onCancel}
                            />
                        </Panel.Footer>
                    </Panel>
                );
            }}
        </FormikDefaultFormBase>
    );
};
