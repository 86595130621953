import * as React from 'react';
import { NodeType, sanitizeAddressInput } from '@poolware/api';
import {
    Alert,
    AlertContent,
    AlertHeader,
    FormikDefaultForm,
    FormikInputField,
    FormikRadioField,
    Icon,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    MenuBarSection,
    PageLayout,
    Panel,
    StickyMenuBar,
    stringFormatters,
    toastError,
} from '@ez/components';

import { PageControl } from './Page.Control';
import {
    AddressValidationSchema,
    FormikAddressInputFields,
} from '../../../CommonComponents/Address/FormikAddressInputFields';
import * as Yup from 'yup';

const WarningMessage = () => {
    return (
        <Alert type={'warning'}>
            <AlertHeader>
                <Icon name={'warning sign'} color={'yellow'} />
                DANGER!!!
                <Icon name={'warning sign'} color={'yellow'} />
            </AlertHeader>
            <AlertContent>
                <p />
                <p>This site will be deleted permanently!</p>
                <p>This action is irreversible!</p>
            </AlertContent>
        </Alert>
    );
};

interface DeletePermission {
    deletable: boolean;
    reason?: string;
}

const DropdownItemDeleteButton: React.FC<{ onDelete: any; deletePermission: DeletePermission }> = ({
    onDelete,
    deletePermission,
}) => {
    return (
        <MenuBarDropdownItemWithConfirm
            icon={'trash'}
            color="orange"
            content="Delete Site"
            onClick={onDelete}
            disabled={!deletePermission.deletable}
            popup={!deletePermission.deletable ? { content: deletePermission.reason } : undefined}
            confirm={{
                confirmMessage: {
                    header: 'Delete Site?',
                    content: <WarningMessage />,
                },
                negative: true,
                confirmButton: {
                    content: 'Delete Site',
                    icon: 'trash',
                },
            }}
        />
    );
};

const NEW_ADDRESS = '___new_address___';

const validationSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Too Short!').max(100, 'Too Long!').required('Required'),
    addressId: Yup.string().required('Required'),
    newAddress: Yup.object().when('addressId', {
        is: (address) => address === NEW_ADDRESS,
        then: AddressValidationSchema.required('Required'),
        otherwise: Yup.object().notRequired(),
    }),
});

class Page extends React.Component<PageControl, { isMutating: boolean }> {
    state = {
        isMutating: false,
    };

    mutationConfigMapper_Update = (formValues): NodeType.UpdateSiteMutationInput => {
        let mutationConfig: NodeType.UpdateSiteMutationInput = {
            id: formValues.id, // site id
            name: formValues.name,
        };

        let { addressId } = formValues;
        const isNewAddress = addressId === NEW_ADDRESS;

        if (isNewAddress) {
            const { newAddress } = formValues;
            let addressInput = sanitizeAddressInput(newAddress);
            mutationConfig.address = {
                create: {
                    ...addressInput,
                    entity: formValues.entityId,
                },
            };
        }

        return mutationConfig;
    };

    onSubmit = async (formValues) => {
        try {
            const mutationConfig: NodeType.UpdateSiteMutationInput = this.mutationConfigMapper_Update(formValues);
            await this.props.mutateSite.update(mutationConfig);
            this.props.AppNavigator.navigateToOrigin();
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to update site', description: error.message });
        }
    };

    deletePermission = () => {
        // if this.props.Permissions.Site is not provider, show button.
        // The server will fail request anyway if not enough permissions to delete site.
        const deletePermission =
            this.props.Permissions && this.props.Permissions.Site ? this.props.Permissions.Site.delete : true;
        if (!deletePermission) {
            return {
                deletable: false,
                reason: 'Not enough permissions',
            };
        } else {
            const hasPools = this.props.pools && this.props.pools.length > 0;
            if (hasPools) {
                return {
                    deletable: false,
                    reason: 'Cannot delete site with pools. Remove pools first.',
                };
            } else {
                return {
                    deletable: true,
                    reason: undefined,
                };
            }
        }
    };

    onDelete = async () => {
        const r = window.confirm('Delete Site?');
        if (r !== true) {
            return;
        }

        try {
            this.setState({ isMutating: true });
            await this.props.mutateSite.delete({ id: this.props.site.id });
            this.setState({ isMutating: false }, () => {
                this.props.AppNavigator.replaceToOrigin();
            });
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to Delete', description: error.message });
            this.setState({ isMutating: false });
        }
    };

    onCancel = () => {
        this.props.AppNavigator.navigateToOrigin();
    };

    goBack = () => {
        this.props.AppNavigator.navigateToOrigin();
    };

    render() {
        const { site } = this.props;

        let allAddressesEdges = [];
        let currentAddress = site.address;
        let addresses = allAddressesEdges.length > 0 ? allAddressesEdges : [currentAddress];

        const addressOptions = addresses.map((a) => ({
            key: a.id,
            value: a.id,
            text: stringFormatters.formatAddress(a),
        }));

        addressOptions.push({
            key: NEW_ADDRESS,
            value: NEW_ADDRESS,
            text: 'New address',
        });

        const initialValues = {
            id: site.id,
            name: site.name,
            entityId: site.entity.id,
            addressId: site.address.id,
            newAddress: {
                city: undefined,
                state: undefined,
                postCode: undefined,
            },
        };

        return (
            <PageLayout>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarItem onClick={this.goBack} icon={'chevron left'} title={'Back to site'} />
                    </MenuBarSection>
                    <MenuBarSection position={'right'}>
                        <MenuBarDropdown icon={'bars'} color={'red'}>
                            <DropdownItemDeleteButton
                                onDelete={this.onDelete}
                                deletePermission={this.deletePermission()}
                            />
                        </MenuBarDropdown>
                    </MenuBarSection>
                </StickyMenuBar>

                <PageLayout.BodySection width={'screen-md'}>
                    <FormikDefaultForm
                        validationSchema={validationSchema}
                        debug={false}
                        header={'Edit site'}
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}
                    >
                        {({ values }) => {
                            const addressId = values['addressId'];
                            return (
                                <>
                                    <Panel.ItemEntity label="Site owner" content={site.entity} />
                                    <Panel.Divider />
                                    <FormikInputField label={'Site Name'} name={'name'} required={true} autoFocus />
                                    <FormikRadioField
                                        label={'Address'}
                                        name={'addressId'}
                                        required={true}
                                        options={addressOptions}
                                    />
                                    {addressId === NEW_ADDRESS && <FormikAddressInputFields name={'newAddress'} />}
                                </>
                            );
                        }}
                    </FormikDefaultForm>
                </PageLayout.BodySection>
            </PageLayout>
        );
    }
}

export default Page;
