import _set from 'lodash/set';
import _omit from 'lodash/omit';

export const pwapiDeserializer = (pwapiState) => {
    pwapiState = _omit(pwapiState, ['isAuthenticating', 'isFetchingProfile']);
    return pwapiState;
};

export const pwapiSerializer = (pwapiState) => {
    _set(pwapiState, 'isAuthenticating', false);
    _set(pwapiState, 'isFetchingProfile', false);
    pwapiState = _omit(pwapiState);
    return pwapiState;
};
