import * as React from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    Icon,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    PageLayout,
    StickyMenuBar,
    VStack,
} from '@ez/components';
import { NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useQueryProductsDocsConnection } from './use-query-products-docs-connection';
import { getIconNameForMimeType, truncateString } from './utils';
import { SuggestInputOrgSwitcher, useOrgSwitcher } from '@poolware/app-shell';

export interface PageProps {}

export const PageList: React.FC<PageProps> = () => {
    const { AppNavigator } = useAppNavigator();
    const { organisation } = useOrgSwitcher();
    const { connectionData, connectionState } = useQueryProductsDocsConnection({
        // type: NodeType.ProductDocumentType.Brochure,
        // organisationType: organisation,
    });

    const onView = (item) => {
        AppNavigator.navigateRelative(`/${item.id}`);
    };

    const onNew = () => {
        AppNavigator.navigateRelative(`/new`, { modal: false });
    };

    const tableDef: ConnectionTableDef<NodeType.ProductDocument> = [
        {
            header: 'Document Type',
            width: 1,
            cell: (r) => <>{r.type}</>,
        },
        {
            header: 'Document Title',
            sortKey: 'title',
            cell: (r) => (
                <>
                    <Icon name={getIconNameForMimeType(r.mimeType)} /> {truncateString(r.title, 120)}
                </>
            ),
        },
        {
            header: 'Ownership',
            cell: (r) => {
                return (
                    <span>
                        {r.organisationType?.name}
                        {r.franchise?.name}
                    </span>
                );
            },
        },
        {
            header: 'MIME',
            width: 1,
            cell: (r) => {
                return r.mimeType;
            },
        },

        {
            header: 'Uploaded',
            width: 1,
            sortKey: 'createdAt',
            cell: (r) => {
                return <Display.Date value={r.createdAt} format={'LL'} />;
            },
        },
    ];

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'file'}>Product Documents</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <div className={'p-1 w-80'}>
                        <SuggestInputOrgSwitcher />
                    </div>
                    <MenuBarItem onClick={onNew} icon={'plus'} color={'green'} title={'New'} />
                </MenuBarSection>
            </StickyMenuBar>

            <DefaultConnectionTable
                onRowClick={onView}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </PageLayout>
    );
};
