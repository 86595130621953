import { NodeType } from '@poolware/api';
import * as React from 'react';
import { Alert, clsxm, DebugJsonButton, Icon, PageLayout, Panel, VStack } from '@ez/components';
import { AdminOnly, useAppConfig } from '@poolware/app-shell';
import {
    DeviceStateType,
    EZCDevicesLinkWithState,
    getExternalServicesURLs,
    LifeGuardShadowType,
} from '../../../common';
import { PanelTargets } from './PanelTargets';
import { PanelSchedules } from './PanelSchedules';
import { PanelActiveModes } from './PanelActiveModes';

const ConnectionStatus: React.FC<{ isConnected: boolean }> = ({ isConnected }) => {
    const label = isConnected ? 'Connected' : 'Not Connected';
    return (
        <div className={'flex flex-row gap-1'}>
            <div className={clsxm('text-accent font-bold')}>{label}</div>
            <Icon name={'circle'} color={isConnected ? 'green' : 'red'} />
        </div>
    );
};

export interface DeviceStateLifeGuardProps {
    device: EZCDevicesLinkWithState;
    refetchQueries?: any[];
}

export const DeviceStateLifeGuard: React.FC<DeviceStateLifeGuardProps> = ({ device, refetchQueries }) => {
    const deviceState = device.deviceState;

    if (!deviceState) {
        return <Alert type={'warning'}>Device state is not available</Alert>;
    }

    const state: DeviceStateType<LifeGuardShadowType> = deviceState.state?.lifeguard || {};
    const stateReported = state.reported;
    const isAlive = state.isAlive;

    const extURLs = getExternalServicesURLs(deviceState.device);

    return (
        <>
            <PageLayout.TwoColumns>
                <VStack>
                    <Panel>
                        <Panel.Header
                            content={
                                <div className={'flex flex-row gap-2'}>
                                    <span>Lifeguard Targets</span>
                                </div>
                            }
                            button={[
                                <DebugJsonButton size={'xs'} data={deviceState} variant={'tertiary'} />,
                                <ConnectionStatus isConnected={isAlive} />,
                            ]}
                        />
                        <Panel.Body>
                            <PanelTargets device={device} refetchQueries={refetchQueries} />
                        </Panel.Body>
                    </Panel>
                </VStack>

                <VStack>
                    <PanelActiveModes device={device} refetchQueries={refetchQueries} />

                    <PanelSchedules device={device} refetchQueries={refetchQueries} />

                    <Panel>
                        <Panel.Header content={'Pumps'} />
                        <Panel.Body>
                            <Panel.Item
                                label={'Heat Pump'}
                                content={stateReported?.heatPumpConnected ? 'Connected' : 'Not Connected'}
                            />
                            <Panel.Item
                                label={'Acid Pump'}
                                content={stateReported?.acidPumpConnected ? 'Connected' : 'Not Connected'}
                            />
                        </Panel.Body>
                    </Panel>
                    <Panel>
                        <Panel.Header content={'Configuration'} />
                        <Panel.Body>
                            <div>
                                <Panel.Item label={'Lifeguard Version'}>{stateReported?.firmwareVersion}</Panel.Item>
                                <Panel.Item label={'Nipper Version'}>{stateReported?.nipperVersion}</Panel.Item>
                                <Panel.Item label={'Nipper Cell Size'}>{stateReported?.nipperCellSize}</Panel.Item>
                                <Panel.Item label={'Nipper Status'}>
                                    {stateReported?.nipperStatus?.toString()}
                                </Panel.Item>
                                {/*<Panel.Item label={'Pool SIze'}>litres</Panel.Item>*/}
                                {/*<Panel.Item label={'Region'}>ANZ</Panel.Item>*/}
                                <AdminOnly>
                                    {(extURLs.urlEZ || extURLs.urlLG) && (
                                        <Panel.Item label={'External Services'}>
                                            {extURLs.urlLG && (
                                                <a
                                                    href={extURLs.urlLG}
                                                    target={'_blank'}
                                                    className={'pr-4'}
                                                    rel="noreferrer"
                                                >
                                                    Lifeguard Portal <Icon name={'external'} />
                                                </a>
                                            )}
                                            {extURLs.urlEZ && (
                                                <a href={extURLs.urlEZ} target={'_blank'} rel="noreferrer">
                                                    EZ Connect <Icon name={'external'} />
                                                </a>
                                            )}
                                        </Panel.Item>
                                    )}
                                </AdminOnly>
                            </div>
                        </Panel.Body>
                    </Panel>
                </VStack>
                {/*<DebugJSON data={stateReported}/>*/}
            </PageLayout.TwoColumns>
        </>
    );
};
