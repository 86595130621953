import * as React from 'react';
import { LucideProps, WifiOff } from 'lucide-react';
import { SemanticICONS } from './SemanticICONS';
import { clsxm, cn } from '../utils';
import { Icon as SemanticIcon, SemanticCOLORS } from 'semantic-ui-react';

const mapIconName = (name: string) => {
    const map = {
        // camera: 'Camera',
        // print: 'Printer',
        // edit: 'FilePenLine',
        // // edit: 'Pencil',
        // 'ellipsis vertical': 'EllipsisVertical',
        // 'chevron right': 'ChevronRight',
        // 'chevron left': 'ChevronLeft',
        // 'chevron down': 'ChevronDown',
        // 'chevron up': 'ChevronUp',
        // 'calendar alternate outline': 'CalendarDays',
        // 'address book outline': 'BookUser',
        // calendar: 'CalendarDays',
        // 'remove circle': 'CircleX',
        // 'user outline': 'User',
        // 'user cancel': 'UserX',
        // 'check circle outline': 'CircleCheck',
        // spy: 'User',
        // time: 'Clock',
        // repeat: 'Repeat2',
        // // spinner: 'LoaderCircle',
        // cancel: 'X',
        // close: 'X',
        // trash: 'Trash',
        // 'save outline': 'Save',
        // checkmark: 'Check',
        // 'life ring': 'LifeBuoy',
        // 'life ring outline': 'LifeBuoy',
        // refresh: 'ListRestart',
        'wifi-off': WifiOff,
    };

    return map[name];
};

export interface IconProps extends LucideProps {
    name?: string | SemanticICONS;
    spin?: boolean;
    className?: string;
    // TODO: deprecate loading prop
    loading?: boolean;
    circular?: boolean;
    disabled?: boolean;
    inverted?: boolean;
}

export const Icon: React.FC<IconProps> = ({ disabled, name, spin, loading, className, circular, color, ...props }) => {
    if (!name) {
        return null;
    }

    const LucideIcon = mapIconName(name);
    if (!LucideIcon) {
        // console.log(`Icon ${name} not found`);
        return (
            <span>
                <SemanticIcon
                    className={clsxm('p-0 m-0', className)}
                    name={name as SemanticICONS}
                    loading={loading || spin}
                    color={color as SemanticCOLORS}
                    disabled={disabled}
                    {...(props as any)}
                />
            </span>
        );
        // return <LucideBug color={'red'} className={'h-[1.0em] w-[1.0em]'} {...props} />;
    }

    return (
        <LucideIcon
            className={cn(
                'inline-block text-center align-middle h-[1.0em] w-[1.0em]',
                (spin || loading) && 'animate-spin-slow',
                color === 'grey' && 'text-gray-500',
                color === 'green' && 'text-green-500',
                color === 'red' && 'text-red-500',
                className
            )}
            {...props}
        />
    );
};

export const resolveIconComponent = (icon: React.ReactNode, color?: string) => {
    if (typeof icon === 'string') {
        return <Icon name={icon as SemanticICONS} color={color as SemanticCOLORS} />;
    }
    return icon;
};
