import * as React from 'react';
import {
    FilterByBrand,
    FilterByProductCompany,
    FilterByProductName,
    FilterBySku,
    FilterByTraitDeclaration_Tree,
} from '../../../components-redux-connected';

const SearchSideBar: React.FC = () => {
    return (
        <div className={'flex flex-col px-2'}>
            <FilterByProductName />
            <FilterBySku />
            <FilterByBrand label={'Brand'} />
            <FilterByProductCompany label={'Product Company'} />
            <FilterByTraitDeclaration_Tree header={'Search By Category'} />
        </div>
    );
};

export default SearchSideBar;
