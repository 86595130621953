import * as React from 'react';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { compose } from '@ez/tools';
import { NavLink } from 'react-router-dom';
import { withAppBreadcrumbs, WithAppBreadcrumbsInjectedProps } from './with-breadcrumbs';

interface Props extends IAppNavigatorProps<{}>, WithAppBreadcrumbsInjectedProps {}

class AppBreadcrumbs extends React.Component<Props> {
    render() {
        if (this.props.AppNavigator.isModal) {
            // Make sure breadcrumbs are not visible when the route is displayed inside a modal window.
            return null;
        }

        const { breadcrumbs } = this.props;
        const count = breadcrumbs.length;
        if (count === 0) {
            return null;
        }
        return (
            <div className={'shadow px-4 py-1 bg-panel print:hidden mb-2'}>
                {breadcrumbs.map(({ breadcrumb, match }, index) => {
                    return (
                        <div key={match.url} className={'inline-block align-middle'}>
                            {count === index + 1 ? (
                                breadcrumb
                            ) : (
                                <>
                                    <NavLink to={match.url}>{breadcrumb}</NavLink>{' '}
                                    <i className="right chevron icon !text-xs" />
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export const createAppBreadrumbsComponent = (breadcrumbRoutes) =>
    compose(withAppNavigator(), withAppBreadcrumbs(breadcrumbRoutes, { disableDefaults: true }))(AppBreadcrumbs);
