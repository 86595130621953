import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { useTheme } from './reducer';

export const AppThemeProvider: React.FC = (props) => {
    const { ThemeState } = useTheme();
    const { theme } = ThemeState;

    return (
        <ThemeProvider theme={theme}>
            <>{props.children}</>
        </ThemeProvider>
    );
};
