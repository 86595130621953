import { useEffect, useRef, useState } from 'react';
import { useDragDropManager } from 'react-dnd';

export const useDragPageAutoScroll = () => {
    const [dragValue, setDragValue] = useState<boolean>(false);

    const dragDropManager = useDragDropManager();
    const monitor = dragDropManager.getMonitor();
    const timerRef = useRef<NodeJS.Timer>();
    const unsubscribeRef = useRef<any>();

    const setScrollInterval = (speed: number, container: HTMLElement) => {
        timerRef.current = setInterval(() => {
            container.scrollBy(0, speed);
        }, 1);
    };

    useEffect(() => {
        if (dragValue) {
            unsubscribeRef.current = monitor.subscribeToOffsetChange(() => {
                const offset = monitor.getClientOffset();
                // it can be html, body, div, any container that have scroll
                const container = document.getElementById('content');

                if (!offset || !container) return;

                if (offset.y < container.clientHeight / 2 - 300) {
                    if (timerRef.current) clearInterval(timerRef.current);
                    setScrollInterval(-3, container);
                } else if (offset.y > container.clientHeight / 2 + 300) {
                    if (timerRef.current) clearInterval(timerRef.current);
                    setScrollInterval(3, container);
                } else if (offset.y > container.clientHeight / 2 - 300 && offset.y < container.clientHeight / 2 + 300) {
                    if (timerRef.current) clearInterval(timerRef.current);
                }
            });
        } else if (unsubscribeRef.current) {
            if (timerRef.current) clearInterval(timerRef.current);
            unsubscribeRef.current();
        }
    }, [dragValue, monitor]);

    useEffect(() => {
        const unsubscribe = monitor.subscribeToStateChange(() => {
            if (monitor.isDragging()) setDragValue(() => true);
            else if (!monitor.isDragging()) setDragValue(() => false);
        });

        return () => {
            unsubscribe();
        };
    }, [monitor]);
};
