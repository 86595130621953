import { Panel } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import * as React from 'react';
import { PhotoSlidesHorizontal } from '../../../Scheduler/PageAppointmentMobile/Page/PageSectionPhotos/PhotoSlidesHorizontal';
import { useWorkOrderViewContext } from '../WorkOrderPageContext';

export interface FileAttachmentPanelProps {
    readOnly: boolean;
    onEdit?: () => void;
}

export const PanelFileAttachments: React.FC<FileAttachmentPanelProps> = ({ readOnly, onEdit }) => {
    const { workOrder } = useWorkOrderViewContext();
    const files = fromEdges(workOrder?.files);
    const beforeFiles = files.filter((f) => f.beforeAfterStatus === NodeType.FileUploadBeforeAfterEnum.BEFORE);
    const afterFiles = files.filter((f) => f.beforeAfterStatus === NodeType.FileUploadBeforeAfterEnum.AFTER);
    const otherFiles = files.filter((f) => !f.beforeAfterStatus);
    const otherTitle = !beforeFiles.length && !afterFiles.length ? 'Photos' : 'Other';

    return (
        <Panel>
            <Panel.Header
                content={'Attachments'}
                button={{ content: 'Edit', icon: 'edit', onClick: onEdit, disabled: readOnly }}
            />
            <Panel.Body>
                {beforeFiles.length > 0 && <PhotoSlidesHorizontal files={beforeFiles} label={'Before Service'} />}
                {afterFiles.length > 0 && <PhotoSlidesHorizontal files={afterFiles} label={'After Service'} />}
                {otherFiles.length > 0 && <PhotoSlidesHorizontal files={otherFiles} label={otherTitle} />}
            </Panel.Body>
        </Panel>
    );
};
