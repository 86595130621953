import * as React from 'react';
import { HTMLAttributes } from 'react';
import { clsxm } from '../../utils';

export interface NavbarMenuItemProps extends HTMLAttributes<any> {
    onClick?: any;
    icon?: React.ReactNode;
    content?: any;
}

export const NavbarMenuItem: React.FC<NavbarMenuItemProps> = ({ onClick, icon, color, content, children }) => {
    const hasContent = content || children;
    return (
        <button
            className={clsxm('ez-navbar-menu-item', !onClick && 'hover:bg-transparent cursor-default')}
            onClick={onClick}
        >
            {icon && <span>{icon}</span>}
            {hasContent && (
                <span className={'hidden md:inline-block whitespace-nowrap'}>
                    {content}
                    {children}
                </span>
            )}
        </button>
    );
};

export const NavbarMenuLabel: React.FC<{ icon?: React.ReactNode; className?: string }> = ({
    icon,
    className,
    children,
}) => {
    return (
        <div className={clsxm('ez-navbar-menu-label', className)}>
            {icon && <span>{icon}</span>}
            {children && <span className={'text-sm whitespace-nowrap'}>{children}</span>}
        </div>
    );
};
