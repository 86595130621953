import * as React from 'react';
import {
    Button,
    FormGroup,
    FormikDefaultFormBase,
    FormikInputField,
    Icon,
    Modal,
    Panel,
    useModalCtrl,
} from '@ez/components';
import { BarcodeScanAndSearch } from './BarcodeScanAndSearch';
import { useViewer } from '@poolware/app-shell';

interface CustomerSearchFormProps {
    isSearching: boolean;
    onSubmit: (values?: any) => any;
    onChange?: (any) => any;
    onClear: () => any;
    value: any;
}

export interface SearchValuesType {
    crn?: string;
    bottleNumber?: string;
    address?: string;
    siteName?: string;
    customerName?: string;
    contact?: string;
}

const SearchButton: React.FC<{ isSearching: boolean }> = ({ isSearching }) => {
    return (
        <Button size={'lg'} color={'primary'} className={'w-full mt-6 mb-2'} type="submit" variant="primary">
            {isSearching ? <Icon loading name="spinner" /> : <Icon name="search" />}
            {isSearching ? 'Searching' : 'Search'}
        </Button>
    );
};

const CustomerSearchForm: React.FC<CustomerSearchFormProps> = ({
    value = {},
    onChange,
    onSubmit,
    isSearching,
    onClear,
}) => {
    const { modulesAccess } = useViewer();

    const modalCtl = useModalCtrl(false);

    const initialFormValues = {
        crn: '',
        customerName: '',
        address: '',
        contact: '',
        bottleNumber: '',
        siteName: '',
        ...value,
    };

    const handleSubmit = (values: typeof initialFormValues) => {
        onSubmit();
    };

    const handleChangeNew = (value: string, name: string) => {
        onChange({ name, value });
    };

    const buttons = [
        modulesAccess.Pools?.barcodedBottles && {
            content: 'Scan Bottle Barcode',
            onClick: modalCtl.onOpen,
            icon: 'barcode',
            color: 'blue',
        },
        { content: 'Clear', onClick: onClear },
    ];

    const addressLabel = modulesAccess.Pools?.enabled ? 'Site, Pool or Customer Address' : 'Address';

    return (
        <>
            <Panel>
                <Panel.Header icon={'search'} button={buttons}>
                    Search
                </Panel.Header>
                <Panel.Body>
                    <FormikDefaultFormBase
                        enableReinitialize={true}
                        initialValues={initialFormValues}
                        onSubmit={handleSubmit}
                    >
                        <FormGroup>
                            <FormikInputField
                                label="Customer CRN"
                                name="crn"
                                id="crn"
                                size={'small'}
                                onDidChange={handleChangeNew}
                            />
                            <FormikInputField
                                label="Customer Name"
                                name="customerName"
                                id="customerName"
                                size={'small'}
                                onDidChange={handleChangeNew}
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormikInputField
                                label={addressLabel}
                                name="address"
                                id="address"
                                size={'small'}
                                onDidChange={handleChangeNew}
                            />
                            <FormikInputField
                                label="Phone/Email"
                                name="contact"
                                id="contact"
                                autoComplete="off"
                                size={'small'}
                                onDidChange={handleChangeNew}
                            />
                        </FormGroup>

                        {modulesAccess.Pools?.enabled && (
                            <FormGroup>
                                <FormikInputField
                                    label="Pool Number"
                                    size={'small'}
                                    name="bottleNumber"
                                    id="bottleNumber"
                                    onDidChange={handleChangeNew}
                                />
                                <FormikInputField
                                    label="Site Name"
                                    name="siteName"
                                    size={'small'}
                                    id="site_name"
                                    onDidChange={handleChangeNew}
                                />
                            </FormGroup>
                        )}

                        <SearchButton isSearching={isSearching} />
                    </FormikDefaultFormBase>
                </Panel.Body>
            </Panel>

            <Modal {...modalCtl} closeOnDimmerClick={false}>
                <BarcodeScanAndSearch onClose={modalCtl.onClose} />
            </Modal>
        </>
    );
};

export default CustomerSearchForm;
