import { fromEdges, NodeType, staffFragment } from '@poolware/api';
import { stringFormatters, withFormikSuggestInput } from '@ez/components';
import { createSuggestInputWithQuery } from './create-connected-suggest-input/create-suggest-input-with-query';
import * as React from 'react';
import gql from 'graphql-tag';

const QL = gql`
    query QueryStaffConnection($first: Int, $page: Int, $search: StaffViewerStaffSearch, $sort: StaffViewerStaffSort) {
        viewer {
            staff(first: $first, page: $page, search: $search, sort: $sort) {
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        ...StaffFragment
                        teams {
                            edges {
                                node {
                                    id
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${staffFragment}
`;

export const SuggestInputStaff = createSuggestInputWithQuery<NodeType.Staff, NodeType.StaffViewerStaffSearch>({
    gqlQuery: QL,
    gqlQueryConnectionPath: 'viewer.staff',
    mapQueryToSearch: (query) => {
        return {
            user: {
                anyString: [query],
            },
        };
    },
    formatOptionLabel: (item) => {
        const staffName = stringFormatters.formatEntityName(item);
        const staffRole = item?.role?.name;
        const teams = fromEdges(item?.teams);
        if (staffRole) {
            return (
                <div className={'flex flex-row items-center w-full'}>
                    <div>{staffName}</div>
                    <div className={'flex-1'} />
                    {teams.map((t) => (
                        <div
                            className={
                                'hidden md:inline-block ml-1 rounded px-2 bg-tertiary text-tertiary-foreground text-sm max-w-32 whitespace-nowrap overflow-hidden overflow-ellipsis'
                            }
                            key={t.id}
                        >
                            {t.title}
                        </div>
                    ))}
                </div>
            );
        } else {
            return <>{staffName}</>;
        }
    },
    getOptionLabel: (item) => {
        return stringFormatters.formatEntityName(item) || 'N/A';
    },
});

export const FormikSuggestInputStaff = withFormikSuggestInput(SuggestInputStaff);
