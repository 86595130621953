import * as React from 'react';
import { FormFieldInputDebounced } from '@ez/components';
import { useServiceJobListActions } from '../../redux';

export const FilterByServiceJobNumber: React.FC<{ label?: string; placeholder?: string }> = ({
    label,
    placeholder,
}) => {
    const { State, Actions } = useServiceJobListActions();
    const { serviceJobNumber } = State.filters;

    const handleChange = (term: string) => {
        const newValue = term?.trim();
        if (!newValue) {
            Actions.setServiceJobNumber(null);
        } else {
            Actions.setServiceJobNumber(newValue);
        }
    };

    return (
        <FormFieldInputDebounced
            label={label}
            variant={'search'}
            value={serviceJobNumber}
            placeholder={placeholder || 'Service Case ID...'}
            onChange={handleChange}
        />
    );
};

export const FilterByWorkOrderNumber: React.FC<{ label?: string; placeholder?: string }> = ({ label, placeholder }) => {
    const { State, Actions } = useServiceJobListActions();
    const { workOrderNumber } = State.filters;

    const handleChange = (term: string) => {
        const newValue = term?.trim();
        if (!newValue) {
            Actions.setWorkOrderNumber(null);
        } else {
            Actions.setWorkOrderNumber(newValue);
        }
    };

    return (
        <FormFieldInputDebounced
            label={label}
            variant={'search'}
            value={workOrderNumber}
            placeholder={placeholder || 'Work Order ID...'}
            onChange={handleChange}
        />
    );
};

export const FilterByCustomerCRN: React.FC<{ label?: string; placeholder?: string }> = ({ label, placeholder }) => {
    const { State, Actions } = useServiceJobListActions();
    const { customerCRN } = State.filters;

    const handleChange = (term: string) => {
        const newValue = term?.trim();
        if (!newValue) {
            Actions.setCustomerCRN(null);
        } else {
            Actions.setCustomerCRN(newValue);
        }
    };

    return (
        <FormFieldInputDebounced
            label={label}
            variant={'search'}
            value={customerCRN}
            placeholder={placeholder || 'Customer CRN...'}
            onChange={handleChange}
        />
    );
};
