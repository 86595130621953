import * as React from 'react';
import { LayoutControlProps } from '../AppLayoutManager';
import { Icon } from '../../Icon';
import { NavbarMenuItem } from './NavbarMenuItem';
import { clsxm } from '../../utils';
import * as Menubar from '@radix-ui/react-menubar';

export const NavbarRoot: React.FC<LayoutControlProps> = ({ showSidebarToggle, onToggleMenu, children }) => {
    // Note: it is important to use the id='header' for the NavbarMenuContainer
    return (
        <nav className={'ez-navbar-root'}>
            {showSidebarToggle && onToggleMenu && (
                <NavbarMenuItem
                    style={{ minWidth: '32px' }}
                    onClick={() => onToggleMenu()}
                    icon={<Icon style={{ fontSize: '18px', color: 'white' }} inverted name={'bars'} />}
                />
            )}
            <Menubar.Root className={'h-full flex-1 flex flex-row items-center gap-1'}>{children}</Menubar.Root>
        </nav>
    );
};

export const NavbarMenuSection: React.FC<{
    position?: 'left' | 'right';
    className?: string;
}> = ({ className, position, children }) => {
    return (
        <>
            <div
                className={clsxm([
                    'ez-navbar-menu-section',
                    position === 'left' && 'justify-start',
                    position === 'right' && 'justify-end',
                    className,
                ])}
            >
                {children}
            </div>
        </>
    );
};
