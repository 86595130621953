import * as React from 'react';
import { AuthConnectProps } from '@ez/api-core';
import { IAppNavigatorProps } from '@poolware/react-app-navigator';
import { ThemeStateProps } from '../../app-theme';
import { getAppConfig, WithAppConfigProps } from '../../app-config';
import { Alert, AlertHeader, Button, FormikDefaultFormBase, FormikInputField } from '@ez/components';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Required'),
    password: Yup.string().required('Required'),
});

export interface PageVariantDaveyProps
    extends AuthConnectProps,
        IAppNavigatorProps,
        ThemeStateProps,
        WithAppConfigProps {
    onSubmit: (email: string, password: string) => Promise<void>;
}

const config = getAppConfig();

export const LoginForm: React.FC<PageVariantDaveyProps> = (props) => {
    const { AuthState } = props;

    const initialValues = {
        email: '',
        password: '',
    };

    const onSubmit = async (values: typeof initialValues) => {
        await props.onSubmit(values.email, values.password);
    };

    return (
        <div>
            <FormikDefaultFormBase
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {(formikBag) => {
                    const handleSubmit = async (event) => {
                        event.preventDefault();
                        await formikBag.submitForm();
                    };

                    return (
                        <form className={'flex flex-col gap-4'}>
                            {AuthState.authError && (
                                <Alert type={'error'}>
                                    <p>{AuthState.authError}</p>
                                </Alert>
                            )}
                            <FormikInputField label={'Email'} name={'email'} type={'email'} />
                            <FormikInputField label={'Password'} name={'password'} type={'password'} />
                            <Button
                                type={'submit'}
                                variant={'primary'}
                                color={'primary'}
                                className={'w-full'}
                                onClick={handleSubmit}
                            >
                                Login
                            </Button>
                            <div>
                                <div className={'my-2 text-right'}>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        id={'forgot-password'}
                                        href={config.api.passwordResetURL}
                                    >
                                        Forgot password?
                                    </a>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </FormikDefaultFormBase>
        </div>
    );
};

export const PageVariantDavey: React.FC<PageVariantDaveyProps> = (props) => {
    return (
        <div className={'bg-[#404040] h-full w-full flex flex-row gap-4'}>
            <div className={'flex-1 flex flex-col full md:max-w-[480px] p-4'}>
                <div className={'flex-0 flex flex-row gap-4 w-full items-center justify-between px-8'}>
                    <img className={'size-36'} src={'/themes/davey/Davey_logotype_Yellow.svg'} />
                    <div className={'w-0.5 h-20 border border-l border-l-white'} />
                    <img className={'size-36'} src={'/themes/davey/90_years_logo_White.svg'} />
                </div>
                <div className={'flex-1 w-full grid items-center mb-20'}>
                    <div className={'rounded bg-black w-full m-auto overflow-clip shadow'}>
                        <div className={'flex flex-row items-center justify-center gap-2 py-2 rounded-t'}>
                            <img className={'size-8 rounded'} src={'/themes/davey/icon.png'} />
                            <span className={'text-xl text-bold text-white uppercase'}>Login</span>
                        </div>
                        <div className={'p-4 bg-[#DDDDDD]'}>
                            <LoginForm {...props} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={'hidden md:block flex-1 relative'}>
                <img className={'w-full h-full object-cover'} src={'/themes/davey/Davey-Pool-Pro-BG-Image.jpg'} />
                <img className={'absolute left-4 top-8 h-32'} src={'/themes/davey/PoolPro_STACK_Yellow_White.svg'} />
            </div>
        </div>
    );
};
