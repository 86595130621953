import * as React from 'react';
import { NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { FormikDefaultForm, FormikInputField, PageLayout, toastError } from '@ez/components';
import * as Yup from 'yup';
import { FormikParentTraitDeclarationInput } from './ParentTraitDeclarationInput';
import * as _ from 'lodash';
import { FormikSuggestInputOrganisationType, useOrgSwitcher, useViewer } from '@poolware/app-shell';
import { queryNames } from '../query-names';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';

const ValidationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
    tag: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!'),
});

const New: React.FC<PageControlProps> = (props) => {
    const { AppNavigator } = useAppNavigator();
    const { organisation } = useOrgSwitcher();
    const { viewer } = useViewer();

    const onSubmit = async (values, actions) => {
        try {
            const { name, tag, parent } = values;
            const mutationInput = {
                name: name ? _.trim(name) : undefined,
                tag: tag ? _.trim(tag) : undefined,
                parent: parent ? NodeType.extractId(parent) : undefined,
                organisationType: values.organisation,
            };
            const res = await props.ProductCatalogMutator.createTraitDeclaration(mutationInput);
            AppNavigator.replace(`/${res.id}`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Error', description: e.message });
        }
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin();
    };

    const { parent } = AppNavigator.state;
    const initialValues = {
        name: '',
        organisation: organisation || viewer.organisation,
        parent,
    };

    return (
        <PageLayout>
            <PageLayout.BodySection width={'screen-md'}>
                <FormikDefaultForm
                    header={'New Trait Declaration'}
                    debug={true}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={ValidationSchema}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    submitButton={{ content: 'Create' }}
                >
                    <FormikInputField label={'Trait Declaration Name'} name={'name'} required={true} />
                    <FormikSuggestInputOrganisationType label={'Organisation'} name={'organisation'} required={true} />
                    <FormikParentTraitDeclarationInput label={'Parent Trait'} name={'parent'} />
                </FormikDefaultForm>
            </PageLayout.BodySection>
        </PageLayout>
    );
};

export interface PageControlProps extends IProductCatalogMutators {}

export default withProductCatalogMutators(queryNames)(New);
