import * as React from 'react';
import { useMobileAppointmentCtx } from '../Root';
import { extractRecurrenceData } from '../../utils';
import { Frame, SectionHeader } from '../components';
import { clsxm, Display, Icon, PageLayout } from '@ez/components';
import { ModuleIconNames } from '../../../constants';
import { NodeType } from '@poolware/api';
import moment from 'moment';
import * as URLBuilder from '../../url-builder';
import { Link } from 'react-router-dom';

const SectionItem: React.FC<{ icon: string; label?: React.ReactNode }> = ({ icon, label, children }) => {
    return (
        <div className={'flex flex-row gap-1 items-start '}>
            <Icon className={'!opacity-90'} name={icon} />
            <div className={'flex flex-col md:flex-row gap-x-2 items-start'}>
                <div className={'opacity-90'}>{children}</div>
                {label && <div className={'bg-tertiary text-sm rounded-full px-2 text-tertiary italic'}> {label}</div>}
            </div>
        </div>
    );
};

export const JobTitle: React.FC<{ appointmentItem: NodeType.AppointmentItem }> = ({ appointmentItem }) => {
    const { serviceJob } = appointmentItem;

    const recurData = extractRecurrenceData(appointmentItem);
    const jobTitle = serviceJob?.title;
    const groupTitle = serviceJob?.group?.title || 'Appointment';

    let recurIconColor: string = undefined;
    if (recurData.isRecurring) {
        if (recurData.isRecurringService) {
            recurIconColor = 'bg-purple text-white';
        } else {
            recurIconColor = 'bg-blue text-white';
        }
    }

    return (
        <div>
            <div className={'flex flex-row gap-2 items-center'}>
                <div className={clsxm('rounded-lg px-2 py-0 bg-gray-200 text-black', recurIconColor)}>
                    {recurData.isRecurring ? <Icon name={'refresh'} /> : <Icon name={ModuleIconNames.ServiceCall} />}
                    {groupTitle && <span className={'text-xs uppercase'}>{groupTitle}</span>}
                </div>
                <b>{jobTitle}</b>
            </div>
        </div>
    );
};
const timeRangeFormatter = (startDate: Date | string, duration: number) => {
    const startMoment = moment(startDate);
    const endMoment = moment(startDate).add(duration, 'm');
    if (startMoment.isSame(endMoment, 'day')) {
        return (
            <span>
                {startMoment.format('HH:mm')} - {endMoment.format('HH:mm')} ({duration} min)
            </span>
        );
    } else {
    }
};

interface PageSectionProps {}

export const PageSectionApptDetails: React.FC<PageSectionProps> = () => {
    const { appointmentItem } = useMobileAppointmentCtx();
    const { address, pool, staff, customer, isRecurring, isRecurrentOrigin } = appointmentItem;

    const poolVolume = pool?.volume || '--';
    const poolType = pool?.type?.name;
    const poolString = `${poolType}, ${poolVolume} L`;
    const poolSanitiser = pool?.sanitiser?.name;
    const poolSiteAccess = pool?.site?.accessKeyLocation;
    const jobAddress = address || pool?.address;

    return (
        <>
            <PageLayout.BodySection vStack={true}>
                <JobTitle appointmentItem={appointmentItem} />
                <Frame bordered={true} className={'bg-tertiary flex flex-row items-start gap-1'}>
                    <Icon name={'calendar outline'} />
                    <div className={'flex flex-row flex-wrap justify-between flex-1 items-start'}>
                        <div className={'flex flex-col'}>
                            <Display.Date value={appointmentItem?.startDate} format={'dddd, D MMMM'} />
                            <span className={'text-sm text-tertiary'}>
                                {timeRangeFormatter(appointmentItem?.startDate, appointmentItem?.duration)}
                            </span>
                        </div>
                        <div
                            className={
                                'flex flex-row flex-nowrap py-2 px-4 rounded-full bg-panel text-primary border border-solid border-tertiary border-0.5 text-sm'
                            }
                        >
                            <Icon name={'spy'} />
                            <Display.Entity
                                value={appointmentItem.staff}
                                className={'overflow-ellipsis whitespace-nowrap overflow-hidden max-w-36'}
                            />
                        </div>
                    </div>
                </Frame>
            </PageLayout.BodySection>

            <SectionHeader>Appointment Details</SectionHeader>
            <PageLayout.BodySection vStack={true}>
                <Frame bordered={true}>
                    <SectionItem icon={'user'}>
                        {customer ? (
                            <div>
                                <strong>
                                    <Link to={URLBuilder.Customer(customer.id).view}>
                                        <Display.Entity value={customer} />
                                    </Link>
                                </strong>
                                <div>({customer?.crn})</div>
                            </div>
                        ) : (
                            <div>
                                <strong>Customer</strong> (unknown)
                            </div>
                        )}
                    </SectionItem>
                    <div className={'w-full border-t'} />
                    <Frame className={'gap-2 p-0'}>
                        <SectionItem icon={'phone'} label={customer?.primaryPhone?.label}>
                            {customer?.primaryPhone ? (
                                <Display.Phone value={customer?.primaryPhone?.data} />
                            ) : (
                                <i>unknown</i>
                            )}
                        </SectionItem>
                        <SectionItem icon={'mail'} label={customer?.primaryEmail?.label}>
                            {customer?.primaryEmail ? (
                                <Display.Email value={customer?.primaryEmail?.data} />
                            ) : (
                                <i>unknown</i>
                            )}
                        </SectionItem>
                    </Frame>
                </Frame>
                {pool ? (
                    <Frame bordered={true}>
                        <SectionItem icon={'life ring outline'}>
                            <b>{pool?.name || 'Pool'}</b> ({pool?.bottleNumber})
                        </SectionItem>
                        <div className={'w-full border-t'} />
                        <Frame className={'gap-2'}>
                            <SectionItem icon={'map marker alternate'}>
                                {jobAddress ? <Display.Address value={jobAddress} showMapLink={true} /> : 'unknown'}
                            </SectionItem>
                            {pool && (
                                <>
                                    <SectionItem icon={'life ring outline'}>
                                        {poolString} ({pool?.bottleNumber})
                                    </SectionItem>
                                    {poolSanitiser && <SectionItem icon="box">{poolSanitiser}</SectionItem>}
                                    {poolSiteAccess && (
                                        <SectionItem icon={'key'}>
                                            <div className={'max-h-40 text-tertiary italic'}>
                                                <Display.FormattedText value={poolSiteAccess} limit={180} />
                                            </div>
                                        </SectionItem>
                                    )}
                                </>
                            )}
                        </Frame>
                    </Frame>
                ) : (
                    <Frame bordered={true}>
                        <SectionItem icon={'life ring outline'}>
                            <b>Pool</b> (Unknown)
                        </SectionItem>
                        <div className={'w-full border-t'} />
                        <SectionItem icon={'map marker alternate'}>
                            <i>unknown</i>
                        </SectionItem>
                    </Frame>
                )}
            </PageLayout.BodySection>
        </>
    );
};
