import * as React from 'react';
import { Optionable, Panel, SegmentedSelectButtons, ThemeInterface, ViewJSON } from '@ez/components';
import { getThemeByName, ThemeName } from '@poolware/themes';
import { useTheme } from '../app-theme';
import { generateCssVarsMap } from '@ez/components/dist/app-theme/theme-css-vars';
import { useMemo } from 'react';

export interface HomeProps {}

const themes: Record<any, ThemeInterface | null> = {
    default: null,
};

const themeOptions: Optionable<keyof typeof themes>[] = Object.keys(ThemeName).map((key) => ({
    text: key,
    value: ThemeName[key],
}));

export const ThemeSwitchButtons = () => {
    const { ThemeState, ThemeAction } = useTheme();

    const onSetTheme = (o: Optionable<keyof typeof themes> | null) => {
        if (!o || o.value === 'default') {
            ThemeAction.setDefaultTheme();
        } else {
            const baseURL = process.env.PUBLIC_URL || '';
            const theme = getThemeByName(baseURL, o.value);
            ThemeAction.setTheme(theme);
        }
    };

    return (
        <SegmentedSelectButtons
            separated={true}
            fluid={false}
            onChange={(o) => onSetTheme(o)}
            value={ThemeState.theme.themeName}
            options={themeOptions}
        />
    );
};

export const AppThemeSwitcher: React.FC<HomeProps> = ({}) => {
    const { ThemeState } = useTheme();
    const cssVars = useMemo(() => generateCssVarsMap(ThemeState.theme), [ThemeState.theme]);

    return (
        <Panel>
            <Panel.Header basic={true} icon={'paint brush'} content={'Theme'} />
            <Panel.Body>
                <ThemeSwitchButtons />
                <Panel.Divider />
                <Panel.Item label={'Active Theme'} content={ThemeState.theme?.themeName} />
                <Panel.ItemJSONView wrap={true} content={cssVars} label={'Theme CSS vars'} />
                <Panel.ItemJSONView wrap={true} content={ThemeState} label={'Theme Config'} />
            </Panel.Body>
        </Panel>
    );
};
