import * as React from 'react';
import { useMemo } from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import {
    Alert,
    DebugJsonButton,
    Icon,
    MenuBar,
    MenuBarDropdownItemWithConfirm,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    Modal,
    PageLayout,
    PageTabs,
    StickyMenuBar,
    TabPaneConf,
    toastError,
    toastSuccess,
    useModalCtrl,
    useTabCtrl,
} from '@ez/components';
import { useViewer } from '@poolware/app-shell';
import { PanelAppointments } from './PanelAppointments';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { NodeType } from '@poolware/api';
import { PanelFormServiceJobDetails } from './PanelFormServiceJobDetails';
import { PanelServiceJobTemplate } from './PanelServiceJobTemplate';
import { PanelStopService } from './PanelStopService';
import { TableWorkOrders } from './PanelWorkOrders/TableWorkOrders';
import { PanelFormCustomerAndLocation } from './PanelFormCustomerAndLocation';
import { PanelDeleteService } from './PanelDeleteService';
import { PanelServiceSchedules } from './PanelServiceSchedules/PanelServiceSchedules';
import { AppointmentViewContextProvider } from '../../Scheduler';
import { RecurCasePageProvider, useRecurCaseCtx } from './RecurCaseProvider';

const DeleteMessage: React.FC<{ serviceJob: NodeType.ServiceJob }> = ({ serviceJob }) => {
    return (
        <div>
            <p>
                If you proceed, the following will happen:
                <ul>
                    <li>
                        Recurring Service #{serviceJob.serviceJobNumber}{' '}
                        <span style={{ color: 'red' }}>will be deleted</span>!
                    </li>
                    <li>
                        ALL work orders associated with this service{' '}
                        <span style={{ color: 'red' }}>will be deleted</span>!
                    </li>
                    <li>
                        ALL appointments (future and past!) created by this service{' '}
                        <span style={{ color: 'red' }}>will be deleted</span>!
                    </li>
                </ul>
            </p>
            <p>
                <b>There is NO UNDO for this action!</b>
            </p>
        </div>
    );
};

const DeleteServiceJobMenuButtonItem = ({ onDelete, serviceJob }) => {
    return (
        <MenuBarDropdownItemWithConfirm
            icon={'trash'}
            color="orange"
            content={`Delete Recurring Service`}
            onClick={onDelete}
            confirm={{
                showLoaderOnConfirm: true,
                confirmMessage: {
                    header: `Delete Recurring Service?`,
                    content: <DeleteMessage serviceJob={serviceJob} />,
                },
                negative: true,
                confirmButton: {
                    content: 'Delete',
                    icon: 'trash',
                },
                cancelButton: {
                    content: 'Keep',
                },
            }}
        />
    );
};

const usePageTabCtrl = () => {
    const { AppNavigator } = useAppNavigator();
    const { serviceJob } = useRecurCaseCtx();
    const panes: TabPaneConf[] = useMemo(() => {
        if (!serviceJob) return [];
        return [
            {
                key: '1',
                title: 'Appointments',
                icon: 'calendar alternate outline',
                render: () => <PanelAppointments serviceJob={serviceJob} />,
            },
            {
                key: '2',
                title: 'Work Orders',
                icon: ModuleIconNames.WorkOrder,
                render: () => <TableWorkOrders serviceJob={serviceJob} />,
            },
            {
                key: '3',
                title: 'Service Job Template',
                icon: ModuleIconNames.JobTodoTemplate,
                render: () => <PanelServiceJobTemplate />,
            },
        ];
    }, [serviceJob]);

    return useTabCtrl({ panes, defaultTab: AppNavigator.tab, onTabChange: AppNavigator.setTab });
};

const Page: React.FC = () => {
    const modalCtrlStop = useModalCtrl();
    const modalCtrlDelete = useModalCtrl();
    const { AppNavigator } = useAppNavigator();
    const { isAdmin } = useViewer();
    const { serviceJobMutators, serviceJob, canEdit, isStopped, isArchived, isActive } = useRecurCaseCtx();

    const tabCtrl = usePageTabCtrl();

    const goBack = () => {
        AppNavigator.navigateToOrigin('/sj/recur', { relativeToModule: true });
    };

    const onDelete = async () => {
        try {
            const res = window.confirm('Are you absolutely sure you want to delete this service?');
            if (!res) {
                return;
            }
            await serviceJobMutators.ServiceJobMutator.delete(serviceJob);
            toastSuccess({ title: 'Deleted successfully' });
            AppNavigator.replaceToOrigin('/sj/recur', { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    const menuBarGroup: MenuBarGroupProps = {
        menu: {
            menuMode: MenuBarGroupMODE.DROPDOWN,
            color: 'blue',
            icon: 'bars',
            direction: 'left',
        },
        items: [
            !isStopped && {
                locked: !canEdit,
                onClick: modalCtrlStop.onOpen,
                icon: 'cancel',
                responsive: true,
                content: 'Stop Service...',
                color: 'blue',
            },
            isStopped && {
                locked: !canEdit,
                onClick: modalCtrlDelete.onOpen,
                icon: 'trash',
                content: 'Delete...',
                color: 'red',
            },
            isAdmin && {
                render: <DeleteServiceJobMenuButtonItem serviceJob={serviceJob} onDelete={onDelete} />,
            },
        ],
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item onClick={goBack} icon={'chevron left'} title={'Back'} />
                    <MenuBar.HeaderItem>
                        <Icon
                            color={isActive ? ModuleColorNames.RecurringService : undefined}
                            name={ModuleIconNames.RecurringService}
                        />
                        Recurring Service
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <DebugJsonButton data={serviceJob} />
                    <MenuBar.Group {...menuBarGroup} />
                </MenuBar.Section>
            </StickyMenuBar>

            {isArchived && (
                <Alert type={'warning'}>
                    <Icon name={'archive'} /> Recurring Service is archived
                </Alert>
            )}
            <PageLayout.PageHeader>
                <Icon name={ModuleIconNames.RecurringService} style={{ marginRight: '0.25rem' }} />
                {serviceJob.serviceJobNumber} - {serviceJob.title}
            </PageLayout.PageHeader>

            <PageLayout.BodySection width={'screen-wide'}>
                <PageLayout.TwoColumns>
                    <PanelFormServiceJobDetails />
                    <PanelFormCustomerAndLocation />
                </PageLayout.TwoColumns>

                <PanelServiceSchedules />

                <div className={'my-2'} />

                <PageTabs {...tabCtrl} />

                {modalCtrlStop.open && (
                    <Modal {...modalCtrlStop}>
                        <PanelStopService
                            serviceJob={serviceJob}
                            serviceJobMutators={serviceJobMutators}
                            onDone={modalCtrlStop.onClose}
                        />
                    </Modal>
                )}
            </PageLayout.BodySection>

            <Modal size={'small'} {...modalCtrlDelete}>
                <PanelDeleteService onDone={modalCtrlDelete.onClose} />
            </Modal>
        </PageLayout>
    );
};

export const ServiceJobRecurPageView: React.FC = (props) => {
    return (
        <AppointmentViewContextProvider>
            <RecurCasePageProvider>
                <Page {...props} />
            </RecurCasePageProvider>
        </AppointmentViewContextProvider>
    );
};
