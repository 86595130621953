import { NodeType } from '@poolware/api';

export { CalNavigate, CalViewMode } from '@poolware/react-big-calendar';

export enum CalViewLayoutMode {
    GRID = 'grid',
    LIST = 'list',
}

export enum CalResourceMode {
    SPLIT = 'split',
    COMBINED = 'combined',
}

export enum FileStatus {
    PENDING = 'Pending',
    UPLOADING = 'Uploading',
    UPLOADED = 'Uploaded',
    FAILED = 'Failed',
}

export type FileHandle = {
    tempId: string;
    status: FileStatus;
    error?: string;
    file: File;
    preview?: string;
    beforeAfterStatus?: NodeType.FileUploadBeforeAfterEnum;
};

export type FileUploadFn = (files: FileHandle[]) => Promise<{
    success: { file: FileHandle; error?: null; fileId: string }[];
    failed: { file: FileHandle; error: Error }[];
}>;
