import * as React from 'react';
import { useMemo, useState } from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    FormFieldInputDebounced,
    Icon,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    Optionable,
    PageLayout,
    SegmentedSelectButtons,
    StickyMenuBar,
} from '@ez/components';
import { NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { StatusFilterType, useQueryWarrantyClaimsConnection } from './use-query-warranty-claims-connection';
import { OwnershipLabel } from '../components/OwnershipLabel';
import { CellText } from '../WarrantyClaimsHQ/components';
import { usePersistedString } from '@ez/tools';
import { useViewer } from '@poolware/app-shell';

export const FilterByTypeButtons: React.FC<{ value: StatusFilterType; onChange }> = ({ value = 'ALL', onChange }) => {
    const allOptions: Optionable<StatusFilterType>[] = [
        {
            text: 'All',
            // icon: '',
            value: 'ALL',
        },
        {
            text: 'Opened',
            icon: 'circle outline',
            value: 'OPEN',
        },
        {
            text: 'Closed',
            icon: 'check',
            value: 'CLOSED',
        },
    ];

    return (
        <SegmentedSelectButtons
            // size={'small'}
            options={allOptions}
            value={value}
            onChange={(v) => {
                onChange(v.value);
            }}
        />
    );
};

const getStatusConf = (statusGroup: NodeType.WarrantyClaimStatusGroupEnum): any => {
    switch (statusGroup) {
        case NodeType.WarrantyClaimStatusGroupEnum.CLOSED:
            return {
                name: 'Closed',
                icon: 'check',
                iconColor: 'green',
            };
        case NodeType.WarrantyClaimStatusGroupEnum.NEW:
            return {
                name: 'Submitted',
                icon: 'circle outline',
                iconColor: 'grey',
                highlight: true,
            };
        case NodeType.WarrantyClaimStatusGroupEnum.ON_HOLD:
            return {
                name: 'On Hold',
                icon: 'pause',
                iconColor: 'orange',
            };
        case NodeType.WarrantyClaimStatusGroupEnum.PROCESSING:
            return {
                name: 'Processing',
                icon: 'sync alternate',
                iconColor: 'blue',
            };
        default:
            return {
                name: 'Unknown',
                icon: '',
                iconColor: undefined,
            };
    }
};

const StatusIndicator: React.FC<{ claim: NodeType.WarrantyClaim }> = ({ claim }) => {
    const statusGroup = claim?.statusGroup;
    const isSubmitted = Boolean(claim?.submittedAt);

    if (!isSubmitted) {
        return (
            <>
                <Icon name={'edit'} color={'grey'} />
                Draft
            </>
        );
    }

    // Address if claim is confirmed, but status is NEW, then treat is as PROCESSING
    const statusConf = getStatusConf(statusGroup);

    return (
        <CellText>
            {statusConf.icon && <Icon name={statusConf.icon} color={statusConf.iconColor} />}
            <span>{statusConf.name}</span>
        </CellText>
    );
};

const useTableDef = () => {
    const tableDef: ConnectionTableDef<NodeType.WarrantyClaim> = useMemo(
        () => [
            {
                header: 'Status',
                width: '1',
                cell: (r) => {
                    return <StatusIndicator claim={r} />;
                },
            },
            {
                header: 'Claim ID',
                // sortKey: 'name',
                cell: (r) => <>{r.warrantyClaimNumber}</>,
            },
            {
                header: 'Customer',
                cell: (r) => <Display.Entity value={r.refCustomer} />,
            },
            {
                header: 'Asset/Product',
                cell: (r) => <>{r.assetName}</>,
            },
            {
                header: 'Asset Serial',
                cell: (r) => <>{r.assetSerial}</>,
            },
            {
                header: 'Install Date',
                cell: (r) => <Display.Date format={'LL'} value={r.assetInstallDate} />,
            },
            {
                header: 'Claim Date',
                cell: (r) => <Display.Date format={'LL'} value={r.claimedAt} />,
            },
            {
                header: 'Submit Date',
                cell: (r) => <Display.Date format={'LLL'} value={r.submittedAt} />,
            },
            {
                header: 'Submitted by',
                cell: (r) => <Display.Entity value={r.addedBy} />,
            },
            {
                header: 'Franchise',
                cell: (r) => {
                    return (
                        <span>
                            <OwnershipLabel franchise={r.franchise} />
                        </span>
                    );
                },
            },
        ],
        []
    );

    return tableDef;
};

export const PageList: React.FC = () => {
    const { modulesAccess } = useViewer();
    const { AppNavigator } = useAppNavigator();
    const [filterValue, setFilterValue] = usePersistedString<StatusFilterType>(
        'warranty-claims.franchise.list.filter',
        'ALL'
    );
    const [searchString, setSearchString] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const { connectionData, connectionState } = useQueryWarrantyClaimsConnection({
        searchClaimId: searchString,
        statusFilter: filterValue,
    });
    const tableDef = useTableDef();

    const onView = (item) => {
        AppNavigator.navigateRelative(`/${item.id}`, { setOrigin: true });
    };

    const onNew = () => {
        AppNavigator.navigateRelative(`/new`);
    };

    return (
        <PageLayout>
            {!modulesAccess.Warranties.franchiseMsgInbox && (
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarHeaderItem icon={'certificate'}>Warranty Claims</MenuBarHeaderItem>
                    </MenuBarSection>
                    <MenuBarSection position={'right'}>
                        {false && (
                            <MenuBarItem
                                icon={'search'}
                                onClick={() => {
                                    setShowSearch(!showSearch);
                                }}
                            />
                        )}
                        <MenuBarItem onClick={onNew} icon={'plus'} color={'green'} title={'New Claim'} />
                    </MenuBarSection>
                </StickyMenuBar>
            )}

            <FilterByTypeButtons value={filterValue} onChange={setFilterValue} />

            {showSearch && (
                <div style={{ maxWidth: '380px' }}>
                    <FormFieldInputDebounced variant={'search'} value={searchString} onChange={setSearchString} />
                </div>
            )}

            <DefaultConnectionTable
                debug={false}
                onRowClick={onView}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </PageLayout>
    );
};
