import * as React from 'react';

import { Field } from 'formik';
import { AppointmentGroupSingleSelector } from '../../Components/AppointmentGroupSingleSelector';
import { FormField, FormikFormFieldLabel } from '@ez/components';

interface AppointmentGroupFieldProps {
    name: string;
    label: string;
    required?: boolean;
}

const AppointmentGroupField: React.FC<AppointmentGroupFieldProps> = ({ name, label, required }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleChange = (newValue) => form.setFieldValue(field.name, newValue);
                const value = field?.value?.id;
                return (
                    <FormField className={'max-w-xs'}>
                        <FormikFormFieldLabel label={label} name={name} required={required} />
                        <AppointmentGroupSingleSelector selectedGroupId={value} onChange={handleChange} />
                    </FormField>
                );
            }}
        </Field>
    );
};

export default AppointmentGroupField;
