import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { addressFragment, contactFragment, fromEdges, NodeType } from '@poolware/api';
import { SearchValuesType } from './SearchForm';
import _set from 'lodash/set';
import _words from 'lodash/words';

export const performGraphQLQuery = async (
    client: ApolloClient<any>,
    query,
    queryVariables: any = {},
    forceFetch: boolean = false
): Promise<any> => {
    return client.query({ query, variables: queryVariables, fetchPolicy: forceFetch ? 'network-only' : undefined });
};

export const entityAsCustomerFragment = gql`
    fragment EntityAsCustomerFragment_Search on Entity {
        id
        user {
            id
            firstName
            lastName
            customer {
                id
                contactName
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const customerFragment = gql`
    fragment CustomerFragment_Search on Customer {
        id
        crn
        contactName
        user {
            id
            firstName
            lastName
            entity {
                id
                franchise {
                    id
                    name
                }
                chainUpdate
            }
        }
    }
`;

export const poolFragment = gql`
    fragment PoolFragment_Search on Pool {
        id
        name
        volume
        bottleNumber
        sanitiser {
            id
            name
        }
        site {
            id
            address {
                id
                ...AddressFragment
            }
        }
        type {
            id
            name
            description
        }
        note
        entity {
            id
            ...EntityAsCustomerFragment_Search
        }
    }
    ${addressFragment}
    ${entityAsCustomerFragment}
`;

export const createCustomerSearchQueryVariable = (input: SearchValuesType): NodeType.CustomerViewerCustomersSearch => {
    const { crn, customerName, contact, address } = input;
    const req = {};

    if (address) {
        _set(req, 'user.entity.addresses.anyString', _words(address));

        if (!crn) {
            _set(req, 'user.entity.addresses.detailsSortDirection', false);
        }
    }

    if (crn) {
        _set(req, 'crn.like', crn);
    }

    const customerNameWords = _words(customerName);
    if (customerNameWords.length > 0) {
        _set(req, 'user.anyString', customerNameWords);
    }

    if (contact) {
        let contactSanitized = contact;
        try {
            contactSanitized = contact.replace(/ /g, ''); // remove spaces from string.
        } catch (e) {
            console.error(e);
        }
        _set(req, 'user.entity.contacts.data.like', contactSanitized);
    }

    return req;
};

export const performCustomerSearch = async (
    client: ApolloClient<any>,
    searchVariables: SearchValuesType,
    forceFetch: boolean = false
) => {
    const customerSearchQuery = gql`
        query searchCustomer($customerSearchQuery: CustomerViewerCustomersSearch) {
            viewer {
                customers(first: 100, search: $customerSearchQuery, sort: { crn: true }) {
                    edges {
                        node {
                            id
                            ...CustomerFragment_Search
                            nextAppointment {
                                id
                                startDate
                            }
                            primaryAddress {
                                id
                                ...AddressFragment
                            }
                            billAddress {
                                id
                                ...AddressFragment
                            }
                            root {
                                id
                                contacts(first: 10) {
                                    edges {
                                        node {
                                            id
                                            ...ContactFragment
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        ${customerFragment}
        ${addressFragment}
        ${contactFragment}
    `;

    const searchQueryVariables = createCustomerSearchQueryVariable(searchVariables);

    const res = await performGraphQLQuery(
        client,
        customerSearchQuery,
        { customerSearchQuery: searchQueryVariables },
        forceFetch
    );
    return { customers: fromEdges(res?.data?.viewer?.customers) };
};

export const createPoolSearchQueryVariables = (input: SearchValuesType): NodeType.PoolViewerPoolsSearch => {
    const { bottleNumber, address } = input;
    return {
        bottleNumber: {
            like: bottleNumber,
        },
        site: {
            address: {
                anyString: _words(address),
                detailsSortDirection: bottleNumber ? undefined : false,
            },
        },
    };
};

export const performPoolsSearch = async (
    client: ApolloClient<any>,
    searchVariables: SearchValuesType,
    forceFetch: boolean = false
) => {
    const searchQuery = gql`
        query searchPools($poolSearchQuery: PoolViewerPoolsSearch) {
            viewer {
                pools(first: 100, search: $poolSearchQuery, sort: { bottleNumber: true }) {
                    edges {
                        node {
                            id
                            name
                            bottleNumber
                            address {
                                id
                                ...AddressFragment
                            }
                            entity {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                    customer {
                                        id
                                        primaryAddress {
                                            id
                                            ...AddressFragment
                                        }
                                        billAddress {
                                            id
                                            ...AddressFragment
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        ${addressFragment}
    `;

    const searchQueryVariables = createPoolSearchQueryVariables(searchVariables);

    const res = await performGraphQLQuery(client, searchQuery, { poolSearchQuery: searchQueryVariables }, forceFetch);
    return { pools: fromEdges(res?.data?.viewer?.pools) };
};

export const performSiteSearch = async (
    client: ApolloClient<any>,
    searchVariables: SearchValuesType,
    forceFetch: boolean = false
) => {
    const searchQuery = gql`
        query searchAddress($searchQuery: SiteViewerSitesSearch) {
            viewer {
                sites(first: 100, search: $searchQuery) {
                    edges {
                        node {
                            id
                            name
                            address {
                                id
                                ...AddressFragment
                            }
                            entity {
                                id
                                ...EntityAsCustomerFragment_Search
                            }
                            pools(first: 100) {
                                edges {
                                    node {
                                        id
                                        ...PoolFragment_Search
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        ${entityAsCustomerFragment}
        ${poolFragment}
    `;

    const { address, siteName } = searchVariables;

    const searchQueryVariables: NodeType.SiteViewerSitesSearch = {
        address: {
            anyString: _words(address),
            detailsSortDirection: false,
        },
    };

    if (siteName) {
        searchQueryVariables.name = {
            like: siteName,
        };
    }

    const res = await performGraphQLQuery(client, searchQuery, { searchQuery: searchQueryVariables }, forceFetch);
    return { sites: fromEdges(res?.data?.viewer?.sites) };
};
