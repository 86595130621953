import * as React from 'react';
import { ActionBar, FormFieldInputDebounced } from '@ez/components';
import { useWorkOrderFeedActions } from '../../redux';

export const FilterByWorkOrderNumber: React.FC<{ label?: string; placeholder?: string }> = ({ label, placeholder }) => {
    const { Action, State } = useWorkOrderFeedActions();
    const { woNumber } = State.filters;

    const handleChange = (term: string) => {
        const newValue = term?.trim();
        if (!newValue) {
            Action.setWorkOrderNumber(null);
        } else {
            Action.setWorkOrderNumber(newValue);
        }
    };

    return (
        <ActionBar>
            <ActionBar.Header content={label} icon={'filter'} />
            <FormFieldInputDebounced
                value={woNumber}
                placeholder={placeholder || 'Work Order ID...'}
                onChange={handleChange}
            />
        </ActionBar>
    );
};
