import * as React from 'react';
import { NodeType } from '@poolware/api';
import { withFormikSuggestInput } from '@ez/components';
import { createSuggestInputWithQuery } from '@poolware/app-shell';
import gql from 'graphql-tag';

export const OfficeSuggestInput = createSuggestInputWithQuery<NodeType.FranchiseWarranter>({
    gqlQuery: gql`
        query FranchiseWarranters {
            viewer {
                franchiseWarranters {
                    edges {
                        node {
                            id
                            email
                            name
                        }
                    }
                }
            }
        }
    `,
    gqlQueryConnectionPath: 'viewer.franchiseWarranters',
    getOptionLabel: (item) => item.name,
    formatOptionLabel: (item) => {
        return (
            <span>
                {item?.name} | {item?.email}
            </span>
        );
    },
});

export const FormikSuggestInputOffice = withFormikSuggestInput(OfficeSuggestInput);
