import * as React from 'react';
import { ConnectionTableDef, DefaultConnectionTable, Display, Icon } from '@ez/components';
import { useMemo } from 'react';
import { NodeType } from '@poolware/api';
import { useQueryEzcDeviceLinksConnection } from '../../../Devices/common/use-query-ezc-device-link-connection';

const useTableDef = (isAdmin?: boolean) => {
    return useMemo(() => {
        const tableDef: ConnectionTableDef<NodeType.EZCDeviceLink> = [
            // {
            //     header: 'Status',
            //     // width: "10px",
            //     cellProps: {
            //         width: '10px',
            //     },
            //     cell: (link) => {
            //         return (
            //             <>
            //                 <Icon name={'circle'} color={'green'} />
            //             </>
            //         );
            //     },
            // },
            {
                header: 'Device',
                width: '3',
                cell: (link) => {
                    return (
                        <>
                            <Icon name={'circle'} color={'green'} />
                            {link.device?.title || '--'}
                        </>
                    );
                },
            },

            // {
            //     header: 'Label',
            //     width: '1',
            //     cell: (link) => {
            //         return link.label;
            //     },
            // },
            {
                header: 'Owner',
                width: '3',
                cell: (item) => {
                    return <Display.Entity value={item.owner} />;
                },
            },
            {
                header: 'Pool',
                cell: (item) => {
                    return (
                        <div>
                            {item.pool?.name} ({item.pool?.volume || '--'} L)
                        </div>
                    );
                },
            },
            {
                header: 'Address',
                cell: (item) => {
                    const address = item?.pool?.address || item?.owner?.primaryAddress;
                    return <Display.Address value={address} />;
                },
            },
            // {
            //     header: 'Device Version',
            //     width: '1',
            //     cell: (link) => {
            //         return link.device?.version || '--';
            //     },
            // },
            // {
            //     header: '',
            //     cell: (item) => {
            //         return <Icon name={'chevron right'} />;
            //     },
            // },
        ];

        if (isAdmin) {
            tableDef.push({
                header: 'Franchise',
                cell: (r) => {
                    return <>{r.franchise?.name}</>;
                },
            });
        }

        return tableDef;
    }, [isAdmin]);
};

export interface EzsDevicesProps {
    poolId?: string;
    ownerId?: string;
    onView?: (device: NodeType.EZCDeviceLink) => void;
}

export const EzsDevicesTable: React.FC<EzsDevicesProps> = ({ poolId, ownerId, onView }) => {
    const { connectionData, connectionState } = useQueryEzcDeviceLinksConnection({
        search: {
            pool: {
                id: poolId,
            },
            owner: {
                id: ownerId,
            },
        },
    });

    const tableDef = useTableDef();
    return (
        <div>
            <DefaultConnectionTable
                debug={false}
                onRowClick={onView}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </div>
    );
};
