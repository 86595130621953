import * as React from 'react';
import {
    ButtonWithConfirm,
    ConfirmProps,
    FormikDefaultForm,
    FormikInputField,
    Panel,
    toastError,
} from '@ez/components';
import { NodeType, useMutationServiceJobGroup } from '@poolware/api';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
});

export interface FormNewServiceTemplateGroupProps {
    onCancel: () => any;
    onDone: (newId: NodeType.ID) => any;
    refetchQueries?: any;
    parentGroup?: NodeType.ServiceJobGroup;
}

export const FormNewServiceTemplateGroup: React.FC<FormNewServiceTemplateGroupProps> = ({
    onCancel,
    onDone,
    refetchQueries,
    parentGroup,
}) => {
    const { create } = useMutationServiceJobGroup({ refetchQueries: [refetchQueries] });

    const initialValues = {
        title: '',
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const res = await create({
                title: values.title?.trim() || '',
                parent: parentGroup?.id || null,
            });

            const id = res?.data?.ServiceJobGroup?.ServiceJobGroup?.id;
            onDone(id);
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            validationSchema={validationSchema}
            header={'New Service Group'}
            initialValues={initialValues}
            submitButton={{ content: 'Create' }}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            {parentGroup && (
                <>
                    <Panel.Item label={'Parent Group'} content={parentGroup.title} />
                    <Panel.Divider />
                </>
            )}
            <FormikInputField autoFocus label={'Group Name'} name={'title'} required={true} />
        </FormikDefaultForm>
    );
};

const confirm: ConfirmProps = {
    negative: true,
    confirmMessage: {
        header: 'Delete Service Job Group?',
        content: (
            <div>
                <p>If you proceed, this service job group will be delete.</p>
                <p>All service job templates belonging to this groups will not be deleted.</p>
            </div>
        ),
    },
    confirmButton: {
        content: 'Delete',
        icon: 'trash',
    },
    cancelButton: {
        content: 'Keep',
    },
};

const ButtonDelete: React.FC<{ onClick }> = ({ onClick }) => {
    return (
        <ButtonWithConfirm
            content={'Delete'}
            size={'mini'}
            icon={'trash'}
            confirm={confirm}
            basic={true}
            onClick={onClick}
        />
    );
};

export const FormEditServiceTemplateGroup: React.FC<
    FormNewServiceTemplateGroupProps & {
        group: NodeType.ServiceJobGroup;
        onDidDelete: () => any;
    }
> = ({ onCancel, onDone, group, refetchQueries, onDidDelete }) => {
    const { update, delete: deleteGroup } = useMutationServiceJobGroup({ refetchQueries: [refetchQueries] });

    const initialValues = {
        title: group?.title,
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const res = await update({
                id: group.id,
                title: values.title?.trim() || '',
            });

            const id = res?.data?.ServiceJobGroup?.ServiceJobGroup?.id;
            onDone(id);
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onDelete = async () => {
        try {
            await deleteGroup({ id: group.id });
            onDidDelete?.();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            validationSchema={validationSchema}
            header={'Edit Group'}
            initialValues={initialValues}
            submitButton={{ content: 'Update' }}
            onSubmit={onSubmit}
            onCancel={onCancel}
            headerButton={[<ButtonDelete onClick={onDelete} />]}
        >
            <FormikInputField autoFocus label={'Group Name'} name={'title'} required={true} />
        </FormikDefaultForm>
    );
};
