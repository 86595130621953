import { Display, IconButton, Modal, Panel, PanelHeaderProps, PanelItemProps, SemanticCOLORS } from '@ez/components';
import { CustomerLink, PoolLink } from '../Links';
import * as React from 'react';
import { NodeType } from '@poolware/api';
import { createContactItemView } from './ContactItems';
import _truncate from 'lodash/truncate';
import { CustomerContactsPanel } from './CustomerContactsPanel';

const CustomerContactsModalButton = ({ customer }) => {
    return (
        <Modal
            centered={false}
            size={'small'}
            trigger={
                <IconButton icon={'address book outline'} color={'primary'} size={'sm'}>
                    <span style={{ color: '#a5673f' }}>All Contacts</span>
                </IconButton>
            }
            closeIcon={true}
        >
            <CustomerContactsPanel customerId={customer.id} />
        </Modal>
    );
};

const LocationDetailsModalButton = ({ address, pool }) => {
    const poolName = pool?.name || '--';
    const poolVolume = pool?.volume || '--';
    const poolType = pool?.type?.name;
    const poolString = `${_truncate(poolName, { length: 60 })} (${poolVolume} L, ${poolType})`;
    const poolSanitiser = pool?.sanitiser?.name;
    const poolSiteAccess = pool?.site?.accessKeyLocation;

    return (
        <Modal
            centered={false}
            trigger={
                <IconButton icon={'life ring outline'} color={'primary'} size={'sm'}>
                    <span style={{ color: '#a5673f' }}>Pool Details</span>
                </IconButton>
            }
            closeIcon={true}
        >
            <Panel>
                <Panel.Header content={'Pool'} icon={'life ring'} />
                <Panel.Body>
                    <>
                        {address && (
                            <Panel.Item label={'Address'}>
                                <Display.Address value={address} showMapLink={true} />
                            </Panel.Item>
                        )}

                        {pool && (
                            <>
                                <Panel.Item label={'Pool'}>{poolString}</Panel.Item>

                                {poolSanitiser && <Panel.Item label="Pool Sanitiser">{poolSanitiser}</Panel.Item>}

                                {poolSiteAccess && (
                                    <Panel.Item label="Site Access Key">
                                        <Display.FormattedText value={poolSiteAccess} limit={180} />
                                    </Panel.Item>
                                )}
                            </>
                        )}
                    </>
                </Panel.Body>
            </Panel>
        </Modal>
    );
};

interface CustomerAndLocationPreviewPanelProps {
    customer?: NodeType.Customer;
    pool?: NodeType.Pool;
    address?: NodeType.Address;
    headerButton?: PanelHeaderProps['button'];
    headerLabel?: React.ReactNode;
    color?: SemanticCOLORS;
}

const PanelItem: React.FC<PanelItemProps> = (props) => <Panel.Item labelWidth={130} {...props} />;

const getDefaultHeaderLabel = (customer: NodeType.Customer) => {
    return customer ? (
        <>
            <b>Customer</b> - {customer?.crn}
        </>
    ) : (
        <b>Customer</b>
    );
};

export const PanelCustomerAndLocationBodyItems: React.FC<{
    customer?: NodeType.Customer;
    pool?: NodeType.Pool;
    address?: NodeType.Address;
}> = ({ customer, pool, address }) => {
    const hasCustomer = Boolean(customer);
    const hasLocation = Boolean(pool || address);

    if (!hasLocation && !hasCustomer) {
        return (
            <>
                <div className={'my-2'} />
            </>
        );
    }

    const poolName = pool?.name || '--';
    const poolVolume = pool?.volume || '--';
    const poolType = pool?.type?.name;
    const poolString = `${_truncate(poolName, { length: 40 })} (${poolVolume} L, ${poolType})`;
    const poolSanitiser = pool?.sanitiser?.name;

    const PrimaryPhoneView = customer?.primaryPhone && createContactItemView(customer?.primaryPhone).renderComp;
    const PrimaryEmailView = customer?.primaryEmail && createContactItemView(customer?.primaryEmail).renderComp;

    return (
        <>
            {customer && (
                <>
                    <Panel.SectionHeader
                        size={'small'}
                        content={'Customer & Preferred Contact'}
                        button={<CustomerContactsModalButton customer={customer} />}
                    />

                    <PanelItem label={'Customer CRN'}>{customer.crn}</PanelItem>

                    <PanelItem label={'Customer'}>
                        <CustomerLink id={customer.id}>
                            <Display.Entity value={customer} />
                        </CustomerLink>
                        {customer.contactName && <> ({customer.contactName})</>}
                    </PanelItem>
                    {customer?.companyName && (
                        <PanelItem label={'Company'} iconMarker={'building'}>
                            {customer.companyName}
                        </PanelItem>
                    )}

                    {PrimaryPhoneView && (
                        <PanelItem label={'Primary Phone'}>
                            <PrimaryPhoneView />
                        </PanelItem>
                    )}

                    {PrimaryEmailView && (
                        <PanelItem label={'Primary Email'}>
                            <PrimaryEmailView />
                        </PanelItem>
                    )}
                </>
            )}

            {hasLocation && (
                <>
                    <Panel.SectionHeader
                        size={'small'}
                        button={pool && <LocationDetailsModalButton pool={pool} address={address} />}
                    >
                        Location
                    </Panel.SectionHeader>

                    {address && (
                        <PanelItem label={'Address'}>
                            <Display.Address value={address} showMapLink={true} />
                        </PanelItem>
                    )}

                    {pool && (
                        <>
                            <PanelItem label={'Pool'}>
                                <PoolLink poolId={pool.id} customerId={customer ? customer.id : undefined}>
                                    {poolString}
                                </PoolLink>
                            </PanelItem>

                            {poolSanitiser && <PanelItem label="Pool Sanitiser">{poolSanitiser}</PanelItem>}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export const PanelCustomerAndLocation: React.FC<CustomerAndLocationPreviewPanelProps> = ({
    customer,
    pool,
    address,
    headerLabel,
    headerButton,
    color,
}) => {
    headerLabel = headerLabel || getDefaultHeaderLabel(customer);

    return (
        <Panel>
            <Panel.Header color={color} icon={'user'} content={headerLabel} button={headerButton} />
            <Panel.Body>
                <PanelCustomerAndLocationBodyItems address={address} customer={customer} pool={pool} />
            </Panel.Body>
        </Panel>
    );
};
