import * as React from 'react';
import { CreateStaffRegistrationInputType } from '@poolware/api';
import { FormikDefaultForm, FormikInputField, PageLayout, toastError } from '@ez/components';
import { PageControlProps } from './Page.Control';
import { useAppNavigator } from '@poolware/react-app-navigator';
import * as Yup from 'yup';
import { FormikSuggestInputRole } from '@poolware/app-shell';

// import { generateFakeStaff } from './faker';

const validationSchema = Yup.object().shape({
    password: Yup.string().min(10).max(64, 'Too Long!').required('Required'),
    role: Yup.mixed().required('Required'),
    firstName: Yup.string().max(100, 'Too Long!').required('Required'),
    lastName: Yup.string().max(100).required('Required'),
    email: Yup.string().email().required('Required'),
});

const StaffEditPage: React.FC<PageControlProps> = (props) => {
    const { AppNavigator } = useAppNavigator();

    let initialValues = {
        role: undefined,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    };

    const onSubmit = async (values: typeof initialValues) => {
        const staffRegConfig: CreateStaffRegistrationInputType = {
            firstName: values.firstName?.trim(),
            lastName: values.lastName?.trim(),
            role: values.role,
            email: values.email?.trim(),
            password: values.password,
            franchise: props.franchise?.id,
        };
        try {
            await props.StaffMutator.createRegistration(staffRegConfig);
            AppNavigator.replaceToOrigin();
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to register staff', description: error.message });
        }
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin();
    };

    // if (isDevelopment) {
    //     initialValues = { ...initialValues, ...generateFakeStaff(props.roles) };
    // }

    return (
        <PageLayout>
            <PageLayout.BodySection width={'screen-md'}>
                <FormikDefaultForm
                    validationSchema={validationSchema}
                    header={'New Staff'}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    submitButton={{ content: 'Register' }}
                >
                    <FormikInputField name={'firstName'} label={'First Name'} required={true} />
                    <FormikInputField name={'lastName'} label={'Last Name'} required={true} />
                    <FormikSuggestInputRole label={'Role'} name={'role'} />
                    <FormikInputField name={'email'} label={'email'} required={true} />
                    <FormikInputField
                        name={'password'}
                        label={'Password'}
                        type={'password'}
                        autoComplete={'new-password'}
                        required={true}
                    />
                </FormikDefaultForm>
            </PageLayout.BodySection>
        </PageLayout>
    );
};

export default StaffEditPage;
