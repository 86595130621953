import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { fromEdges, NodeType, useMutationWorkOrderTemplate } from '@poolware/api';
import { useDndTable } from '../../../../../components/table-dnd-helpers';
import { JobTodoTemplateAssocEditForm } from './JobTodoTemplateAssocEditForm';
import { JobTodoTemplatePicker } from '../../../../JobTodoTemplate/JobTodoTemplatePicker';
import {
    JobTodoBody,
    JobTodoContainer,
    JobTodoDetailItem,
    JobTodoDetailItemLabel,
    JobTodoDetailItemValue,
    JobTodoHeader,
    JobTodoHeaderLabel,
    JobTodoRowContainer,
    JobTodoRowDraggable,
    JotTodoHeaderSummary,
} from './components';
import { JobTodoTemplateView } from './JobTodoTemplateView';
import {
    ButtonWithDropdownMenu,
    confirmModalImperative,
    ConfirmProps,
    DropdownMenuOptionType,
    Modal,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { JobTodoTemplateAssocNewForm } from './JobTodoTemplateAssocNewForm';
import { Button } from '@ez/components';

const confirmRemove: ConfirmProps = {
    confirmMessage: {
        header: 'Remove Job Task',
        content: 'Remove this Job Task. This job task will be removed but not delete.',
    },
    negative: true,
    confirmButton: {
        content: 'Remove',
        icon: 'unlink',
    },
};

const confirmDelete: ConfirmProps = {
    confirmMessage: {
        header: 'Delete Job Task',
        content: 'There is no UNDO! This job task will be delete.',
    },
    negative: true,
    confirmButton: {
        content: 'Delete',
        icon: 'trash',
    },
};

const confirmEmbed: ConfirmProps = {
    confirmMessage: {
        header: 'Import from template and customize?',
        content: (
            <div>
                <p>
                    If you proceed, this job task will be unlinked from the template and you will be able to customise
                    it.
                </p>
                <p>The original template will stay unchanged.</p>
            </div>
        ),
    },
    confirmButton: {
        content: 'Import',
        icon: 'unlink',
        color: 'orange',
    },
};

type OrderEstimateResult = {
    totalPrice: number;
    totalTime: number;
};
const getWorkOrderSummary = (assocs: NodeType.JobTodoTemplateAssoc[]): OrderEstimateResult => {
    return assocs.reduce<OrderEstimateResult>(
        (acc, curr) => {
            return {
                totalPrice: acc.totalPrice + Number(curr.jobTodoTemplate.recommendedPrice),
                totalTime: acc.totalTime + curr.jobTodoTemplate.timeEstimate,
            };
        },
        { totalPrice: 0, totalTime: 0 }
    );
};

export interface WorkOrderTemplateJobTodosProps {
    workOrderTemplate: NodeType.WorkOrderTemplate;
    templateMutation: ReturnType<typeof useMutationWorkOrderTemplate>;
    refetchQuery: any;
    handleOnTemplatePickerSubmit: any;
    jobTaskTemplatesEnabled?: boolean;
}

export const JobTodoTemplateAssocsList: React.FC<WorkOrderTemplateJobTodosProps> = ({
    workOrderTemplate,
    templateMutation,
    refetchQuery,
    handleOnTemplatePickerSubmit,
    jobTaskTemplatesEnabled = true,
}) => {
    const [appendingNew, setAppendingNew] = useState(false);
    const [editWorkOrderTemplateAssoc, setEditWorkOrderTemplateAssoc] = useState<NodeType.JobTodoTemplateAssoc>(null);
    const modalPicker = useModalCtrl();

    useEffect(() => {
        return () => {
            setEditWorkOrderTemplateAssoc(null);
            setAppendingNew(false);
        };
    }, [workOrderTemplate]);

    const sortedJobTodoTemplateAssocs = useMemo(() => {
        return fromEdges(workOrderTemplate?.jobTodoTemplateAssocs).sort((l, r) => {
            return l.priority > r.priority ? 1 : -1;
        });
    }, [workOrderTemplate]);

    const selectedItems = sortedJobTodoTemplateAssocs.map((assoc) => assoc.jobTodoTemplate);

    const handleOnRemove = async (assoc: NodeType.JobTodoTemplateAssoc) => {
        const answer = await confirmModalImperative(confirmRemove);
        if (!answer) {
            return;
        }

        try {
            await templateMutation.update({
                id: workOrderTemplate.id,
                jobTodoTemplateAssocs: {
                    delete: [assoc.id],
                },
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const handleOnDelete = async (assoc: NodeType.JobTodoTemplateAssoc) => {
        const answer = await confirmModalImperative(confirmDelete);
        if (!answer) {
            return;
        }

        try {
            await templateMutation.update({
                id: workOrderTemplate.id,
                jobTodoTemplateAssocs: {
                    delete: [assoc.id],
                },
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const handleOnEmbed = async (assoc: NodeType.JobTodoTemplateAssoc) => {
        const answer = await confirmModalImperative(confirmEmbed);
        if (!answer) {
            return;
        }

        const convertTemplateToNew = (assoc: NodeType.JobTodoTemplateAssoc) => {
            const jobTodoTemplate = assoc.jobTodoTemplate;
            if (!jobTodoTemplate) return [];
            const items = fromEdges(assoc.jobTodoTemplate?.items);

            const conf: NodeType.UpdateWorkOrderTemplateListJobTodoTemplateAssocCreateInput = {
                priority: assoc.priority,
                jobTodoTemplate: {
                    create: {
                        description: jobTodoTemplate.description,
                        title: jobTodoTemplate.title,
                        timeEstimate: jobTodoTemplate.timeEstimate,
                        recommendedPrice: jobTodoTemplate.recommendedPrice,
                        items: items.map((item) => ({
                            title: item.title,
                            description: item.description,
                            orderIndex: item.orderIndex,
                        })),
                    },
                },
            };
            return [conf];
        };

        try {
            const conf = convertTemplateToNew(assoc);
            await templateMutation.update({
                id: workOrderTemplate.id,
                jobTodoTemplateAssocs: {
                    delete: [assoc.id],
                    create: conf,
                },
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onReorder = async (tableRowItems) => {
        try {
            const reindexedItems = tableRowItems
                .map((assoc, index) => ({
                    id: assoc.id,
                    oldPriority: assoc.priority,
                    newPriority: index,
                }))
                .filter((item) => item.oldPriority !== item.newPriority)
                .map((item) => ({ id: item.id, priority: item.newPriority }));

            await templateMutation.update({
                id: workOrderTemplate.id,
                jobTodoTemplateAssocs: {
                    update: reindexedItems,
                },
            });
        } catch (e) {
            toastError(e);
        }
    };

    const { rowItems, dropRow, moveRow } = useDndTable({ items: sortedJobTodoTemplateAssocs, onReorder });

    const workOrderSummary = getWorkOrderSummary(sortedJobTodoTemplateAssocs);

    function getMenuItems(assoc: NodeType.JobTodoTemplateAssoc) {
        const isGlobalJobTodoTemplate = !Boolean(assoc?.jobTodoTemplate?.ownedByWorkOrderTemplate?.id);

        const menuItems: DropdownMenuOptionType[] = isGlobalJobTodoTemplate
            ? [
                  {
                      content: 'Unlink from Task Template and customise',
                      icon: 'unlink',
                      onClick: () => handleOnEmbed(assoc),
                  },
                  {
                      content: 'Remove',
                      icon: 'trash',
                      onClick: () => handleOnRemove(assoc),
                  },
              ]
            : [
                  {
                      content: 'Edit',
                      icon: 'edit',
                      onClick: () => setEditWorkOrderTemplateAssoc(assoc),
                  },
                  {
                      content: 'Delete',
                      icon: 'trash',
                      onClick: () => handleOnDelete(assoc),
                  },
              ];
        return menuItems;
    }

    const jobTaskEditingMode = !!editWorkOrderTemplateAssoc || appendingNew;

    return (
        <JobTodoContainer>
            <JobTodoHeader>
                <JobTodoHeaderLabel>Job Tasks</JobTodoHeaderLabel>
                <JotTodoHeaderSummary>
                    <JobTodoDetailItem>
                        <JobTodoDetailItemLabel>Total Duration:</JobTodoDetailItemLabel>
                        <JobTodoDetailItemValue>{workOrderSummary.totalTime} min</JobTodoDetailItemValue>
                    </JobTodoDetailItem>
                    {false && (
                        <JobTodoDetailItem>
                            <JobTodoDetailItemLabel>Total Price:</JobTodoDetailItemLabel>
                            <JobTodoDetailItemValue>$ {workOrderSummary.totalPrice}.00</JobTodoDetailItemValue>
                        </JobTodoDetailItem>
                    )}
                </JotTodoHeaderSummary>
            </JobTodoHeader>

            <JobTodoBody>
                {rowItems.map((assoc, index) => {
                    const isEditingMode = !!editWorkOrderTemplateAssoc;
                    const isEditingAssoc = assoc.id === editWorkOrderTemplateAssoc?.id;
                    const menuItems = getMenuItems(assoc);

                    if (isEditingAssoc) {
                        return (
                            <JobTodoRowDraggable key={assoc.id} index={index} moveRow={moveRow} dropRow={dropRow}>
                                <div className={'row-content-main edit'}>
                                    <JobTodoTemplateAssocEditForm
                                        jobTodoTemplateAssoc={assoc}
                                        onDone={() => setEditWorkOrderTemplateAssoc(null)}
                                        refetchQuery={refetchQuery}
                                    />
                                </div>
                            </JobTodoRowDraggable>
                        );
                    }

                    return (
                        <JobTodoRowDraggable key={assoc.id} index={index} moveRow={moveRow} dropRow={dropRow}>
                            <JobTodoTemplateView
                                jobTodoTemplate={assoc.jobTodoTemplate}
                                menuItems={menuItems}
                                isEditingMode={isEditingMode}
                            />
                        </JobTodoRowDraggable>
                    );
                })}

                {appendingNew && (
                    <JobTodoRowContainer>
                        <div className={'row-content'}>
                            <div className={'drag-handle nondraggable'} />
                            <JobTodoTemplateAssocNewForm
                                refetchQuery={refetchQuery}
                                workOrderTemplate={workOrderTemplate}
                                onDone={() => setAppendingNew(false)}
                            />
                        </div>
                    </JobTodoRowContainer>
                )}
            </JobTodoBody>

            {/*<Divider hidden={true} />*/}

            {!jobTaskTemplatesEnabled && !jobTaskEditingMode && (
                <Button //
                    icon={'plus'}
                    color={'blue'}
                    content={'Add Job Task'}
                    onClick={() => setAppendingNew(true)}
                />
            )}
            {jobTaskTemplatesEnabled && !jobTaskEditingMode && (
                <ButtonWithDropdownMenu
                    icon={'plus'}
                    color={'blue'}
                    content={'Add Job Task'}
                    options={[
                        {
                            content: 'Add New',
                            onClick: () => setAppendingNew(true),
                        },
                        {
                            content: 'Add from templates',
                            onClick: modalPicker.onOpen,
                        },
                    ]}
                />
            )}

            <Modal {...modalPicker}>
                <JobTodoTemplatePicker
                    onCancel={modalPicker.onClose}
                    onSubmit={async (d) => {
                        await handleOnTemplatePickerSubmit(d);
                        modalPicker.onClose();
                    }}
                    selectedItems={selectedItems}
                />
            </Modal>
        </JobTodoContainer>
    );
};
