import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from '@ez/tools';
import { FormField, FormFieldLabel } from '../form-components';
import { Input, InputProps, InputWithAction, InputWithActionProps } from '../radix';
import { useId } from '../utils';
import { FormFieldBaseProps } from './FormFieldTypes';

export type FormFieldInputOptionType = {
    key: string | number;
    value: any;
    text: string;
};

export interface FormFieldInputProps extends Omit<InputProps, 'name' | 'onChange'>, FormFieldBaseProps {
    datalist?: FormFieldInputOptionType[];
    options?: FormFieldInputOptionType[]; // Same as datalist
    subLabel?: React.ReactNode | string;
    subLabelStyle?: any;
    debounceMs?: number;
    actionRight?: React.ReactNode;
    onChange?: (value: string) => void;
    variant?: 'default' | 'search';
}

const variantPropsMap: { [key in 'default' | 'search']: Partial<InputProps> } = {
    search: {
        iconRight: 'search',
        placeholder: 'Search...',
        clearable: true,
    },
    default: {},
};

export const FormFieldInput = React.forwardRef<HTMLInputElement, FormFieldInputProps>(
    (
        {
            dataTestId,
            datalist,
            options,
            label,
            required,
            className,
            subLabel,
            debounceMs = 0,
            subLabelStyle,
            disabled,
            readOnly,
            actionRight,
            value = '',
            id,
            onChange,
            error,
            variant,
            ...rest
        },
        ref
    ) => {
        const fieldId = useId(id);
        const opts = datalist || options;
        const datalistname = opts?.length ? `datalist-${id}` : undefined;

        const [presVal, setPresValue] = useState(value);

        useEffect(() => setPresValue(value), [value]);

        const setValue = (value: string) => {
            onChange?.(value);
        };

        const handleChange = (newValue) => {
            setPresValue(newValue);
            if (debounceMs > 1) {
                debouncedHandleChange(newValue);
            } else {
                setValue(newValue);
            }
        };

        const [debouncedHandleChange] = useDebouncedCallback((value) => {
            setValue(value);
        }, debounceMs);

        const isDisabled = disabled || readOnly;

        const variantProps = variantPropsMap[variant || 'default'];
        return (
            <FormField className={className}>
                <FormFieldLabel
                    readOnly={readOnly}
                    disabled={disabled}
                    required={required}
                    label={label}
                    errorLabel={error}
                    htmlFor={fieldId}
                />
                <div className={'flex flex-row w-full'}>
                    <Input
                        autoComplete="off"
                        ref={ref}
                        list={datalistname}
                        aria-invalid={!!error}
                        id={fieldId}
                        // name={name}
                        value={presVal}
                        onChange={(event) => {
                            handleChange(event.target.value);
                        }}
                        data-testid={dataTestId}
                        disabled={isDisabled}
                        readOnly={readOnly}
                        className={actionRight && 'border-r-0 rounded-r-none'}
                        onClear={() => {
                            handleChange('');
                        }}
                        {...variantProps}
                        {...rest}
                    />
                    {actionRight}
                </div>
                {opts?.length > 0 && (
                    <datalist id={datalistname}>
                        {opts.map((d, index) => (
                            <option key={d.key || index} value={d.value} />
                        ))}
                    </datalist>
                )}
            </FormField>
        );
    }
);

export const FormFieldInputDebounced: React.FC<FormFieldInputProps> = (props) => {
    return <FormFieldInput debounceMs={500} {...props} />;
};

export const FormFieldInputWithAction: React.FC<InputWithActionProps & FormFieldBaseProps> = ({
    label,
    fluid = false,
    className,
    readOnly,
    required,
    id,
    error,
    disabled,
    ...rest
}) => {
    return (
        <FormField>
            <FormFieldLabel readOnly={readOnly} label={label} required={required} htmlFor={id} />
            <InputWithAction
                id={id}
                actionLabel={'Select'}
                hasError={!!error}
                readOnly={readOnly}
                disabled={disabled}
                {...rest}
            />
        </FormField>
    );
};
