import * as React from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    PageLayout,
    StickyMenuBar,
} from '@ez/components';
import { IAppNavigatorProps, ModuleLink, withAppNavigator } from '@poolware/react-app-navigator';
import * as _ from 'lodash';
import { NodeType } from '@poolware/api';
import { QueryPoolProductDeclarationsConnection } from '../../../queries/QueryPoolProductDeclarationsConnection';

interface PageControlProps extends IAppNavigatorProps {}

const Page: React.FC<PageControlProps> = (props) => {
    const onNew = () => {
        props.AppNavigator.navigate('/new', { relativeToModule: true });
    };

    const tableDef: ConnectionTableDef<NodeType.PoolProductDeclaration> = [
        {
            header: 'Name',
            cell: (pd) => <ModuleLink to={`/${pd.id}`}>{pd.name}</ModuleLink>,
        },
        {
            header: 'Declaration',
            cell: (pd) => _.get(pd, 'declaration.name', '--'),
            width: 3,
        },
    ];

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem>Pool Traits</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarItem onClick={onNew} icon={'plus'} color={'green'} title={'New'} />
                </MenuBarSection>
            </StickyMenuBar>
            <QueryPoolProductDeclarationsConnection>
                {({ connectionData, connectionState }) => {
                    return (
                        <DefaultConnectionTable
                            connectionData={connectionData}
                            connectionState={connectionState}
                            tableDef={tableDef}
                        />
                    );
                }}
            </QueryPoolProductDeclarationsConnection>
        </PageLayout>
    );
};

export default withAppNavigator()(Page);
