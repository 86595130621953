import * as React from 'react';
import Page from './Page';
import _sortBy from 'lodash/sortBy';
import { compose, mapProps } from '@ez/tools';
import {
    customerFragment,
    fromEdges,
    ICustomerMutatorsProps,
    NodeType,
    staffFragment,
    withCustomerMutators,
} from '@poolware/api';
import { stringFormatters, withApolloLoading, Icon } from '@ez/components';
import * as ContactsEditors from '../../../CommonComponents/Contacts/ContactsEditors';
import CustomerContactsEditors from '../../../CommonComponents/Contacts/ContactsEditors_Customer';
import ContactItem from '../../../CommonComponents/Contacts/ContactItem';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withViewer, WithViewerProps } from '@poolware/app-shell';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';

const { formatAddress, formatEntityName } = stringFormatters;

type ContactItemsType = {
    detailsItems: ContactItem[];
    entityContactItems: ContactItem[];
};

const prepareContactItems = (customer: NodeType.Customer): ContactItemsType => {
    if (!customer) {
        return { detailsItems: [], entityContactItems: [] };
    }

    // Contact items extracted from "Customer" and "User" objects

    const makeSpan = (val) => () => <span>{val}</span>;

    const customerDetailsItems = [
        new ContactItem({
            label: 'CRN',
            view: makeSpan(customer.crn),
        }),

        new ContactItem({
            label: 'Name',
            view: makeSpan(formatEntityName(customer)),
            editView: CustomerContactsEditors.EditUserNamePage(customer),
        }),

        new ContactItem({
            label: 'Contact Name',
            view: makeSpan(customer.contactName),
            editView: CustomerContactsEditors.EditCustomerContactNamePage(customer),
            deleteView: customer.contactName
                ? CustomerContactsEditors.DeleteCustomerContactNamePage(customer)
                : undefined,
        }),
        new ContactItem({
            label: 'Company Name',
            view: makeSpan(customer.companyName),
            deleteView: customer.companyName
                ? CustomerContactsEditors.DeleteCustomerCompanyNamePage(customer)
                : undefined,
            editView: CustomerContactsEditors.EditCustomerCompanyNamePage(customer),
        }),
        new ContactItem({
            label: 'Primary Address',
            view: makeSpan(formatAddress(customer.primaryAddress)),
            editView: customer.primaryAddress ? CustomerContactsEditors.EditPrimaryAddressPage(customer) : undefined,
            newView: CustomerContactsEditors.NewPrimaryAddressPage(customer),
        }),
        new ContactItem({
            label: 'Bill Address',
            view: makeSpan(formatAddress(customer.billAddress)),
            deleteView: customer.billAddress ? CustomerContactsEditors.DeleteBillAddressPage(customer) : undefined,
            editView: customer.billAddress ? CustomerContactsEditors.EditBillAddressPage(customer) : undefined,
            newView: CustomerContactsEditors.NewBillAddressPage(customer),
        }),
    ];

    // Contact items extracted from "Entity" object

    let entityContacts = fromEdges(customer?.user?.entity?.contacts);

    entityContacts = _sortBy(entityContacts, (c) => {
        return c?.type?.name;
    });

    const entityContactItems = entityContacts.map((contact: NodeType.Contact) => {
        const typeName = contact?.type?.name;
        const displayString = () => (
            <span>
                {contact.data} {contact.label && <i>({contact.label})</i>}
                {contact?.isPrimary && <Icon name={'star'} color={'yellow'} />}
            </span>
        );

        return new ContactItem({
            contact: contact,
            label: typeName,
            view: displayString,
            makePrimaryView: !contact.isPrimary ? ContactsEditors.MakePrimaryContactItemPage(contact) : null,
            editView: ContactsEditors.EditContactItemPage(contact),
            deleteView: ContactsEditors.DeleteContactItemPage(contact),
        });
    });

    return {
        detailsItems: customerDetailsItems,
        entityContactItems: entityContactItems,
    };
};

const prepareContactTypes = (allContactTypes: any[], customer: any): ContactItem[] => {
    return allContactTypes.map((contactType) => {
        return new ContactItem({
            label: contactType.name,
            view: contactType.name,
            newView: ContactsEditors.NewContactItemPage(customer, contactType),
        });
    });
};

export const CustomerEditQuery = gql`
    query CustomerEditQuery($customerId: ID!) {
        customer: node(id: $customerId) {
            id
            ...CustomerFragment
            ... on Customer {
                addedBy {
                    id
                    ...StaffFragment
                }
                createdAt
                registeredAt
                vendLink {
                    id
                }
            }
        }
        viewer {
            contactTypes(first: 1000) {
                edges {
                    node {
                        id
                        name
                        validatorTag
                    }
                }
            }
        }
    }
    ${customerFragment}
    ${staffFragment}
`;

export default compose(
    withAppNavigator(),
    withViewer(),
    graphql(CustomerEditQuery, {
        options: (props: any) => ({
            variables: { customerId: props?.params?.customerId },
            fetchPolicy: 'network-only',
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.customer.id' }),
    mapProps((props) => {
        let customer = props?.data?.customer;
        let allContactTypes = fromEdges(props?.data?.viewer?.contactTypes);
        let contactTypes = prepareContactTypes(allContactTypes, customer);
        let contactItems = prepareContactItems(customer);
        const markers = fromEdges(customer?.markers);

        return {
            ...props,
            customer,
            customerId: customer.id,
            contactItems: contactItems,
            contactTypes: contactTypes,
            markers: markers,
            vendAPI: customer?.root?.franchise?.vend,
        };
    }),
    withCustomerMutators(['QueryCustomersConnection'])
)(Page);

export interface PageControlProps extends IAppNavigatorProps, ICustomerMutatorsProps, WithViewerProps {
    customer: NodeType.Customer;
    contactItems: ContactItemsType;
    contactTypes: any[];
    vendAPI?: NodeType.VendAPI;
}
