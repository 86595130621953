import * as React from 'react';
import { NodeType, useMutationVendAPI } from '@poolware/api';
import { Alert, Display, FormikDefaultForm, FormikInputField, Panel, toast } from '@ez/components';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    vendCRN: Yup.string().required('Required'),
});

export const FormLinkWithVendCustomer: React.FC<{
    vendAPI: NodeType.VendAPI;
    customer: NodeType.Customer;
    onDone?: () => void;
    refetchQueries?: any[];
}> = ({ vendAPI, customer, onDone, refetchQueries }) => {
    const { connectCustomerToVendCRN } = useMutationVendAPI({ refetchQueries });

    const initialValues = {
        vendCRN: '',
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            await connectCustomerToVendCRN({
                id: vendAPI.id,
                customer: customer.id,
                vendCRN: values.vendCRN,
            });
            toast({
                type: 'success',
                title: 'Customer linked with VEND',
            });
            onDone?.();
        } catch (error) {
            let message = error.message || 'Failed to link customer';
            if (message.includes('ON_LINK_EVENT_VEND_CUSTOMER_IN_USE_BY_OTHER')) {
                message = 'Vend Customer Code is already in use by another customer';
            } else {
                console.error(error);
            }
            toast({
                type: 'error',
                time: 4000,
                title: 'Failed to link customer',
                description: message,
            });
        }
    };

    return (
        <FormikDefaultForm
            header={'Link Customer with Vend Customer'}
            initialValues={initialValues}
            submitButton={{ content: 'Link' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            onCancel={onDone}
        >
            <Panel.Item label={'Customer'}>
                <Display.Entity value={customer} />
            </Panel.Item>
            <Panel.Divider hidden={true} />

            <FormikInputField label={'VEND Customer Code'} name={'vendCRN'} />
            <Alert type={'info'}>
                Please enter the customer code from VEND to link with the existing customer. The customer code can be
                found in the customer profile page on VEND.
            </Alert>
        </FormikDefaultForm>
    );
};
