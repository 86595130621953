import * as React from 'react';
import { Icon } from '@ez/components';
import { DropdownStatusChange, TrimmedOneLiner } from './ComponentsVirtual';
import { CalendarEventType } from '../CalendarView/types';
import { DropdownStaff } from '@poolware/app-shell';
import { cn } from '@ez/tools';
import { CellCustomer, CellServiceCase } from './Cells';
import { NodeType } from '@poolware/api';

interface AppointmentTableProps {
    range: {
        startIndex: number;
        endIndex: number;
    };
    tableDef;
    count: number;
    onChangeStatus;
    canChangeStaff;
    onChangeStaff;
    onDidDelete;
    onAppointmentItemEdit;
    onShowInCalendar;
    selectedEventId?: string;
    onSelectedEvent: (event: CalendarEventType | null) => any;
    onSwitchToDockMode: (item: NodeType.AppointmentItem) => any | Promise<any>;
}

export const AppointmentsTable: React.FC<AppointmentTableProps> = ({
    range,
    tableDef,
    count,
    onChangeStatus,
    canChangeStaff,
    onChangeStaff,
    onDidDelete,
    onAppointmentItemEdit,
    onShowInCalendar,
    onSelectedEvent,
    selectedEventId,
    onSwitchToDockMode,
}) => {
    const columnNum = 7;
    let rowIndex = 0;

    const onRowClick = (event: CalendarEventType | null) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        onSelectedEvent(event);
    };
    return (
        <table>
            <colgroup>
                <col width="40px" />
                <col width="90px" />
                <col width="120px" />
                <col width="180px" />
                <col style={{ minWidth: '220px', maxWidth: '260px' }} />
                <col style={{ minWidth: '220px' }} />
                <col style={{ minWidth: '100px', maxWidth: '120px' }} />
            </colgroup>
            <thead>
                <tr>
                    <th>
                        <small>{count}</small>
                    </th>
                    <th>Time</th>
                    <th>Status</th>
                    <th>Staff</th>
                    <th>Customer</th>
                    <th>Job</th>
                    <th>Group</th>
                </tr>
            </thead>
            <tbody>
                {tableDef.sections.length == 0 && (
                    <tr className={'empty-row'}>
                        <td colSpan={columnNum}>---</td>
                    </tr>
                )}
                {tableDef.sections.map((section, sectionIndex) => {
                    rowIndex += 1;
                    return (
                        <React.Fragment key={sectionIndex}>
                            <tr className={'section'}>
                                <th colSpan={columnNum}>
                                    <div className={'count'}>{section.data?.length}</div>
                                    {section.sectionTitle}
                                </th>
                            </tr>

                            {section.data.map((event: CalendarEventType, eventIndex) => {
                                rowIndex += 1;
                                const isRecurring =
                                    event.item?.isRecurring ||
                                    event.item?.isRecurrentOrigin ||
                                    event.item?.serviceJob?.isRecurring;

                                const iconName = isRecurring ? 'refresh' : null;
                                const { color, group } = event.item;

                                const groupColor = group?.defaultColor || color || undefined;
                                const styleFirstCell = {
                                    borderColor: groupColor,
                                    backgroundColor: color,
                                };

                                const isSelected = event.selected || event.id === selectedEventId;
                                const isOutOfRange = rowIndex < range?.startIndex || rowIndex > range?.endIndex;
                                const rowCl = cn('data', {
                                    invisible: isOutOfRange,
                                    selected: isSelected,
                                });
                                const exRowCl = cn('row-expanded', { invisible: isOutOfRange });

                                return (
                                    <React.Fragment key={eventIndex}>
                                        {isOutOfRange ? (
                                            <tr className={rowCl}>
                                                <td className={'first'} onClick={onRowClick(event)}>
                                                    <Icon size={'large'} color={'blue'} name={'chevron right'} />
                                                </td>
                                                <td className={'time'}>
                                                    <Icon name={iconName} /> {event.formattedDate}
                                                </td>
                                                <td colSpan={columnNum - 2}>---</td>
                                            </tr>
                                        ) : (
                                            <tr className={rowCl}>
                                                <td className={'first'} onClick={onRowClick(event)}>
                                                    <Icon size={'large'} color={'blue'} name={'chevron right'} />
                                                </td>
                                                <td className={'time'}>
                                                    <Icon name={iconName} /> {event.formattedDate}
                                                </td>
                                                <td>
                                                    <DropdownStatusChange
                                                        appointmentItem={event.item}
                                                        onChangeStatus={(newStatus) =>
                                                            onChangeStatus(event.item, newStatus)
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <DropdownStaff
                                                        readonly={!canChangeStaff}
                                                        truncateLength={28}
                                                        value={event?.staff?.id}
                                                        onChange={(newStaff) => onChangeStaff(event.item, newStaff)}
                                                    />
                                                </td>
                                                <td>
                                                    <CellCustomer event={event} />
                                                </td>
                                                <td className={'job'}>
                                                    <CellServiceCase event={event} />
                                                </td>
                                                <td className={'group'}>
                                                    <TrimmedOneLiner className={'cont'} style={styleFirstCell}>
                                                        {group?.title || 'Default Group'}
                                                    </TrimmedOneLiner>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table>
    );
};
