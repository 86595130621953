import * as React from 'react';
import { ConnectionPaginationButtons, PageLayout } from '@ez/components';
import { useViewer } from '@poolware/app-shell';
import { DeviceCard, DeviceCardLoadingPlaceholder } from './DeviceCard';
import { LayoutViewProps } from '../types';
import { NoDeviceMessage } from '../common';

export interface PageListProps extends LayoutViewProps {}

export const LayoutCards: React.FC<PageListProps> = ({
    onView,
    connectionData,
    connectionState,
    onNew,
    onFavoriteChange,
    favoriteDevices,
}) => {
    const { isAdmin } = useViewer();

    const isLoading = connectionState.loading && connectionState.isInitialLoading;
    const noData = connectionData.length === 0 && !isLoading;

    return (
        <PageLayout.BodySection>
            {noData && (
                <div className={'p-4'}>
                    <NoDeviceMessage onNew={onNew} />
                </div>
            )}
            <div
                className={
                    'p-1 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-8 gap-4'
                }
            >
                {isLoading && (
                    <>
                        <DeviceCardLoadingPlaceholder />
                        <DeviceCardLoadingPlaceholder />
                        <DeviceCardLoadingPlaceholder />
                    </>
                )}
                {!noData &&
                    connectionData.map((device) => {
                        const isFavorite = favoriteDevices?.includes(device.id);
                        return (
                            <DeviceCard
                                key={device.id}
                                onView={() => onView(device)}
                                device={device}
                                isFavorite={isFavorite}
                                admin={isAdmin}
                                onFavoriteChange={onFavoriteChange}
                            />
                        );
                    })}
            </div>
            <div className={'p-1 mt-4'}>
                <ConnectionPaginationButtons connectionState={connectionState} connectionData={connectionData} />
            </div>
            {/*<DebugJSON data={{ loading, deviceStates, error }} />*/}
        </PageLayout.BodySection>
    );
};
