import { useFormikContext } from 'formik';
import { FormikHeatCalcValueType } from './types';
import { useMutationHeaterRecommendation } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { serializeForm } from './state-serializer';
import { Button, Display, Icon, StickyMenuBar, toastError } from '@ez/components';
import * as React from 'react';

export const PageHeaderNewDocument = () => {
    const { values, dirty } = useFormikContext<FormikHeatCalcValueType>();
    const { create, mutationResult } = useMutationHeaterRecommendation({
        refetchQueries: ['QueryHeaterRecommendationConnection'],
    });

    const { AppNavigator } = useAppNavigator();

    const onSave = async () => {
        try {
            const data = serializeForm(values);
            const result = await create({ payload: data.payload, product: data.productId, entity: data.entityId });
            const id = result.data?.HeaterRecommendation?.HeaterRecommendation?.id;
            AppNavigator.navigateRelative(`/combined/rc/${id}`);
        } catch (e) {
            toastError(e);
        }
    };

    const onClose = () => {
        AppNavigator.navigateRelative('/combined');
    };

    return (
        <StickyMenuBar className={'bg-yellow-50 px-2'}>
            <div className={'flex flex-grow flex-row items-center gap-2'}>
                <Button size={'sm'} icon={'cancel'} content={'Close'} onClick={() => onClose()} />
                <div className={'font-bold text-lg'}>
                    <Icon name={'calculator'} />
                    Heater Recommendation
                </div>
                <div className={'text-indigo-900'}>
                    <Display.Entity value={values?.entity} />
                </div>
            </div>
            <div className={'flex flex-grow flex-row justify-end space-x-4'}>
                <Button
                    size={'sm'}
                    icon={'save outline'}
                    color={'primary'}
                    variant={'primary'}
                    content={'Save'}
                    disabled={!dirty}
                    onClick={() => onSave()}
                />
            </div>
        </StickyMenuBar>
    );
};
