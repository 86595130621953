import { FilterType, useProductCatalogActions } from '../redux';
import { FormFieldInputDebounced } from '@ez/components';
import * as React from 'react';

export interface FilterByStringValueExternalProps {
    label?: string;
    placeholder?: string;
}

type ConfType = {
    filterType: FilterType;
    defaultLabel: string;
    defaultPlaceholder: string;
};

const createConnectedInput = (conf: ConfType): React.FC<FilterByStringValueExternalProps> => {
    return ({ label, placeholder }) => {
        const { ProductCatalogAction, ProductCatalogState } = useProductCatalogActions();

        const nameValue = ProductCatalogState.getFilterValueByType(conf.filterType) as string;
        const filterType = conf.filterType;
        const handleChange = (term: string) => {
            const newValue = term?.trim();
            if (!newValue) {
                ProductCatalogAction.removeFilterItem({
                    type: filterType,
                    filterId: filterType.toString(),
                    value: null,
                });
            } else {
                ProductCatalogAction.replaceFilterItem({
                    type: filterType,
                    filterId: filterType.toString(),
                    value: newValue,
                });
            }
        };

        return (
            <FormFieldInputDebounced
                label={label || conf.defaultLabel}
                value={nameValue || ''}
                placeholder={placeholder || conf.defaultPlaceholder}
                onChange={handleChange}
            />
        );
    };
};

export const FilterByProductName = createConnectedInput({
    filterType: FilterType.NAME,
    defaultPlaceholder: 'Product name...',
    defaultLabel: 'Product',
});

export const FilterBySku = createConnectedInput({
    filterType: FilterType.SKU,
    defaultPlaceholder: 'SKU...',
    defaultLabel: 'SKU',
});
