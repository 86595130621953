import { NodeType } from '@poolware/api';
import { useQueryProductCompanyConnection } from '../queries/QueryProductCompanyConnection';
import { createSuggestInputWithQueryConnectionHook } from '@poolware/app-shell';

export const SuggestSupplierInput = createSuggestInputWithQueryConnectionHook<
    NodeType.ProductCompany,
    NodeType.ProductCompanyViewerProductCompanySearch
>({
    useQueryConnectionHook: useQueryProductCompanyConnection,
    getOptionLabel: (item) => item.name,
    mapQueryToSearch: (query) => ({
        anyString: [query],
    }),
});
