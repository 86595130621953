import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Frame } from './components';
import { Button, Display } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import * as URLBuilder from '../../url-builder';

const WaterTestMeasurementItem: React.FC = (props) => {
    return (
        <div className={'flex flex-row justify-between'}>
            <div className={'flex-1'}>Calcium Hardness</div>
            <div className={'w-20'}>
                <strong>60</strong> pH
            </div>
            <div className={'w-16 text-red'}>HIGH</div>
        </div>
    );
};

export const WaterTestSection: React.FC<{ testReport: NodeType.TestReport }> = ({ testReport }) => {
    const { AppNavigator } = useAppNavigator();
    const openPDFReport = () => {
        window.open(testReport.pdfUrl, '_blank');
    };

    const onViewReport = () => {
        const pool = testReport?.pool;
        const customerId = testReport?.pool?.entity?.user?.customer?.id;
        let link = URLBuilder.Customer(customerId).Pool(pool?.id).WaterTest(testReport.id).view;
        AppNavigator.navigate(link, { setOrigin: true });
    };

    return (
        <Frame className={'bg-tertiary rounded p-2'}>
            <Frame className={'bg-main rounded p-2 flex flex-col gap-4'}>
                <div className={'gap-x-2 gap-y-1 flex-row flex items-center'}>
                    <div className={'bg-blue text-white rounded-full px-2 max-w-fit'}>Water test</div>
                    <strong className={'text-sm'}>
                        <Display.Date value={testReport.createdAt} />{' '}
                    </strong>
                </div>
                <div className={'rounded border p-2 hidden'}>
                    <WaterTestMeasurementItem />
                    <WaterTestMeasurementItem />
                    <WaterTestMeasurementItem />
                    <WaterTestMeasurementItem />
                    <WaterTestMeasurementItem />
                    <WaterTestMeasurementItem />
                </div>
            </Frame>
            <div className={'flex flex-row items-center justify-between'}>
                <Button size={'sm'} variant={'tertiary'} color={'primary'} icon={'chart area'} onClick={onViewReport}>
                    View Report
                </Button>
                <Button size={'sm'} variant={'tertiary'} color={'primary'} icon={'download'} onClick={openPDFReport}>
                    Download PDF
                </Button>
            </div>
        </Frame>
    );
};
