import * as React from 'react';
import { useMemo } from 'react';
import * as URLBuilder from '../../../../routes/url-builder';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    Icon,
    IconWithPopup,
    MenuBar,
    MenuBarHeaderItem,
    PageLayout,
    StickyMenuBar,
    TableRowClickable,
    TableRowDef,
    VStack,
} from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { useQueryCustomersConnection } from './use-query-customer-connection';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useAppLayoutMode, useViewer } from '@poolware/app-shell';
import { AppIconNames } from '../../../../components/AppIcons';

const RenderCRNCell = (customer: NodeType.Customer) => {
    const isOnVend = !!customer?.vendLink?.id;
    return (
        <div>
            {customer.crn} {isOnVend ? <Icon size={'small'} name={'tag'} color={'green'} /> : undefined}
        </div>
    );
};

const RenderCustomerName = (customer: NodeType.Customer) => {
    return (
        <div>
            <Icon name="user" />
            <Display.Entity value={customer} />
        </div>
    );
};

const RenderFranchise = (customer) => {
    return <small>{customer?.user?.entity?.franchise?.name}</small>;
};

const CustomersList: React.FC<any> = () => {
    const { connectionData, connectionState } = useQueryCustomersConnection();
    const { AppNavigator } = useAppNavigator();
    const { isAdmin, modulesAccess } = useViewer();
    const showNextAppt = modulesAccess.FieldServices?.appointments;

    const { gteDesktopMD } = useAppLayoutMode();

    const _addNew = () => {
        AppNavigator.navigate(URLBuilder.Customers.new, {
            setOrigin: true,
            modal: false,
        });
    };

    const tableDef = useMemo(() => {
        const tableDef: ConnectionTableDef<NodeType.Customer> = [
            {
                header: 'CRN',
                sortKey: 'crn',
                cell: (customer) => {
                    return RenderCRNCell(customer);
                },
            },
            {
                header: 'Name',
                cellProps: { width: 6 },
                sortKey: 'lastName',
                cell: (customer) => RenderCustomerName(customer),
            },
            {
                header: 'Contact Name',
                cellProps: { width: 3 },
                sortKey: 'contactName',
                cell: (customer) => customer.contactName,
            },
            {
                header: 'Company',
                cellProps: { width: 3 },
                cell: (customer) => {
                    return customer?.companyName;
                },
            },
            {
                header: 'Pools',
                // width: 1,
                cellProps: { textAlign: 'center' },
                cell: (customer) => {
                    const count = fromEdges(customer?.pools).length;
                    if (count === 0) return null;
                    return (
                        <span className={'test-sm text-tertiary'}>
                            <IconWithPopup name={AppIconNames.Pool} popup={{ content: 'Pools' }} />
                            {count}
                        </span>
                    );
                },
            },
        ];

        if (gteDesktopMD) {
            if (modulesAccess.FieldServices?.recurringCases) {
                tableDef.push({
                    header: 'Rec. Services',
                    // width: 1,
                    cellProps: { textAlign: 'center' },
                    cell: (customer) => {
                        const count = fromEdges(customer.serviceJobs).length;
                        if (count == 0) {
                            return null;
                        }
                        return (
                            <span className={'test-sm text-tertiary'}>
                                <IconWithPopup
                                    name={AppIconNames.ServiceJob.RecurringService}
                                    popup={{ content: 'Active Recurring services' }}
                                />
                                {count}
                            </span>
                        );
                    },
                });
            }

            if (showNextAppt) {
                tableDef.push({
                    header: 'Next Appointment',
                    cell: (customer) => {
                        if (!customer.nextAppointment?.startDate) return null;
                        return (
                            <small>
                                <Icon name={AppIconNames.ServiceJob.Appointment} />
                                <Display.Date value={customer.nextAppointment?.startDate} />
                            </small>
                        );
                    },
                });
            }
            tableDef.push({
                header: 'Last Update',
                sortKey: 'chainUpdate',
                cell: (customer) => (
                    <small>
                        <Display.Date value={customer?.user?.entity?.chainUpdate} />
                    </small>
                ),
            });
        }
        if (isAdmin) {
            tableDef.push({
                header: 'Franchise',
                cell: (customer) => RenderFranchise(customer),
            });
        }
        return tableDef;
    }, [gteDesktopMD, isAdmin, modulesAccess]);

    const onView = (customer: NodeType.Customer) => {
        const link = URLBuilder.Customer(customer.id).view;
        AppNavigator.navigate(link);
    };

    const tableRowProps: TableRowDef<NodeType.Customer> = (connectionDataItem, connectionState) => {
        return {
            comp: (props) => (
                <TableRowClickable
                    onClick={() => {
                        onView(connectionDataItem);
                    }}
                    {...props}
                />
            ),
        };
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section position={'left'}>
                    <MenuBarHeaderItem icon={'users'}>Customers</MenuBarHeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item icon={'plus'} color={'green'} onClick={_addNew}>
                        New Customer
                    </MenuBar.Item>
                </MenuBar.Section>
            </StickyMenuBar>

            <DefaultConnectionTable
                tableProps={{ color: 'grey' }}
                tableRowDef={tableRowProps}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </PageLayout>
    );
};

export default CustomersList;
