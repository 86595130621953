import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMobileAppointmentCtx } from '../Root';
import { ButtonRadix, ButtonRadixProps, Icon, PageLayout } from '@ez/components';
import { SectionHeader } from './components';
import * as React from 'react';
import { NodeType } from '@poolware/api';
import AppointmentStatusEnum = NodeType.AppointmentStatusEnum;

export const QuickActionButton: React.FC<{
    icon?: string;
    label: string;
    onClick?: () => any;
    disabled?: boolean;
    color?: ButtonRadixProps['color'];
}> = ({ icon, label, onClick, color, disabled }) => {
    const [loading, setLoading] = React.useState(false);
    const handleClick = async () => {
        setLoading(true);
        await onClick?.();
        setLoading(false);
    };

    const _color = disabled ? 'grey' : color || 'primary';

    return (
        <ButtonRadix
            onClick={handleClick}
            disabled={disabled}
            color={_color}
            // variant={'secondary'}
            className={
                '!w-[76px] !h-[81px] flex flex-col gap-2 p-2 pt-4 items-center border rounded justify-start hover:bg-accent'
            }
        >
            <div className={'size-[32px]'}>
                {!loading && <Icon name={icon} size={'large'} />}
                {loading && <Icon name={'spinner'} size={'large'} loading={true} />}
            </div>
            <div className={'text-sm whitespace-normal leading-none'}>{label}</div>
        </ButtonRadix>
    );
};

export const PageSectionQuickActions = () => {
    const { AppNavigator } = useAppNavigator();
    const {
        isWorkOrderPending,
        workOrder,
        serviceJob,
        checkIn,
        checkOut,
        appointmentItem,
        goToNewWaterTest,
        canAddWaterTest,
    } = useMobileAppointmentCtx();
    if (!serviceJob) {
        return null;
    }

    const canCheckIn = appointmentItem.status === AppointmentStatusEnum.NotStarted;
    // todo: implement checkOut
    const canCheckedOut = false && appointmentItem.status === AppointmentStatusEnum.InProgress;

    const quickActions = [
        canCheckIn && {
            icon: 'check',
            content: 'Check In',
            color: 'green',
            onClick: async () => {
                return checkIn();
            },
        },
        canCheckedOut && {
            icon: 'check',
            content: 'Check Out',
            color: 'purple',
            onClick: async () => {},
        },
        {
            icon: 'camera',
            disabled: isWorkOrderPending,
            content: 'Take Photos',
            onClick: () => AppNavigator.navigateRelative('/photos'),
        },
        {
            icon: 'sticky note',
            content: 'Add Notes',
            disabled: isWorkOrderPending,
            onClick: () => AppNavigator.navigateRelative('/notes'),
        },
        false && {
            icon: 'bell',
            content: 'Additional Work',
            disabled: isWorkOrderPending,
            onClick: () => AppNavigator.navigateRelative('/new-actionable'),
        },
        {
            icon: 'lab',
            content: 'Water Test',
            disabled: !canAddWaterTest,
            onClick: () => {
                goToNewWaterTest();
            },
        },
    ].filter(Boolean) as {
        icon: string;
        content: string;
        onClick: () => Promise<any>;
        disabled?: boolean;
        color?: ButtonRadixProps['color'];
    }[];

    return (
        <>
            <SectionHeader>Quick Actions</SectionHeader>
            <PageLayout.BodySection>
                <div className={'flex flex-row space-x-4 w-full overflow-x-auto pb-4'}>
                    {quickActions.map((action, i) => (
                        <QuickActionButton
                            disabled={action.disabled}
                            key={i}
                            label={action.content}
                            icon={action.icon}
                            onClick={action.onClick}
                            color={action.color}
                        />
                    ))}
                </div>
            </PageLayout.BodySection>
        </>
    );
};
