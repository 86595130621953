import * as React from 'react';
import { useQueryEzcDeviceLinksConnection } from './use-query-ezc-device-link-connection';
import { useField } from 'formik';
import { clsxm, FormFieldLabel, Icon, useId } from '@ez/components';
import { NodeType } from '@poolware/api';
import { Checkbox } from '@ez/components/dist/semantic-ui';

export interface EzcDeviceLinkSelectProps {
    // poolId?: string;
    ownerId?: string;
    name: string;
    required?: boolean;
    id?: string;
}

export const EzcDeviceLinkSelect: React.FC<EzcDeviceLinkSelectProps> = ({ ownerId, name, id }) => {
    const fieldId = useId(id);
    const [field, meta, helpers] = useField(name);

    const { connectionData, connectionState } = useQueryEzcDeviceLinksConnection({
        search: {
            // pool: {
            //     id: poolId,
            // },
            owner: {
                id: ownerId,
            },
        },
        skip: !ownerId,
    });

    const toggleCheck = (link: NodeType.EZCDeviceLink) => {
        if (link.id === field.value?.id) {
            helpers.setValue(null);
        } else {
            helpers.setValue(link);
        }
    };

    if (connectionState.loading || !ownerId) {
        return null;
    }

    return (
        <>
            <FormFieldLabel>Managed Device</FormFieldLabel>
            <div className={'ml-2 flex flex-col rounded border'}>
                {connectionData?.length === 0 && (
                    <div
                        className={
                            'flex flex-row items-center space-x-1 h-input-base px-2 border-0 border-b last:border-b-0 border-gray-200'
                        }
                    >
                        <span className={'pl-2 italic text-sm'}>No managed devices found for this customer.</span>
                    </div>
                )}
                {connectionData.map((link) => {
                    const isChecked = link.id === field.value?.id;
                    return (
                        <div
                            key={link.id}
                            className={clsxm(
                                'flex flex-row items-center space-x-1 h-input-base hover:bg-accent px-2 border-0 border-b last:border-b-0 border-gray-200',
                                'cursor-pointer',
                                isChecked && 'bg-accent'
                            )}
                            onClick={() => toggleCheck(link)}
                        >
                            <Checkbox radio checked={isChecked} />
                            <span className={'pl-1'}>
                                <Icon name={'microchip'} />
                                {link.device?.title}
                            </span>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
