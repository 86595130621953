import * as React from 'react';
import 'yet-another-react-lightbox/styles.css';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Button, confirmModalImperative, PageLayout, toastError } from '@ez/components';
import { BottomButtonContainer, Frame, SectionHeader } from '../components';
import { useMobileAppointmentCtx } from '../Root';
import { fromEdges, NodeType } from '@poolware/api';
import { PhotoGroups } from './PhotoGroups';

export enum PhotoAttachmentType {
    BeforeService = 'BeforeService',
    AfterService = 'AfterService',
    Other = 'Other',
}

export const photoGroupTypeToLabel = {
    [PhotoAttachmentType.BeforeService]: 'Before service',
    [PhotoAttachmentType.AfterService]: 'After service',
    [PhotoAttachmentType.Other]: 'Photos',
};

export interface PhotoPickerProps {}

export const Page: React.FC<PhotoPickerProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();
    const onGoBack = () => {
        AppNavigator.navigateToOrigin('/', { relativeToModule: true });
    };

    const { workOrder, workOrderMutator, refetchQueries, uploadFiles } = useMobileAppointmentCtx();

    const onDeleteFiles = async (filesToDelete: NodeType.FileUpload[]) => {
        try {
            const res = await confirmModalImperative({
                confirmMessage: {
                    header: 'Delete Selected Files',
                    content: 'Are you sure you want to delete the selected files?',
                },
                negative: true,
                confirmButton: {
                    content: 'Delete Files',
                    icon: 'trash',
                },
            });
            if (!res) {
                return;
            }
            await workOrderMutator.removeFile(workOrder, filesToDelete);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete files', description: e.message });
        }
    };

    return (
        <>
            <PageLayout.BodySection>
                <Frame>
                    <SectionHeader>Attachments</SectionHeader>
                    <PhotoGroups
                        refetchQueries={refetchQueries}
                        onFileUploadRequest={uploadFiles}
                        files={fromEdges(workOrder?.files)}
                        onDeleteFiles={onDeleteFiles}
                    />
                </Frame>
            </PageLayout.BodySection>
            <BottomButtonContainer>
                <Button
                    size={'lg'}
                    content={'Back'}
                    className={'w-full h-[48px] border-2 rounded-none'}
                    onClick={onGoBack}
                />
            </BottomButtonContainer>
        </>
    );
};
