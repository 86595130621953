import { Optionable, SegmentedSelectButtons } from '@ez/components';
import * as React from 'react';
import { useFormikContext } from 'formik';
import { enumToOptions } from './use-select-state';
import { FormikHeatCalcValueType } from './types';
import _isEqual from 'lodash/isEqual';

enum HeatPumpUsagePeriod {
    FULL_YEAR = 'Full Year',
    EXTENDED = 'Extended Season',
    SUMMER = 'Summer',
}

const heatingPeriods = enumToOptions(HeatPumpUsagePeriod);

const FULL_YEAR = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
const EXTENDED = ['0', '1', '2', '3', '4', '8', '9', '10', '11'];
const SUMMER = ['0', '1', '2', '9', '10', '11'];

export const FormikQuickMonthSelectButtons: React.FC = () => {
    const { values, setFieldValue } = useFormikContext<FormikHeatCalcValueType>();

    let value = null;
    if (_isEqual(values.selectedMonths, FULL_YEAR)) {
        value = HeatPumpUsagePeriod.FULL_YEAR;
    } else if (_isEqual(values.selectedMonths, EXTENDED)) {
        value = HeatPumpUsagePeriod.EXTENDED;
    } else if (_isEqual(values.selectedMonths, SUMMER)) {
        value = HeatPumpUsagePeriod.SUMMER;
    }

    const onChange = (selectedOption: Optionable<HeatPumpUsagePeriod>) => {
        switch (selectedOption.value) {
            case HeatPumpUsagePeriod.FULL_YEAR:
                setFieldValue('selectedMonths', FULL_YEAR);
                break;
            case HeatPumpUsagePeriod.EXTENDED:
                setFieldValue('selectedMonths', EXTENDED);
                break;
            case HeatPumpUsagePeriod.SUMMER:
                setFieldValue('selectedMonths', SUMMER);
                break;
        }
    };

    return <SegmentedSelectButtons size={'sm'} value={value} options={heatingPeriods} onChange={onChange} />;
};
