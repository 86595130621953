import * as React from 'react';
import { useRef, useEffect, useCallback, useState, useLayoutEffect, useMemo, useDebugValue, createElement, Component } from 'react';
import * as __CJS__import__0__ from 'debug';
import produce from 'immer';

function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    function tick() {
      if (savedCallback && savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
function useTimeout(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    if (!delay) {
      return;
    }
    function tick() {
      if (savedCallback && savedCallback.current) {
        savedCallback.current();
      }
    }
    let id = setTimeout(tick, delay);
    return () => clearTimeout(id);
  }, [delay]);
}

function useDebouncedCallback(callback, delay, options = {}) {
  const maxWait = options.maxWait;
  const maxWaitHandler = useRef(null);
  const maxWaitArgs = useRef([]);
  const leading = options.leading;
  const wasLeadingCalled = useRef(false);
  const functionTimeoutHandler = useRef(null);
  const isComponentUnmounted = useRef(false);
  const debouncedFunction = useRef(callback);
  debouncedFunction.current = callback;
  const cancelDebouncedCallback = useCallback(() => {
    clearTimeout(functionTimeoutHandler.current);
    clearTimeout(maxWaitHandler.current);
    maxWaitHandler.current = null;
    maxWaitArgs.current = [];
    functionTimeoutHandler.current = null;
    wasLeadingCalled.current = false;
  }, []);
  useEffect(
    () => () => {
      isComponentUnmounted.current = true;
    },
    []
  );
  const debouncedCallback = useCallback(
    (...args) => {
      maxWaitArgs.current = args;
      clearTimeout(functionTimeoutHandler.current);
      if (!functionTimeoutHandler.current && leading && !wasLeadingCalled.current) {
        debouncedFunction.current(...args);
        wasLeadingCalled.current = true;
        return;
      }
      functionTimeoutHandler.current = setTimeout(() => {
        cancelDebouncedCallback();
        if (!isComponentUnmounted.current) {
          debouncedFunction.current(...args);
        }
      }, delay);
      if (maxWait && !maxWaitHandler.current) {
        maxWaitHandler.current = setTimeout(() => {
          const args2 = maxWaitArgs.current;
          cancelDebouncedCallback();
          if (!isComponentUnmounted.current) {
            debouncedFunction.current.apply(null, args2);
          }
        }, maxWait);
      }
    },
    [maxWait, delay, cancelDebouncedCallback, leading]
  );
  const callPending = () => {
    if (!functionTimeoutHandler.current) {
      return;
    }
    debouncedFunction.current.apply(null, maxWaitArgs.current);
    cancelDebouncedCallback();
  };
  return [debouncedCallback, cancelDebouncedCallback, callPending];
}

class BrowserInfo {
  constructor(name, version, os) {
    this.name = name;
    this.version = version;
    this.os = os;
  }
  type = "browser";
}
class NodeInfo {
  constructor(version) {
    this.version = version;
  }
  type = "node";
  name = "node";
  os = process.platform;
}
class SearchBotDeviceInfo {
  constructor(name, version, os, bot) {
    this.name = name;
    this.version = version;
    this.os = os;
    this.bot = bot;
  }
  type = "bot-device";
}
class BotInfo {
  type = "bot";
  bot = true;
  // NOTE: deprecated test name instead
  name = "bot";
  version = null;
  os = null;
}
class ReactNativeInfo {
  type = "react-native";
  name = "react-native";
  version = null;
  os = null;
}
const SEARCHBOX_UA_REGEX = /alexa|bot|crawl(er|ing)|facebookexternalhit|feedburner|google web preview|nagios|postrank|pingdom|slurp|spider|yahoo!|yandex/;
const SEARCHBOT_OS_REGEX = /(nuhk|curl|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask\ Jeeves\/Teoma|ia_archiver)/;
const REQUIRED_VERSION_PARTS = 3;
const userAgentRules = [
  ["aol", /AOLShield\/([0-9\._]+)/],
  ["edge", /Edge\/([0-9\._]+)/],
  ["edge-ios", /EdgiOS\/([0-9\._]+)/],
  ["yandexbrowser", /YaBrowser\/([0-9\._]+)/],
  ["kakaotalk", /KAKAOTALK\s([0-9\.]+)/],
  ["samsung", /SamsungBrowser\/([0-9\.]+)/],
  ["silk", /\bSilk\/([0-9._-]+)\b/],
  ["miui", /MiuiBrowser\/([0-9\.]+)$/],
  ["beaker", /BeakerBrowser\/([0-9\.]+)/],
  ["edge-chromium", /EdgA?\/([0-9\.]+)/],
  ["chromium-webview", /(?!Chrom.*OPR)wv\).*Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
  ["chrome", /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],
  ["phantomjs", /PhantomJS\/([0-9\.]+)(:?\s|$)/],
  ["crios", /CriOS\/([0-9\.]+)(:?\s|$)/],
  ["firefox", /Firefox\/([0-9\.]+)(?:\s|$)/],
  ["fxios", /FxiOS\/([0-9\.]+)/],
  ["opera-mini", /Opera Mini.*Version\/([0-9\.]+)/],
  ["opera", /Opera\/([0-9\.]+)(?:\s|$)/],
  ["opera", /OPR\/([0-9\.]+)(:?\s|$)/],
  ["pie", /^Microsoft Pocket Internet Explorer\/(\d+\.\d+)$/],
  ["pie", /^Mozilla\/\d\.\d+\s\(compatible;\s(?:MSP?IE|MSInternet Explorer) (\d+\.\d+);.*Windows CE.*\)$/],
  ["netfront", /^Mozilla\/\d\.\d+.*NetFront\/(\d.\d)/],
  ["ie", /Trident\/7\.0.*rv\:([0-9\.]+).*\).*Gecko$/],
  ["ie", /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/],
  ["ie", /MSIE\s(7\.0)/],
  ["bb10", /BB10;\sTouch.*Version\/([0-9\.]+)/],
  ["android", /Android\s([0-9\.]+)/],
  ["ios", /Version\/([0-9\._]+).*Mobile.*Safari.*/],
  ["safari", /Version\/([0-9\._]+).*Safari/],
  ["facebook", /FB[AS]V\/([0-9\.]+)/],
  ["instagram", /Instagram\s([0-9\.]+)/],
  ["ios-webview", /AppleWebKit\/([0-9\.]+).*Mobile/],
  ["ios-webview", /AppleWebKit\/([0-9\.]+).*Gecko\)$/],
  ["curl", /^curl\/([0-9\.]+)$/],
  ["searchbot", SEARCHBOX_UA_REGEX]
];
const operatingSystemRules = [
  ["iOS", /iP(hone|od|ad)/],
  ["Android OS", /Android/],
  ["BlackBerry OS", /BlackBerry|BB10/],
  ["Windows Mobile", /IEMobile/],
  ["Amazon OS", /Kindle/],
  ["Windows 3.11", /Win16/],
  ["Windows 95", /(Windows 95)|(Win95)|(Windows_95)/],
  ["Windows 98", /(Windows 98)|(Win98)/],
  ["Windows 2000", /(Windows NT 5.0)|(Windows 2000)/],
  ["Windows XP", /(Windows NT 5.1)|(Windows XP)/],
  ["Windows Server 2003", /(Windows NT 5.2)/],
  ["Windows Vista", /(Windows NT 6.0)/],
  ["Windows 7", /(Windows NT 6.1)/],
  ["Windows 8", /(Windows NT 6.2)/],
  ["Windows 8.1", /(Windows NT 6.3)/],
  ["Windows 10", /(Windows NT 10.0)/],
  ["Windows ME", /Windows ME/],
  ["Windows CE", /Windows CE|WinCE|Microsoft Pocket Internet Explorer/],
  ["Open BSD", /OpenBSD/],
  ["Sun OS", /SunOS/],
  ["Chrome OS", /CrOS/],
  ["Linux", /(Linux)|(X11)/],
  ["Mac OS", /(Mac_PowerPC)|(Macintosh)/],
  ["QNX", /QNX/],
  ["BeOS", /BeOS/],
  ["OS/2", /OS\/2/]
];
function detectBrowser(userAgent) {
  if (!!userAgent) {
    return parseUserAgent(userAgent);
  }
  if (typeof document === "undefined" && typeof navigator !== "undefined" && navigator.product === "ReactNative") {
    return new ReactNativeInfo();
  }
  if (typeof navigator !== "undefined") {
    return parseUserAgent(navigator.userAgent);
  }
  return getNodeVersion();
}
function matchUserAgent(ua) {
  return ua !== "" && userAgentRules.reduce((matched, [browser, regex]) => {
    if (matched) {
      return matched;
    }
    const uaMatch = regex.exec(ua);
    return !!uaMatch && [browser, uaMatch];
  }, false);
}
function browserName(ua) {
  const data = matchUserAgent(ua);
  return data ? data[0] : null;
}
function parseUserAgent(ua) {
  const matchedRule = matchUserAgent(ua);
  if (!matchedRule) {
    return null;
  }
  const [name, match] = matchedRule;
  if (name === "searchbot") {
    return new BotInfo();
  }
  let versionParts = match[1] && match[1].split(".").join("_").split("_").slice(0, 3);
  if (versionParts) {
    if (versionParts.length < REQUIRED_VERSION_PARTS) {
      versionParts = [...versionParts, ...createVersionParts(REQUIRED_VERSION_PARTS - versionParts.length)];
    }
  } else {
    versionParts = [];
  }
  const version = versionParts.join(".");
  const os = detectOS(ua);
  const searchBotMatch = SEARCHBOT_OS_REGEX.exec(ua);
  if (searchBotMatch && searchBotMatch[1]) {
    return new SearchBotDeviceInfo(name, version, os, searchBotMatch[1]);
  }
  return new BrowserInfo(name, version, os);
}
function detectOS(ua) {
  for (let ii = 0, count = operatingSystemRules.length; ii < count; ii++) {
    const [os, regex] = operatingSystemRules[ii];
    const match = regex.exec(ua);
    if (match) {
      return os;
    }
  }
  return null;
}
function getNodeVersion() {
  const isNode = typeof process !== "undefined" && process.version;
  return isNode ? new NodeInfo(process.version.slice(1)) : null;
}
function createVersionParts(count) {
  const output = [];
  for (let ii = 0; ii < count; ii++) {
    output.push("0");
  }
  return output;
}

const _isTouch = function() {
  try {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  } catch (e) {
    console.error(e);
    return false;
  }
}();
const isTouch = _isTouch;
const isMobileDevice = () => {
  const browser = detectBrowser();
  if (browser?.os === "iOS" || browser?.os === "android" || browser?.os === "Android OS") {
    return true;
  } else {
    let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
    if (isIOS) return true;
    return false;
  }
};

const wait = async (x = 0) => {
  return await new Promise((resolve) => setTimeout(resolve, x));
};

const isScreenWider = (limit = 768) => {
  const w = window?.innerWidth || 1e3;
  return w >= limit;
};

/* [vite-plugin-commonjs] import-hoist-E */let _debug;
const noop = () => void 0;
if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test") {
  let DEBUG;
  try {
    DEBUG = window.localStorage.debug;
  } catch (e) {
    console.error("Semantic-UI-React could not enable debug.");
    console.error(e);
  }
  _debug = (__CJS__import__0__.default || __CJS__import__0__);
  _debug.enable(DEBUG);
} else {
  _debug = () => noop;
}
const makeDebugger = (namespace) => {
  let d = _debug(namespace);
  if (d.enabled) {
    d.group = (...data) => console.group(...data);
    d.groupEnd = () => console.groupEnd();
    d.groupCollapsed = (...data) => console.groupCollapsed(...data);
  } else {
    d.group = () => noop;
    d.groupEnd = () => noop;
    d.groupCollapsed = () => noop;
  }
  return d;
};
const debug = makeDebugger("log");

function isLocalStorageUsable() {
  const localStorageTestKey = "ez_localstorage_support_test";
  try {
    localStorage.setItem(localStorageTestKey, "true");
    localStorage.removeItem(localStorageTestKey);
    return true;
  } catch (e) {
    return false;
  }
}
const EZStorageNoop = {
  setItem(key, value) {
  },
  getItem(key) {
    return null;
  },
  clear() {
  },
  key(index) {
    return null;
  },
  length: 0,
  removeItem(key) {
  }
};
const getStorage = function() {
  const isEnabled = isLocalStorageUsable();
  if (!isEnabled) {
    return EZStorageNoop;
  }
  try {
    return localStorage;
  } catch (e) {
    return EZStorageNoop;
  }
};
const _EZStorage = getStorage();
const EZStorage = _EZStorage;
function checkCookie() {
  try {
    document.cookie = "cookietest=1";
    const ret = document.cookie.indexOf("cookietest=") !== -1;
    document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
    return ret;
  } catch (e) {
    return false;
  }
}

const serializerFn = (value) => {
  return JSON.stringify(value);
};
const deserializerFn = (value) => {
  return JSON.parse(value);
};
const stringSerializer = (value) => {
  return value;
};
const stringDeserializer = (value) => {
  return value;
};
const boolSerializer = (value) => {
  return JSON.stringify(value);
};
const boolDeserializer = (value) => {
  return Boolean(JSON.parse(value));
};
const numberSerializer = (value) => {
  return JSON.stringify(value);
};
const numberDeserializer = (value) => {
  return Number(JSON.parse(value));
};
const usePersistedState = (persistKey, initialState, serializer = serializerFn, deserializer = deserializerFn, skipStorage) => {
  const getInitialState = () => {
    try {
      const savedState = !skipStorage ? EZStorage.getItem(persistKey) : void 0;
      if (savedState) {
        return deserializer(savedState);
      } else {
        return initialState;
      }
    } catch (e) {
      return initialState;
    }
  };
  const [state, setState] = useState(getInitialState);
  useEffect(() => {
    try {
      if (skipStorage) {
        return;
      }
      EZStorage.setItem(persistKey, serializer(state));
    } catch (e) {
      console.error(e);
    }
  }, [state, skipStorage]);
  return [state, setState];
};
const usePersistedToggle = (persistKey, initialState, skipStorage) => {
  return usePersistedState(persistKey, initialState, boolSerializer, boolDeserializer, skipStorage);
};
const usePersistedNumber = (persistKey, initialState, skipStorage) => {
  return usePersistedState(persistKey, initialState, numberSerializer, numberDeserializer, skipStorage);
};
const usePersistedString = (persistKey, initialState, skipStorage) => {
  return usePersistedState(persistKey, initialState, stringSerializer, stringDeserializer, skipStorage);
};

function useDebouncedValue(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

function getWindowSize() {
  return {
    innerHeight: window?.innerHeight,
    innerWidth: window?.innerWidth,
    outerHeight: window?.outerHeight,
    outerWidth: window?.outerWidth
  };
}
const useWindowSize = () => {
  let [windowSize, setWindowSize] = useState(getWindowSize());
  function handleResize() {
    setWindowSize(getWindowSize());
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return windowSize;
};

const isEdge = typeof navigator !== "undefined" && /Edge\/\d./i.test(window.navigator.userAgent);
function useResizeObserver(ref, callback) {
  useEffect(() => {
    if (typeof window !== "undefined" && window.ResizeObserver) {
      const resizeObserver = new ResizeObserver((entries) => {
        callback(entries[0].contentRect);
      });
      resizeObserver.observe(ref.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [ref]);
}
function throttle(func, wait) {
  let context, args, result;
  let timeout = null;
  let previous = 0;
  const later = function() {
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) {
      context = args = null;
    }
  };
  return function() {
    const now = Date.now();
    const remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) {
        context = args = null;
      }
    } else if (!timeout) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}
const useScrollInfo = () => {
  const [scroll, setScroll] = useState({ x: {}, y: {} });
  const ref = useRef(null);
  const previousScroll = useRef(null);
  useResizeObserver(ref, () => {
    update();
  });
  const throttleTime = 50;
  function update() {
    const element = ref.current;
    if (!element) {
      return;
    }
    let maxY = element.scrollHeight - element.clientHeight;
    const maxX = element.scrollWidth - element.clientWidth;
    if (isEdge && maxY === 1 && element.scrollTop === 0) {
      maxY = 0;
    }
    const percentageY = maxY !== 0 ? element.scrollTop / maxY : null;
    const percentageX = maxX !== 0 ? element.scrollLeft / maxX : null;
    let classNameY = "no-scroll-y";
    if (percentageY === 0) {
      classNameY = "scroll-top";
    } else if (percentageY === 1) {
      classNameY = "scroll-bottom";
    } else if (percentageY) {
      classNameY = "scroll-middle-y";
    }
    let classNameX = "no-scroll-x";
    if (percentageX === 0) {
      classNameX = "scroll-left";
    } else if (percentageX === 1) {
      classNameX = "scroll-right";
    } else if (percentageX) {
      classNameX = "scroll-middle-x";
    }
    const previous = previousScroll.current;
    const scrollInfo = {
      x: {
        percentage: percentageX,
        value: element.scrollLeft,
        total: maxX,
        className: classNameX,
        direction: previous ? Math.sign(element.scrollLeft - previous.x.value) : 0
      },
      y: {
        percentage: percentageY,
        value: element.scrollTop,
        total: maxY,
        className: classNameY,
        direction: previous ? Math.sign(element.scrollTop - previous.y.value) : 0
      }
    };
    previousScroll.current = scrollInfo;
    setScroll(scrollInfo);
  }
  const throttledUpdate = throttle(update, throttleTime);
  const setRef = useCallback((node) => {
    if (node) {
      node.addEventListener("scroll", throttledUpdate);
      if (!window.ResizeObserver) {
        window.addEventListener("resize", throttledUpdate);
      }
      ref.current = node;
      throttledUpdate();
    } else if (ref.current) {
      ref.current.removeEventListener("scroll", throttledUpdate);
      if (!window.ResizeObserver) {
        window.removeEventListener("resize", throttledUpdate);
      }
    }
  }, []);
  return { scrollInfo: scroll, setRef, ref };
};

const isReactNative = typeof global !== "undefined" && // @ts-ignore
global.navigator && // @ts-ignore
global.navigator.product === "ReactNative";
const isDOM = typeof document !== "undefined";
const useIsomorphicEffect = isDOM || isReactNative ? useLayoutEffect : useEffect;

const matchers = /* @__PURE__ */ new Map();
const getMatcher = (query) => {
  if (!query || typeof window == "undefined") return void 0;
  let mql = matchers.get(query);
  if (!mql) {
    mql = window.matchMedia(query);
    mql.refCount = 0;
    matchers.set(mql.media, mql);
  }
  return mql;
};
function useMediaQuery(query) {
  const mql = getMatcher(query);
  const [matches, setMatches] = useState(() => mql ? mql.matches : false);
  useIsomorphicEffect(() => {
    let mql2 = getMatcher(query);
    if (!mql2) {
      return setMatches(false);
    }
    const handleChange = () => {
      setMatches(mql2.matches);
    };
    mql2.refCount++;
    mql2.addListener(handleChange);
    handleChange();
    return () => {
      mql2.removeListener(handleChange);
      mql2.refCount--;
      if (mql2.refCount <= 0) {
        matchers.delete(mql2.media);
      }
      mql2 = void 0;
    };
  }, [query]);
  return matches;
}

function useIsMounted() {
  const ref = useRef(true);
  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
  return () => ref.current;
}

const useSafeState = (initialState) => {
  const [state, _setState] = useState(initialState);
  const isMounted = useIsMounted();
  const setState = (v) => {
    if (isMounted()) {
      _setState(v);
    }
  };
  return [state, setState];
};

const createMediaQueries = (breakpoints) => {
  const sortedBreakpoints = Object.keys(breakpoints).sort((a, b) => breakpoints[b] - breakpoints[a]);
  return sortedBreakpoints.map((breakpoint, index) => {
    let query = "";
    const minWidth = breakpoints[breakpoint];
    const nextBreakpoint = sortedBreakpoints[index - 1];
    const maxWidth = nextBreakpoint ? breakpoints[nextBreakpoint] : null;
    if (minWidth >= 0) {
      query = `(min-width: ${minWidth}px)`;
    }
    if (maxWidth !== null) {
      if (query) {
        query += " and ";
      }
      query += `(max-width: ${maxWidth - 1}px)`;
    }
    const mediaQuery = {
      breakpoint,
      maxWidth: maxWidth ? maxWidth - 1 : null,
      minWidth,
      query
    };
    return mediaQuery;
  });
};

const EMPTY_BREAKPOINT = {
  breakpoint: void 0,
  minWidth: void 0,
  maxWidth: void 0
};
const useBreakpoint = (config, defaultBreakpoint, hydrateInitial = true) => {
  const mediaQueries = useMemo(() => createMediaQueries(config), [config]);
  const [currentBreakpoint, setCurrentBreakpoint] = useState(() => {
    for (const { query, ...breakpoint } of mediaQueries) {
      if (typeof window !== "undefined" && !(defaultBreakpoint && hydrateInitial)) {
        const mediaQuery = window.matchMedia(query);
        if (mediaQuery.matches) {
          return breakpoint;
        }
      } else if (breakpoint.breakpoint === defaultBreakpoint) {
        return breakpoint;
      }
    }
    return EMPTY_BREAKPOINT;
  });
  const updateBreakpoint = useCallback(
    ({ matches }, breakpoint) => {
      if (matches) {
        setCurrentBreakpoint(breakpoint);
      }
    },
    []
  );
  useIsomorphicEffect(() => {
    const unsubscribers = mediaQueries.map(({ query, ...breakpoint }) => {
      const list = window.matchMedia(query);
      updateBreakpoint(list, breakpoint);
      const handleChange = (event) => {
        updateBreakpoint(event, breakpoint);
      };
      list.addListener(handleChange);
      return () => list.removeListener(handleChange);
    });
    return () => unsubscribers.forEach((unsubscriber) => unsubscriber());
  }, [mediaQueries, updateBreakpoint]);
  useDebugValue(
    currentBreakpoint,
    (c) => typeof c.breakpoint === "string" ? `${c.breakpoint} (${c.minWidth} ≤ x${c.maxWidth ? ` < ${c.maxWidth + 1}` : ""})` : ""
  );
  return currentBreakpoint;
};

function useKeyPress(targetKey, ctrlKey, upCb) {
  const [keyPressed, setKeyPressed] = useState(false);
  function downHandler({ key, ctrlKey: ctrlKey2 }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }
  const upHandler = ({ key, ctrlKey: ctrlKey2 }) => {
    if (key === targetKey && ctrlKey2) {
      setKeyPressed(false);
      upCb && upCb();
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);
  return keyPressed;
}

function useSetState(initialState) {
  const [state, _setState] = useState(initialState);
  const setState = (statePartial) => _setState((current) => ({
    ...current,
    ...typeof statePartial === "function" ? statePartial(current) : statePartial
  }));
  return [state, setState];
}

function createAction(type, payload, scope) {
  let action = { type };
  if (payload !== void 0) {
    action.payload = payload;
  }
  if (scope) {
    action.scope = scope;
  }
  return action;
}
function createReducer(initialState, cb, scope) {
  const _cb = (state, action) => {
    if (action.scope && scope && action.scope !== scope) {
      return state;
    }
    return cb(state, action);
  };
  return produce(_cb, initialState);
}

const _createActionType = (actionScope, field) => {
  return actionScope + "/" + field;
};
const createAutoBoundReducerActions = (initialState, actionScope, actionsMap) => {
  const actions = {};
  const reducers = {};
  const actionTypes = [];
  for (const key of Object.keys(actionsMap)) {
    const action = actionsMap[key];
    const actionType = action.actionType || _createActionType(actionScope, action.field);
    actions[key] = (payload) => createAction(actionType, payload, actionScope);
    reducers[actionType] = (state, payload) => {
      state[action.field] = payload;
      return state;
    };
    actionTypes.push(actionType);
  }
  return { actions, reducers, actionTypes };
};

function toValue(mix) {
  var k, y, str = "";
  if (typeof mix === "string" || typeof mix === "number") {
    str += mix;
  } else if (typeof mix === "object") {
    if (Array.isArray(mix)) {
      for (k = 0; k < mix.length; k++) {
        if (mix[k]) {
          if (y = toValue(mix[k])) {
            str && (str += " ");
            str += y;
          }
        }
      }
    } else {
      for (k in mix) {
        if (mix[k]) {
          str && (str += " ");
          str += k;
        }
      }
    }
  }
  return str;
}
function cn(...args) {
  var i = 0, tmp, x, str = "";
  while (i < args.length) {
    if (tmp = args[i++]) {
      if (x = toValue(tmp)) {
        str && (str += " ");
        str += x;
      }
    }
  }
  return str;
}

function truncateMiddle(str, frontLen, backLen, truncateStr) {
  if (str === void 0) {
    return "";
  }
  if (str === null) {
    return "";
  }
  var strLen = str.length;
  frontLen = ~~frontLen;
  backLen = ~~backLen;
  truncateStr = truncateStr || "…";
  if (frontLen === 0 && backLen === 0 || frontLen >= strLen || backLen >= strLen || frontLen + backLen >= strLen) {
    return str;
  } else if (backLen === 0) {
    return str.slice(0, frontLen) + truncateStr;
  } else {
    return str.slice(0, frontLen) + truncateStr + str.slice(strLen - backLen);
  }
}

const compose = (...funcs) => funcs.reduce(
  (a, b) => (...args) => a(b(...args)),
  (arg) => arg
);

const createFactory = (Type) => createElement.bind(null, Type);

const mapProps = (propsMapper) => (BaseComponent) => {
  const factory = createFactory(BaseComponent);
  const MapProps = (props) => factory(propsMapper(props));
  return MapProps;
};

const withProps = (input) => {
  const hoc = mapProps((props) => ({
    ...props,
    ...typeof input === "function" ? input(props) : input
  }));
  return hoc;
};

const withState = (stateName, stateUpdaterName, initialState) => (BaseComponent) => {
  const factory = createFactory(BaseComponent);
  class WithState extends React.Component {
    state = {
      stateValue: typeof initialState === "function" ? initialState(this.props) : initialState
    };
    updateStateValue = (updateFn, callback) => this.setState(
      ({ stateValue }) => ({
        stateValue: typeof updateFn === "function" ? updateFn(stateValue) : updateFn
      }),
      callback
    );
    render() {
      return factory({
        ...this.props,
        [stateName]: this.state.stateValue,
        [stateUpdaterName]: this.updateStateValue
      });
    }
  }
  return WithState;
};

const isClassComponent = (Component) => Boolean(Component && Component.prototype && typeof Component.prototype.render === "function");
const toClass = (baseComponent) => isClassComponent(baseComponent) ? baseComponent : class ToClass extends React.Component {
  // static displayName = getDisplayName(baseComponent);
  static propTypes = baseComponent.propTypes;
  static contextTypes = baseComponent.contextTypes;
  static defaultProps = baseComponent.defaultProps;
  render() {
    if (typeof baseComponent === "string") {
      return React.createElement(baseComponent, this.props);
    }
    return baseComponent(this.props, this.context);
  }
};

const identity = (Component) => Component;
const branch = (test, left, right = identity) => (BaseComponent) => {
  let leftFactory;
  let rightFactory;
  const Branch = (props) => {
    if (test(props)) {
      leftFactory = leftFactory || createFactory(left(BaseComponent));
      return leftFactory(props);
    }
    rightFactory = rightFactory || createFactory(right(BaseComponent));
    return rightFactory(props);
  };
  return Branch;
};

const renderComponent = (Component) => (_) => {
  const factory = createFactory(Component);
  const RenderComponent = (props) => factory(props);
  return RenderComponent;
};

class Nothing extends Component {
  render() {
    return null;
  }
}
const renderNothing = (_) => Nothing;

const mapValues = (obj, func) => {
  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = func(obj[key], key);
    }
  }
  return result;
};
const withHandlers = (handlers) => (BaseComponent) => {
  const factory = createFactory(BaseComponent);
  class WithHandlers extends React.Component {
    handlers = mapValues(
      typeof handlers === "function" ? handlers(this.props) : handlers,
      (createHandler) => (...args) => {
        const handler = createHandler(this.props);
        if (process.env.NODE_ENV !== "production" && typeof handler !== "function") {
          console.error(
            // eslint-disable-line no-console
            "withHandlers(): Expected a map of higher-order functions. Refer to the docs for more info."
          );
        }
        return handler(...args);
      }
    );
    render() {
      return factory({
        ...this.props,
        ...this.handlers
      });
    }
  }
  return WithHandlers;
};

export { BotInfo, BrowserInfo, EZStorage, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo, branch, browserName, checkCookie, cn, compose, createAction, createAutoBoundReducerActions, createReducer, debug, detectBrowser, detectOS, getNodeVersion, getWindowSize, isLocalStorageUsable, isMobileDevice, isScreenWider, isTouch, makeDebugger, mapProps, parseUserAgent, renderComponent, renderNothing, toClass, truncateMiddle, useBreakpoint, useDebouncedCallback, useDebouncedValue, useInterval, useIsMounted, useKeyPress, useMediaQuery, usePersistedNumber, usePersistedState, usePersistedString, usePersistedToggle, useResizeObserver, useSafeState, useScrollInfo, useSetState, useTimeout, useWindowSize, wait, withHandlers, withProps, withState };
