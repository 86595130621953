import * as React from 'react';
import { useState } from 'react';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { MenuBar, MenuBarItem, MenuBarItemWithConfirm, StickyMenuBar, toastError } from '@ez/components';
import { Modal } from '@ez/components';
import { NodeType } from '@poolware/api';
import { NewProductTrait } from '../ProductTrait';
import BulkEdit from './BulkEdit';
import BulkTraitRemove from './BulkTraitRemove';
import { compose } from '@ez/tools';
import { ProductCatalogActionProps, withProductCatalogActions } from '../../../redux';
import { queryNames } from '../query-names';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';

export interface PageMenuBarExternalProps {
    selectedItems: NodeType.Product[];
    showSidebar?: boolean;
    setShowSidebar: (show: boolean) => void;
}

export interface PageMenuBarProps
    extends IAppNavigatorProps,
        IProductCatalogMutators,
        PageMenuBarExternalProps,
        ProductCatalogActionProps {}

const PageMenuBarComp: React.FC<PageMenuBarProps> = ({
    selectedItems,
    AppNavigator,
    ProductCatalogMutator,
    ProductCatalogAction,
    ProductCatalogState,
    showSidebar,
    setShowSidebar,
}) => {
    const onNewProduct = () => {
        AppNavigator.navigate('/new', { relativeToModule: true, setOrigin: true });
    };

    const [bulkAddNewTrait, setNewTraits] = useState([]);
    const [bulkRemoveTrait, setBulkRemoveTrait] = useState([]);
    const [bulkEditProducts, setBulkEditProducts] = useState([]);

    const hideNewForm = () => {
        setNewTraits([]);
    };

    const onDelete = async () => {
        try {
            await ProductCatalogMutator.deleteProducts(selectedItems);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete products', description: e.message });
        }
    };

    const clearAll = () => {
        ProductCatalogAction.removeAllFilterItems();
    };

    let menuButtons = null;
    if (selectedItems.length > 0) {
        menuButtons = (
            <>
                <MenuBarItem
                    title={'Add Trait'}
                    icon={'plus square outline'}
                    onClick={() => setNewTraits(selectedItems)}
                />
                <MenuBarItem
                    title={'Remove Traits'}
                    icon={'remove'}
                    onClick={() => setBulkRemoveTrait(selectedItems)}
                />
                <MenuBarItem
                    title={`Edit ${selectedItems.length} `}
                    icon={'edit outline'}
                    onClick={() => setBulkEditProducts(selectedItems)}
                />
                <MenuBarItemWithConfirm
                    title={`Delete ${selectedItems.length} `}
                    icon={'trash'}
                    onClick={onDelete}
                    confirm={{
                        negative: true,
                        confirmButton: {
                            content: `Delete ${selectedItems.length} products`,
                            icon: 'trash',
                        },
                        confirmMessage: {
                            header: 'Delete products?',
                            content: 'Permanently delete products',
                        },
                    }}
                />
            </>
        );
    } else if (ProductCatalogState.filters.hasActiveFilters) {
        menuButtons = <MenuBarItem title={`Clear All Filters`} color={'purple'} icon={'delete'} onClick={clearAll} />;
    }

    return (
        <>
            <StickyMenuBar>
                <MenuBar.Section position={'left'}>
                    {!showSidebar && (
                        <MenuBar.Item onClick={() => setShowSidebar(!showSidebar)} icon={'filter'} color={'grey'} />
                    )}
                    {menuButtons}
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item icon={'plus'} color={'green'} onClick={onNewProduct} title={'New Product'} />
                </MenuBar.Section>

                <Modal open={bulkAddNewTrait.length > 0}>
                    <NewProductTrait products={bulkAddNewTrait} onCancel={hideNewForm} onSubmit={hideNewForm} />
                </Modal>
                <Modal open={bulkEditProducts.length > 0}>
                    <BulkEdit products={bulkEditProducts} onFinish={() => setBulkEditProducts([])} />
                </Modal>
                <Modal open={bulkRemoveTrait.length > 0}>
                    <BulkTraitRemove products={bulkRemoveTrait} onFinish={() => setBulkRemoveTrait([])} />
                </Modal>
            </StickyMenuBar>
        </>
    );
};

export const PageMenuBar = compose(
    withAppNavigator(),
    withProductCatalogActions(),
    withProductCatalogMutators(queryNames)
)(PageMenuBarComp) as React.ComponentType<PageMenuBarExternalProps>;
