import * as React from 'react';
import { connect as connectFormik, getIn } from 'formik';
import { FormFieldLabel, FormFieldLabelProps } from '../form-components';
import { FormikFieldProps } from './FormikTypes';

export const FormikFormFieldLabel = connectFormik<FormFieldLabelProps & FormikFieldProps>((props) => {
    const { label, disabled, htmlFor, name, required, formik, readOnly, className, ...rest } = props;
    if (!label) {
        return null;
    }
    const touched = getIn(formik.touched, name);
    const error = getIn(formik.errors, name);
    let hasError = touched && error;
    return (
        <FormFieldLabel
            readOnly={readOnly}
            disabled={disabled}
            required={required}
            label={label}
            errorLabel={hasError}
            htmlFor={htmlFor}
            className={className}
            {...rest}
        />
    );
});
