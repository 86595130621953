import * as React from 'react';
import { useCalendarActions } from '../../../redux';
import { ActionBar } from '@ez/components';
import { CalResourceMode, CalViewMode } from '../../types';

const AutoHideResourcesSection: React.FC = () => {
    const { CalendarState, CalendarAction } = useCalendarActions();
    const hasStaffFilter = CalendarState.filters?.staffIds?.length > 0;
    const { resourceMode, viewMode } = CalendarState;
    const hideFilter1 = !(viewMode === CalViewMode.DAY && resourceMode === CalResourceMode.SPLIT);

    const onChangeShowAddressOnly = (checked: boolean) => {
        CalendarAction.setStreetFilter({ showWithAddressOnly: checked });
    };

    return (
        <>
            {!hideFilter1 && (
                <ActionBar.ItemCheckbox
                    disabled={hasStaffFilter}
                    checked={CalendarState.filters.autoHideEmptyResources}
                    onChange={(checked) => CalendarAction.setAutoHideEmptyResources(checked)}
                    content={
                        <small style={{ color: hasStaffFilter ? '#888888' : undefined }}>
                            Hide columns without appointments
                        </small>
                    }
                />
            )}
            <ActionBar.ItemCheckbox
                checked={CalendarState.filters.showWithAddressOnly}
                onChange={(checked) => onChangeShowAddressOnly(checked)}
                content={<small>Hide appointments without address</small>}
            />
        </>
    );
};

export default AutoHideResourcesSection;
