import * as React from 'react';
import { compose } from '@ez/tools';
import {
    FormikDatePickerInputField,
    FormikInputField,
    FormikPanelForm,
    FormikTextareaField,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    MenuBarSection,
    Panel,
    SectionHeader,
    StickyMenuBar,
    VStack,
} from '@ez/components';
import { fromEdges, IMutateItem, NodeType, withItemMutator } from '@poolware/api';
import * as URLBuilder from '../../../../routes/url-builder';
import { BrandLabel } from '../RegisterNew/components';
import { withItemQuery, WithItemQueryProps } from '../withItemQuery';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { PageLayout } from '@ez/components';

interface DeletePermission {
    deletable: boolean;
    reason?: string;
}

const DropdownItemDeleteButton: React.FC<{ onDelete: any; deletePermission: DeletePermission }> = ({
    onDelete,
    deletePermission,
}) => {
    return (
        <MenuBarDropdownItemWithConfirm
            icon={'trash'}
            color="orange"
            content="Delete Equipment"
            onClick={onDelete}
            disabled={!deletePermission.deletable}
            popup={!deletePermission.deletable ? { content: deletePermission.reason } : undefined}
            confirm={{
                confirmMessage: {
                    header: 'Delete Equipment?',
                    content: 'There is NO UNDO. This will permanently remove equipment from this pool.',
                },
                negative: true,
                confirmButton: {
                    content: 'Delete',
                    icon: 'trash',
                },
            }}
        />
    );
};

interface EquipmentTableProps extends WithItemQueryProps, IAppNavigatorProps, IMutateItem {}

const EquipmentItemDetails: React.FC<EquipmentTableProps> = (props) => {
    const { item } = props;

    const itemLabel = item?.label;
    const productName = item?.product?.name;
    const brand = item?.product?.brand;
    const traits = fromEdges(item?.product?.traits);
    const declarationsString = traits.map((t) => t?.declaration?.name || '').join(', ');

    const onGoBack = () => {
        const customerLink = URLBuilder.Customer(props.params.customerId).Pool(props.params.poolId).view;
        props.AppNavigator.navigateToOrigin(customerLink);
    };

    const initialValues = {
        ...item,
    };

    const deletePermission = () => {
        const deletePermission = item.checkMutations.delete;
        if (!deletePermission) {
            return {
                deletable: false,
                reason: 'Not enough permissions',
            };
        } else {
            return {
                deletable: true,
                reason: undefined,
            };
        }
    };

    const onDelete = async (item: NodeType.Item) => {
        await props.mutateItem.delete({ id: item.id });
        props.AppNavigator.replaceToOrigin();
    };

    const onSubmit = async (values: typeof initialValues, actions) => {
        const updateFields = {
            id: values.id,
            note: values.note,
            label: values.label,
            serial: values.serial,
            installedAt: values.installedAt,
        };
        await props.mutateItem.update(updateFields);
        actions.resetForm({ values: { ...values, ...updateFields } });
    };

    const headerString = itemLabel ? `${productName}  (${itemLabel})` : `${productName}`;

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem title={'Back'} icon={'chevron left'} onClick={onGoBack} />
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarDropdown icon={'bars'}>
                        <DropdownItemDeleteButton
                            onDelete={() => onDelete(item)}
                            deletePermission={deletePermission()}
                        />
                    </MenuBarDropdown>
                </MenuBarSection>
            </StickyMenuBar>

            <PageLayout.BodySection width={'screen-md'}>
                <SectionHeader content={headerString} />
                <FormikPanelForm header={'Equipment Asset'} initialValues={initialValues} onSubmit={onSubmit}>
                    <Panel>
                        <Panel.Header>Product</Panel.Header>
                        <Panel.Body>
                            <Panel.Item label={'Product Name'}>{productName}</Panel.Item>
                            <Panel.Item label={'Brand'}>
                                <BrandLabel brand={brand} />
                            </Panel.Item>
                            <Panel.Item label={'Categories'}>{declarationsString}</Panel.Item>
                        </Panel.Body>
                    </Panel>

                    <SectionHeader>Equipment Details</SectionHeader>
                    <FormikInputField name={'serial'} label={'Serial Number'} />
                    <FormikInputField name={'label'} label={'Equipment Label'} />
                    <FormikDatePickerInputField name={'installedAt'} label={'Installation Date'} />
                    <FormikTextareaField name={'note'} label={'Equipment Notes'} rows={10} />

                    <div style={{ display: 'flex', alignItems: 'baseline', fontSize: 'small', color: '#9e9e9e' }}>
                        <div style={{ paddingRight: '0.5em' }}>Asset UUID</div>
                        <pre>{item.uuid}</pre>
                    </div>
                </FormikPanelForm>
            </PageLayout.BodySection>
        </PageLayout>
    );
};

export default compose(
    withAppNavigator(),
    withItemQuery((props) => props.params.itemId),
    withItemMutator(['QueryPoolAndPoolEquipmentDetails'])
)(EquipmentItemDetails);
