import * as React from 'react';
import _findIndex from 'lodash/findIndex';
import { NodeType, TestReportMutatorsProps } from '@poolware/api';
import { ConfirmButton, Display, Icon, SemanticCOLORS, Table } from '@ez/components';

const IconWarning = () => <Icon name="warning sign" color="orange" />;

const lookupChemicalTarget = (
    chemicalTargets: NodeType.PoolChemicalTarget[],
    measurementType: NodeType.Measurement
) => {
    if (!chemicalTargets || !measurementType) return null;

    let index = _findIndex(chemicalTargets, (c) => {
        let id = c?.measurementType?.id;
        return id === measurementType.id;
    });
    if (index < 0) return null;
    return chemicalTargets[index];
};

const SampleDeleteButton = ({ measurementTypeName, ...rest }) => {
    return (
        <ConfirmButton
            icon={'trash'}
            basic
            floated={'right'}
            size={'tiny'}
            negative={true}
            confirmButton={{
                content: 'Delete',
                icon: 'trash',
            }}
            confirmMessage={{
                header: `Delete "${measurementTypeName}" Measurement?`,
                content: (
                    <div>
                        <p>
                            <Icon color={'yellow'} name={'warning sign'} />
                            IMPORTANT!!! <Icon color={'yellow'} name={'warning sign'} />
                        </p>
                        <p>All recommendations will be recalculated!</p>
                        <p>
                            {`If you have any custom recommendation overrides for ${measurementTypeName}, they will be reset.`}
                        </p>
                    </div>
                ),
            }}
            {...rest}
        />
    );
};

interface EditableSampleViewProps {
    sample: NodeType.Sample;
    isDataWarning: boolean;
}

class EditableSampleView extends React.Component<EditableSampleViewProps> {
    render() {
        const { sample, isDataWarning } = this.props;
        let unitName = sample?.measurementType?.unit?.name || '';
        return (
            <React.Fragment>
                <Display.Num value={sample.value} unit={unitName} /> {isDataWarning && <IconWarning />}
            </React.Fragment>
        );
    }
}

interface WaterTestMeasurementTableProps extends Partial<TestReportMutatorsProps> {
    report?: NodeType.TestReport;
    samples: NodeType.Sample[] | NodeType.SolutionAffect[];
    chemicalTargets: NodeType.PoolChemicalTarget[];
    color?: SemanticCOLORS;
    editable?: boolean;
}

class WaterTestMeasurementTable extends React.Component<WaterTestMeasurementTableProps> {
    onDelete = async (sample: NodeType.Sample) => {
        const { report } = this.props;
        await this.props.TestReportMutator.deleteSample(report, sample);
    };

    render() {
        const { samples, chemicalTargets, color, TestReportMutator, editable = false } = this.props;

        const sampleItems = samples;

        const canDelete = TestReportMutator && editable;

        return (
            <div style={{ overflowX: 'auto' }}>
                <Table celled color={color || 'grey'} unstackable compact={'very'} className={'compact-header'}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width="4">Parameters</Table.HeaderCell>
                            <Table.HeaderCell width="3">Result</Table.HeaderCell>
                            <Table.HeaderCell width="2">Min</Table.HeaderCell>
                            <Table.HeaderCell width="2">Max</Table.HeaderCell>
                            <Table.HeaderCell>Comments</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {sampleItems.map((si) => {
                            let s: any = { ...si };
                            let measurementTypeName = s?.measurementType?.name;
                            if (!measurementTypeName) return null;

                            let chemicalTarget = lookupChemicalTarget(chemicalTargets, s.measurementType);

                            let isWarning = false;
                            let isDataWarning = false;
                            let comment: React.ReactNode = '';

                            if (chemicalTarget) {
                                s.min = chemicalTarget.lowerThreshold;
                                s.max = chemicalTarget.higherThreshold;

                                if (s.value > s.max) {
                                    isWarning = true;
                                    if (s.value > s.max * 3) {
                                        isDataWarning = true;
                                        comment = (
                                            <span>
                                                <IconWarning />
                                                Extremely High
                                            </span>
                                        );
                                    } else {
                                        comment = (
                                            <span>
                                                <IconWarning />
                                                High
                                            </span>
                                        );
                                    }
                                } else if (s.value < s.min) {
                                    isWarning = true;
                                    if (s.value < s.min / 3 && s.value > 0.1) {
                                        isDataWarning = true;
                                        comment = (
                                            <span>
                                                <IconWarning />
                                                Extremely Low
                                            </span>
                                        );
                                    } else {
                                        comment = (
                                            <span>
                                                <IconWarning />
                                                Low
                                            </span>
                                        );
                                    }
                                } else {
                                    comment = (
                                        <span>
                                            <Icon name="checkmark" />
                                            OK
                                        </span>
                                    );
                                }
                            }

                            return (
                                <Table.Row key={measurementTypeName} warning={isDataWarning}>
                                    <Table.Cell>{measurementTypeName}</Table.Cell>
                                    <Table.Cell>
                                        <EditableSampleView
                                            sample={s as NodeType.Sample}
                                            isDataWarning={isDataWarning}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Display.Num value={s.min} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Display.Num value={s.max} />
                                    </Table.Cell>
                                    <Table.Cell warning={isWarning} positive={!isWarning}>
                                        {comment}
                                        {canDelete && (
                                            <SampleDeleteButton
                                                onClick={() => this.onDelete(s)}
                                                measurementTypeName={measurementTypeName}
                                            />
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>

                    {sampleItems.length === 0 && (
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan="5">No measurement samples submitted</Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    )}
                </Table>
            </div>
        );
    }
}

export default WaterTestMeasurementTable;
