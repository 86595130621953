import React from 'react';
import clsx from 'clsx';
import EventWrapper from './EventWrapper';
import EventContainerWrapper from './EventContainerWrapper';
import { mergeComponents } from './common';
import type { DnDInteractionInfoType } from './DndContext';
import { DndContext, DndContextValueType, DragAndDropActionState } from './DndContext';
import { CalEvent } from '../../types';
import { DND_ACTION, RESIZE_DIRECTION } from './types';
import { DebugDnd } from './DebugDND';
import Calendar from '../../Calendar';
import { wrapAccessor } from '../../utils/accessors';
import { CalendarBaseProps } from '../../CalendarPropsTypes';
import { CalendarContextValueType } from '../../CalendarContext';
import RootViewWrapper from './RootViewWrapper';

export type { DnDInteractionInfoType } from './DndContext';

interface CalendarDnDState extends DragAndDropActionState {}

export interface CalendarDnDProps extends CalendarBaseProps {
    onEventMove?: (DnDInteractionInfoType) => Promise<any>;
    onEventResize?: (DnDInteractionInfoType) => any;
    onDragStart?: ({ event, action, direction }) => any;
    onDragOver?: (event: any) => any | Promise<any>;
    onDropFromOutside?: (DnDInteractionInfoType) => any;
    dragFromOutsideItem?: () => any;
    draggableAccessor: () => any;
    resizable: boolean;
    resizableAccessor: string | (() => any);
}

export class CalendarDnD extends React.Component<CalendarDnDProps, CalendarDnDState> {
    static defaultProps: Partial<CalendarDnDProps> = {
        // TODO: pick these up from Calendar.defaultProps
        components: {},
        draggableAccessor: null,
        resizableAccessor: null,
        step: 30,
    };

    // draggable;
    private readonly components: Partial<CalendarContextValueType['components']> = {};

    constructor(props, context) {
        super(props, context);

        const { components } = this.props;

        this.components = mergeComponents(components, {
            rootViewWrapper: RootViewWrapper,
            eventWrapper: EventWrapper,
            eventContainerWrapper: EventContainerWrapper,
            // weekWrapper: WeekWrapper,
        });

        this.state = {
            interacting: false,
            action: null,
            direction: null,
            event: null,
        };
    }

    getDnDContextValue = (): DndContextValueType => {
        return {
            draggable: {
                onStart: this.handleInteractionStart,
                onEnd: this.handleInteractionEnd,
                onBeginAction: this.handleBeginAction,
                onContinueAction: this.onContinueAction,
                onDropFromOutside: this.handleInteractionEnd,
                dragFromOutsideItem: this.props.dragFromOutsideItem,
                draggableAccessor: wrapAccessor(this.props.draggableAccessor),
                resizableAccessor: wrapAccessor(this.props.resizableAccessor),
                dragAndDropAction: this.state,
            },
        };
    };

    onContinueAction = (event: CalEvent, action: DND_ACTION) => {
        if (action === DND_ACTION.DRAGOVER) {
            this.setState({ action, event });
        }
    };
    handleBeginAction = (event: CalEvent, action: DND_ACTION, direction?: RESIZE_DIRECTION) => {
        if (action === DND_ACTION.DRAGOVER) {
            this.setState({ action, event });
        } else {
            this.setState({ event, action, direction });
            const { onDragStart } = this.props;
            if (onDragStart) {
                onDragStart({ event, action, direction });
            }
        }
    };

    handleInteractionStart = () => {
        if (this.state.interacting === false) {
            this.setState({ interacting: true });
        }
    };

    handleInteractionEnd = async (interactionInfo: DnDInteractionInfoType) => {
        const { action, event } = this.state;

        if (!action) {
            return;
        }

        this.setState({
            action: null,
            event: null,
            interacting: false,
            direction: null,
        });

        if (interactionInfo == null) {
            return;
        }

        interactionInfo.event = event;
        const { onEventMove, onEventResize, onDropFromOutside } = this.props;
        if (onEventMove && action === DND_ACTION.MOVE) {
            return onEventMove(interactionInfo);
        }
        if (onEventResize && action === DND_ACTION.RESIZE) {
            return onEventResize(interactionInfo);
        }
        if (onEventResize && action === DND_ACTION.DRAGOVER) {
            return onDropFromOutside?.(interactionInfo);
        }
    };

    render() {
        let { selectable, onEventMove, onEventResize, draggableAccessor, resizableAccessor, ...props } = this.props;
        const { interacting } = this.state;

        // @ts-ignore
        // delete props.onEventDrop
        // @ts-ignore
        // delete props.onEventResize

        props.selectable = selectable ? 'ignoreEvents' : false;

        const className = clsx(props.className, 'rbc-addons-dnd', !!interacting && 'rbc-addons-dnd-is-dragging');

        return (
            <DndContext.Provider value={this.getDnDContextValue()}>
                <Calendar
                    {...props}
                    className={className}
                    // elementProps={elementPropsWithDropFromOutside}
                    components={this.components}
                />
                {this.props.debug && (
                    <div
                        style={{
                            position: 'fixed',
                            bottom: 40,
                            left: '20%',
                            zIndex: 11000,
                            padding: 5,
                            fontSize: 'x-small',
                            backgroundColor: 'beige',
                            width: 300,
                            maxHeight: 800,
                            overflow: 'auto',
                        }}
                    >
                        <DebugDnd />
                    </div>
                )}
            </DndContext.Provider>
        );
    }
}
