import * as React from 'react';
import { compose } from '@ez/tools';
import { ConfirmButton, ConnectionTableDef, DefaultConnectionTable, Icon, toastError } from '@ez/components';
import { IAppNavigatorProps, ModuleLink } from '@poolware/react-app-navigator';
import { NodeType } from '@poolware/api';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';
import { useQueryBrandConnection } from '../../../queries/QueryBrandConnection';

const BrandsTable: React.FC<PageControlProps> = (props) => {
    const { connectionState, connectionData } = useQueryBrandConnection();

    const onDelete = async (brand: NodeType.Brand) => {
        try {
            await props.mutateBrand.delete({ id: brand.id });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    const { companyId } = props;

    const tableDef: ConnectionTableDef<NodeType.Brand> = [
        {
            header: 'Brand Name',
            sortKey: 'name',
            cell: (brand) => {
                if (companyId) {
                    return <ModuleLink to={`/${companyId}/brands/${brand.id}`}>{brand.name}</ModuleLink>;
                } else {
                    return <ModuleLink to={`/brands/${brand.id}`}>{brand.name}</ModuleLink>;
                }
            },
        },
        {
            header: 'Visibility',
            cellProps: {
                width: 3,
            },
            cell: (brand) => {
                const { franchise } = brand;
                if (franchise) {
                    return (
                        <div>
                            <Icon name={'building'} />
                            {franchise.name}
                        </div>
                    );
                } else {
                    return 'Global';
                }
            },
        },
        {
            header: '',
            cellProps: {
                textAlign: 'center',
                width: 1,
            },
            cell: (brand) => {
                return (
                    <ConfirmButton
                        icon={'trash'}
                        onClick={() => onDelete(brand)}
                        confirmMessage={{
                            header: 'Delete Brand?',
                        }}
                        negative={true}
                        confirmButton={{
                            icon: 'trash',
                            content: 'Delete',
                        }}
                    />
                );
            },
        },
    ];

    return (
        <DefaultConnectionTable
            tableProps={{ color: 'teal' }}
            connectionData={connectionData}
            connectionState={connectionState}
            tableDef={tableDef}
            noDataComponent={<span>-</span>}
        />
    );
};

export default compose(withProductCatalogMutators(['BrandsList']))(BrandsTable);

interface PageControlProps extends IAppNavigatorProps, IProductCatalogMutators {
    companyId: NodeType.ID;
}
