import styled from 'styled-components';

const sidebarWidth = 260;

export const PageContainer = styled.div.attrs({
    // @ts-ignore
    'data-testid': 'PageContainer',
})`
    display: flex;
    height: 100%;
    padding: 0.5rem;
`;

export const LeftSidebarContainer = styled.div.attrs({
    // @ts-ignore
    'data-testid': 'LeftSidebarContainer',
})`
    display: flex;
    flex-direction: column;
    flex: 0 0 ${sidebarWidth}px;
    max-width: ${sidebarWidth}px;
    padding: 0 0.5rem 0 1px;
    gap: 0.5rem;
    //max-height: calc(100vh - 160px);
    //overflow-y: auto;
`;

export const DetailsViewContainer = styled.div.attrs({
    // @ts-ignore
    'data-testid': 'DetailsViewContainer',
})`
    flex: 1 1 auto;
    padding-left: 0.5rem;
    min-width: 0;
`;
