import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { Icon } from '@ez/components';
import { ModuleIconNames } from '../constants';
import styled from 'styled-components';
import _ from 'lodash';

const StyledUL = styled.ul`
    margin: 0.5em 0;
    //padding: 0 1.5em;
    padding-inline-start: 1.5em;
    list-style-type: none;

    ul {
        color: #444444;
        font-size: 0.9em;
    }
`;

export const JobTodoTemplatePreview: React.FC<{ jobTemplate: NodeType.JobTodoTemplate; jobItemsLimit?: number }> = ({
    jobTemplate,
    jobItemsLimit,
}) => {
    let items = fromEdges(jobTemplate.items).sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1));

    const shouldLimit = items.length > jobItemsLimit;
    if (shouldLimit) {
        items = items.slice(0, jobItemsLimit);
    }

    return (
        <>
            <span>
                <Icon name={ModuleIconNames.WorkOrderItem} /> {jobTemplate.title}
            </span>
            <StyledUL>
                {items.map((i) => {
                    return (
                        <li key={i.id}>
                            <Icon name={ModuleIconNames.WorkOrderItemTask} />
                            {i.title}
                        </li>
                    );
                })}
                {shouldLimit && (
                    <li>
                        <Icon name={ModuleIconNames.WorkOrderItemTask} /> ...
                    </li>
                )}
            </StyledUL>
        </>
    );
};

export const WorkOrderTemplatePreview: React.FC<{
    woTemplate: NodeType.WorkOrderTemplate;
    hideTitle?: boolean;
    full?: boolean;
    jobItemsLimit?: number;
}> = ({ woTemplate, full, jobItemsLimit, hideTitle }) => {
    const jobTodoTemplates =
        full &&
        fromEdges(woTemplate?.jobTodoTemplateAssocs)
            .sort((a, b) => (a.priority > b.priority ? 1 : -1))
            .map((a) => a.jobTodoTemplate);

    const woTemplateTitle = _.truncate(woTemplate.templateTitle, { length: 70 });
    return (
        <>
            {!hideTitle && (
                <span>
                    <Icon name={ModuleIconNames.WorkOrder} /> <b>Work Order</b> {woTemplateTitle}
                </span>
            )}
            {full && (
                <StyledUL>
                    {jobTodoTemplates.map((jt) => {
                        return (
                            <li key={jt.id}>
                                <JobTodoTemplatePreview key={jt.id} jobTemplate={jt} jobItemsLimit={jobItemsLimit} />
                            </li>
                        );
                    })}
                </StyledUL>
            )}
        </>
    );
};

export const ServiceJobTemplatePreview: React.FC<{
    serviceJobTemplate: NodeType.ServiceJobTemplate;
    // limit preview to only work orders with the provided ids
    workOrderIds?: NodeType.ID[];
    full?: boolean;
    jobItemsLimit?: number;
}> = ({ serviceJobTemplate, full = false, jobItemsLimit = 4, workOrderIds }) => {
    if (!serviceJobTemplate) {
        return null;
    }

    const isPublished = serviceJobTemplate.isPublished;
    const isArchived = !!serviceJobTemplate.archivedAt;

    const woTemplates = fromEdges(serviceJobTemplate?.workOrderTemplateAssocs)
        .sort((a, b) => (a.priority > b.priority ? 1 : -1))
        .map((a) => a.workOrderTemplate);

    const sjTemplateTitle = _.truncate(serviceJobTemplate.templateTitle, { length: 80 });
    const sjProvidedTitle =
        serviceJobTemplate.templateTitle !== serviceJobTemplate.providedTitle
            ? _.truncate(serviceJobTemplate.providedTitle, { length: 80 })
            : undefined;

    const style = isArchived ? { color: '#c67821' } : undefined;

    return (
        <div style={style}>
            <span>
                {isArchived ? (
                    <i>
                        <Icon name={'archive'} /> {sjTemplateTitle} (archived)
                    </i>
                ) : (
                    <>
                        <Icon name={ModuleIconNames.ServiceCall} /> {sjTemplateTitle}{' '}
                        {sjProvidedTitle && <i>({sjProvidedTitle})</i>}
                        {!isPublished && <i> (draft)</i>}
                    </>
                )}
            </span>
            <StyledUL>
                {woTemplates.map((woTemplate, index) => {
                    if (workOrderIds?.length > 0 && !workOrderIds.includes(woTemplate.id)) {
                        return null;
                    }
                    return (
                        <li key={woTemplate.id} style={{ marginTop: '0.5em' }}>
                            <WorkOrderTemplatePreview
                                woTemplate={woTemplate}
                                full={full}
                                jobItemsLimit={jobItemsLimit}
                            />
                        </li>
                    );
                })}
            </StyledUL>
        </div>
    );
};
