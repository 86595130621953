import * as React from 'react';
import { clsxm } from '../utils';

export const FormGroup: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, children, ...rest }) => {
    return (
        <div
            className={clsxm(
                'w-full flex flex-col md:flex-row flex-wrap gap-x-2 justify-between group ez-child-grow',
                className
            )}
            {...rest}
        >
            {children}
        </div>
    );
};
