import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Modal, ConnectionTableDef, FormFieldInputDebounced, TablePickerPanel, useModalCtrl } from '@ez/components';
import { useQueryBrandConnection } from '../queries/QueryBrandConnection';
import { Slot } from '@radix-ui/react-slot';
import { SuggestProductCompanyInput } from './SuggestProductCompanyInput';

const BrandTablePickerPanel: React.FC<{
    onCancel: () => void;
    onSelect: (brand: NodeType.Brand) => any;
}> = ({ onSelect, onCancel }) => {
    const [filterName, setFilterName] = React.useState<string>('');
    const [filterCompany, setFilterCompany] = React.useState<NodeType.ProductCompany>(null);
    const { connectionData, connectionState } = useQueryBrandConnection({
        nameSearch: filterName,
        companyId: filterCompany?.id,
        defaultPageSize: 10,
    });

    const tableDef: ConnectionTableDef<NodeType.Brand> = [
        {
            header: 'Brand',
            cell: (r) => {
                return <>{r?.name}</>;
            },
        },
        {
            header: 'Company',
            cell: (r) => {
                return <>{r?.company?.name}</>;
            },
        },
    ];

    const filterComp = (
        <div className={'flex gap-2'}>
            <FormFieldInputDebounced value={filterName} onChange={setFilterName} placeholder={'Brand name...'} />
            <SuggestProductCompanyInput onChange={setFilterCompany} value={filterCompany} />
        </div>
    );

    return (
        <TablePickerPanel
            filterComp={filterComp}
            multiSelect={false}
            panelHeader={{ content: 'Brands' }}
            onCancel={onCancel}
            onSubmit={(items) => onSelect(items[0])}
            connectionState={connectionState}
            connectionData={connectionData}
            tableDef={tableDef}
        />
    );
};

export interface BrandPickerProps {
    onChange?: (brand: NodeType.Brand) => any;
}

export const BrandPicker: React.FC<BrandPickerProps> = ({ children, onChange }) => {
    const modalCtrl = useModalCtrl();

    const onSelect = (brand: NodeType.Brand) => {
        modalCtrl.onClose();
        // do something with the selected brand
        onChange?.(brand);
    };

    return (
        <>
            <Slot onClick={modalCtrl.onOpen}>{children}</Slot>
            {modalCtrl.open && (
                <Modal {...modalCtrl} size={'small'}>
                    <BrandTablePickerPanel onSelect={onSelect} onCancel={modalCtrl.onClose} />
                </Modal>
            )}
        </>
    );
};
