import * as React from 'react';
import { useMemo } from 'react';
import { clsxm, ConnectionTableDef, DefaultConnectionTable, Display } from '@ez/components';
import { useViewer } from '@poolware/app-shell';
import { NoDeviceMessage, OnlineIcon } from '../common';
import { LayoutViewProps } from '../types';
import { EZCDevicesLinkWithState, resolveColorForDeviceAlarmStatus } from '../../common';

const useTableDef = (isAdmin?: boolean) => {
    return useMemo(() => {
        const tableDef: ConnectionTableDef<EZCDevicesLinkWithState> = [
            {
                header: 'Device',
                width: '3',
                cell: (device) => {
                    const statusColor = resolveColorForDeviceAlarmStatus(device?.alarmStatus);
                    const className = clsxm(
                        'bg-light_gray px-2 flex flex-row rounded flex-nowrap gap-2',
                        statusColor.bg,
                        statusColor.text
                    );
                    return (
                        <div className={className}>
                            <OnlineIcon device={device} />
                            {device.device?.title || '--'}
                        </div>
                    );
                },
            },
            {
                header: 'Owner',
                width: '3',
                cell: (item) => {
                    return <Display.Entity value={item.owner} />;
                },
            },
            {
                header: 'Pool',
                cell: (item) => {
                    if (!item.pool) return null;
                    return (
                        <div>
                            {item.pool?.name} ({item.pool?.volume || '--'} L)
                        </div>
                    );
                },
            },
            {
                header: 'Address',
                cell: (item) => {
                    const address = item?.pool?.address || item?.owner?.primaryAddress;
                    return <Display.Address value={address} />;
                },
            },
        ];

        if (isAdmin) {
            tableDef.push({
                header: 'Franchise',
                cell: (r) => {
                    return <>{r.franchise?.name}</>;
                },
            });
        }

        return tableDef;
    }, [isAdmin]);
};

export interface PageListProps extends LayoutViewProps {}

export const LayoutTable: React.FC<PageListProps> = ({ onView, connectionData, connectionState, onNew }) => {
    const { isAdmin } = useViewer();
    const tableDef = useTableDef(isAdmin);

    return (
        <DefaultConnectionTable
            // debug={true}
            onRowClick={onView}
            tableDef={tableDef}
            connectionData={connectionData}
            connectionState={connectionState}
            noDataComponent={<NoDeviceMessage onNew={onNew} />}
        />
    );
};
