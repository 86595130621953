import * as React from 'react';
import { useState } from 'react';
import {
    Alert,
    ConfirmButton,
    Icon,
    MenuBarItem,
    MenuBarSection,
    PageLayout,
    PageSkeletonLoader,
    Panel,
    SectionHeader,
    StickyMenuBar,
    Table,
    toastError,
    VStack,
} from '@ez/components';
import { PageControlProps } from './Page.Control';
import { NewCustomerSelectPanel } from './NewCustomerSelectPanel';
import { NodeType } from '@poolware/api';
import * as URLBuilder from '../../../../routes/url-builder';
import { CustomerDetails, QueryCustomerNode } from './CustomerDetails';

const PoolDescription: React.FC<{ pool: NodeType.Pool }> = ({ pool }) => {
    return (
        <div className={'grid gid-2'}>
            {pool.type && (
                <span>
                    <b>Type:</b> {pool?.type?.name}
                </span>
            )}
            {pool.volume && (
                <span>
                    <b>Volume:</b> {pool?.volume}
                </span>
            )}
            {pool.sanitiser && (
                <span>
                    <b>Sanitiser:</b> {pool?.sanitiser?.name}
                </span>
            )}
        </div>
    );
};

const ArrowDown = () => (
    <div style={{ width: '100%' }}>
        <Icon style={{ margin: '10px auto', display: 'block' }} name={'arrow down'} />
    </div>
);

const TransferDetails = ({ from, toID, site }) => {
    return (
        <QueryCustomerNode customerId={toID}>
            {({ loading, customer }) => {
                if (loading) {
                    return <PageSkeletonLoader />;
                }
                if (!customer) {
                    return <div>Failed to load customer details</div>;
                }
                return (
                    <>
                        <Panel.Item label="Site name" content={site.name} />
                        <Panel.ItemAddress label="Address" content={site.address} />
                        <Panel.Divider />
                        <Panel.ItemEntity label="Current Owner" content={from} />
                        <ArrowDown />
                        <Panel.ItemEntity label="New Owner" content={customer} />
                    </>
                );
            }}
        </QueryCustomerNode>
    );
};

const Page: React.FC<PageControlProps> = ({ site, pools, customer, AppNavigator, mutateSite }) => {
    const [newOwnerID, setNewOwnerID] = useState<NodeType.ID>(null);

    const goBack = () => {
        AppNavigator.replaceToOrigin();
    };

    const onTransferSubmit = async () => {
        try {
            await mutateSite.transferTo({ to: newOwnerID, id: site.id });
            const link = URLBuilder.Customer(newOwnerID).view;
            AppNavigator.replace(link);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to transfer ownership', description: e.message });
        }
    };

    const onNewCustomerSelect = (newCustomerID: NodeType.ID) => {
        setNewOwnerID(newCustomerID);
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'}>
                        Back To Site
                    </MenuBarItem>
                </MenuBarSection>
            </StickyMenuBar>
            <SectionHeader dividing>
                <Icon name={'exchange'} />
                Change Site Ownership
            </SectionHeader>
            <Panel>
                <Panel.Header content={`Site to transfer`} color={'green'} />
                <Panel.Body>
                    <Panel.Item label="Site name" content={site.name} />
                    <Panel.ItemAddress label="Address" content={site.address} />
                    <SectionHeader>Pools</SectionHeader>
                    <Table compact size={'small'}>
                        <Table.Body>
                            {pools.map((pool) => {
                                const bottleNumber = pool.bottleNumber;
                                return (
                                    <Table.Row key={pool.id}>
                                        <Table.Cell width={4} verticalAlign={'top'}>
                                            <b>{pool.name}</b>
                                            {bottleNumber && (
                                                <>
                                                    <br />
                                                    <span style={{ fontSize: '0.9em' }}>{bottleNumber}</span>
                                                </>
                                            )}
                                        </Table.Cell>

                                        <Table.Cell>
                                            <PoolDescription pool={pool} />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </Panel.Body>
            </Panel>
            <div className={'my-2'} />
            <div className={'grid gap-2 grid-cols-1 md:grid-cols-2'}>
                <div>
                    <Panel>
                        <Panel.Header icon="user">FROM: Current Owner</Panel.Header>
                        <Panel.Body>
                            <CustomerDetails customerId={customer.id} />
                        </Panel.Body>
                    </Panel>
                </div>
                <div>
                    <NewCustomerSelectPanel site={site} onCustomerSelect={onNewCustomerSelect} />
                </div>
            </div>

            <div className={'my-2'} />

            {newOwnerID && (
                <>
                    <Alert type={'info'}>This site with all its pools will be transferred to the new owner.</Alert>
                    <ConfirmButton
                        fluid
                        primary
                        basic={false}
                        size={'medium'}
                        onClick={onTransferSubmit}
                        content={'Transfer'}
                        icon={'exchange'}
                        confirmButton={{
                            content: 'Transfer',
                            icon: 'exchange',
                        }}
                        confirmMessage={{
                            header: 'Transfer site ownership?',
                            content: <TransferDetails site={site} from={customer} toID={newOwnerID} />,
                        }}
                    />
                </>
            )}
        </PageLayout>
    );
};

export default Page;
