import * as React from 'react';
import { NodeType } from '@poolware/api';
import {
    Alert,
    Button,
    ButtonWithPopup,
    ConnectionErrorMessage,
    FormikDefaultForm,
    Modal,
    PageSkeletonLoader,
    Panel,
    PanelHeaderProps,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { FormikCustomerAndLocationInputFields } from '@poolware/app-shell';
import { PanelCustomerAndLocation, PanelCustomerAndLocationBodyItems } from '../../components/PanelCustomerAndLocation';
import { useQueryServiceJob } from '../../queries/use-query-service-job';
import gql from 'graphql-tag';
import { serviceJobFragmentFull } from '../../queries/fragments';
import { useWorkOrderViewContext } from './WorkOrderPageContext';

const QL = gql`
    query QueryServiceJob($id: ID!) {
        node(id: $id) {
            id
            ...ServiceJobFragment
        }
    }
    ${serviceJobFragmentFull}
`;

const PanelCopyCustomer: React.FC<{ serviceJobId: NodeType.ID; onCancel; onSubmit }> = ({
    serviceJobId,
    onSubmit,
    onCancel,
}) => {
    const { node: serviceJob, loading, error } = useQueryServiceJob(serviceJobId, { query: QL });

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !serviceJob) {
        return <div>Failed to load service job.</div>;
    }

    const customer = serviceJob.customer;
    const pool = serviceJob.pool;
    const address = serviceJob.address;

    const handleSubmit = async () => {
        await onSubmit({
            customer,
            pool,
            address,
        });
    };

    return (
        <Panel>
            <Panel.Header content={'Apply Customer Details?'} />
            <Panel.Body>
                {customer ? (
                    <PanelCustomerAndLocationBodyItems pool={pool} customer={customer} address={address} />
                ) : (
                    <Alert type={'warning'}>Parent Service Case is not linked to any customer.</Alert>
                )}
            </Panel.Body>
            <Panel.Footer className={'flex flex-row justify-between'}>
                <Button primary type="button" basic onClick={onCancel} content={'Cancel'} />
                <ButtonWithPopup
                    showLoaderOnClick={true}
                    variant={'primary'}
                    disabled={!customer}
                    onClick={handleSubmit}
                    content={'Assign'}
                />
            </Panel.Footer>
        </Panel>
    );
};

export const WorkOrderCustomerAndLocationPanelForm: React.FC<{
    readonly?: boolean;
    locked?: boolean;
}> = ({ readonly, locked }) => {
    const { workOrder, workOrderMutators } = useWorkOrderViewContext();

    const modalCtrlFind = useModalCtrl();
    const modalCtrlCopy = useModalCtrl();

    const customer = workOrder?.customer;
    const pool = workOrder?.pool;
    const address = workOrder?.address;
    const initialValues = { customer: customer, ...workOrder };

    const onSubmit = async (values: typeof initialValues) => {
        const updateConf = {
            workFor: values?.customer?.id || null,
            address: values?.address?.id || null,
            pool: values?.pool?.id || null,
        };

        try {
            await workOrderMutators.update({ id: workOrder.id, ...updateConf });
            modalCtrlFind.onClose();
            modalCtrlCopy.onClose();
            return updateConf;
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    const hasCustomer = customer?.id || pool?.id || address?.id;

    const headerButton: PanelHeaderProps['button'] = hasCustomer
        ? {
              icon: 'edit',
              content: 'Edit',
              locked: locked,
              onClick: modalCtrlFind.onOpen,
          }
        : {
              icon: 'plus',
              content: 'Add',
              locked: locked,
              menuItems: [
                  {
                      content: 'Find',
                      icon: 'address book',
                      locked: locked,
                      onClick: modalCtrlFind.onOpen,
                  },
                  {
                      content: 'Copy from Parent Case',
                      icon: 'paste',
                      locked: locked,
                      onClick: modalCtrlCopy.onOpen,
                  },
              ],
          };

    return (
        <>
            <PanelCustomerAndLocation
                customer={customer}
                pool={pool}
                address={address}
                headerButton={!readonly && headerButton}
            />
            {modalCtrlFind.open && (
                <Modal size={'small'} {...modalCtrlFind}>
                    <FormikDefaultForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onCancel={modalCtrlFind.onClose}
                        icon={'user'}
                    >
                        <FormikCustomerAndLocationInputFields />
                    </FormikDefaultForm>
                </Modal>
            )}
            {modalCtrlCopy.open && (
                <Modal size={'small'} {...modalCtrlCopy}>
                    <PanelCopyCustomer
                        serviceJobId={workOrder.serviceJob?.id}
                        onSubmit={onSubmit}
                        onCancel={modalCtrlCopy.onClose}
                    />
                </Modal>
            )}
        </>
    );
};
