import tw, { css, styled } from 'twin.macro';
import { Checkbox, StrictCheckboxProps } from '../semantic-ui';
import * as React from 'react';
import { Icon, SemanticICONS } from '../Icon';

interface ActionBarItemProps {
    dividing?: boolean;
    level?: 'first' | 'second'; // defaults to 'first'
}

const ActionBarItem = styled.div<ActionBarItemProps>`
    font-size: 1em;
    margin: 0;
    padding: 0;
    padding-left: ${(props) => (props.level === 'second' ? '1em' : '2px')};
    border-bottom: ${(props) => props.dividing !== undefined && '1px solid #CCC'};
    margin-bottom: ${(props) => props.dividing !== undefined && '0.25em'};
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 100%;
`;

interface StyledCheckboxProps extends StrictCheckboxProps {
    fullWidth?: boolean;
}

const Ch: React.FC<StyledCheckboxProps> = ({ fullWidth, ...rest }) => <Checkbox {...rest} />;

const CheckboxStyled = styled(Ch)<{ $highlightChecked?: boolean }>(({ $highlightChecked }) => {
    return [
        css`
            &&& {
                font-size: 1em;
                padding: 2px;

                &:hover {
                    //background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                }
                flex-grow: 1;
            }
        `,
        $highlightChecked &&
            css`
                &&& {
                    &.checked {
                        color: ${(props) => props.theme?.components?.activeFilterColor};
                        border-radius: 4px;

                        & > label {
                            color: ${(props) => props.theme?.components?.activeFilterColor};
                        }
                    }
                }
            `,
    ];
});

const CheckboxLabel = styled.label`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
`;

interface ActionBarCheckboxProps {
    icon?: SemanticICONS | React.ReactNode;
    onChange: (checked: boolean) => any;
    content: string | any;
    checked: boolean;
    indeterminate?: boolean;
    labelStyle?: any;
    radio?: boolean;
    actionComponent?: any;
    disabled?: boolean;
    highlightChecked?: boolean;
}

const ActionBarCheckbox: React.FC<ActionBarCheckboxProps> = ({
    content,
    checked,
    indeterminate,
    onChange,
    icon,
    disabled,
    radio = false,
    labelStyle = {},
    actionComponent,
    highlightChecked = true,
}) => {
    const isContentString = typeof content === 'string';
    const showTooltip = isContentString ? content.length > 15 : false;
    const fullWidth = !actionComponent;

    const iconComp = typeof icon === 'string' ? <Icon name={icon as SemanticICONS} /> : icon;

    return (
        <>
            <CheckboxStyled
                $highlightChecked={highlightChecked}
                disabled={disabled}
                radio={radio}
                indeterminate={indeterminate}
                checked={checked}
                onChange={(_, { checked }) => onChange?.(checked)}
                fullWidth={fullWidth}
                label={
                    <CheckboxLabel title={showTooltip ? content : undefined} style={labelStyle}>
                        {iconComp}
                        {content}
                    </CheckboxLabel>
                }
            />
            {actionComponent && <span style={{ float: 'right', width: '2em' }}>{actionComponent}</span>}
        </>
    );
};

export interface ActionBarItemCheckboxProps extends ActionBarCheckboxProps, ActionBarItemProps {
    onMouseEnter?: () => any;
    onMouseLeave?: () => any;
}

export const ActionBarItemCheckbox: React.FC<ActionBarItemCheckboxProps> = ({
    dividing,
    onMouseEnter,
    onMouseLeave,
    checked,
    level = 'first',
    ...props
}) => {
    return (
        <ActionBarItem
            dividing={dividing}
            onMouseEnter={() => onMouseEnter?.()}
            onMouseLeave={() => onMouseLeave?.()}
            level={level}
        >
            <ActionBarCheckbox checked={checked} {...props} />
        </ActionBarItem>
    );
};

const HeaderContainer = styled.div(() => [
    tw`flex flex-row uppercase`,
    tw`p-0 text-sm font-bold`,
    css`
        &:not(:last-child) {
            padding-bottom: 0.125em;
            border-bottom: rgba(34, 36, 38, 0.15) solid 1px;
        }

        & > .section-header-content {
            flex-grow: 1;
        }

        & > .section-header-action {
            flex-grow: 0;
            min-width: 2em;
            text-align: center;
        }
    `,
]);

const ActionBarSectionHeader: React.FC<{ content: any; icon?: SemanticICONS; actionComponent?: any }> = ({
    content,
    icon,
    actionComponent,
}) => {
    return (
        <HeaderContainer>
            <span className={'section-header-content'}>
                <Icon name={icon} />
                {content}
            </span>
            {actionComponent && <span className={'section-header-action'}>{actionComponent}</span>}
        </HeaderContainer>
    );
};

const ActionBarScroll = styled.div<{ maxHeight?: number }>`
    height: 100%;
    max-height: ${(props) => props.maxHeight || 320}px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const ActionBarContainer = styled.div<{ active?: boolean; outlineColor?: string }>(
    ({ theme, outlineColor, active }) => {
        let _outlineColor = undefined;
        if (active) {
            _outlineColor = outlineColor || theme?.components?.activeFilterColor || '#a333c8';
        }
        return [
            tw`rounded m-0 p-2 shadow-sm text-base border border-solid space-y-2`,
            css`
                background: ${(props) => props.theme?.panel?.body?.background || '#FFFFFF'};
                background-color: ${(props) => props.theme?.panel?.body?.backgroundColor};
                color: ${(props) => props.theme?.panel?.body?.color};
            `,

            active &&
                css`
                    border-color: ${_outlineColor};
                `,
        ];
    }
);

const ActionBarDivider = styled.div`
    margin: 0.25em;
    padding: 0 0.25em;
    border: 0.5px solid rgba(234, 236, 238, 1);
`;

const ActionBarBody = tw.div``;

export interface ActionBarProps extends React.HTMLAttributes<HTMLDivElement> {
    outlineColor?: string;
    active?: boolean;
}

export class ActionBar extends React.Component<ActionBarProps> {
    public static Header = ActionBarSectionHeader;
    public static Body = ActionBarBody;
    public static Item = ActionBarItem;
    public static Scroll = ActionBarScroll;
    public static Checkbox = ActionBarCheckbox;
    public static ItemCheckbox = ActionBarItemCheckbox;
    // public static Select = ActionBarSelect;
    public static Divider = ActionBarDivider;

    render() {
        const { outlineColor, active, ...props } = this.props;
        return <ActionBarContainer active={active} outlineColor={outlineColor} {...props} />;
    }
}

export interface ActionBar extends React.Component<ActionBarProps> {
    Header: typeof ActionBarSectionHeader;
    Body: typeof ActionBarBody;
    Scroll: typeof ActionBarScroll;
    Item: typeof ActionBarItem;
    Checkbox: typeof ActionBarCheckbox;
    ItemCheckbox: typeof ActionBarItemCheckbox;
}
