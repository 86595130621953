import { DataPointsHistoryType } from '../../data';

import { scaleTime } from 'd3-scale';
import { DateRange } from '@ez/components';
import { NodeType } from '@poolware/api';

const prefferedVisualRanges = {
    TEMP: [10, 40],
    PH: [6, 8.5],
    ORP: [1, 800],
    // SALINITY: [0, 2500],
    FLOW: [0, 1],
    VSDW: [0, 2400],
};
const formatDataPoint = (dataPoint: number, type: NodeType.EZCDeviceMetricsEntryType) => {
    if (!dataPoint === null || dataPoint === undefined) {
        return null;
    }
    if (dataPoint === -9999) {
        return null;
    }

    if (type.tag === 'FLOW') {
        if (dataPoint === 0) {
            return null;
        }
    }

    if (type.tag === 'VSDW') {
        if (dataPoint === 65535) {
            return null;
        }
        if (dataPoint === 0) {
            // 0 means either the sensor is not connected or the value is 0.
            // We can't differentiate between the two.
            return null;
        }
    }
    return dataPoint;
};

export const getRangeForCharts = (metrics: DataPointsHistoryType, dataKey: string) => {
    const minValue =
        prefferedVisualRanges[dataKey]?.[0] ?? metrics?.types?.find((t) => t.tag === dataKey)?.minValue ?? 'dataMin';
    const maxValue =
        prefferedVisualRanges[dataKey]?.[1] ?? metrics?.types?.find((t) => t.tag === dataKey)?.maxValue ?? 'dataMax';

    const minValueFn = (dataMin: number) => {
        if (minValue === undefined || minValue === 'dataMin') {
            return dataMin;
        }
        return Math.min(dataMin, minValue);
    };

    const maxValueFn = (dataMax: number) => {
        if (minValue === undefined) {
            return dataMax;
        }
        return dataMax * 1.1;
    };

    return [minValueFn, maxValue];
};

export const prepareGraphData = (metrics: DataPointsHistoryType, dateRange: DateRange) => {
    const dataPoints: any[] = metrics?.data?.map((samples) => {
        const flowSamplesIndex = metrics?.types?.findIndex((type) => type.tag === 'FLOW');
        const flowSample = samples[flowSamplesIndex];
        const isFlowOn = flowSample > 0;
        const types: any = metrics?.types?.reduce((acc, type, currentIndex) => {
            acc[type.tag] = formatDataPoint(samples[currentIndex], type);
            if (['PH', 'ORP', 'SALINITY'].includes(type.tag)) {
                if (isFlowOn) {
                    acc[type.tag + '_flow'] = formatDataPoint(samples[currentIndex], type);
                }
            }
            return acc;
        }, {});
        return types;
    });

    const yRanges = metrics?.types?.reduce((acc, type, currentIndex) => {
        acc[type.tag] = getRangeForCharts(metrics, type.tag);
        return acc;
    }, {});

    const timeScale = scaleTime().domain([dateRange.startDate.getTime(), dateRange.endDate.getTime()]).nice();

    const ticks = timeScale.ticks(12).map((tick) => tick.valueOf());

    return { yRange: yRanges, dataPoints, ticks };
};

export type DataChartType = ReturnType<typeof prepareGraphData>;
