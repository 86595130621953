import React from 'react';
import { AttributeRenderProps } from './types';
import { ButtonWithDropdownMenu } from '@ez/components';
import { getAttributeLabels, ReadOnlyValueContainer, ValueContainer } from './common';

export const AttributeRendererBool: React.FC<AttributeRenderProps<boolean>> = ({ attrModel, onChange }) => {
    const { value, valueStr, isReadOnly } = getAttributeLabels(attrModel);

    if (isReadOnly) {
        return <ReadOnlyValueContainer value={valueStr} />;
    }

    return (
        <ButtonWithDropdownMenu
            size={'sm'}
            variant={'tertiary'}
            content={<ValueContainer value={valueStr} />}
            showLoaderOnClick={true}
            options={[
                {
                    content: 'Connect',
                    icon: 'plug',
                    onClick: () => onChange(true),
                },
                {
                    content: 'Disconnect',
                    icon: 'cancel',
                    onClick: () => onChange(false),
                },
            ]}
        />
    );
};
