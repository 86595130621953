import React, { useMemo } from 'react';
import { AttributeModel, AttributeRenderProps } from './types';
import {
    Button,
    FormikDefaultFormBase,
    FormikDefaultFormBaseProps,
    FormikInputField,
    Icon,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@ez/components';
import { getAttributeLabels, ReadOnlyValueContainer, ValueContainer } from './common';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { ShadowAttrStatus } from '../../../../common/get-shadow-attribute';

const createYupSchema = (attrModel: AttributeModel<number>) => {
    let attr = Yup.number();
    if (attrModel?.attributeMetaType?.min) {
        attr = attr.min(attrModel?.attributeMetaType?.min);
    }
    if (attrModel?.attributeMetaType?.max) {
        attr = attr.max(attrModel?.attributeMetaType?.max);
    }
    return Yup.object().shape({
        attribute: attr,
    });
};

const SubmitButton: React.FC = () => {
    const formikContext = useFormikContext();
    const { handleSubmit, isSubmitting, dirty } = formikContext;
    const activateOnDirty = true;
    return (
        <Button
            variant={'primary'}
            type={'button'}
            onClick={(e) => handleSubmit(e as any)}
            loading={isSubmitting}
            disabled={isSubmitting || (activateOnDirty && !dirty)}
            content={'Set'}
            className={'mb-2'}
        />
    );
};

interface AttributeEditFormProps extends FormikDefaultFormBaseProps {
    attrModel: AttributeModel<number>;
}

const AttributeEditForm: React.FC<AttributeEditFormProps> = ({ attrModel, ...rest }) => {
    const validationSchema = useMemo(() => createYupSchema(attrModel), [attrModel]);
    return (
        <FormikDefaultFormBase validationSchema={validationSchema} {...rest}>
            <div className={'flex flex-row gap-1 w-full items-end'}>
                <FormikInputField
                    name={'attribute'}
                    label={attrModel.attributeMetaType?.label}
                    min={attrModel?.attributeMetaType?.min}
                    max={attrModel?.attributeMetaType?.max}
                    step={attrModel?.attributeMetaType?.step}
                    type={'number'}
                />
                <SubmitButton />
            </div>
        </FormikDefaultFormBase>
    );
};

export const AttributeRendererFloat: React.FC<AttributeRenderProps<number>> = ({ attrModel, onChange }) => {
    const { value, valueStr, isReadOnly } = getAttributeLabels(attrModel);
    const [open, setOpen] = React.useState(false);
    const isPending = attrModel.attribute?.status === ShadowAttrStatus.Pending;

    if (isReadOnly) {
        return <ReadOnlyValueContainer value={valueStr} />;
    }

    const onSubmitChange = async (values: { attribute: number }) => {
        const newValue = values.attribute;
        await onChange(newValue);
        setOpen(false);
    };

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild={true}>
                <Button
                    size={'sm'}
                    variant={'tertiary'}
                    iconRight={'chevron down'}
                    onClick={() => setOpen(true)}
                    content={<ValueContainer value={valueStr} isPending={isPending} />}
                />
            </PopoverTrigger>
            <PopoverContent sideOffset={8} className={'py-2 px-4'}>
                <AttributeEditForm
                    debug={false}
                    attrModel={attrModel}
                    onSubmit={onSubmitChange}
                    initialValues={{
                        attribute: value,
                    }}
                />
            </PopoverContent>
        </Popover>
    );
};
