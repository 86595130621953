import * as React from 'react';
import { NodeType } from '@poolware/api';
import { useQueryBrandConnection } from '../queries/QueryBrandConnection';
import { createSuggestInputWithQueryConnectionHook } from '@poolware/app-shell';

export const SuggestBrandInput = createSuggestInputWithQueryConnectionHook<
    NodeType.Brand,
    NodeType.BrandViewerBrandsSearch
>({
    useQueryConnectionHook: useQueryBrandConnection,
    mapQueryToSearch: (term) => {
        return {
            name: {
                like: term,
            },
        };
    },
    getOptionLabel: (item) => item?.name,
    formatOptionLabel: (item) => {
        const brandName = item?.name;
        const companyName = item?.company?.name;
        if (!companyName) {
            return <>{brandName}</>;
        }

        return (
            <div className={'flex flex-row items-center'}>
                <div>{brandName}</div>
                <div className={'flex-1'} />
                <div className={'pr-2 text-sm italic text-tertiary'}>{companyName}</div>
            </div>
        );
    },
});
