import * as React from 'react';

import { useAppointmentReportsActions } from '../../../redux';
import { ActionBar } from '@ez/components';
import { SuggestInputFranchise } from '@poolware/app-shell';
import { NodeType } from '@poolware/api';

export interface FranchiseFilterExternalProps {
    label?: string;
}

export interface FranchiseFilterProps extends FranchiseFilterExternalProps {}

const FranchiseFilter: React.FC<FranchiseFilterProps> = () => {
    const { AppointmentReportsState, AppointmentReportsAction } = useAppointmentReportsActions();
    return (
        <ActionBar>
            <ActionBar.Header content={'Franchise'} icon={'filter'} />
            <SuggestInputFranchise
                onItemSelect={AppointmentReportsAction.setFranchiseFilter}
                value={AppointmentReportsState.filters?.franchise as NodeType.Franchise}
            />
        </ActionBar>
    );
};

export default FranchiseFilter;
