import React from 'react';
import { AttributeRenderProps } from './types';
import { ButtonWithDropdownMenu } from '@ez/components';
import { getAttributeLabels, ReadOnlyValueContainer, ValueContainer } from './common';
import { ShadowAttrStatus } from '../../../../common/get-shadow-attribute';

export const AttributeRendererEnum: React.FC<AttributeRenderProps<any>> = ({ attrModel, onChange }) => {
    const { value, valueStr, isReadOnly } = getAttributeLabels(attrModel);

    const options =
        attrModel.attributeMetaType?.options?.map((o) => ({
            content: o,
            onClick: () => onChange(o),
        })) || [];

    const isPending = attrModel.attribute?.status === ShadowAttrStatus.Pending;

    if (isReadOnly) {
        return <ReadOnlyValueContainer value={valueStr} />;
    }

    return (
        <ButtonWithDropdownMenu
            size={'sm'}
            variant={'tertiary'}
            content={<ValueContainer value={valueStr} isPending={isPending} />}
            showLoaderOnClick={true}
            options={options}
        />
    );
};
