import * as React from 'react';
import { AttributeRenderProps } from './types';
import { JSONView } from '@ez/components';

export const AttributeRenderUnknown: React.FC<AttributeRenderProps<any>> = ({ attrModel, onChange }) => {
    return (
        <div>
            Unknown
            <JSONView data={attrModel} />
        </div>
    );
};
