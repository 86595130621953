import * as React from 'react';
import {
    ButtonWithPopup,
    MenuBarDropdownItemWithConfirm,
    MenuBarGroup,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    MultiColumnGrid,
    PageLayout,
    StickyMenuBar,
    toastError,
    VStack,
    Segment,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType, useMutationWarrantyClaim, useMutationWarrantyClaimFileAttach } from '@poolware/api';
import { FileAttachmentPanel } from '../../components/FileAttachments/FileAttachmentPanel';
import { WarrantyPreview } from '../../components/WarrantyPreview';
import { DiscussionPanelParticipantType, PanelDiscussion } from '../../components/PanelDiscussion';
import { useViewer } from '@poolware/app-shell';
import { PanelClaimStatus } from './PanelCaseStatus';

const READ_ONLY_MSG = 'This claim has been submitted to the office. It cannot be deleted.';

export interface PageProps {
    claim: NodeType.WarrantyClaim;
    refetchQuery: any;
}

export const PageSubmitted: React.FC<PageProps> = ({ claim, refetchQuery }) => {
    const { isAdmin } = useViewer();

    const { AppNavigator, params } = useAppNavigator<{ id?: string }>();
    const { delete: deleteMutation, forceClaimCase } = useMutationWarrantyClaim({
        refetchQueries: ['QueryWarrantyClaimsConnection', refetchQuery],
        awaitRefetchQueries: true,
    });

    const mutatorWarrantyClaimFileAttach = useMutationWarrantyClaimFileAttach({
        refetchQueries: ['QueryWarrantyClaimsConnection', refetchQuery],
        awaitRefetchQueries: true,
    });

    const isSubmitted = Boolean(claim.submittedAt);
    const isClosed = Boolean(claim.statusGroup === NodeType.WarrantyClaimStatusGroupEnum.CLOSED);
    const canDelete = claim.checkMutations?.delete;
    const locked = !isAdmin && isClosed ? 'Case is closed' : false;

    const goBack = () => {
        AppNavigator.navigateToOrigin('/', { relativeToModule: true });
    };

    const onDelete = async () => {
        try {
            await deleteMutation({ id: params.id });
            goBack();
        } catch (e) {
            toastError(e);
        }
    };

    const onEdit = () => {
        AppNavigator.navigateRelative(`/${claim.id}/edit`);
    };

    const onForceResubmit = async () => {
        try {
            await forceClaimCase({ id: claim.id });
        } catch (e) {
            toastError(e);
        }
    };

    const barGroupItemConf: MenuBarGroupProps = {
        menu: { menuMode: MenuBarGroupMODE.DROPDOWN, icon: 'bars', color: 'red' },
        items: [
            {
                render: (
                    <MenuBarDropdownItemWithConfirm
                        icon={'trash'}
                        color="orange"
                        content="Delete"
                        onClick={onDelete}
                        disabled={isSubmitted || !canDelete}
                        popup={isSubmitted ? { content: READ_ONLY_MSG } : { content: 'Delete Warranty Claim' }}
                        confirm={{
                            confirmMessage: {
                                header: 'Delete?',
                                content: 'There is no undo! This tag will be deleted forever! ',
                            },
                            negative: true,
                            confirmButton: {
                                content: 'Delete',
                                icon: 'trash',
                            },
                        }}
                    />
                ),
            },
        ],
    };

    const adminBarGroupItemConf: MenuBarGroupProps = !isAdmin
        ? undefined
        : {
              menu: { menuMode: MenuBarGroupMODE.DROPDOWN, icon: 'spy', content: 'Admin', color: 'black' },
              items: [
                  {
                      onClick: onForceResubmit,
                      content: 'Force Resubmit',
                      icon: 'upload',
                      popup: { content: 'Force to create Claim Case without sending any notifications.' },
                  },
                  {
                      onClick: onDelete,
                      content: 'Force Delete',
                      icon: 'trash',
                  },
              ],
          };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back'} />
                    <MenuBarHeaderItem icon={'certificate'}>
                        Warranty Claim - {claim.warrantyClaimNumber}
                    </MenuBarHeaderItem>
                </MenuBarSection>

                <MenuBarSection position={'right'}>
                    {adminBarGroupItemConf && <MenuBarGroup {...adminBarGroupItemConf} />}
                    {barGroupItemConf && <MenuBarGroup {...barGroupItemConf} />}
                </MenuBarSection>
            </StickyMenuBar>

            {/*<SubmissionSteps step={isSubmitted ? 'done' : 'review'} />*/}

            <MultiColumnGrid col1={'minmax(480px, 1fr)'} col2={'minmax(480px, 1fr)'} vStackBrkPt={800} gridGap={2}>
                <VStack>
                    <Segment>
                        <VStack>
                            <WarrantyPreview claim={claim} />
                            <FileAttachmentPanel
                                claim={claim}
                                readOnly={false}
                                locked={locked}
                                mutatorWarrantyClaimFileAttach={mutatorWarrantyClaimFileAttach}
                            />
                            <div>
                                <ButtonWithPopup
                                    onClick={onEdit}
                                    locked={locked}
                                    variant={'secondary'}
                                    floated={'left'}
                                    icon={'edit'}
                                    content={'Edit Form'}
                                />
                            </div>
                        </VStack>
                    </Segment>
                </VStack>
                <VStack>
                    <PanelClaimStatus claim={claim} />
                    <PanelDiscussion viewerType={DiscussionPanelParticipantType.WARRANTY_REQUESTOR} claim={claim} />
                </VStack>
            </MultiColumnGrid>
        </PageLayout>
    );
};
