import {
    addressFragment,
    NodeType,
    QueryConnectionConfigProps,
    QueryConnectionExternalProps,
    SortDirection,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import gql from 'graphql-tag';
import { SearchByAssignmentState } from './use-query-work-orders-connection';
import * as _ from 'lodash';
import { RecurServiceJobStatus } from '../constants';

const defaultQL = gql`
    query QueryServiceJobList(
        $first: Int
        $page: Int
        $search: ServiceJobViewerServiceJobsSearch
        $sort: ServiceJobViewerServiceJobsSort
    ) {
        viewer {
            serviceJobs(first: $first, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        title
                        serviceJobNumber
                        dueDate
                        isRecurring
                        isImplicit
                        group {
                            id
                            title
                        }
                        stage {
                            id
                            title
                            type
                            status
                        }
                        staff {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                            role {
                                id
                                name
                            }
                        }
                        address {
                            id
                            ...AddressFragment
                        }
                        customer {
                            id
                            crn
                            companyName
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        workOrders(first: 5, sort: { createdAt: true }) {
                            edges {
                                node {
                                    id
                                    workOrderNumber
                                    assignedTo {
                                        id
                                        user {
                                            id
                                            firstName
                                            lastName
                                        }
                                    }

                                    title
                                    appointmentItem {
                                        id
                                        startDate
                                        duration
                                    }
                                    stage {
                                        id
                                        title
                                        status
                                        type
                                    }
                                }
                            }
                        }
                        createdAt
                        updatedAt
                        entity {
                            id
                            franchise {
                                id
                                name
                            }
                        }
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${addressFragment}
`;

export interface QueryServiceJobConnectionProps extends Partial<QueryConnectionConfigProps> {
    isImplicit?: boolean;
    isStatus?: number | string;
    serviceJobNumber?: string;
    customerCRN?: string;
    customerId?: string;
    workOrderNumber?: string;
    isRecurring?: boolean;
    assignedToStaff?: NodeType.NodeOrId<NodeType.Staff>;
    assignmentState?: SearchByAssignmentState;
    relatedCustomer?: NodeType.ID;
    relatedPool?: NodeType.ID;
    relatedStaff?: NodeType.ID;
    serviceJobGroup?: NodeType.NodeOrId<NodeType.ServiceJobGroup>;
    recurJobStatus?: RecurServiceJobStatus;
    usedTemplate?: NodeType.ID;
    stage?: NodeType.ID;
    stageType?: NodeType.ServiceJobStageType[];
    ezcDeviceLink?: NodeType.ID;
}

const mapPropsToSearchQuery = (props: QueryServiceJobConnectionProps): NodeType.ServiceJobViewerServiceJobsSearch => {
    if (!props) {
        return undefined;
    }

    const {
        isStatus,
        assignedToStaff,
        customerId,
        serviceJobNumber,
        customerCRN,
        workOrderNumber,
        isRecurring,
        recurJobStatus,
        isImplicit,
    } = props;

    const search: NodeType.ServiceJobViewerServiceJobsSearch = {};

    if (serviceJobNumber !== undefined && serviceJobNumber !== null) {
        _.set(search, 'serviceJobNumber', serviceJobNumber);
    }

    if (isStatus !== undefined && isStatus !== null) {
        _.set(search, 'status.is', isStatus);
    }

    if (isRecurring !== undefined && isRecurring !== null) {
        _.set(search, 'isRecurring.is', Boolean(isRecurring));
    }

    if (assignedToStaff) {
        _.set(search, 'staff.id', NodeType.extractId(assignedToStaff));
    }

    if (props.assignmentState === SearchByAssignmentState.UNASSIGNED) {
        _.set(search, 'staff.id', null);
    }

    if (workOrderNumber !== undefined && workOrderNumber !== null) {
        _.set(search, 'workOrders.workOrderNumber', workOrderNumber);
    }

    if (customerCRN !== undefined && customerCRN !== null) {
        _.set(search, 'customer.crn.like', customerCRN);
    }

    if (customerId) {
        _.set(search, 'customer.id', customerId);
    }

    if (props.relatedCustomer) {
        _.set(search, 'relatedCustomer', props.relatedCustomer);
    }

    if (props.relatedPool) {
        _.set(search, 'relatedPool', props.relatedPool);
    }

    if (props.ezcDeviceLink) {
        _.set(search, 'ezcDeviceLink.id', props.ezcDeviceLink);
    }

    if (isImplicit === true) {
        _.set(search, 'isImplicit', true);
    } else if (isImplicit === false) {
        _.set(search, 'isImplicit', false);
    }

    if (recurJobStatus !== undefined) {
        console.error('TODO: not supported yet');
        throw new Error('not supported yet');
        // switch (recurJobStatus) {
        //     case RecurServiceJobStatus.Active:
        //         _.set(search, 'isEnded', false);
        //         break;
        //     case RecurServiceJobStatus.Stopped:
        //         _.set(search, 'isEnded', true);
        //         break;
        //     default:
        //         throw new Error('Unknown value of recurJobStatus');
        // }
    }

    if (props.relatedStaff) {
        _.set(search, 'relatedStaff', props.relatedStaff);
    }

    if (props.usedTemplate) {
        _.set(search, 'usedTemplate.id', props.usedTemplate);
    }

    if (props.serviceJobGroup) {
        _.set(search, 'group.id', props.serviceJobGroup);
    }

    if (props.stage) {
        _.set(search, 'stage.id', props.stage);
    }
    if (props.stageType) {
        _.set(search, 'stageType', props.stageType?.filter(Boolean));
    }

    return search;
};

export function useQueryServiceJobConnection(
    props?: QueryConnectionExternalProps<NodeType.ServiceJob> & QueryServiceJobConnectionProps
) {
    const connectionPath = 'viewer.serviceJobs';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultSortKey: 'createdAt',
        defaultSortDirection: SortDirection.DES,
        search: mapPropsToSearchQuery(props),
        ...props,
    });

    return useQueryConnection({
        query: props?.query || defaultQL,
        fetchPolicy: 'cache-and-network',
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
        skip: props.skip,
    });
}
