import * as React from 'react';
import { useField } from 'formik';
import { FormikFormFieldLabel } from './FormikFormFieldLabel';
import { Optionable } from '../optionable';
import { FormField } from '../form-components/FormField';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../radix';
import { useId } from '../utils';
import { FormikFieldProps } from './FormikTypes';

export interface FormikSelectFieldProps extends FormikFieldProps {
    onDidChange?: ({ newValue, prevValue }) => any;
    options?: Optionable<any>[];
    iconLeft?: React.ReactNode;
    placeholder?: string;
    id?: string;
    className?: string;
    clearable?: boolean;
}

export const FormikSelectField: React.FC<FormikSelectFieldProps> = ({
    label,
    name,
    required,
    onDidChange,
    options,
    dataTestId,
    placeholder,
    iconLeft,
    disabled,
    readOnly,
    id,
    className,
    clearable,
    ...rest
}) => {
    const fieldId = useId(id);
    const [field, meta, helpers] = useField(name);
    let hasError = meta.touched && !!meta.error;

    const handleChange = (newValue) => {
        helpers.setValue(newValue);
        onDidChange?.({ newValue, prevValue: field.value });
    };
    const isDisabled = disabled || readOnly;

    return (
        <FormField className={className}>
            <FormikFormFieldLabel htmlFor={fieldId} label={label} name={name} required={required} />
            <Select
                value={field.value}
                key={field.value}
                onValueChange={handleChange}
                disabled={isDisabled}
                required={required}
            >
                <SelectTrigger
                    className={'w-full'}
                    id={fieldId}
                    aria-invalid={hasError}
                    onBlur={field.onBlur}
                    onClear={() => {
                        helpers.setValue(null);
                    }}
                    showClear={clearable && !!field.value}
                    iconLeft={iconLeft}
                >
                    <SelectValue placeholder={placeholder} />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        {options?.map((o, index) => {
                            return (
                                <SelectItem key={index} value={o.value}>
                                    {o.text}
                                </SelectItem>
                            );
                        })}
                    </SelectGroup>
                </SelectContent>
            </Select>
        </FormField>
    );
};
