import gql from 'graphql-tag';
import { createUseQueryNodeHook } from '@ez/api-core';
import { NodeType } from '@poolware/api';

const QL = gql`
    query EZCDeviceLink_Events($id: ID!, $scope: String) {
        node(id: $id) {
            id
            ... on EZCDeviceLink {
                id
                device {
                    id
                    externalServiceError
                    events(scope: $scope) {
                        id
                        messageContentType
                        message
                        triggerValue
                        forDateAt
                        type {
                            name
                            description
                            tag
                            presentationTag
                            importance
                        }
                    }
                }
            }
        }
    }
`;

export const useQueryEzcDeviceLinkEvents = createUseQueryNodeHook<NodeType.EZCDeviceLink>(QL);
