import * as React from 'react';
import { FormikFormDebug } from '@ez/components';

export type LayoutConfig = {
    left: React.ReactNode[];
    middle: React.ReactNode[];
    right: React.ReactNode[];
};

export const CalcLayoutConfigurable: React.FC<{ config: LayoutConfig }> = ({ config }) => {
    return (
        <>
            <div className="w-full flex flex-col md:flex-row gap-4">
                <div className={'w-auto md:w-1/5 min-w-[280px] flex flex-col gap-2'}>
                    {config.left?.map((c, index) => (
                        <div key={index}>{c}</div>
                    ))}
                </div>
                <div className={'flex-auto overflow-y-auto min-w-[200px] flex flex-col gap-2'}>
                    {config.middle?.map((c, index) => (
                        <div key={index}>{c}</div>
                    ))}
                </div>
                <div className={'w-auto md:w-1/3 min-w-[280px] flex flex-col gap-2'}>
                    {config.right?.map((c, index) => (
                        <div key={index}>{c}</div>
                    ))}
                </div>
            </div>
            <FormikFormDebug hidden={true} />
        </>
    );
};
