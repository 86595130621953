import * as React from 'react';
import { useMemo } from 'react';
import { ConnectionTableDef, DefaultConnectionTable, Display, Icon, Popup } from '@ez/components';
import { useQueryWaterTestReportConnection } from '../../../../Queries/QueryWaterTestReportConnection';
import { fromEdges, NodeType } from '@poolware/api';
import gql from 'graphql-tag';
import _uniqBy from 'lodash/uniqBy';
import { useAppConfig, useViewer } from '@poolware/app-shell';

const ArchiveIcon = () => {
    return (
        <Popup
            position="top center"
            trigger={<Icon name="archive" />}
            content={
                <>
                    <Icon name="lock" />
                    Archived and locked
                </>
            }
            basic
        />
    );
};

const LegacyIcon = () => {
    return <Popup position="top center" trigger={<Icon name="history" />} content="Legacy report" basic />;
};

const QL = gql`
    query PoolTestReportsList_History(
        $first: Int
        $page: Int
        $search: TestReportViewerReportsSearch
        $sort: TestReportViewerReportsSort
    ) {
        viewer {
            reports(first: $first, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        isArchived
                        isFinalised
                        docVersion
                        createdAt
                        updatedAt
                        previewPdfUrl
                        pdfUrl
                        sampleSet {
                            id
                            createdAt
                            samples {
                                edges {
                                    node {
                                        id
                                        value
                                        measurementType {
                                            id
                                            name
                                            unit {
                                                id
                                                name
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        reportBy {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        reportTo {
                            id
                            user {
                                id
                                customer {
                                    id
                                    crn
                                    user {
                                        id
                                        firstName
                                        lastName
                                    }
                                }
                            }
                        }
                        pool {
                            id
                            name
                            bottleNumber
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                pageMeta {
                    pageCount
                    totalCount
                }
            }
        }
    }
`;

const WaterTestHistory: React.FC<{ poolId: string; onSelect }> = ({ poolId, onSelect }) => {
    const config = useAppConfig();
    const { appLayoutMode } = useViewer();
    const sort = { createdAt: false };
    const { connectionState, connectionData } = useQueryWaterTestReportConnection({
        query: QL,
        pageSize: 10,
        poolId: poolId,
        sort: sort,
    });

    const measurementTypes = connectionData
        .flatMap((report) => {
            if (!report.sampleSet) {
                return [];
            } else {
                return fromEdges(report.sampleSet.samples);
            }
        })
        .map((s) => s.measurementType);

    // Get unique measurement types
    const uniqueMesTypes = _uniqBy(measurementTypes, 'id');

    // Generate column definitions for each measurement type.
    let colls: ConnectionTableDef<NodeType.TestReport> = [];
    if (appLayoutMode.gteTablet) {
        colls = uniqueMesTypes.map((type) => {
            return {
                header: <div>{type.name}</div>,
                cellProps: {
                    textAlign: 'center',
                },
                singleLine: true,
                textAlign: 'center',
                cell: (report: NodeType.TestReport) => {
                    const set = report.sampleSet;
                    if (!set) {
                        return '--';
                    }
                    const sample = fromEdges(set.samples).find((s) => s?.measurementType?.id === type.id);
                    return <small>{sample ? <Display.Num value={sample.value} /> : '--'}</small>;
                },
            };
        });
    }

    const handleOnSelect = (report) => {
        onSelect?.(report);
    };

    const tableDef: ConnectionTableDef<NodeType.TestReport> = useMemo(
        () => [
            {
                header: 'Date',
                textAlign: 'center',
                cellProps: {
                    width: 2,
                },
                cell: (report) => {
                    return (
                        <span className={'text-blue-500'}>
                            <Display.Date value={report.createdAt} format={'YYYY-MMM-DD, hh:mm A'} />
                        </span>
                    );
                },
            },
            ...colls,
            {
                header: 'Submitted By',
                singleLine: true,
                cell: (report) => {
                    return <Display.Entity value={report.reportBy} />;
                },
            },
            {
                header: 'PDF Report',
                textAlign: 'center',
                onCellClick: () => {
                    /*noop*/
                },
                cell: (report) => {
                    let previewPdfUrl = report?.pdfUrl;
                    if (!previewPdfUrl) return null;

                    return (
                        <a
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            target={'_blank'}
                            href={previewPdfUrl}
                            rel="noopener noreferrer"
                        >
                            <Icon name={'file pdf'} color={'red'} /> Download
                        </a>
                    );
                },
            },
            {
                header: '',
                singleLine: true,
                cell: (report) => {
                    const { isArchived } = report;
                    const isLegacy = isArchived && !(report.docVersion > 0.1);
                    return (
                        <>
                            {isArchived && <ArchiveIcon />}
                            {isLegacy && <LegacyIcon />}
                        </>
                    );
                },
            },
        ],
        [connectionState]
    );

    return (
        <DefaultConnectionTable
            tableProps={{
                color: 'green',
                size: 'small',
            }}
            onRowClick={handleOnSelect}
            tableDef={tableDef}
            connectionData={connectionData}
            connectionState={connectionState}
        />
    );
};

export default WaterTestHistory;
