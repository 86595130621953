export interface Shadow<State extends Record<string, any>> {
    desired?: State;
    reported?: State;
    actionQueue?: any[];
    apiVersion?: number | null;
}

export enum ShadowAttrStatus {
    Pending = 'Pending',
    Unknown = 'Unknown',
    Settled = 'Settled',
}

export interface ShadowAttribute<Type> {
    desired: Type | undefined;
    reported: Type | undefined;
    status: ShadowAttrStatus;
    key: string | number | symbol;
}

export type InferState<ShadowType> = ShadowType extends Shadow<infer State> ? State : never;

export const getShadowAttribute = <
    ShadowType extends Shadow<any>,
    State extends InferState<ShadowType>,
    Key extends keyof State,
    Value extends State[Key]
>(
    shadow: ShadowType | undefined,
    key: Key
): ShadowAttribute<Value> => {
    const desired = shadow?.desired?.[key];
    const reported = shadow?.reported?.[key];
    let status: ShadowAttrStatus;
    if (desired === undefined && reported === undefined) {
        status = ShadowAttrStatus.Unknown;
    } else if (desired !== undefined && desired !== reported) {
        status = ShadowAttrStatus.Pending;
    } else {
        status = ShadowAttrStatus.Settled;
    }
    return {
        key,
        desired,
        reported,
        status,
    };
};
