import * as React from 'react';

import { Page as Main } from './Page/Page';
import { PageChecklist } from './PageCheckList/PageChecklist';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { PageNewActionable } from './PageNewActionable/PageNewActionable';
import { Page } from './PagePhotoPicker/Page';
import { PageJobNotes } from './PageJobNotes/PageJobNotes';
import { MobileAppointmentViewRoot } from './Root';
import { createGlobalStyle } from 'styled-components';
import { ThemeInterface } from '@ez/components';

export const Router = () => {
    return (
        <MobileAppointmentViewRoot>
            <ModuleRootSwitch>
                <ModuleRoute path="/" exact component={Main} />
                <ModuleRoute path="/checklist" component={PageChecklist} />
                <ModuleRoute path="/new-actionable" component={PageNewActionable} />
                <ModuleRoute path="/photos" component={Page} />
                <ModuleRoute path="/notes" component={PageJobNotes} />
            </ModuleRootSwitch>
        </MobileAppointmentViewRoot>
    );
};
