import * as React from 'react';
import { NodeType } from '@poolware/api';
import { useQueryEzcDeviceLinkEvents } from './use-query-ezc-device-events';
import {
    Alert,
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    MenuBar,
    PageSkeletonLoader,
    Panel,
    ScrollableLayout,
    SlideInDock,
    useModalCtrl,
} from '@ez/components';
import { EZCDevicesLinkWithState } from '../../common';
import { pageSpinner } from '@poolware/app-shell';

export interface TabEventFeedProps {
    device: EZCDevicesLinkWithState;
    scope: 'history' | 'alerts';
}

const tableDef: ConnectionTableDef<NodeType.EZCDeviceEvent> = [
    {
        header: 'Date',
        width: 2,
        cell: (event) => <Display.Date value={event.forDateAt} format={'lll'} />,
    },
    {
        header: 'Type',
        width: 2,
        cell: (event) => event.type?.name,
    },
    {
        header: 'Message',
        cell: (event) => <div tw={'truncate max-w-screen-sm'}>{event.message}</div>,
    },
    {
        width: 1,
        header: 'Trigger Value',
        cell: (event) => event.triggerValue,
    },
];

export const TabEventFeed: React.FC<TabEventFeedProps> = ({ device, scope }) => {
    const slideInCtrl = useModalCtrl<NodeType.EZCDeviceEvent>();
    const { node, loading, error } = useQueryEzcDeviceLinkEvents(device.id, {
        variables: {
            id: device.id,
            scope: scope,
        },
    });
    const connectionData = node?.device?.events;
    const activeEvent = slideInCtrl.payload;

    const spinner = pageSpinner(loading, error, node);
    if (spinner) {
        return spinner;
    }

    if (device?.device?.externalServiceError) {
        return <Alert type={'warning'}>Failed to reach device service</Alert>;
    }

    return (
        <div>
            <DefaultConnectionTable
                onRowClick={(event) => slideInCtrl.onOpen(event)}
                tableDef={tableDef}
                connectionData={connectionData}
            />
            <SlideInDock {...slideInCtrl}>
                <ScrollableLayout>
                    <ScrollableLayout.MenuBar>
                        <MenuBar.Section position={'left'}>
                            <MenuBar.HeaderItem color={'grey'} icon={'bell'}>
                                Event
                            </MenuBar.HeaderItem>
                        </MenuBar.Section>
                        <MenuBar.Section position={'right'}>
                            <MenuBar.Item onClick={() => slideInCtrl.onClose()} icon={'cancel'} color={'grey'} />
                        </MenuBar.Section>
                    </ScrollableLayout.MenuBar>
                    <ScrollableLayout.BodyScroll>
                        <div className={'p-2'}>
                            <Panel labelWidth={120}>
                                <Panel.Body>
                                    <Panel.ItemDate label={'Date'} content={activeEvent?.forDateAt} />
                                    <Panel.Divider />
                                    <Panel.Item label={'Event Type'} content={activeEvent?.type?.name} />
                                    {/*<Panel.Item label={'Importance'} content={activeEvent?.type?.importance} />*/}
                                    {/*<Panel.Item label={'Event Description'} content={activeEvent?.type?.description} />*/}
                                    <Panel.Divider />
                                    <Panel.Item label={'Trigger Value'} content={activeEvent?.triggerValue} />
                                    <Panel.Item wrap={false} label={'Event Message'} content={activeEvent?.message} />
                                </Panel.Body>
                            </Panel>
                        </div>
                    </ScrollableLayout.BodyScroll>
                </ScrollableLayout>
            </SlideInDock>
        </div>
    );
};
