import * as React from 'react';
import {
    confirmModalImperative,
    DevOnly,
    Display,
    HeaderButtonType,
    Icon,
    LinkButton,
    Modal,
    Panel,
    PrintSectionColumn,
    ScrollY,
    SectionHeader,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { fromEdges, NodeType, useMutationWorkOrder } from '@poolware/api';
import { ServiceJobLink, WorkOrderLink } from '../../../Links';
import {
    mapServiceJobStageTypeToIconProps,
    mapServiceJobStageTypeToName,
    ModuleIconNames,
    ServiceJobModuleRouterID,
} from '../../../constants';
import { ModuleLink, useAppNavigator } from '@poolware/react-app-navigator';
import { WorkOrderJobItemsPanel } from '../../../ServiceJobs/Print/WorkOrderPrintView/WorkOrderItemPanel';
import * as _ from 'lodash';
import { NotesFeed } from '../../../components/NotesFeed';
import { ServiceJobTemplatePicker } from '../../../connected-components/ServiceJobTemplatePicker';
import { ServiceJobTemplatePreview } from '../../../components/ServiceJobTemplatePreview';
import { usePersistedToggle } from '@ez/tools';
import { useCalendarActions } from '../../../redux';
import { useAppointmentViewCtx } from '../AppointmentViewContext';
import * as URLBuilder from '../../url-builder';

type PanelSectionHeaderProps = any; // TODO: temporary workaround

export const PanelItemServiceJob: React.FC<{ serviceJob: NodeType.ServiceJob }> = ({ serviceJob }) => {
    if (!serviceJob || (serviceJob.isImplicit && !serviceJob.isRecurring)) {
        return null;
    }

    const groupTitle = serviceJob?.group?.title;

    const label = serviceJob.isRecurring ? 'Recurring Service' : 'Service Case';

    return (
        <>
            {groupTitle && (
                <Panel.Item iconMarker={ModuleIconNames.ServiceCase} label={'Service Group'}>
                    {groupTitle && <span className={'pl-1 font-bold text-purple-600'}>{groupTitle}</span>}
                </Panel.Item>
            )}
            <Panel.Item iconMarker={serviceJob.isRecurring ? 'refresh' : ModuleIconNames.ServiceCase} label={label}>
                <ServiceJobLink id={serviceJob.id}>#{serviceJob.serviceJobNumber}</ServiceJobLink>
                {serviceJob?.title && <span> - {serviceJob?.title}</span>}
            </Panel.Item>
        </>
    );
};

const ButtonOpenWo: React.FC<{ onOpen; onReplace }> = ({ onReplace, onOpen }) => {
    if (!onReplace) {
        return <SectionHeader.Button onClick={onOpen}>Open W/O</SectionHeader.Button>;
    }

    return (
        <Panel.SectionHeader.ButtonGroup>
            <Panel.SectionHeader.Button segmentPosition={'first'} onClick={onOpen}>
                Open W/O
            </Panel.SectionHeader.Button>
            <Panel.SectionHeader.DropdownButton
                segmentPosition={'last'}
                options={[
                    {
                        key: 'replace',
                        icon: 'repeat',
                        content: 'Replace W/O Template',
                        value: 'replace',
                        onClick: onReplace,
                    },
                ]}
            />
        </Panel.SectionHeader.ButtonGroup>
    );
};

const PanelItemsWaterTestReports: React.FC<{
    appointmentItem: NodeType.AppointmentItem;
}> = ({ appointmentItem }) => {
    const testReports = fromEdges(appointmentItem.workOrder.testReports);
    if (testReports.length === 0) return null;
    return (
        <>
            <Panel.SectionHeader size={'small'} icon={'lab'}>
                Water Tests ({testReports.length})
            </Panel.SectionHeader>
            {testReports.map((testReport) => {
                const pool = testReport?.pool;
                const customerId = testReport?.pool?.entity?.user?.customer?.id;
                let link = URLBuilder.Customer(customerId).Pool(pool?.id).WaterTest(testReport.id).view;
                return (
                    <Panel.Item
                        labelWidth={128}
                        label={
                            <span className={'text-gray-500 pl-1'}>
                                <Display.Date value={testReport.createdAt} format={'LL'} />
                            </span>
                        }
                        key={testReport.id}
                    >
                        <ModuleLink relativeToModule={false} to={link} setOrigin={true}>
                            Open Report <Icon name={'external'} />
                        </ModuleLink>
                        <span className={'w-4'} />
                        <a target={'_blank'} href={testReport.pdfUrl} rel="noopener noreferrer">
                            Download PDF <Icon name={'file pdf'} color={'red'} />
                        </a>
                    </Panel.Item>
                );
            })}
        </>
    );
};

const PanelSectionHeaderWithCollapseButton: React.FC<
    PanelSectionHeaderProps & {
        expanded: boolean;
        onToggleExpand: (boolean) => any;
        showCollapseButton: boolean;
    }
> = ({ expanded, onToggleExpand, showCollapseButton = false, ...rest }) => {
    const collapseButton: HeaderButtonType = (
        <SectionHeader.Button
            onClick={() => onToggleExpand(!expanded)}
            icon={expanded ? 'chevron down' : 'chevron right'}
        />
    );
    return (
        <Panel.SectionHeader size={'small'} icon={'comments'} button={showCollapseButton && collapseButton} {...rest} />
    );
};

export const PanelWorkOrderInstantiated: React.FC<{
    appointmentItem: NodeType.AppointmentItem;
    workOrderMutator: ReturnType<typeof useMutationWorkOrder>;
    canEdit: boolean;
}> = ({ appointmentItem, workOrderMutator, canEdit }) => {
    const modalWOPicker = useModalCtrl();
    const { CalendarAction } = useCalendarActions();
    const previewItemCtx = useAppointmentViewCtx();

    const { AppNavigator, location } = useAppNavigator();
    const { workOrder, serviceJob } = appointmentItem || { isRecurring: false };
    const hasWo = !!workOrder?.id;
    const [expandedJobs, setExpandedJobs] = usePersistedToggle('service-jobs.dock.appt.wo-show.toggle', true);
    const [expandedNotes, setExpandedNotes] = usePersistedToggle('service-jobs.dock.appt.notes-show.toggle', true);

    const woTitle = _.truncate(workOrder?.title, { length: 40 });

    const onOpenWorkOrder = async () => {
        AppNavigator.navigateRelative(`/wo/${workOrder?.id}`, {
            setOrigin: true,
            moduleId: ServiceJobModuleRouterID,
            query: {
                'return-appt': location.pathname,
            },
        });
    };

    const openButton: HeaderButtonType = hasWo && (
        <ButtonOpenWo onOpen={onOpenWorkOrder} onReplace={canEdit ? modalWOPicker.onOpen : undefined} />
    );
    const wrap = false;

    if (!hasWo) {
        return null;
    }

    const onSelectJobTemplate = async (
        template: NodeType.ServiceJobTemplate,
        workOrderTemplates: NodeType.WorkOrderTemplate[]
    ) => {
        modalWOPicker.onClose();
        if (!template) {
            return null;
        }

        const workOrderTemplate = workOrderTemplates?.[0];

        try {
            const res = await confirmModalImperative({
                confirmMessage: {
                    header: 'Replace Work Order Template?',
                    content: (
                        <ServiceJobTemplatePreview
                            serviceJobTemplate={template}
                            full={true}
                            workOrderIds={[workOrderTemplate.id]}
                        />
                    ),
                },
                confirmButton: { content: 'Replace' },
                cancelButton: { content: 'Cancel' },
            });

            if (!res) {
                return;
            }

            return await workOrderMutator.replaceWorkOrderTemplate({
                template: workOrderTemplate?.id,
                id: appointmentItem?.workOrder.id,
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const notes = fromEdges(workOrder?.notes);
    const files = fromEdges(workOrder?.files);
    const jobTodos = fromEdges(workOrder?.jobs).sort((l, r) => {
        return l.index > r.index ? 1 : -1;
    });

    const onFollowupAppointmentSelect = (item: NodeType.AppointmentItem) => {
        CalendarAction.setPreviewAppt(item.id);
        previewItemCtx.setAppointmentItemId(item.id);
    };

    return (
        <Panel>
            <Panel.Body color={'grey'}>
                <Panel.SectionHeader size={'small'} icon={ModuleIconNames.WorkOrder} button={openButton}>
                    Work Order
                </Panel.SectionHeader>
                <>
                    <PanelItemServiceJob serviceJob={serviceJob} />
                    <Panel.Item iconMarker={ModuleIconNames.WorkOrder} label={'Work Order'} wrap={wrap}>
                        <WorkOrderLink id={workOrder.id}>#{workOrder.workOrderNumber}</WorkOrderLink> - {woTitle}
                    </Panel.Item>
                    <Panel.Item label={'Work Order Status'} wrap={wrap}>
                        <span>
                            <Icon {...mapServiceJobStageTypeToIconProps(workOrder.stage?.type)} />
                            {mapServiceJobStageTypeToName(workOrder.stage?.type)}
                        </span>
                    </Panel.Item>
                    <DevOnly hidden={true}>
                        <Panel.ItemText label={'Job Description'} content={workOrder.description} />
                    </DevOnly>
                    {jobTodos.length > 0 && (
                        <>
                            <PanelSectionHeaderWithCollapseButton
                                size={'small'}
                                icon={ModuleIconNames.WorkOrderItem}
                                expanded={expandedJobs}
                                onToggleExpand={setExpandedJobs}
                                showCollapseButton={jobTodos.length > 0}
                            >
                                Job Tasks ({jobTodos.length})
                            </PanelSectionHeaderWithCollapseButton>
                            {expandedJobs && (
                                <ScrollY maxHeight={400} className={'bg-gray-100 rounded shadow-inner'}>
                                    {jobTodos.map((jobTodo) => {
                                        return (
                                            <div key={jobTodo.id} className={'py-1 text-sm'}>
                                                <PrintSectionColumn>
                                                    <WorkOrderJobItemsPanel jobTodo={jobTodo} />
                                                </PrintSectionColumn>
                                            </div>
                                        );
                                    })}
                                </ScrollY>
                            )}
                        </>
                    )}

                    <>
                        <Panel.SectionHeader size={'small'} icon={'file alternate'}>
                            Files <>({files.length})</>
                        </Panel.SectionHeader>
                        {files.length > 0 && <Panel.Item label={'Attached Files'}>{files.length || '--'}</Panel.Item>}
                    </>

                    <>
                        <PanelSectionHeaderWithCollapseButton
                            size={'small'}
                            icon={'comments'}
                            expanded={expandedNotes}
                            onToggleExpand={setExpandedNotes}
                            showCollapseButton={notes.length > 0}
                        >
                            Notes <>({notes.length})</>
                        </PanelSectionHeaderWithCollapseButton>
                        {expandedNotes && notes.length > 0 && (
                            <div className={'p-1 bg-gray-100 rounded  shadow-inner'}>
                                <NotesFeed mode={'inputbottom'} maxHeight={1000} notes={notes} />
                            </div>
                        )}
                    </>

                    {appointmentItem.workOrder?.followUpFrom?.appointmentItem && (
                        <>
                            <Panel.SectionHeader size={'small'} icon={'history'}>
                                Follow up from
                            </Panel.SectionHeader>

                            <Panel.Item label={'Appointment'}>
                                <Display.Date
                                    value={appointmentItem.workOrder.followUpFrom?.appointmentItem?.startDate}
                                    format={'LLL'}
                                />
                                <LinkButton
                                    className={'pl-1'}
                                    content={'view'}
                                    onClick={() =>
                                        onFollowupAppointmentSelect(
                                            appointmentItem.workOrder.followUpFrom?.appointmentItem
                                        )
                                    }
                                />
                            </Panel.Item>
                        </>
                    )}
                    <PanelItemsWaterTestReports appointmentItem={appointmentItem} />
                </>
            </Panel.Body>
            {modalWOPicker.open && (
                <Modal {...modalWOPicker}>
                    <ServiceJobTemplatePicker
                        onCancel={modalWOPicker.onClose}
                        onSubmit={onSelectJobTemplate}
                        selectWorkOrder={'single'}
                        requiredWorkOrder={true}
                    />
                </Modal>
            )}
        </Panel>
    );
};
