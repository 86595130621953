import * as React from 'react';
import { useState } from 'react';
import { NodeType } from '@poolware/api';
import { SlideInDock } from '@ez/components';
import { DeviceList } from './DeviceList';
import { DevicePreviewPanel } from './DevicePreviewPanel';
import { DeviceMap } from './DeviceMap';
import { LayoutViewProps } from '../types';
import { EZCDevicesLinkWithState } from '../../common';

export interface PageListProps extends LayoutViewProps {}

export const LayoutMap: React.FC<PageListProps> = ({ onView, connectionData, connectionState, onNew }) => {
    const [selectedDevice, setSelectedDevice] = useState<EZCDevicesLinkWithState | null>(null);

    const onSelectDevice = (deviceLink: NodeType.EZCDeviceLink | null) => {
        if (selectedDevice === deviceLink) {
            setSelectedDevice(null);
        } else {
            setSelectedDevice(deviceLink);
        }
    };

    return (
        <>
            <div
                className={'flex flex-row gap-1 items-stretch'}
                style={{ width: '100%', height: 'calc(100vh - 140px)' }}
            >
                <div className={'w-[380px] flex-shrink-0 h-full bg-accent rounded p-1'}>
                    <DeviceList
                        connectionState={connectionState}
                        connectionData={connectionData}
                        onView={(deviceLink) => onSelectDevice(deviceLink)}
                        onNew={onNew}
                        selectedDevice={selectedDevice}
                    />

                    {/*<DebugJSON data={cameraCenter} />*/}
                </div>

                {selectedDevice && (
                    <div className={'z-10 fixed right-2 h-[620px] w-[320px] bg-accent shadow rounded p-1 text-sm'}>
                        <DevicePreviewPanel
                            device={selectedDevice}
                            onClose={() => onSelectDevice(null)}
                            onView={onView}
                        />
                    </div>
                )}

                <div
                    className={'flex-grow bg-accent rounded overflow-clip p-1'}
                    style={{ width: '100%', height: 'calc(100vh - 140px)' }}
                >
                    {true && (
                        <DeviceMap
                            onSelectDevice={onSelectDevice}
                            selectedDevice={selectedDevice}
                            connectionData={connectionData}
                        />
                    )}
                </div>
                {false && (
                    <SlideInDock open={!!selectedDevice} onClose={() => onSelectDevice(null)} preferredWidth={'400px'}>
                        {selectedDevice && (
                            <DevicePreviewPanel
                                device={selectedDevice}
                                onClose={() => onSelectDevice(null)}
                                onView={onView}
                            />
                        )}
                    </SlideInDock>
                )}
            </div>
        </>
    );
};
