import * as React from 'react';
import { useState } from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    FormFieldInputDebounced,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    PageLayout,
    StickyMenuBar,
} from '@ez/components';
import { NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useViewer } from '@poolware/app-shell';
import { useQueryFranchiseWarrantersConnection } from './use-query-franchise-warranters-connection';

export interface PageProps {}

export const PageList: React.FC<PageProps> = () => {
    const { AppNavigator } = useAppNavigator();
    const [filterName, setFilterName] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const { connectionData, connectionState } = useQueryFranchiseWarrantersConnection({ searchName: filterName });
    const { viewer } = useViewer();
    const canCreateTag = viewer.Permissions?.CustomerTag?.create;

    const onView = (item) => {
        AppNavigator.navigateRelative(`/${item.id}/edit`);
    };

    const onNew = () => {
        AppNavigator.navigateRelative(`/new`);
    };

    const tableDef: ConnectionTableDef<NodeType.FranchiseWarranter> = [
        {
            header: 'Office Name',
            cell: (r) => <>{r.name}</>,
        },
        {
            header: 'Email',
            cell: (r) => <>{r.email}</>,
        },
        {
            header: 'Acting Franchise',
            cell: (r) => <>{r.actingFranchise?.name}</>,
        },
    ];

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'certificate'}>Warranty Offices</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarItem
                        icon={'search'}
                        onClick={() => {
                            setShowSearch(!showSearch);
                        }}
                    />
                    {canCreateTag && <MenuBarItem onClick={onNew} icon={'plus'} color={'green'} title={'New'} />}
                </MenuBarSection>
            </StickyMenuBar>

            {showSearch && (
                <FormFieldInputDebounced
                    variant={'search'}
                    value={filterName}
                    onChange={setFilterName}
                    className={'max-w-96'}
                />
            )}

            <DefaultConnectionTable
                onRowClick={onView}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </PageLayout>
    );
};
