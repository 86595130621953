import * as React from 'react';
import { SemanticWIDTHS } from 'semantic-ui-react';
import { useSafeState } from '@ez/tools';
import { defaultOptionValueComparatorFn, OptionableComponent } from '../optionable';
import { ButtonRadixProps } from '../radix';
import { cn } from '../utils';
import { ButtonWithPopup } from './ButtonWithPopup';

export interface SegmentedButtonRadixProps<V = any>
    extends OptionableComponent<V>,
        Omit<ButtonRadixProps, 'onChange' | 'value' | 'variant' | 'name'> {
    widths?: SemanticWIDTHS | 'equal';
    vertical?: boolean;
    loading?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    separated?: boolean;
    id?: string;
    error?: React.ReactNode;
    fluid?: boolean;
}

const NO_MUTATION = '____NO_MUTATIONS____';

export const SegmentedSelectButtons: React.FC<SegmentedButtonRadixProps> = (props) => {
    const {
        onChange,
        options,
        value,
        color,
        disabled,
        separated,
        size,
        className,
        readOnly,
        required,
        id,
        error,
        fluid = false,
        valueComparator = defaultOptionValueComparatorFn,
        ...rest
    } = props;

    const [mutatingValue, setMutatingValue] = useSafeState(NO_MUTATION);

    const handleClick = (o) => async (e) => {
        if (!onChange) {
            return;
        }
        setMutatingValue(o.value);
        try {
            await onChange(o);
        } catch (e) {
            console.error(e);
        }
        setMutatingValue(NO_MUTATION);
    };

    return (
        <div
            id={id}
            className={cn([
                'flex flex-row items-center',
                fluid ? 'w-full' : 'w-fit',
                separated && 'gap-2 flex-wrap justify-items-start',
                className,
            ])}
        >
            {options.map((o, index) => {
                const isActive = valueComparator(value, o);
                const isMutating = valueComparator(mutatingValue, o);
                const isMutationInProgress = mutatingValue !== NO_MUTATION || disabled;
                const segmentPosition = index === 0 ? 'first' : index === options.length - 1 ? 'last' : 'middle';
                return (
                    <ButtonWithPopup
                        aria-invalid={rest['aria-invalid']}
                        key={index}
                        disabled={isMutationInProgress}
                        loading={isMutating}
                        segmentPosition={!separated ? segmentPosition : undefined}
                        variant={isActive ? 'primary' : 'secondary'}
                        onClick={handleClick(o)}
                        icon={o.icon}
                        className={fluid && 'flex-1'}
                        color={color}
                        size={size}
                    >
                        {o.text || null}
                    </ButtonWithPopup>
                );
            })}
        </div>
    );
};
