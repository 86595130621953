import * as React from 'react';
import { Button, EmptyBoxImage, Icon } from '@ez/components';
import { DeviceAlarmStatus, DeviceStateType, EZCDevicesLinkWithState, LifeGuardShadowType } from '../common';

export const OnlineIcon: React.FC<{
    device?: EZCDevicesLinkWithState;
}> = ({ device }) => {
    if (device?.isLoadingShadow) {
        return <Icon name={'spinner'} color={'grey'} loading={true} />;
    }

    if (device?.isAlive) {
        return <Icon name={'wifi'} color={'green'} />;
    } else {
        return <Icon name={'wifi-off'} color={'grey'} />;
    }
};

export const NoDeviceMessage: React.FC<{ onNew: () => void }> = ({ onNew }) => {
    return (
        <div className={'w-full flex justify-center items-center'}>
            <div className={'w-64 h-48 p-6 bg-panel rounded-lg flex flex-col gap-4 items-center justify-center'}>
                <EmptyBoxImage className={'size-16'} />
                <Button variant={'primary'} onClick={onNew} icon={'plus'}>
                    Add device
                </Button>
            </div>
        </div>
    );
};
