import * as React from 'react';
import { useState } from 'react';
import { ServiceJobTemplatePicker } from '@poolware/app-service-jobs';
import {
    Button,
    FormFieldSegmentedSelectButtons,
    Modal,
    Optionable,
    SegmentedSelectButtons,
    useModalCtrl,
    ViewJSON,
} from '@ez/components';
import { Checkbox } from '@ez/components';
import { usePersistedState } from '@ez/tools';
import { NodeType } from '@poolware/api';

export interface PageSjTemplatePickerProps {}

export const PageSjTemplatePicker: React.FC<PageSjTemplatePickerProps> = ({}) => {
    const modalWOPicker = useModalCtrl(false);
    const [selected, setSelected] = useState({ sj: null, wo: [] });

    const [requiredWorkOrder, setRequiredWorkOrder] = usePersistedState('dev.require.workorder', false);
    const [selectWorkOrder, setSelectWorkOrder] = usePersistedState('dev.select.workorder', false);
    const [preselectAllWO, setPreselectAllWO] = usePersistedState('dev.select.preselectAllWO', false);

    const onSubmit = (sj: NodeType.ServiceJobTemplate, wo: NodeType.WorkOrderTemplate[]) => {
        setSelected({ sj, wo });
        modalWOPicker.onClose();
    };

    const optionsSelectOrder: Optionable<any>[] = [
        { value: undefined, text: 'Undefined' },
        { value: false, text: 'false' },
        { value: true, text: 'true' },
        { value: 'single', text: 'Single' },
        { value: 'multiple', text: 'Multiple' },
    ];

    return (
        <div>
            <div className={'flex flex-row w-full'}>
                <div
                    style={{ maxHeight: '1200px', lineHeight: '1em' }}
                    className={'flex-grow overflow-auto bg-gray-100 rounded p-2'}
                >
                    <ServiceJobTemplatePicker
                        requiredWorkOrder={requiredWorkOrder}
                        selectWorkOrder={selectWorkOrder}
                        defaultSelectAllWorkOrders={preselectAllWO}
                        onSubmit={onSubmit}
                    />
                    <Modal {...modalWOPicker} size={'large'}>
                        {modalWOPicker.open && (
                            <ServiceJobTemplatePicker
                                requiredWorkOrder={requiredWorkOrder}
                                selectWorkOrder={selectWorkOrder}
                                defaultSelectAllWorkOrders={preselectAllWO}
                                onSubmit={onSubmit}
                                onCancel={modalWOPicker.onClose}
                            />
                        )}
                    </Modal>
                </div>
                <div className={'w-96 flex-shrink-0 p-2'}>
                    <div className={'flex flex-col gap-2'}>
                        <Button onClick={() => modalWOPicker.onOpen()} content={'Open Picker'} />
                        <Checkbox
                            label={'requiredWorkOrder'}
                            checked={requiredWorkOrder}
                            onChange={(_, { checked }) => {
                                setRequiredWorkOrder(checked);
                            }}
                        />
                        <Checkbox
                            label={'defaultSelectAllWorkOrders'}
                            checked={preselectAllWO}
                            onChange={(_, { checked }) => {
                                setPreselectAllWO(checked);
                            }}
                        />
                        <FormFieldSegmentedSelectButtons
                            label={'selectWorkOrder'}
                            // separated={true}
                            onChange={(o) => setSelectWorkOrder(o.value)}
                            value={selectWorkOrder}
                            options={optionsSelectOrder}
                        />
                        <ViewJSON data={selected} jsonExpandLevel={2} />
                    </div>
                </div>
            </div>
        </div>
    );
};
