import { DropzoneOptions, useDropzone } from 'react-dropzone';
import * as React from 'react';
import { clsxm, toastError } from '@ez/components';

const MAX_FILE_SIZE = 20 * 1024 * 1024;

function humanFileSize(bytes, si?: boolean): string {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}

interface FileDropzoneContainerProps {
    onDrop: (files: File[]) => any;
    dzOptions?: DropzoneOptions;
    children?: (props: { open: any }) => React.ReactNode;
}

export const FileDropzoneContainer: React.FC<FileDropzoneContainerProps> = ({ onDrop, dzOptions, ...props }) => {
    const onDropAccepted = (acceptedFiles: any[]) => {
        onDrop(acceptedFiles);
    };

    const onDropRejected = (rejectedFiles: any[]) => {
        const errors = (
            <ul>
                {rejectedFiles?.map((f, index) => {
                    return (
                        <li key={index}>
                            {f?.file?.name} - <b>{f?.errors?.map((e) => e.message).join('. ')}</b>
                        </li>
                    );
                })}
            </ul>
        );
        // @ts-ignore
        toastError({ title: 'Invalid file', description: errors, time: 15000 });
    };

    const { open, getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDropAccepted,
        onDropRejected,
        maxSize: MAX_FILE_SIZE,
        multiple: true,
        accept: { 'image/*': [] },
        ...dzOptions,
    });

    return (
        <div className={'relative'} {...getRootProps()}>
            <input {...getInputProps()} />
            {props.children({ open })}
            <div
                className={clsxm([
                    'absolute inset-0 bg-[rgba(255,255,255,0.8)]',
                    'rounded p-4 border-dashed border-primary border-4',
                    'flex items-start justify-center',
                    isDragActive ? 'flex' : 'hidden',
                    isDragActive && isDragAccept ? 'border-green' : 'border-red',
                ])}
            >
                <div className={'bg-panel p-4 rounded'}>
                    {isDragAccept ? 'Drop the files here ...' : 'Unsupported file type'}
                    (Max {humanFileSize(MAX_FILE_SIZE)} per file)
                </div>
            </div>
        </div>
    );
};
