import * as React from 'react';
import { Button, ButtonWithPopupProps } from '../buttons';
import { CalendarPicker, Popover, PopoverContent, PopoverTrigger } from '../radix';
import { Display } from '../display';
import { DayPickerSingleProps } from 'react-day-picker';

export interface CalendarPickerButtonProps
    extends Omit<ButtonWithPopupProps, 'tabIndex' | 'name' | 'onChange' | 'onSelect'> {
    datePickerProps?: any;
    monthsShown?: number;
    minDate?: Date;
    maxDate?: Date;
    dateFormatCalendar?: string;
    icon?: string;
    disabled?: boolean;
    readOnly?: boolean;
    calendarProps?: Partial<DayPickerSingleProps>;
    selected?: DayPickerSingleProps['selected'] | null;
    onSelect?: DayPickerSingleProps['onSelect'];
    dateFormat?: string;
    shouldCloseOnSelect?: boolean;
}

export const CalendarPickerButton: React.FC<CalendarPickerButtonProps> = ({
    icon,
    content,
    dateFormat,
    selected,
    shouldCloseOnSelect = true,
    onSelect,
    disabled,
    monthsShown,
    datePickerProps,
    minDate,
    maxDate,
    calendarProps,
    ...props
}) => {
    const [open, setOpen] = React.useState(false);

    const minYear = minDate?.getFullYear() || 2020;
    const maxYear = maxDate?.getFullYear() || 2040;

    const handleSelect: DayPickerSingleProps['onSelect'] = (...rest) => {
        onSelect?.(...rest);
        if (shouldCloseOnSelect) {
            setOpen(false);
        }
    };

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild={true}>
                <Button
                    icon={icon || 'calendar'}
                    disabled={disabled}
                    {...props}
                    onClick={() => setOpen(true)}
                    content={<Display.Date format={dateFormat || 'll'} value={selected} />}
                />
            </PopoverTrigger>
            <PopoverContent className={'w-auto p-0 bg-popover text-popover-foreground'} sideOffset={8}>
                <CalendarPicker
                    numberOfMonths={1}
                    captionLayout="dropdown-buttons"
                    mode="single"
                    fromYear={minYear}
                    toYear={maxYear}
                    selected={selected}
                    initialFocus
                    onSelect={handleSelect}
                    {...calendarProps}
                />
                <div className={'flex justify-stretch p-2 mt-2'}>
                    <Button
                        className={'flex-grow'}
                        onClick={() => {
                            handleSelect(new Date(), selected, undefined, undefined);
                        }}
                        content={'Today'}
                    />
                </div>
            </PopoverContent>
        </Popover>
    );
};
