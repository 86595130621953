import * as React from 'react';
import { ButtonGroupRenders, HeaderButtonType } from '../panel';
import styled from 'styled-components';

const RightSpan = styled.span`
    flex-grow: 2;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;

    &&& > * {
        margin: 0;
    }
`;

export interface FormSectionHeaderProps {
    button?: HeaderButtonType | HeaderButtonType[];
}

export const FormSectionHeader: React.FC<FormSectionHeaderProps> = ({ children, button }) => {
    return (
        <div
            className={
                'flex flex-row text-tertiary text-lg font-bold mb-4 border-b-tertiary border-b border-solid first:mt-0'
            }
        >
            <span className={'flex-grow-0 self-center'}>{children}</span>
            <RightSpan>
                <ButtonGroupRenders buttons={button} autoHideButtonsContent={false} />
            </RightSpan>
        </div>
    );
};

export const FormSectionBody: React.FC = (props) => <div className={'py-1 mb-2'} {...props} />;
