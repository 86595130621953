import * as React from 'react';
import moment from 'moment';
import { useBookingActions, useCalendarActions } from '../../redux';
import * as URLBuilder from '../url-builder';
import styled from 'styled-components';
import {
    Button,
    cn,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
    Icon,
    Optionable,
    SegmentedSelectButtons,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { CalNavigate, CalResourceMode, CalViewLayoutMode, CalViewMode } from '../types';
import { useViewer } from '@poolware/app-shell';
import { ToolbarDatePicker } from './ToolbarDatePicker';
import { ModuleIconNames } from '../../constants';
import { useCalendarLayoutState } from '../utils/use-calendar-layout-state';

const WarningIcon = () => {
    return <Icon color="red" inverted name={'warning sign'} />;
};

const getTodayDate = () => {
    // Note:
    // This weird way of acquiring current time is because Date.now function is getting mocked in the unit-tests.
    // It helps to have deterministic unit-tests.
    return new Date(Date.now());
};

const ButtonsGroupRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.25rem;
`;
const ButtonsRow = styled.div`
    display: flex;
    flex-grow: 1;
    gap: 6px;
    flex-direction: row;
    //flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
`;

// const ButtonStyled = styled(Button)`
//     &&& {
//         white-space: nowrap;
//     }
// `;

const mapFull = {
    [CalViewMode.MONTH]: 'Month',
    [CalViewMode.WEEK]: 'Week',
    [CalViewMode.WORK_WEEK]: 'Work Week',
    [CalViewMode.DAY]: 'Day',
    [CalViewMode.AGENDA]: 'Agenda',
};

const mapShort = {
    [CalViewMode.MONTH]: 'M',
    [CalViewMode.WEEK]: 'W',
    [CalViewMode.WORK_WEEK]: 'W',
    [CalViewMode.DAY]: 'D',
    [CalViewMode.AGENDA]: 'A',
};

export const mapCalViewModeToLabel = (mode: CalViewMode, isShort?: boolean) => {
    return (isShort ? mapShort[mode] : mapFull[mode]) || mode;
};

interface ToolbarProps {
    view: CalViewMode;
    views: CalViewMode[];
    label: React.ReactNode;
    localizer: any;
    onNavigate: (...arg) => any;
    onView: (...arg) => any;
}

export const CalendarToolbar: React.FC<ToolbarProps> = (props) => {
    const { modulesAccess, appLayoutMode } = useViewer();
    const { AppNavigator } = useAppNavigator();
    const { CalendarState, CalendarAction } = useCalendarActions();
    const { BookingAction, BookingState } = useBookingActions();
    const CalLayoutState = useCalendarLayoutState();

    const { viewMode, layoutMode } = CalendarState;
    const isResourceShown =
        (viewMode === CalViewMode.DAY || viewMode === CalViewMode.WEEK) && layoutMode === CalViewLayoutMode.GRID;
    // const isResourceShown = viewMode !== CalViewMode.MONTH && layoutMode === CalViewLayoutMode.GRID;
    const isLayoutModeToggleShown = viewMode !== CalViewMode.MONTH;

    const activeDate = CalendarState?.activeDate;

    const navigate = (action: CalNavigate, date?: Date) => {
        props.onNavigate(action, date);
    };

    const startBooking = () => {
        BookingAction.setDetails({ startDate: new Date(), duration: 60 });
        if (!BookingState.isSagaMode) {
            // Start new saga
            BookingAction.startFromCalendar();
        }
    };

    const onPrint = () => {
        const url = URLBuilder.Print().AppointmentItem().list;
        AppNavigator.navigate(url, { setOrigin: true });
    };

    const setLayoutMode = (newMode: any) => {
        const { layoutMode } = CalendarState;
        if (newMode !== undefined) {
            if (newMode === layoutMode) return;
            CalendarAction.setLayoutMode(newMode);
        } else {
            const isChecked = layoutMode === CalViewLayoutMode.GRID;
            CalendarAction.setLayoutMode(isChecked ? CalViewLayoutMode.LIST : CalViewLayoutMode.GRID);
        }
    };

    const renderFilterToggleButton = () => {
        const {
            filters: { hasActiveFilters },
        } = CalendarState;

        const handleClick = () => {
            CalLayoutState.setFilterBarState(!CalLayoutState.isFilterBarOpen);
        };

        if (CalLayoutState.isFilterBarOpen) {
            return null;
            // return <Button basic compact color={'purple'} onClick={handleClick} icon={'chevron left'} />;
        }

        const content = hasActiveFilters && appLayoutMode.gteTablet ? <span>Filtering</span> : undefined;
        return (
            <Button
                icon={true}
                color={CalLayoutState.isFilterBarOpen || hasActiveFilters ? 'purple' : 'grey'}
                onClick={handleClick}
            >
                {hasActiveFilters ? <WarningIcon /> : <Icon name={'filter'} />}
                {content}
            </Button>
        );
    };

    const renderStaffGroupingToggle = () => {
        if (modulesAccess.FieldServices?.calendarSingleStaffMode) {
            return null;
        }

        const rm =
            CalendarState.viewMode === CalViewMode.WEEK ? CalendarState.resourceModeWeek : CalendarState.resourceMode;
        // if (!(viewMode === CalViewMode.DAY || viewMode === CalViewMode.WEEK)) {
        //     return null;
        // }

        const modes = {
            [CalResourceMode.COMBINED]: { label: 'Grouped', icon: 'object group' },
            [CalResourceMode.SPLIT]: { label: 'By Person', icon: 'object ungroup' },
        };

        const options = Object.keys(modes).map<Optionable<CalResourceMode>>((mode: CalResourceMode) => {
            const { label } = modes[mode];
            const isActive = mode === rm;
            return {
                value: mode,
                icon: <Icon name={modes[mode].icon} />,
                active: isActive,
                // onClick: () => setResourceMode(mode),
                text: label,
            };
        });

        const setResourceMode = (newMode: CalResourceMode) => {
            const _newMode = newMode ?? CalResourceMode.COMBINED;
            switch (CalendarState.viewMode) {
                case CalViewMode.WEEK:
                    CalendarAction.setResourceModeWeek(_newMode);
                    break;
                default:
                    CalendarAction.setResourceMode(_newMode);
                    break;
            }
        };

        return (
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <Icon name={modes[rm]?.icon!} /> {modes[rm].label}
                </DropdownMenuTrigger>
                <DropdownMenuContent
                    value={rm}
                    options={options}
                    onChange={(option) => setResourceMode(option.value)}
                />
            </DropdownMenu>
        );
    };

    const renderLayoutModeToggle = (mobile = false) => {
        const { layoutMode } = CalendarState;

        if (BookingState.isSelectingSlot) {
            return null;
        }

        const modes = [
            { mode: CalViewLayoutMode.GRID, label: 'Grid', icon: 'calendar alternate outline' },
            { mode: CalViewLayoutMode.LIST, label: 'List', icon: 'list ul' },
        ];

        const options = modes.map((m) => {
            const { label, icon, mode } = m;
            const isActive = mode === layoutMode;
            return {
                key: mode,
                icon: icon,
                active: isActive,
                value: mode,
                text: mobile ? label : '',
            };
        });

        return (
            <SegmentedSelectButtons
                fluid={false}
                options={options}
                value={layoutMode}
                onChange={(option) => {
                    setLayoutMode(option.value);
                }}
            />
        );
    };

    const renderViewRangeModeSwitch = (collapsed = false) => {
        const { localizer } = props;
        let { messages } = localizer;
        let viewNames = props.views;
        const view = props.view;

        const options = viewNames.map<Optionable<any>>((name) => {
            const isActive = view === name;
            const label = mapCalViewModeToLabel(name, !collapsed);
            return {
                value: name,
                key: name,
                // icon: isActive ? 'check' : '',
                active: isActive,
                onClick: () => props.onView(name),
                text: label,
            };
        });

        if (collapsed) {
            return (
                <DropdownMenu>
                    <DropdownMenuTrigger>
                        <Icon name={'calendar outline'} /> {messages[view]}
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        value={view}
                        options={options}
                        onChange={(option) => CalendarAction.setCalendar({ viewMode: option.value })}
                    />
                </DropdownMenu>
            );
        }

        if (viewNames.length > 1) {
            return (
                <SegmentedSelectButtons
                    options={options}
                    value={CalendarState.viewMode}
                    onChange={(o) => CalendarAction.setCalendar({ viewMode: o.value })}
                />
            );
        }
    };

    const renderNavGroup = (fluid: boolean = false) => {
        const isToday = moment(activeDate).isSame(moment(), 'd');
        return (
            <div className={cn('flex flex-row')}>
                <Button
                    segmentPosition={'first'}
                    onClick={() => navigate(CalNavigate.PREVIOUS)}
                    className={'px-2.5'}
                    icon={'chevron left'}
                />
                {appLayoutMode.gteTablet && (
                    <Button
                        className={'px-2'}
                        onClick={() => navigate(CalNavigate.DATE, getTodayDate())}
                        variant={isToday ? 'primary' : 'secondary'}
                        segmentPosition={'middle'}
                        content={'Today'}
                    />
                )}
                <ToolbarDatePicker activeDate={activeDate} label={props.label} navigate={navigate} />
                <Button
                    className={'px-2.5'}
                    segmentPosition={'last'}
                    onClick={() => navigate(CalNavigate.NEXT)}
                    icon={'chevron right'}
                />
            </div>
        );
    };

    const renderPrintButton = () => {
        const { view } = props;
        if (!modulesAccess.System?.printAccess) {
            return null;
        }

        if (view === CalViewMode.DAY || view === CalViewMode.WEEK) {
            return (
                <Button
                    variant={'secondary'}
                    // color={'blue'}
                    onClick={onPrint}
                    icon={'print'}
                    // content={appLayoutMode.gteTablet ? 'Print' : undefined}
                />
            );
        }
        return null;
    };

    const renderNewAppointmentButton = () => {
        if (BookingState.isSagaMode) {
            // TODO: don't show this button if already in saga mode
            // When in saga mode, pressing on this button will start another saga and it screws the hole thing up.
            return null;
        }
        return (
            <Button
                color={'green'}
                variant={'primary'}
                icon="plus"
                onClick={startBooking}
                content={appLayoutMode.gteTablet ? 'New' : undefined}
            />
        );
    };

    const renderDispatcherToolToggle = () => {
        if (!CalLayoutState.canOpenDock) {
            return null;
        }
        const handleClick = () => {
            CalLayoutState.setDockState(!CalLayoutState.isDockOpen);
        };

        if (!CalLayoutState.isDockOpen) {
            return (
                <Button className={'px-2'} color={'purple'} onClick={handleClick} icon={ModuleIconNames.ServiceCase} />
            );
        }
    };

    const renderMobile = () => {
        return (
            <div className={'calendar-toolbar mb-1 p-1 md:p-1 flex flex-col rounded bg-panel gap-2 print:hidden'}>
                <ButtonsRow>
                    <ButtonsGroupRow>
                        {!CalLayoutState.isDockOpen && renderNewAppointmentButton()}
                        {!CalLayoutState.isDockOpen && renderPrintButton()}
                    </ButtonsGroupRow>
                    <ButtonsGroupRow>
                        {isResourceShown && renderStaffGroupingToggle()}
                        {isLayoutModeToggleShown && renderLayoutModeToggle(false)}
                        {!CalLayoutState.isDockOpen && renderDispatcherToolToggle()}
                    </ButtonsGroupRow>
                </ButtonsRow>
                <ButtonsRow>
                    <ButtonsGroupRow>
                        {renderFilterToggleButton()}
                        {renderViewRangeModeSwitch(true)}
                    </ButtonsGroupRow>
                    <ButtonsGroupRow>{renderNavGroup(true)}</ButtonsGroupRow>
                </ButtonsRow>
            </div>
        );
    };

    const renderTablet = () => {
        return (
            <div className={'calendar-toolbar mb-1 p-1 md:p-1 flex flex-col rounded bg-panel gap-2 print:hidden'}>
                <ButtonsRow>
                    <ButtonsGroupRow>
                        {renderNewAppointmentButton()}
                        {isResourceShown && renderStaffGroupingToggle()}
                    </ButtonsGroupRow>
                    <ButtonsGroupRow>
                        {!CalLayoutState.isDockOpen && renderPrintButton()}
                        {!CalLayoutState.isDockOpen && renderDispatcherToolToggle()}
                    </ButtonsGroupRow>
                </ButtonsRow>
                <ButtonsRow>
                    <ButtonsGroupRow>{renderFilterToggleButton()}</ButtonsGroupRow>
                    <ButtonsGroupRow className={'flex-1 max-w-md justify-end'}>
                        {renderNavGroup(true)}
                        {renderViewRangeModeSwitch(false)}
                        {isLayoutModeToggleShown && renderLayoutModeToggle(false)}
                    </ButtonsGroupRow>
                </ButtonsRow>
            </div>
        );
    };

    const renderDesktop = () => {
        return (
            <React.Suspense fallback={null}>
                <div className={'mb-2 p-2 rounded bg-panel shadow justify-between flex flex-row flex-wrap gap-2'}>
                    <ButtonsGroupRow>
                        {renderFilterToggleButton()}
                        {renderNewAppointmentButton()}
                        {isResourceShown && renderStaffGroupingToggle()}
                    </ButtonsGroupRow>
                    <ButtonsGroupRow>
                        {renderNavGroup()}
                        {renderViewRangeModeSwitch()}
                        {isLayoutModeToggleShown && renderLayoutModeToggle()}
                        {renderPrintButton()}
                        {renderDispatcherToolToggle()}
                    </ButtonsGroupRow>
                </div>
            </React.Suspense>
        );
    };

    if (appLayoutMode.gteDesktopSM) {
        return renderDesktop();
    } else if (appLayoutMode.gteTablet) {
        return renderTablet();
    } else {
        return renderMobile();
    }
};

export default CalendarToolbar;
