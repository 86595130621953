import * as React from 'react';
import { useMemo } from 'react';
import { fromEdges, NodeType, SortDirection } from '@poolware/api';
import { QL } from './use-query-service-job-connection';
import { ConnectionTableDef, DefaultConnectionTable, Display, Icon, SectionHeader, VStack } from '@ez/components';
import {
    DueDate,
    IconServiceJobStage,
    ServiceJobModuleRouterID,
    useQueryServiceJobConnection,
    useServiceJobActions,
} from '@poolware/app-service-jobs';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { AppColorNames, AppIconNames, IconRecurServiceCall, IconServiceCall } from '../../../../components/AppIcons';
import _truncate from 'lodash/truncate';
import styled from 'styled-components';

//TODO: DUPE: This is a duplicate of the one in /frontend/src/Pages/Devices/View/TabServiceCases/TabServiceCases.tsx

const StatusDiv = styled.div<{ isOpen?: boolean }>`
    color: ${(props) => (props.isOpen ? '#2179bd' : undefined)};
    // font-weight: ${(props) => (props.isOpen ? 'bold' : undefined)};
`;

const useTableDefSingle = (): ConnectionTableDef<NodeType.ServiceJob> => {
    return useMemo(
        () => [
            {
                sortKey: 'serviceJobNumber',
                header: 'ID',
                width: 1,
                cell: (serviceJob) => {
                    const isOpen = serviceJob.stage?.status === NodeType.ServiceJobStageStatus.Active;
                    return <StatusDiv isOpen={isOpen}>{serviceJob.serviceJobNumber}</StatusDiv>;
                },
            },
            {
                header: 'Date/Time Opened',
                sortKey: 'createdAt',
                cellProps: { verticalAlign: 'top' },
                width: 2,
                cell: (serviceJob) => {
                    const isOpen = serviceJob.stage?.status === NodeType.ServiceJobStageStatus.Active;
                    return (
                        <StatusDiv isOpen={isOpen}>
                            <Display.Date value={serviceJob.createdAt} format={'lll'} />
                        </StatusDiv>
                    );
                },
            },

            {
                header: 'Case',
                cell: (serviceJob) => {
                    const isOpen = serviceJob.stage?.status === NodeType.ServiceJobStageStatus.Active;
                    const wos = fromEdges(serviceJob.workOrders);
                    const title = _truncate(serviceJob.title || '--', { length: 60 });
                    const isRecurring = serviceJob.isRecurring;
                    const IconComp = isRecurring ? IconRecurServiceCall : IconServiceCall;
                    return (
                        <div>
                            <StatusDiv
                                isOpen={isOpen}
                                style={{
                                    marginBottom: '0.5em',
                                }}
                            >
                                <IconServiceJobStage stage={serviceJob?.stage} /> <IconComp /> {title}
                            </StatusDiv>
                            <div style={{ marginBottom: '1em' }}>
                                <div className={'grid grid-2'}>
                                    {wos.map((wo) => {
                                        const isOpen = wo.stage?.status === NodeType.ServiceJobStageStatus.Active;
                                        return (
                                            <div className={'pl-6'} key={wo.id}>
                                                <StatusDiv isOpen={isOpen}>
                                                    <IconServiceJobStage stage={wo?.stage} />
                                                    <Icon name={AppIconNames.ServiceJob.WorkOrder} />
                                                    {wo.workOrderNumber} - {_truncate(wo.title, { length: 60 })}{' '}
                                                    {wo.appointmentItem && <Icon name={'calendar alternate outline'} />}
                                                </StatusDiv>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                },
            },
            {
                header: 'Due Date',
                sortKey: 'dueDate',
                width: 2,
                cell: (sj) => {
                    return (
                        <DueDate
                            format={'LL'}
                            dueDate={sj.dueDate}
                            warnIfOverdue={sj.stage?.status === NodeType.ServiceJobStageStatus.Active}
                        />
                    );
                },
            },
        ],
        []
    );
};

export interface ServiceJobsHistoryProps {
    customer?: NodeType.Customer;
    pool?: NodeType.Pool;
    onView?: (serviceJob: NodeType.ServiceJob) => any;
}

export const PanelServiceCasesHistory: React.FC<ServiceJobsHistoryProps> = ({ customer, pool }) => {
    const { connectionState, connectionData } = useQueryServiceJobConnection({
        query: QL,
        relatedCustomer: customer?.id,
        relatedPool: pool?.id,
        isRecurring: false,
        isImplicit: false,
        skip: !pool && !customer,
        defaultSortDirection: SortDirection.DES,
        defaultSortKey: 'serviceJobNumber',
    });
    const { AppNavigator, location } = useAppNavigator();
    const { ServiceJobAction } = useServiceJobActions();

    const onView = (serviceJob: NodeType.ServiceJob) => {
        AppNavigator.navigate(`/sj/${serviceJob.id}`, {
            moduleId: ServiceJobModuleRouterID,
        });
    };

    const tableDefSingle = useTableDefSingle();

    const onNewServiceJob = () => {
        ServiceJobAction.newServiceJob({
            customer: customer,
            address: pool?.address || customer?.primaryAddress,
            pool: pool,
            returnTo: location,
        });
    };

    const button = {
        content: 'New Case',
        icon: 'plus',
        color: AppColorNames.ServiceJob.ServiceCall,
        onClick: onNewServiceJob,
    };

    return (
        <VStack>
            <SectionHeader size={'small'} content={`Service Cases`} button={button} />
            <DefaultConnectionTable
                tableProps={{
                    color: AppColorNames.ServiceJob.ServiceCall,
                }}
                onRowClick={onView}
                tableCellProps={{ verticalAlign: 'top' }}
                tableDef={tableDefSingle}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </VStack>
    );
};
