import { ClassNameValue, extendTailwindMerge } from 'tailwind-merge';

const customTwMerge = extendTailwindMerge({
    extend: {
        classGroups: {
            h: [{ h: ['input-base'] }],
        },
    },
});

/** Merge classes with tailwind-merge with clsx full feature */
export function clsxm(...classes: ClassNameValue[]) {
    return customTwMerge(...classes);
}

export const cn = clsxm;
