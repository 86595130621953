import * as React from 'react';
import { useState } from 'react';
import { clsxm, Display, Icon } from '@ez/components';
import { NodeType } from '@poolware/api';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import styled from 'styled-components';

const fileToPhoto = (file: NodeType.FileUpload) => {
    if (!file) return null;
    return {
        src: file.url,
        isImage: file.isImage,
        urlThumbnail: file.imageUrl + '?size=md',
        file: file,
    };
};

export const PhotoItem: React.FC<{
    isSelected?: boolean;
    file?: NodeType.FileUpload;
    onClick?: () => void;
    mode?: 'view' | 'select';
}> = ({ file, onClick, isSelected, mode }) => {
    const image = fileToPhoto(file);
    return (
        <div className={'w-[100px] min-w-fit flex flex-col items-center gap-2 relative rounded'} onClick={onClick}>
            {mode === 'select' && (
                <div className={clsxm('absolute top-0 right-0', isSelected ? 'text-green' : 'text-white')}>
                    <Icon className={'shadow'} name={isSelected ? 'check circle' : 'circle outline'} />
                </div>
            )}
            <button
                className={clsxm(
                    'focusable size-[100px] rounded bg-tertiary grid items-center justify-center overflow-clip',
                    isSelected && 'border-2 p-1 border-green border-solid'
                )}
            >
                {file ? (
                    <img src={image.urlThumbnail} alt={''} className={'object-cover w-full h-full'} />
                ) : (
                    <div className={'text-tertiary text-center'}>No image</div>
                )}
            </button>
            <div className={'text-xs h-[20px] px-1 overflow-hidden'}>
                <Display.Date value={file?.createdAt} format={'lll'} />
            </div>
        </div>
    );
};

const PhotoScrollableContainer = styled.div`
    scrollbar-color: var(--ez-color-primary, black) var(--ez-main-background-color-accent, lightblue);

    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--ez-color-primary, black);
    }

    &::-webkit-scrollbar-track {
        background: var(--ez-main-background-color-accent, lightblue);
    }
`;

export const PhotoSlidesHorizontal: React.FC<{
    files: NodeType.FileUpload[];
    label: string;
}> = ({ files, label }) => {
    const slides = files
        ?.map((file) => ({
            ...file,
            src: file.url,
        }))
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
    const [openIndex, setOpenIndex] = useState(-1);

    const sectionWrapperClass = 'bg-main flex flex-row flex-nowrap overflow-x-auto gap-x-2 w-full py-2 items-start';
    return (
        <div>
            <div className={'flex flex-row justify-between py-2'}>
                <strong className={'text-sm'}>{label}</strong>
            </div>
            <PhotoScrollableContainer className={sectionWrapperClass}>
                {files?.map((file, index) => (
                    <PhotoItem key={file.id} file={file} onClick={() => setOpenIndex(index)} />
                ))}
            </PhotoScrollableContainer>
            <Lightbox
                index={openIndex}
                open={openIndex >= 0}
                close={() => {
                    setOpenIndex(-1);
                }}
                thumbnails={{}}
                slides={slides}
                plugins={[Thumbnails]}
            />
        </div>
    );
};
