import * as React from 'react';
import { SegmentedButtonRadixProps } from '../buttons';
import { useField } from 'formik';
import { Optionable } from '../optionable';
import { FormFieldSegmentedSelectButtons } from '../form-components';
import { FormikFieldProps } from './FormikTypes';

export interface FormikSegmentedSelectButtonsProps<T = any>
    extends FormikFieldProps,
        Omit<SegmentedButtonRadixProps<T>, 'onChange' | 'value'> {
    options: Optionable<T>[];
    onDidChange?: (newValue: T) => any;
}

export const FormikSegmentedSelectButtons: React.FC<FormikSegmentedSelectButtonsProps> = ({
    name,
    options,
    onDidChange,
    ...rest
}) => {
    const [field, meta, helpers] = useField<any>({ name: name });
    let value = field?.value;
    const touched = meta.touched;
    const error = meta.error;
    let hasError = touched && !!error;

    const handleChange = async (item) => {
        await onDidChange?.(item.value);
        await helpers.setValue(item.value);
    };

    return (
        <FormFieldSegmentedSelectButtons
            value={value}
            options={options}
            onChange={handleChange}
            error={hasError && error}
            {...rest}
        />
    );
};
