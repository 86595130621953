import * as React from 'react';
import { useMemo } from 'react';
import { Button, CheckboxRadix, Icon, PageLayout, toastError, useId } from '@ez/components';
import { BottomButtonContainer, Frame, SectionButton, SectionHeader } from '../components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { fromEdges, JobTodoItemStatus, NodeType, useMutationJobTodo, useMutationJobTodoItem } from '@poolware/api';
import { useMobileAppointmentCtx } from '../Root';
import { calcChecklistCount } from './utils';
import { PageSectionQuickActions } from '../components/QuickActionButtons';

const ChecklistItem: React.FC<{ label; checked?: boolean; onCheckedChange?: (checked) => any }> = ({
    label,
    checked,
    onCheckedChange,
}) => {
    const id = useId();
    return (
        <div className={'border-b last:border-b-0 p-2 max-h-fit active:bg-accent'}>
            <div className={'w-full min-h-[40px] flex flex-row items-stretch'}>
                <div>
                    <CheckboxRadix
                        id={id}
                        className={'size-[1.5rem] mt-0.5'}
                        checked={checked}
                        onCheckedChange={onCheckedChange}
                    />
                </div>
                <label
                    className={'pl-2 peer-aria-checked:line-through peer-aria-checked:text-tertiary flex-grow'}
                    htmlFor={id}
                >
                    {label} Lorem ipsum dolor sit amet, consectetur adipisicing elit. A deleniti distinctio excepturi in
                    modi natus nemo neque nisi nostrum officia placeat quam qui, quis saepe soluta suscipit unde, ut
                    vero.
                </label>
            </div>
        </div>
    );
};

const CheckListGroup: React.FC<{ jobTodo: NodeType.JobTodo }> = ({ jobTodo }) => {
    const [expanded, setExpanded] = React.useState<boolean>(true);

    const { refetchQueries } = useMobileAppointmentCtx();
    const jobTodoItemMutator = useMutationJobTodoItem({ refetchQueries: refetchQueries, awaitRefetchQueries: true });
    const jobTodoMutator = useMutationJobTodo({ refetchQueries: refetchQueries, awaitRefetchQueries: true });

    const onJobTodoItemStatusChange = (item: NodeType.JobTodoItem) => async (newStatus: boolean) => {
        try {
            const itemUpdate = {
                id: item.id,
                status: newStatus ? JobTodoItemStatus.OK : JobTodoItemStatus.Open,
            };

            await jobTodoItemMutator.update(itemUpdate);
            // window.setTimeout(async () => await onJobDidChange?.(itemUpdate), 0);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to change status', description: e.message });
        }
    };
    const onJobTodoStatusChange = async (newStatus: boolean) => {
        try {
            const itemUpdate = {
                id: jobTodo.id,
                status: newStatus ? JobTodoItemStatus.OK : JobTodoItemStatus.Open,
            };

            await jobTodoMutator.update(itemUpdate);
            // await onJobDidChange?.(itemUpdate);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to change status', description: e.message });
        }
    };

    const items = fromEdges(jobTodo?.items).sort((l, r) => {
        return l.orderIndex > r.orderIndex ? 1 : -1;
    });

    const totalCount = items.length;
    const jobTodoIsActionable = totalCount == 0;
    const jobTodoIsCompleted = jobTodo?.status != JobTodoItemStatus.Open;
    const isJobCompleted = jobTodoIsActionable
        ? jobTodoIsCompleted
        : items.every((item) => item.status != JobTodoItemStatus.Open);

    return (
        <div className={'p-0 rounded border overflow-clip'}>
            <SectionButton
                icon={isJobCompleted && <Icon name={'check circle outline'} color={'green'} size={'large'} />}
                className={'pl-4 text-left bg-tertiary'}
                content={jobTodo?.title}
                expanded={expanded}
                onClick={() => setExpanded(!expanded)}
            />
            {expanded && (
                <div className={'bg-tertiary p-2'}>
                    <Frame className={'bg-main gap-0 p-0 rounded'}>
                        {jobTodoIsActionable ? (
                            <ChecklistItem
                                checked={jobTodoIsCompleted}
                                onCheckedChange={(checked) => onJobTodoStatusChange(checked)}
                                label={jobTodo.title}
                            />
                        ) : (
                            items.map((item) => {
                                return (
                                    <ChecklistItem
                                        key={item.id}
                                        checked={item.status == JobTodoItemStatus.OK}
                                        onCheckedChange={onJobTodoItemStatusChange(item)}
                                        label={item.title}
                                    />
                                );
                            })
                        )}
                    </Frame>
                </div>
            )}
        </div>
    );
};

const CheckLists: React.FC<{ workOrder: NodeType.WorkOrder }> = ({ workOrder }) => {
    const sortedJobTodos = useMemo(() => {
        return fromEdges(workOrder?.jobs).sort((l, r) => {
            return l.index > r.index ? 1 : -1;
        });
    }, [workOrder?.jobs]);

    return (
        <>
            {sortedJobTodos.map((jobTodo) => {
                return <CheckListGroup jobTodo={jobTodo} key={jobTodo.id} />;
            })}
        </>
    );
};

export const PageChecklist: React.FC = () => {
    const { appointmentItem } = useMobileAppointmentCtx();
    const { AppNavigator } = useAppNavigator();
    const onGoBack = () => {
        AppNavigator.replaceToOrigin('/', { relativeToModule: true });
    };

    const checklistCount = calcChecklistCount(appointmentItem.workOrder);

    return (
        <>
            <div className={'my-2'} />

            <PageSectionQuickActions />

            <SectionHeader>
                Checklist ({checklistCount.completed}/{checklistCount.total})
            </SectionHeader>
            <PageLayout.BodySection className={'px-4'} vStack={true}>
                <CheckLists workOrder={appointmentItem.workOrder} />
            </PageLayout.BodySection>
            <BottomButtonContainer>
                <Button
                    size={'lg'}
                    content={'Back'}
                    className={'w-full h-[48px] border-2 rounded-none'}
                    onClick={onGoBack}
                />
            </BottomButtonContainer>
        </>
    );
};
