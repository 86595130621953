import * as React from 'react';
import { ModuleNavLink, ModuleRoute, ModuleSwitch, useAppNavigator } from '@poolware/react-app-navigator';
import { Button, EmptyBoxImage, Icon, PageSkeletonLoader, Popup, Segment } from '@ez/components';
import { NodeType } from '@poolware/api';
import { DeclarationTree } from '../../components/TraitDeclarationTree';
import * as Declaration from './index';
import styled from 'styled-components';
import { DetailsViewContainer, LeftSidebarContainer, PageContainer } from '../../components/page-layout';
import { useQueryViewerProductDeclarationsConnection } from '../../queries/QueryProductDeclarationsConnection';
import { useOrgSwitcher } from '@poolware/app-shell';

const StyledNavLink = styled(ModuleNavLink)`
    &.active {
        font-weight: bold;
        text-decoration: underline;
    }
`;

const ListPage: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { organisation } = useOrgSwitcher();
    const { connectionState, connectionData } = useQueryViewerProductDeclarationsConnection({
        pageSize: 100,
        pageIndex: 0,
        organisationType: organisation,
    });
    const onAddNew = () => {
        AppNavigator.navigate('/new', {
            relativeToModule: true,
            setOrigin: true,
            modal: false,
        });
    };

    const renderPDLink = (pd: NodeType.ProductTraitDeclaration) => {
        const { franchise, tag } = pd;
        const icons = [];
        if (franchise) {
            const i = (
                <Popup key={'franchise'} position={'top center'} trigger={<Icon name={'building'} />}>
                    {franchise.name}
                </Popup>
            );
            icons.push(i);
        }
        if (tag) {
            icons.push(
                <Popup key={'tag'} position={'top center'} trigger={<Icon name={'tag'} />}>
                    <span style={{ display: 'flex' }}>
                        Tagged: <pre style={{ margin: '0 0.5em' }}>{tag}</pre>
                    </span>
                </Popup>
            );
        }

        return (
            <StyledNavLink to={`/${pd.id}`}>
                {pd.name} {icons}
            </StyledNavLink>
        );
    };

    const renderEmpty = () => {
        return (
            <div style={{ width: '100%' }}>
                <EmptyBoxImage style={{ display: 'block', height: '60px', margin: '60px auto' }} />
            </div>
        );
    };

    return (
        <PageContainer>
            <LeftSidebarContainer>
                <Button
                    size={'sm'}
                    variant={'primary'}
                    className={'w-full'}
                    icon={'plus'}
                    color={'green'}
                    onClick={onAddNew}
                    content={'New Trait Declaration'}
                />
                <Segment>
                    {connectionState.loading && <PageSkeletonLoader />}
                    {!connectionState.loading && (
                        <DeclarationTree declarations={connectionData} itemRenderer={renderPDLink} />
                    )}
                </Segment>
            </LeftSidebarContainer>
            <DetailsViewContainer>
                <ModuleSwitch>
                    <ModuleRoute path={'/:id'} component={Declaration.View} />
                    <ModuleRoute path={'*'} component={renderEmpty} />
                </ModuleSwitch>
            </DetailsViewContainer>
        </PageContainer>
    );
};

// export default compose(
//     withAppNavigator(),
//     withQueryProductDeclarationsConnection({
//         pageSize: 100,
//         pageIndex: 0,
//     }),
//     withProductCatalogMutators(['TraitDeclarations'])
// )(ListPage);
//
// export interface PageControlProps
//     extends IAppNavigatorProps,
//         QueryConnectionResult<NodeType.ProductTraitDeclaration>,
//         IProductCatalogMutators {}

export default ListPage;
