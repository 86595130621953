import { useAppNavigator } from '@poolware/react-app-navigator';
import * as React from 'react';
import { useMemo } from 'react';
import { AdminOnly, pageSpinner, useViewer } from '@poolware/app-shell';
import {
    Alert,
    DebugJsonButton,
    MenuBar,
    PageLayout,
    PageTabs,
    Panel,
    StickyMenuBar,
    TabPaneConf,
    toastError,
    useTabCtrl,
} from '@ez/components';
import { useMutationEZCDeviceLink } from '@poolware/api';
import { PanelOwnerDetails } from './PanelOwnerDetails';
import { TabServiceCases } from './TabServiceCases';
import { TabDeviceState } from './TabDeviceState/TabDeviceState';
import { TabGraphs } from './TabGraphs';
import { getExternalServicesURLs, useQueryDevice } from '../common';
import { TabEventFeed } from './TabEventFeed';

export interface PageProps {}

export const ViewPage: React.FC<PageProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();
    const { isAdmin, isPretending } = useViewer();
    const { loading, device, error, refetchQueries } = useQueryDevice(AppNavigator.params?.deviceId);
    const { delete: deleteDevice } = useMutationEZCDeviceLink();

    const panes: TabPaneConf[] = useMemo(() => {
        return [
            {
                title: 'Device Status',
                icon: 'microchip',
                key: 'status',
                render: () => {
                    if (!device.device) {
                        return <Alert type={'error'}>Failed to load device</Alert>;
                    }
                    return <TabDeviceState device={device} refetchQueries={refetchQueries} />;
                },
            },
            {
                title: 'Charts',
                icon: 'chart bar',
                key: 'graphs',
                render: () => <TabGraphs device={device} />,
            },
            {
                title: 'Alarms',
                icon: 'bell',
                key: 'alarms',
                render: () => <TabEventFeed device={device} scope={'alerts'} />,
            },
            {
                title: 'History',
                icon: 'history',
                key: 'history',
                render: () => <TabEventFeed device={device} scope={'history'} />,
            },
            {
                title: 'Service Cases',
                icon: 'briefcase',
                key: 'service-case',
                render: () => (
                    <div>
                        <TabServiceCases device={device} />
                    </div>
                ),
            },
        ];
    }, [device]);

    const tabCtrl = useTabCtrl({ panes, defaultTab: AppNavigator.tab, onTabChange: AppNavigator.setTab });

    const spinner = pageSpinner(loading, error, device);
    if (spinner) return spinner;

    const onDelete = async () => {
        try {
            await deleteDevice({ id: device.id });
            AppNavigator.navigateToOrigin('/', { relativeToModule: true });
        } catch (e) {
            toastError(e);
        }
    };

    const extURLs = isAdmin || isPretending ? getExternalServicesURLs(device?.device) : undefined;
    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item
                        icon={'chevron left'}
                        content={'Back'}
                        onClick={() => AppNavigator.navigateToOrigin('/', { relativeToModule: true })}
                    />
                    <MenuBar.HeaderItem content={'Device'} icon={'microchip'} />
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item asChild={true}>
                        <DebugJsonButton data={device} />
                    </MenuBar.Item>
                    <MenuBar.Dropdown color={'red'} icon={'bars'}>
                        {extURLs?.urlLG && (
                            <MenuBar.DropdownItem icon={'external'}>
                                <a href={extURLs.urlLG} target={'_blank'} className={'pr-4'} rel="noreferrer">
                                    Lifeguard Portal
                                </a>
                            </MenuBar.DropdownItem>
                        )}
                        {extURLs?.urlEZ && (
                            <MenuBar.DropdownItem icon={'external'}>
                                <a href={extURLs.urlEZ} target={'_blank'} className={'pr-4'} rel="noreferrer">
                                    EZ Connect
                                </a>
                            </MenuBar.DropdownItem>
                        )}
                        <MenuBar.DropdownItemWithConfirm
                            icon={'trash'}
                            color="orange"
                            content="Delete"
                            onClick={onDelete}
                            popup={{ content: 'Will be deleted' }}
                            confirm={{
                                confirmMessage: {
                                    header: 'Delete Device?',
                                    content: (
                                        <span>
                                            This action will delete the device <strong>{device?.device?.title}</strong>{' '}
                                            from you Managed Devices.
                                        </span>
                                    ),
                                },
                                negative: true,
                                confirmButton: {
                                    content: 'Delete',
                                    icon: 'trash',
                                },
                            }}
                        />
                    </MenuBar.Dropdown>
                </MenuBar.Section>
            </StickyMenuBar>
            <PageLayout.TwoColumns>
                <Panel>
                    <Panel.Header content={'Device'} />
                    <Panel.Body>
                        <Panel.Item label={'Title'} content={device?.deviceName} />
                        <Panel.Item label={'Device ID'} content={device?.device?.deviceId} />
                        <Panel.ItemDate label={'Registered'} content={device?.createdAt} />
                        <Panel.Divider />
                        <Panel.Item label={'Status'} content={device?.alarmStatus} />
                        <AdminOnly>
                            <Panel.Divider />
                            <Panel.Item label={'Definition'} content={device?.device?.definition?.name} />
                            <Panel.Item label={'Organisation'} content={device.franchise?.organisationType?.name} />
                            <Panel.Item label={'Franchise'} content={device.franchise?.name} />
                        </AdminOnly>
                    </Panel.Body>
                </Panel>
                <PanelOwnerDetails deviceLink={device} refetchQueries={refetchQueries} />
            </PageLayout.TwoColumns>
            <PageTabs color={'blue'} {...tabCtrl} />
        </PageLayout>
    );
};
