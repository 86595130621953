import * as React from 'react';
import { NodeType } from '@poolware/api';
import { ButtonWithConfirm, ConnectionTableDef, DefaultConnectionTable, Display, Icon } from '@ez/components';

export const FileListTable: React.FC<{
    fileAttachments: NodeType.FileUpload[];
    readOnly?: boolean;
    onFileAttachmentRemove?: (file: NodeType.FileUpload) => any;
}> = ({ fileAttachments, readOnly = true, onFileAttachmentRemove }) => {
    const tableDef: ConnectionTableDef<NodeType.FileUpload> = [
        {
            header: 'File',
            cell: (fileAttachment) => {
                return (
                    <>
                        <Icon name={'file alternate outline'} />
                        <a target={'_blank'} href={fileAttachment.url} rel="noopener noreferrer">
                            {fileAttachment.fileName}
                        </a>
                        {'  '}
                        <a
                            href={fileAttachment.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            download={fileAttachment.fileName}
                        >
                            <Icon name={'download'} />
                        </a>
                    </>
                );
            },
        },
        {
            header: 'Uploaded',
            width: '1',
            cell: (r) => {
                return <Display.Date value={r.createdAt} />;
            },
        },
        {
            header: '',
            width: '1',
            cellProps: { textAlign: 'center' },
            cell: (fileAttachment) => {
                if (readOnly) return null;
                const allowDeletion = fileAttachment.checkMutations?.delete;
                return (
                    <>
                        <ButtonWithConfirm
                            popup={{ content: allowDeletion ? 'Delete file' : 'Not enough permission' }}
                            disabled={!allowDeletion}
                            confirm={{
                                confirmMessage: {
                                    header: 'Delete file?',
                                    content: <code>{fileAttachment.fileName}</code>,
                                },
                                negative: true,
                                cancelButton: { content: 'Keep' },
                                confirmButton: { content: 'Delete', icon: 'trash' },
                            }}
                            icon={'trash'}
                            basic
                            size={'tiny'}
                            onClick={() => onFileAttachmentRemove?.(fileAttachment)}
                        />
                    </>
                );
            },
        },
    ];

    return (
        <DefaultConnectionTable
            debug={false}
            tableDef={tableDef}
            connectionData={fileAttachments}
            connectionState={undefined}
            noDataComponent={<i>No documents attached</i>}
        />
    );
};
