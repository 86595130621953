import * as React from 'react';
import gql from 'graphql-tag';
import _set from 'lodash/set';
import { NodeType } from '../api-types';
import {
    QueryConnection,
    QueryConnectionExternalProps,
    useDefaultConnectionState,
    useQueryConnection,
} from '@ez/api-core';

const connectionPath = 'viewer.franchises';
const QL = gql`
    query FranchisesList_Filter($first: Int, $page: Int, $search: FranchiseViewerFranchisesSearch) {
        viewer {
            franchises(first: $first, page: $page, search: $search) {
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        id
                        name
                        types
                        organisationType {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export interface QueryFranchiseConnectionExternalProps extends QueryConnectionExternalProps<NodeType.Franchise> {
    searchName?: string;
    searchOrgId?: NodeType.ID;
    isWarranter?: boolean;
    treatmentGroupId?: NodeType.ID;
}

function mapPropsToSearchQuery(props: QueryFranchiseConnectionExternalProps) {
    const { searchName, isWarranter } = props;
    const search: NodeType.FranchiseViewerFranchisesSearch = {};
    if (isWarranter !== undefined) {
        _set(search, 'isWarranter', Boolean(isWarranter));
    }
    if (searchName) {
        _set(search, 'name.like', searchName);
    }
    if (props?.searchOrgId) {
        _set(search, 'organisationType.id', props.searchOrgId);
    }
    if (props?.treatmentGroupId) {
        _set(search, 'treatmentGroups.id', props.treatmentGroupId);
    }
    return search;
}

export const QueryFranchiseConnection: React.FC<QueryFranchiseConnectionExternalProps> = (props) => {
    const { variables, connectionState } = useDefaultConnectionState(props);
    variables.search = mapPropsToSearchQuery(props);

    return (
        <QueryConnection
            query={props.query || QL}
            variables={variables}
            connectionConfig={connectionState}
            connectionPath={connectionPath}
            children={props.children}
        />
    );
};

export const useQueryFranchiseConnection = (props?: QueryFranchiseConnectionExternalProps) => {
    const { variables, connectionState } = useDefaultConnectionState({
        ...props,
        search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection<NodeType.Franchise>({
        query: props?.query || QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
};
