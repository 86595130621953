import { usePersistedToggle } from '@ez/tools';
import { useEffect, useState } from 'react';
import { useAppBreakpoints } from '../../use-app-breakpoints'; // Use this hook to manage sidebar state.

// Use this hook to manage sidebar state.
// It dynamically switches behaviour depending on screen size.
// - On large screen it will persist sidebar state to local storage,
// - On small screen it will always start as 'showSidebar=false'
export const useStateSidebar = (token: string, defaultState: boolean = true) => {
    const [showSidebar, setShowSidebar] = usePersistedToggle(token, defaultState);
    const [showSidebarMobile, setShowSidebarMobile] = useState(false);
    const layout = useAppBreakpoints();
    const isWide = layout.gteDesktopSM;

    useEffect(() => {
        if (isWide) {
            setShowSidebarMobile(false);
        }
    }, [isWide]);

    if (isWide) {
        return {
            showSidebar,
            setShowSidebar,
        };
    } else {
        return {
            showSidebar: showSidebarMobile,
            setShowSidebar: setShowSidebarMobile,
        };
    }
};
