import * as React from 'react';
import { FeatureFlag } from './feature-flags';
import { useViewer } from './Viewer';

export const FeatureAccess: React.FC<{
    featureFlag: FeatureFlag | string;
}> = ({ featureFlag, children }) => {
    const { canAccessFeature } = useViewer();
    const canAccess = canAccessFeature(featureFlag);
    if (!canAccess) {
        return null;
    }
    return <>{children}</>;
};

export const AdminOnly: React.FC<{ showWhenPretending?: boolean }> = ({ children, showWhenPretending }) => {
    const { isAdmin, isPretending } = useViewer();
    if (isAdmin || (isPretending && showWhenPretending)) {
        return children as React.ReactElement;
    }
    return null;
};
