import * as React from 'react';
import { useEffect, useReducer, useState } from 'react';
import {
    Alert,
    AlertContent,
    AlertHeader,
    Button,
    DevOnly,
    Display,
    Progress,
    SectionHeader,
    toastError,
    VStack,
} from '@ez/components';
import { WaterTestErrorsTable, WaterTestResultTable, WaterTestWarningsTable } from './TestResultTable';
import { SelectPanel } from './SelectPanel';
import { WaterlinkAPI } from '../api-client';
import { useWaterlink, WaterTestResultType } from '../api-react';
import { EZStorage } from '@ez/tools';

interface StateType {
    reagent: number;
    reagents: WaterlinkAPI.OptionType[];
    sample: number;
    samples: WaterlinkAPI.OptionType[];
    allReagents: WaterlinkAPI.OptionType[];
}

const initialState: StateType = {
    reagent: -1,
    reagents: [],
    sample: -1,
    samples: [],
    allReagents: [],
};

const REAGENT_KEY = 'waterlink.defaults.reagent.value';
const SAMPLE_KEY = 'waterlink.defaults.sample.value';

const save = (key: string, value: number) => {
    EZStorage.setItem(key, String(value));
};
const restore = (key: string): number => {
    try {
        return Number(EZStorage.getItem(key));
    } catch (e) {
        return -1;
    }
};

const filterReagentsBySample = (allReagents: WaterlinkAPI.OptionType[], sampleTag: string) => {
    if (!allReagents) {
        throw new Error('No Reagents');
    }
    return allReagents.filter((reagent) => {
        return reagent.Tags.includes(sampleTag);
    });
};

const initFn = (options: WaterlinkAPI.OptionsReply): StateType => {
    if (!options) {
        return initialState;
    }

    const reagentValue = restore(REAGENT_KEY);
    const sampleValue = restore(SAMPLE_KEY);
    const samples = options.SampleTypes || [];
    const allReagents = options.ReagentTypes || [];
    const sample = samples.find((s) => s.Value === sampleValue);
    const reagents = sample !== undefined ? filterReagentsBySample(allReagents, sample.Tags[0]) : [];
    return {
        ...initialState,
        reagent: reagentValue,
        sample: sampleValue,
        reagents: reagents,
        samples: samples,
        allReagents: allReagents,
    };
};

const useTestDiskSelector = (options: WaterlinkAPI.OptionsReply) => {
    function reducer(state: StateType, action) {
        switch (action.type) {
            case 'setSample': {
                const sampleValue = action.value;
                const sample = state.samples.find((s) => String(s.Value) === String(sampleValue));
                const newReagents =
                    sample !== undefined ? filterReagentsBySample(state.allReagents, sample.Tags[0]) : [];

                // Check whether current value of `reagent` is in the new list
                const shouldReset = newReagents.findIndex((r) => String(r.Value) === String(state.reagent)) === -1;
                // ... if it is in the list, keep it
                const reagentValue = shouldReset ? (newReagents.length > 0 ? newReagents[0].Value : -1) : state.reagent;

                save(SAMPLE_KEY, sampleValue);
                save(REAGENT_KEY, reagentValue);
                return {
                    ...state,
                    sample: action.value,
                    reagent: reagentValue,
                    reagents: newReagents,
                };
            }
            case 'setReagent':
                const reagentValue = action.value;
                save(REAGENT_KEY, reagentValue);
                return { ...state, reagent: reagentValue };
            case 'reset':
                return initFn(options);
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, options, initFn);

    const setReagent = (value) => dispatch({ type: 'setReagent', value: value });
    const setSample = (value) => dispatch({ type: 'setSample', value: value });
    const reset = (value) => dispatch({ type: 'reset', value: value });

    useEffect(() => {
        reset(options);
    }, [options]);

    return {
        reagent: state.reagent,
        reagents: state.reagents,
        sample: state.sample,
        samples: state.samples,
        setReagent,
        setSample,
    };
};

type WaterTestPanelProps = {
    onTestFinish?: (data: WaterTestResultType) => any;
};

export const WaterTestPanel: React.FC<WaterTestPanelProps> = ({ onTestFinish }) => {
    const waterlink = useWaterlink();
    const { reagent, reagents, sample, samples, setReagent, setSample } = useTestDiskSelector(waterlink.options.all);

    const reagentOptions = reagents.map((rt) => ({
        // text: `${rt.Name} - ${rt.Tags.join(', ')}`,
        text: `${rt.Name}`,
        key: rt.Value,
        value: rt.Value,
    }));

    const sampleOptions = samples.map((rt) => ({
        // text: `${rt.Name} - ${rt.Tags.join(', ')}`,
        text: `${rt.Name}`,
        key: rt.Value,
        value: rt.Value,
    }));

    // const [lastTestId, setTestId] = useState(null);
    const [lastTestResults, setLastTestResults] = useState(null);

    const startTest = async () => {
        try {
            setLastTestResults({});
            const testResult = await waterlink.testRunner.startWaterTest({ reagent: reagent, sample: sample });
            // setTestId(testResult.testId);
            setLastTestResults(testResult.data);
            onTestFinish?.(testResult);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Error', description: e.message });
        }
    };

    const loadLatestResults = () => {
        if (testRunner.lastResults) {
            setLastTestResults(testRunner.lastResults.data);
        }
    };

    const canDoWaterTest = Boolean(waterlink.canStartTest);
    const deviceStatusString = waterlink.device.statusString;
    const deviceStatusCode = waterlink.device.statusCode;
    const testRunner = waterlink.testRunner;
    const canStartTest = reagent !== -1 && sample !== -1 && canDoWaterTest;
    const isBusy = testRunner.isRunning;
    const progress = testRunner.progress;

    const testResults = lastTestResults?.Results?.length > 0 ? lastTestResults.Results : undefined;
    const testErrors = lastTestResults?.Errors?.length > 0 ? lastTestResults.Errors : undefined;
    const testWarnings = lastTestResults?.Warnings?.length > 0 ? lastTestResults.Warnings : undefined;
    const testRunnerError = waterlink.testRunner?.error?.message;

    return (
        <div>
            <VStack>
                <div className={'grid grid-cols-2 gap-2'}>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Sample</label>
                            <SelectPanel value={sample} options={sampleOptions} onSelect={setSample} />
                        </div>
                    </div>

                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label>Reagent</label>
                            <SelectPanel value={reagent} options={reagentOptions} onSelect={setReagent} />
                        </div>
                    </div>
                </div>

                {/*<div>Sample Type: {sample}</div>*/}
                {/*<div>Reagent: {reagent}</div>*/}
                <Button
                    loading={isBusy}
                    content={'Start Test'}
                    variant={'primary'}
                    size={'lg'}
                    className={'w-full'}
                    disabled={!canStartTest}
                    onClick={startTest}
                />
                {waterlink.server.isMock && (
                    <div>
                        <Alert type={'warning'} className={'text-sm p-1'}>
                            Using Waterlink mock client.
                        </Alert>
                    </div>
                )}

                {waterlink.error && (
                    <div style={{ color: 'red' }}>
                        Error: <b>{waterlink.error}</b>
                    </div>
                )}
                {testRunnerError && (
                    <div style={{ color: 'red' }}>
                        Error: <b>{testRunnerError}</b>
                    </div>
                )}
                {!canDoWaterTest && !testRunner.isRunning && (
                    <div>
                        <div style={{ color: 'orange' }}>
                            Device Status: <b>{deviceStatusString}</b>
                        </div>
                        {deviceStatusCode === 4 && (
                            <Alert type={'warning'}>
                                <AlertHeader>Firmware update is required!</AlertHeader>
                                <AlertContent>
                                    LaMotte has released a new version of firmware for your Waterlink device. It will
                                    not respond to any commands until its firmware is updated. Please open the official
                                    Waterlink Connect application and follow the instructions on the screen.
                                </AlertContent>
                            </Alert>
                        )}
                    </div>
                )}

                {(progress > 0 || testRunner.isRunning) && (
                    <Progress size={'small'} percent={progress} progress success={!testErrors} error={!!testErrors} />
                )}

                {!!testRunner.lastResults && !lastTestResults && (
                    <Button
                        size={'tiny'}
                        disabled={!testRunner.lastResults}
                        content={'Load last water test data'}
                        onClick={loadLatestResults}
                    />
                )}

                {testErrors && (
                    <>
                        <SectionHeader size={'small'}>Water Test Errors</SectionHeader>
                        <WaterTestErrorsTable data={testErrors} />
                    </>
                )}

                {testWarnings && false && (
                    <div>
                        <SectionHeader size={'small'}>Warnings</SectionHeader>
                        <WaterTestWarningsTable data={testWarnings} />
                    </div>
                )}

                {testResults && (
                    <div>
                        <SectionHeader size={'small'}>Water Test Results</SectionHeader>
                        <sub>
                            Time <Display.Date value={testRunner.lastResults.timestamp} />
                        </sub>
                        <WaterTestResultTable data={lastTestResults.Results} />
                    </div>
                )}

                <DevOnly hidden={true}>
                    <pre>{JSON.stringify(waterlink, null, 2)}</pre>
                </DevOnly>
            </VStack>
        </div>
    );
};
