import * as React from 'react';
import { FormikDefaultForm, FormikDefaultFormProps, FormikInputField } from '@ez/components';
import { FormikPoolVolumeInputField } from './FormikPoolVolumeInputField';
import { FormikPoolSanitiserSelectField, FormikPoolTypeSelectField } from './FormikPoolSanitiserSelectField';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Too Short!').max(150, 'Too Long!').required('Required').nullable(),
    volume: Yup.number().required('Required'),
    type: Yup.mixed().required('Required'),
    sanitiser: Yup.mixed().required('Required'),
});

interface PoolFormProps extends FormikDefaultFormProps {
    organisationId: string;
    hidePoolTypeFields?: boolean;
}

export const PoolForm: React.FC<PoolFormProps> = (props) => {
    const { organisationId, initialValues, children, hidePoolTypeFields } = props;
    return (
        <FormikDefaultForm
            header={props.header}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={props.onSubmit}
            onCancel={props.onCancel}
            submitOnEnter={false}
        >
            {children}
            <FormikInputField name={'name'} required={true} label="Pool Name" placeholder="e.g. Main Pool" />
            <FormikPoolVolumeInputField name={'volume'} required={true} label={'Pool Volume (Litres)'} />
            {!hidePoolTypeFields && (
                <>
                    <FormikPoolTypeSelectField
                        name={'type'}
                        required={true}
                        label={'Pool Type'}
                        disabled={!organisationId}
                        organisationId={organisationId}
                    />
                    <FormikPoolSanitiserSelectField
                        name={'sanitiser'}
                        required={true}
                        label={'Sanitiser Type'}
                        disabled={!organisationId}
                        organisationId={organisationId}
                    />
                </>
            )}
        </FormikDefaultForm>
    );
};
