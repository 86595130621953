import * as React from 'react';
import SearchResultsList from './SearchResultsList';
import SearchBar from './SearchSidebar';
import styled from 'styled-components';
import { useAppointmentReportsActions } from '../../redux';
import { MenuBarItem, MenuBarSection, StickyMenuBar, VStack } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { AppointmentViewContextProvider, useQueryAppointmentItemsConnection } from '@poolware/app-service-jobs';
import { PageLayout } from '@ez/components';

const SearchBarContainer = styled.div`
    flex: 0 0 240px;
    max-width: 240px;
    font-size: 0.9rem;
`;

const SearchResultsContainer = styled.div`
    margin-left: 1rem;
    flex: 1 1 auto;
    font-size: 0.9rem;
    overflow-x: auto;
    height: 100%;
    padding-bottom: 2rem;
`;

const ConnectedSearchResultsList: React.FC = (props) => {
    const { AppointmentReportsState } = useAppointmentReportsActions();
    const { dateRange, franchise } = AppointmentReportsState.filters;

    const { connectionData, connectionState } = useQueryAppointmentItemsConnection({
        dateRange: dateRange,
        franchiseId: franchise.id,
        filters: AppointmentReportsState.filters,
    });

    return (
        <AppointmentViewContextProvider>
            <SearchResultsList {...props} connectionData={connectionData} connectionState={connectionState} />
        </AppointmentViewContextProvider>
    );
};

export const Page: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const goBack = () => {
        AppNavigator.navigate('/', { relativeToModule: true });
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Dashboard'} />
                </MenuBarSection>
            </StickyMenuBar>
            <PageLayout.BodySection className={'flex'}>
                <SearchBarContainer className={'no-print'}>
                    <SearchBar />
                </SearchBarContainer>
                <SearchResultsContainer>
                    <ConnectedSearchResultsList />
                </SearchResultsContainer>
            </PageLayout.BodySection>
        </PageLayout>
    );
};

export default Page;
