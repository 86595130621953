import { compose } from '@ez/tools';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { AuthConnectProps, connectWithAuth, IWithPermissionsProps, withPermissions } from '@poolware/api';
import { connectWithTheme, ThemeStateProps, withViewer, WithViewerProps } from '@poolware/app-shell';

export const withPageProps = () =>
    compose(withAppNavigator(), withPermissions(), connectWithAuth(), connectWithTheme(), withViewer());

export interface IWithPageProps
    extends IAppNavigatorProps,
        IWithPermissionsProps,
        AuthConnectProps,
        ThemeStateProps,
        WithViewerProps {}
