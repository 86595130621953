import * as React from 'react';
import PoolDetails from '../../../CommonComponents/PoolDetails';
import * as URLBuilder from '../../../../routes/url-builder';
import {
    Alert,
    AlertHeader,
    Icon,
    Modal,
    PageLayout,
    Panel,
    PhotoFileGalleryPanel,
    SectionHeader,
    Segment,
    toastError,
    toastSuccess,
    useModalCtrl,
} from '@ez/components';
import Instructions from './Components/Instructions';
import WaterTestMeasurementTable from './WaterTestMeasurementTable';
import TreatmentRecommendations from './TreatmentRecommendations';
import { PageControlProps } from './Page.Control';
import { fromEdges, NodeType } from '@poolware/api';
import MenuBar from './MenuBar';
import EmailReportPanel from './EmailReportPanel';
import { FileAttachmentUploader } from '@poolware/app-shell';
import { ModuleLink, useAppNavigator } from '@poolware/react-app-navigator';
import { ServiceJobModuleRouterID } from '@poolware/app-service-jobs';

const ManualProblemsList = ({ manualProblems }) => {
    return (
        <Segment>
            <div className={'grid gap-2'}>
                {manualProblems.map((p) => {
                    return (
                        <div key={p.id}>
                            <div className={'font-bold'}>{p.name}</div>
                            <div>
                                <Instructions usePre={true} content={p.solution} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </Segment>
    );
};

const WaterTestNotes = ({ report }) => (
    <Segment>
        <Instructions style={{ backgroundColor: '#ffffed' }}>{report.note}</Instructions>
    </Segment>
);

const Page: React.FC<PageControlProps> = (props) => {
    const modalCtrlEmail = useModalCtrl();
    const modalCtrlPhoto = useModalCtrl();

    const { AppNavigator } = useAppNavigator();
    let { customerId, poolId, testId, report, contacts, manualProblems = [], chemicalTargets = [] } = props;

    const getLinkToPool = () => {
        let poolLink = null;
        if (customerId && poolId) {
            poolLink = URLBuilder.Customer(customerId).Pool(poolId).view;
        }
        return poolLink;
    };

    const onBackToPool = () => {
        let poolLink = getLinkToPool();
        if (poolLink) {
            AppNavigator.navigate(poolLink);
        }
    };

    const onBackToWorkOrder = () => {
        AppNavigator.navigateRelative(`/wo/${report?.workOrder?.id}`, {
            moduleId: ServiceJobModuleRouterID,
        });
    };

    const getBackConf = () => {
        const poolLink = getLinkToPool();
        if (poolLink) {
            return {
                onBackFn: onBackToPool,
                onBackTitle: 'To Pool',
            };
        }
        // TODO: temporary hack.
        if (report?.workOrder?.id) {
            return {
                onBackFn: onBackToWorkOrder,
                onBackTitle: 'To Work Order',
            };
        }

        return {
            onBackFn: null,
            onBackTitle: null,
        };
    };

    const editReport = () => {
        AppNavigator.navigateRelative(`${testId}/edit`, {
            state: {
                customerId: customerId,
                poolId: poolId,
                testId: testId,
            },
            setOrigin: true,
        });
    };

    const archiveReport = async () => {
        try {
            await props.TestReportMutator.archiveReport(report);
            toastSuccess({
                icon: 'archive',
                title: 'Archived Successfully',
                description: 'This report has been archived. It is read-only now.',
                time: 4000,
            });
        } catch (err) {
            console.error(err);
            toastError({
                icon: 'archive',
                title: 'Failed to archived',
                description: JSON.stringify(err.message),
                time: 4000,
            });
        }
    };

    const deleteReport = async () => {
        try {
            await props.TestReportMutator.deleteReport(report);
            toastSuccess({
                icon: 'delete',
                title: 'Report deleted',
                description: 'The water test report was successfully deleted.',
                time: 4000,
            });
            const { onBackFn } = getBackConf();
            if (onBackFn) {
                onBackFn();
            } else {
                AppNavigator.replace('/');
            }
        } catch (err) {
            console.error(err);
            toastError({
                icon: 'archive',
                title: 'Failed to archived',
                description: JSON.stringify(err.message),
                time: 4000,
            });
        }
    };

    const onDeleteAttachment = async (files: NodeType.FileUpload[]) => {
        const attachments = fromEdges(report.attachments);
        const attachmentIds = files
            .map((file) => attachments.find((attachment) => attachment.file.id === file.id))
            .map((attachment) => attachment.id);
        return props.TestReportMutator.deleteAttachments(report, attachmentIds);
    };

    const onViewEmailLog = () => {
        AppNavigator.navigateRelative(`${testId}/email-logs`, {
            state: {
                customerId: customerId,
                poolId: poolId,
                testId: testId,
            },
            setOrigin: true,
        });
    };

    const onGoBack = () => {
        let poolLink = getLinkToPool();
        AppNavigator.navigateBack(poolLink);
    };

    function renderMenu() {
        const { isArchived } = report;

        // defaults to true. The server will reject mutaion if not allowed
        const canDelete = report?.checkMutations?.delete || true;
        const canUpdate = report?.checkMutations?.update || true;

        return (
            <MenuBar
                PDFPreviewURL={report?.pdfUrl}
                isArchived={isArchived}
                onViewEmailLog={onViewEmailLog}
                disableDelete={!canDelete}
                disableUpdate={!canUpdate}
                onAddPhoto={modalCtrlPhoto.onOpen}
                onSendReport={modalCtrlEmail.onOpen}
                onReportEdit={editReport}
                onArchive={archiveReport}
                onDelete={deleteReport}
                onBack={onGoBack}
                onBackTitle={'Back'}
            />
        );
    }

    if (!report) return <div>Not found</div>;

    const { isArchived } = report;

    const samples = fromEdges(report?.sampleSet?.samples);

    const files = fromEdges(report.attachments)
        .map((attachment) => attachment.file)
        .filter((f) => f?.isImage)
        .map((f) => {
            if (f.imageUrl) {
                return { ...f, urlThumbnail: f.imageUrl + '?size=sm' };
            } else {
                return f;
            }
        });

    return (
        <PageLayout>
            {renderMenu()}
            <PageLayout.BodySection vStack={true} width={'screen-xl'}>
                <>
                    {isArchived && (
                        <Alert type={'warning'}>
                            <AlertHeader>
                                <Icon name="lock" /> This report has been archived!
                            </AlertHeader>
                        </Alert>
                    )}

                    <SectionHeader size={'large'} dividing>
                        <span>
                            <Icon name="file text outline" />
                            Water test report
                        </span>
                    </SectionHeader>

                    <Panel>
                        <Panel.Header content="Submitted Data" />
                        <Panel.Body>
                            <Panel.ItemDate label="Date" content={report.createdAt} />
                            <Panel.ItemEntity label="Submitted by" content={report.reportBy} />
                            {report?.workOrder && (
                                <>
                                    <Panel.Item label={'Work Order'}>
                                        <ModuleLink
                                            moduleId={ServiceJobModuleRouterID}
                                            to={`/wo/${report.workOrder?.id}`}
                                        >
                                            {report.workOrder?.workOrderNumber} - {report.workOrder.title}
                                        </ModuleLink>
                                    </Panel.Item>
                                </>
                            )}
                            <Panel.Divider />

                            <PoolDetails pool={report.pool} />

                            <SectionHeader size={'small'} dividing>
                                <Icon name="flask" /> Measurements
                            </SectionHeader>
                            <WaterTestMeasurementTable
                                samples={samples}
                                report={report}
                                editable={false}
                                TestReportMutator={props.TestReportMutator}
                                chemicalTargets={chemicalTargets}
                            />

                            {manualProblems.length > 0 && (
                                <>
                                    <SectionHeader size={'small'} dividing>
                                        <Icon name="eye" /> Observations
                                    </SectionHeader>
                                    <ManualProblemsList manualProblems={manualProblems} />
                                    <div className={'mb-4'} />
                                </>
                            )}

                            {report.note && (
                                <React.Fragment>
                                    <SectionHeader size={'small'} dividing>
                                        <Icon name="clipboard" /> Notes
                                    </SectionHeader>
                                    <WaterTestNotes report={report} />
                                </React.Fragment>
                            )}
                        </Panel.Body>
                    </Panel>

                    <PhotoFileGalleryPanel files={files} onDeleteFiles={onDeleteAttachment} />

                    <SectionHeader size={'large'} dividing>
                        <Icon name="treatment" />
                        Problems and Recommendations
                    </SectionHeader>

                    <TreatmentRecommendations testId={testId} />
                </>
            </PageLayout.BodySection>

            <Modal {...modalCtrlEmail} closeOnDimmerClick={false} size={'small'}>
                <EmailReportPanel
                    onCancel={modalCtrlEmail.onClose}
                    onFinish={modalCtrlEmail.onClose}
                    report={report}
                    contacts={contacts}
                />
            </Modal>

            <Modal centered={false} {...modalCtrlPhoto}>
                <Panel>
                    <Panel.Header
                        content={'Upload Photos'}
                        button={{ content: 'Close', icon: 'cancel', onClick: modalCtrlPhoto.onClose }}
                    />
                    <Panel.Body>
                        <FileAttachmentUploader
                            onDone={modalCtrlPhoto.onClose}
                            onFileUploadComplete={(fileId) => {
                                return props.TestReportMutator.attachFiles(report, [{ file: fileId }]);
                            }}
                            dzOptions={{ accept: 'image/*' }}
                        />
                    </Panel.Body>
                </Panel>
            </Modal>
            <div>{report?.refId && <span className={'mt-8 text-sm text-gray-400'}>Ref. ID: {report?.refId}</span>}</div>
        </PageLayout>
    );
};

export default Page;
