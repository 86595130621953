import * as React from 'react';
import * as Menubar from '@radix-ui/react-menubar';
import { clsxm } from '../../utils';
import { Icon } from '../../Icon';

export const NavbarDropdownMenu: React.FC<{
    icon?: React.ReactElement;
    label?: React.ReactNode;
    value?: string;
}> = ({ icon, label, children }) => {
    return (
        <Menubar.Menu>
            <Menubar.Trigger className={clsxm(['ez-navbar-dropdown-menu'])}>
                {icon && <span>{icon}</span>}
                {label && <span className={'hidden md:inline-block whitespace-nowrap'}>{label}</span>}
                <Icon
                    name={'caret down'}
                    className={'!mr-0 !-mt-1 '}
                    style={{
                        // HACK: offset the caret down icon to align with the text
                        marginTop: '-4px !important',
                    }}
                />
            </Menubar.Trigger>
            <Menubar.Portal>
                <Menubar.Content
                    className={clsxm([
                        'ez-navbar-dropdown-menu-content',
                        'z-[1000]  overflow-hidden rounded border p-2',
                        'bg-popover text-popover-foreground shadow-md',
                        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
                        'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                        'flex flex-col gap-1',
                    ])}
                    align="start"
                    sideOffset={5}
                    alignOffset={0}
                >
                    {children}
                </Menubar.Content>
            </Menubar.Portal>
        </Menubar.Menu>
    );
};

export const NavbarDropdownMenuItem = React.forwardRef<
    React.ElementRef<typeof Menubar.Item>,
    React.ComponentPropsWithoutRef<typeof Menubar.Item> & {
        icon?: React.ReactElement;
        content?: React.ReactNode;
    }
>(({ asChild, className, icon, children, content, ...props }, ref) => {
    const hasContent = content || children;
    return (
        <Menubar.Item
            ref={ref}
            className={clsxm([
                'focusable relative cursor-pointer select-none items-center rounded-sm text-base outline-none',
                'group focus:bg-menubar-item-hover',
                'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                'flex flex-row gap-2 p-2 px-2',
                className,
            ])}
            asChild={asChild}
            {...props}
        >
            {asChild ? (
                <>{children}</>
            ) : (
                <>
                    <span className={'w-6'}>{icon}</span>
                    {hasContent && (
                        <span className={'inline-block whitespace-nowrap'}>
                            {content}
                            {children}
                        </span>
                    )}
                </>
            )}
        </Menubar.Item>
    );
});

export const NavbarDropdownMenuItemLink = React.forwardRef<
    React.ElementRef<typeof Menubar.Item>,
    React.ComponentPropsWithoutRef<typeof Menubar.Item> &
        React.AnchorHTMLAttributes<HTMLAnchorElement> & {
            icon?: React.ReactElement;
            content?: React.ReactNode;
            href?: string;
        }
>(({ asChild, className, icon, children, content, ...props }, ref) => {
    const hasContent = content || children;
    return (
        <Menubar.Item
            ref={ref}
            asChild={true}
            className={clsxm([
                'focusable relative cursor-pointer select-none items-center rounded-sm text-base outline-none',
                'group focus:bg-accent focus:text-accent-foreground',
                'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
                'flex flex-row gap-2 p-2 px-2',
                className,
            ])}
            {...props}
        >
            <a rel={'noopener noreferrer'}>
                <span className={'w-6'}>{icon}</span>
                {hasContent && (
                    <span className={'inline-block whitespace-nowrap'}>
                        {content}
                        {children}
                    </span>
                )}
            </a>
        </Menubar.Item>
    );
});
export const NavbarDropdownMenuItemLabel = React.forwardRef<
    React.ElementRef<typeof Menubar.Label>,
    React.ComponentPropsWithoutRef<typeof Menubar.Label> & {
        inset?: boolean;
    }
>(({ className, inset, ...props }, ref) => (
    <Menubar.Label
        ref={ref}
        className={clsxm('flex flex-row gap-2 py-0 px-2 text-sm text-secondary', inset && 'pl-8', className)}
        {...props}
    />
));
NavbarDropdownMenuItemLabel.displayName = Menubar.Label.displayName;

export const NavbarDropdownMenuSeparator = React.forwardRef<
    React.ElementRef<typeof Menubar.Separator>,
    React.ComponentPropsWithoutRef<typeof Menubar.Separator>
>(({ className, ...props }, ref) => (
    <Menubar.Separator ref={ref} className={clsxm('-mx-1 my-1 h-px bg-accent', className)} {...props} />
));
NavbarDropdownMenuSeparator.displayName = Menubar.Separator.displayName;

export const NavbarDropdown = {
    Menu: NavbarDropdownMenu,
    Item: NavbarDropdownMenuItem,
    ItemLink: NavbarDropdownMenuItemLink,
    Label: NavbarDropdownMenuItemLabel,
    Separator: NavbarDropdownMenuSeparator,
};
