import * as React from 'react';
import * as URLBuilder from './url-builder';
import { Link } from 'react-router-dom';

export const PoolLink: React.FC<{ poolId; customerId }> = ({ customerId, poolId, ...rest }) => {
    const link = URLBuilder.Customer(customerId).Pool(poolId).view;
    return <Link to={link} {...rest} />;
};

export const CustomerLink: React.FC<{ customerId }> = ({ customerId, ...rest }) => {
    const link = URLBuilder.Customer(customerId).view;
    return <Link to={link} {...rest} />;
};

export const StaffLink: React.FC<{ staffId: string }> = ({ staffId, ...rest }) => {
    const link = URLBuilder.Staff(staffId).view || '';
    return <Link to={link} {...rest} />;
};

export const WorkOrderLink: React.FC<{ workOrderId: string }> = ({ workOrderId, ...rest }) => {
    return <Link to={`/service-jobs/wo/${workOrderId}`} {...rest} />;
};

export const ServiceJobLink: React.FC<{ serviceJobId: string }> = ({ serviceJobId, ...rest }) => {
    return <Link to={`/service-jobs/sj/${serviceJobId}`} {...rest} />;
};

export const WaterTestReportLink: React.FC<{ customerId: string; poolId; reportId }> = ({
    customerId,
    poolId,
    reportId,
    ...rest
}) => {
    let link = URLBuilder.Customer(customerId).Pool(poolId).WaterTest(reportId).view;

    return <Link to={link} {...rest} />;
};
