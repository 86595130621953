import * as React from 'react';
import * as URLBuilder from '../../../../../routes/url-builder';
import _truncate from 'lodash/truncate';
import { NodeType } from '@poolware/api';
import { useAppNavigator, withAppNavigator } from '@poolware/react-app-navigator';
import { Button, clsxm } from '@ez/components';
import { fromEdges } from '@ez/api-core';
import { useViewer } from '@poolware/app-shell';

const PoolItem: React.FC<{ label: React.ReactNode; content: React.ReactNode; className?: string }> = ({
    label,
    content,
    className,
}) => {
    return (
        <div
            className={clsxm(
                // 'flex flex-col border-0 border-r-solid border-r-2 border-r-white px-4 first:pl-0 last:border-0 overflow-hidden',
                'flex flex-row gap-2 px-0 overflow-hidden',
                className
            )}
        >
            <div className={'opacity-80 w-20'}>{label}</div>
            <div className={'overflow-hidden text-ellipsis whitespace-nowrap'}>{content}</div>
        </div>
    );
};

const PoolDescription: React.FC<{ pool: NodeType.Pool }> = ({ pool }) => {
    const ezcDevices = fromEdges(pool.ezcDeviceLinks);

    return (
        <div className={'flex-grow flex flex-col gap-0 w-full'}>
            {pool.volume && <PoolItem label={'Volume'} content={`${pool?.volume} L`} />}
            {pool.type && <PoolItem label={'Type'} content={pool?.type?.name} />}
            {pool.sanitiser && <PoolItem label={'Sanitiser'} content={pool?.sanitiser?.name} />}
        </div>
    );
};

interface ActionButtonProps {
    customerId;
    poolId;
}

const NewTestButton = withAppNavigator<ActionButtonProps>()(({ customerId, poolId, AppNavigator }) => {
    const link = URLBuilder.Customer(customerId).Pool(poolId).WaterTest().new;
    const onClick = () => {
        AppNavigator.navigate(link, {
            setOrigin: true,
            query: {
                poolId: poolId,
            },
        });
    };

    return (
        <Button
            color="primary"
            variant={'primary'}
            // icon={"lab"}
            onClick={onClick}
            icon={'plus'}
            content={'New Test'}
        />
    );
});

const PoolCard: React.FC<{ pool: NodeType.Pool; customerId: string }> = ({ pool, customerId }) => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess } = useViewer();

    const linkViewPool = URLBuilder.Customer(customerId).Pool(pool.id).view;
    const bottleNumber = pool.bottleNumber;

    const onViewPool = () => {
        AppNavigator.navigate(linkViewPool, {
            query: {
                poolId: pool.id,
            },
        });
    };

    return (
        <div className={'rounded bg-accent p-4 flex flex-col gap-4 shadow'}>
            <div className={'text-strong text-lg'}>
                {_truncate(pool.name, { length: 50 })} {bottleNumber && ` (${bottleNumber})`}
            </div>

            <PoolDescription pool={pool} />

            <div className={'flex flex-row gap-2 justify-start w-full items-center'}>
                <Button color="primary" basic={true} onClick={onViewPool} content={'View Pool'} />
                {modulesAccess.WaterTest?.enabled && <NewTestButton poolId={pool.id} customerId={customerId} />}
            </div>
        </div>
    );
};

const NoPoolsCard: React.FC<{ onAddPool: () => any }> = ({ onAddPool }) => {
    return (
        <div className={'rounded bg-accent p-4 flex flex-col gap-4 shadow'}>
            <div className={'py-2'}>
                <div className={'italic text-tertiary'}>No pools registered for this site</div>
            </div>
            <div>
                <Button
                    onClick={onAddPool}
                    color="primary"
                    variant={'primary'}
                    icon={'plus'}
                    content={'Register Pool'}
                />
            </div>
        </div>
    );
};

const PoolList: React.FC<{ pools: NodeType.Pool[]; customerId: string; onAddPool: () => any }> = ({
    pools,
    customerId,
    onAddPool,
}) => {
    return (
        <>
            <div className={'mt-2'}>
                <div className={'grid grid-cols-1 lg:grid-cols-2 3xl:grid-cols-3 gap-4 w-full'}>
                    {pools.length === 0 && <NoPoolsCard onAddPool={onAddPool} />}
                    {pools.map((pool) => {
                        return <PoolCard key={pool.id} pool={pool} customerId={customerId} />;
                    })}
                </div>
            </div>
        </>
    );
};

export default PoolList;
