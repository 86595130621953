import * as React from 'react';
import { NodeType } from '@poolware/api';
import { PhotoAttachmentType, photoGroupTypeToLabel } from './Page';
import { PhotoGroup } from './PhotoGroup';
import { useDragPageAutoScroll } from './use-drag-page-auto-scroll';
import { FileUploadFn } from '../../types';

export interface PhotoGroupsProps {
    files: NodeType.FileUpload[];
    onDeleteFiles: (filesToDelete: NodeType.FileUpload[]) => Promise<any>;
    onFileUploadRequest: FileUploadFn;
    refetchQueries?: any[];
}

export const PhotoGroups: React.FC<PhotoGroupsProps> = ({
    files,
    onDeleteFiles,
    onFileUploadRequest,
    refetchQueries,
}) => {
    const beforeFiles = files.filter((f) => f.beforeAfterStatus === NodeType.FileUploadBeforeAfterEnum.BEFORE);
    const afterFiles = files.filter((f) => f.beforeAfterStatus === NodeType.FileUploadBeforeAfterEnum.AFTER);
    const otherFiles = files.filter((f) => !f.beforeAfterStatus);

    useDragPageAutoScroll();

    return (
        <div className={'flex flex-col gap-2'}>
            <PhotoGroup
                files={beforeFiles}
                onFileUploadRequest={onFileUploadRequest}
                refetchQueries={refetchQueries}
                label={photoGroupTypeToLabel[PhotoAttachmentType.BeforeService]}
                onDeleteFiles={onDeleteFiles}
                beforeAfterStatus={NodeType.FileUploadBeforeAfterEnum.BEFORE}
            />
            <PhotoGroup
                files={afterFiles}
                onFileUploadRequest={onFileUploadRequest}
                label={photoGroupTypeToLabel[PhotoAttachmentType.AfterService]}
                refetchQueries={refetchQueries}
                onDeleteFiles={onDeleteFiles}
                beforeAfterStatus={NodeType.FileUploadBeforeAfterEnum.AFTER}
            />
            <PhotoGroup
                files={otherFiles}
                onFileUploadRequest={onFileUploadRequest}
                label={photoGroupTypeToLabel[PhotoAttachmentType.Other]}
                refetchQueries={refetchQueries}
                onDeleteFiles={onDeleteFiles}
            />
        </div>
    );
};
