import * as React from 'react';
import { ButtonWithPopupProps, clsxm, Icon } from '@ez/components';

export const Frame = React.forwardRef<
    HTMLDivElement,
    React.HTMLProps<HTMLDivElement> & { tertiary?: boolean; bordered?: boolean }
>(({ bordered, className, tertiary, ...props }, ref) => {
    return (
        <div
            ref={ref}
            className={clsxm(
                // 'border border-red',
                'flex flex-col gap-4',
                tertiary && 'bg-tertiary',
                bordered && 'border p-2 px-3 rounded-sm',
                className
            )}
            {...props}
        />
    );
});

export const SectionHeader: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...props }) => {
    return (
        <div
            className={clsxm(
                ' w-full h-[28px] px-4 border-none rounded-none text-xl text-strong font-bold flex flex-row items-center justify-between',
                className
            )}
            {...props}
        />
    );
};

export const SectionButton: React.FC<ButtonWithPopupProps & { expanded?: boolean }> = ({
    content,
    expanded,
    children,
    className,
    icon,
    ...props
}) => {
    return (
        <button
            // variant={'tertiary'}
            // size={'lg'}
            className={clsxm(
                'ez-btn-base w-full h-[56px] px-4 border-none rounded-none text-xl text-strong font-bold flex flex-row items-center justify-between',
                className
            )}
            {...props}
        >
            {icon}
            <div className={'flex-grow text-left'}>
                {content}
                {children}
            </div>
            <Icon size={'big'} name={expanded ? 'caret up' : 'caret down'} />
        </button>
    );
};

export const BottomButtonContainer: React.FC = (props) => {
    return (
        <div
            className={
                'fixed bottom-0 left-[var(--sideBar,0)] right-0 bg-panel shadow-[0_0_16px_0_rgba(0,0,0,0.3)] flex flex-row'
            }
            {...props}
        />
    );
};
