import * as React from 'react';
import {
    FormGroup,
    FormikDefaultForm,
    FormikSelectField,
    FormSectionBody,
    Optionable,
    SectionHeader,
    toastError,
} from '@ez/components';
import { EZCDevicesLinkWithState, LifeGuardTimerType } from '../../../../common';
import { AttributeModel } from '../AttributeRenderer';
import { useMutationDeviceState } from '../use-mutation-device-state';
import { FormikTimePicker } from './FormikTimePicker';

import * as Yup from 'yup';

const timerSchema = Yup.object().shape({
    pumpSpeed: Yup.number().required('Required').nullable(),
    from: Yup.object()
        .shape({
            hour: Yup.number().required('Required').nullable(),
            minute: Yup.number().required('Required').nullable(),
        })
        .required('Required')
        .nullable(),
    to: Yup.object()
        .shape({
            hour: Yup.number().required('Required').nullable(),
            minute: Yup.number().required('Required').nullable(),
        })
        .required('Required')
        .nullable(),
});

const validationSchema = Yup.object().shape({
    timer0: timerSchema.required('Required'),
    timer1: timerSchema.required('Required'),
});

export interface FromEditSchedulesProps {
    onDone?: () => void;
    refetchQueries?: any[];
    device: EZCDevicesLinkWithState;
    timerAttrs: {
        timer0: AttributeModel<LifeGuardTimerType>;
        timer1: AttributeModel<LifeGuardTimerType>;
    };
}

const defaultTimer: Partial<LifeGuardTimerType> = {
    pumpSpeed: undefined,
    from: { hour: undefined, minute: undefined },
    to: { hour: undefined, minute: undefined },
};

export const FromEditSchedules: React.FC<FromEditSchedulesProps> = ({ onDone, device, timerAttrs, refetchQueries }) => {
    const { update } = useMutationDeviceState({ refetchQueries });

    const initialValues = {
        timer0: timerAttrs?.timer0?.attribute?.reported || {
            pumpSpeed: undefined,
            from: { hour: undefined, minute: undefined },
            to: { hour: undefined, minute: undefined },
        },
        timer1: timerAttrs?.timer1?.attribute?.reported || {
            pumpSpeed: undefined,
            from: { hour: undefined, minute: undefined },
            to: { hour: undefined, minute: undefined },
        },
    };

    const onCancel = () => {
        onDone?.();
    };

    const onSubmit = async (values: typeof initialValues) => {
        const prepareTimer = (timer: Partial<LifeGuardTimerType>) => {
            return {
                pumpSpeed: timer.pumpSpeed,
                from: {
                    hour: timer.from.hour,
                    minute: timer.from.minute,
                },
                to: {
                    hour: timer.to.hour,
                    minute: timer.to.minute,
                },
            };
        };

        try {
            const res = await update({
                device: device.device.id,
                scope: 'lifeguard',
                input: {
                    timer0: prepareTimer(values.timer0),
                    timer1: prepareTimer(values.timer1),
                },
            });
            onDone?.();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const pumpSpeedOptions: Optionable<number>[] = [2, 4, 6, 8, 10].map((value) => ({
        text: value.toString(),
        value,
    }));

    return (
        <FormikDefaultForm
            header={'Edit Schedules'}
            initialValues={initialValues}
            validationSchema={validationSchema}
            submitButton={{ content: 'Update' }}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <FormSectionBody>
                <SectionHeader content={'Timer 1'} />
                <FormGroup>
                    <FormikTimePicker name={'timer0.from'} label={'On'} />
                    <FormikTimePicker name={'timer0.to'} label={'Off'} />
                    <FormikSelectField name={'timer0.pumpSpeed'} label={'Speed'} options={pumpSpeedOptions} />
                </FormGroup>
            </FormSectionBody>
            <FormSectionBody>
                <SectionHeader content={'Timer 2'} />
                <FormGroup>
                    <FormikTimePicker name={'timer1.from'} label={'On'} />
                    <FormikTimePicker name={'timer1.to'} label={'Off'} />
                    <FormikSelectField name={'timer1.pumpSpeed'} label={'Speed'} options={pumpSpeedOptions} />
                </FormGroup>
            </FormSectionBody>
        </FormikDefaultForm>
    );
};
