import * as React from 'react';
import { useField } from 'formik';
import { TraitDeclarationPicker_TreeModal } from '../../../components-api-connected';
import { FormFieldInputWithAction, FormikFieldProps, useModalCtrl } from '@ez/components';
import { NodeType } from '@poolware/api';

export const FormikParentTraitDeclarationInput: React.FC<FormikFieldProps> = ({ name, ...props }) => {
    const [field, meta, helpers] = useField<NodeType.ProductTraitDeclaration>({ name: name });
    const modalCtrl = useModalCtrl();

    const touched = meta.touched;
    const error = meta.error;

    const onSelect = (parent) => {
        helpers.setValue(parent);
        modalCtrl.onClose();
    };

    return (
        <>
            <FormFieldInputWithAction
                error={touched || error}
                label={'Parent Trait'}
                value={field.value?.name}
                onClick={modalCtrl.onOpen}
                onClear={() => helpers.setValue(null)}
                {...props}
            />
            <TraitDeclarationPicker_TreeModal
                open={modalCtrl.open}
                onClose={modalCtrl.onClose}
                selected={field.value}
                onSelect={onSelect}
            />
        </>
    );
};
