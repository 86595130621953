import * as React from 'react';
import {
    FormFieldLabel,
    FormGroup,
    FormikDefaultForm,
    FormikDefaultFormProps,
    FormikInputField,
    FormikTextareaField,
} from '@ez/components';
import { FormikInputJobTodoItems } from '../../WorkOrderNew/WorkOrderItemFormFields/FormikInputJobTodoItems';

export const JobTodoForm: React.FC<FormikDefaultFormProps> = (props) => {
    return (
        <FormikDefaultForm {...props}>
            <FormikInputField label={'Task Title'} name={'title'} />
            <FormGroup>
                <FormikInputField name={'timeEstimate'} label={'Duration, min'} />
                {false && <FormikInputField name={'price'} label={'Cost, $'} />}
            </FormGroup>
            <FormikTextareaField rows={8} label={'Task Description'} name={'description'} />
            <FormFieldLabel label={'Task Items (optional)'} />
            <FormikInputJobTodoItems name={'items'} />
        </FormikDefaultForm>
    );
};
