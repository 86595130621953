import * as React from 'react';
import {
    DebugJSON,
    MenuBarHeaderItem,
    MenuBarSection,
    SectionHeader,
    StickyMenuBar,
    VStack,
    Table,
    PageLayout,
} from '@ez/components';
import { FeatureFlag, useViewer } from '@poolware/app-shell';

export const PageFeatureFlagsTest = () => {
    const { viewer, canAccessFeature, getFeatureFlag } = useViewer();

    const flagsToTest = [
        FeatureFlag.ADMIN, //
        'FOO_BAR',
        'THEME',
    ];

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem content={'Feature Flags test'} />
                </MenuBarSection>
            </StickyMenuBar>

            <SectionHeader>Flag tests</SectionHeader>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Flag</Table.HeaderCell>
                        <Table.HeaderCell>canAccessFeature</Table.HeaderCell>
                        <Table.HeaderCell>getFeatureFlag</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {flagsToTest.map((f, i) => {
                        const flagValue = getFeatureFlag(f);
                        let flagValueString = '--';
                        if (flagValue === undefined) {
                            flagValueString = 'undefined';
                        } else if (flagValue === null) {
                            flagValueString = 'null';
                        } else if (typeof flagValue === 'boolean') {
                            flagValueString = `boolean: ${flagValue ? 'true' : 'false'}`;
                        } else {
                            flagValueString = `${typeof flagValue}: ${flagValue?.toString()}`;
                        }

                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{f}</Table.Cell>
                                <Table.Cell>{canAccessFeature(f) ? 'Y' : 'N'}</Table.Cell>
                                <Table.Cell>{flagValueString}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>

            <SectionHeader>Me</SectionHeader>
            <DebugJSON data={viewer.me} />
        </PageLayout>
    );
};
