import * as React from 'react';
import * as _ from 'lodash';
import { compose } from '@ez/tools';
import { AuthConnectProps, connectWithAuth } from '@ez/api-core';
import { getAppConfig, withAppConfig, WithAppConfigProps } from '../../app-config';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import styled from 'styled-components';
import { connectWithTheme, ThemeStateProps } from '../../app-theme';
import { PageVariantDefault } from './PageVariantDefault';
import { PageVariantDavey } from './PageVariantDavey';

interface Props extends AuthConnectProps, IAppNavigatorProps, ThemeStateProps, WithAppConfigProps {
    redirect: any;
}

const config = getAppConfig();

class LoginForm extends React.Component<Props, any> {
    constructor(props, context) {
        super(props, context);

        this.state = {
            error: false,
            email: '',
            password: '',
            authenticating: false,
        };
    }

    redirect = (props?) => {
        const redirect = _.get(props || this.props, 'query.redirect', '/');
        this.props.AppNavigator.replace(redirect);
    };

    UNSAFE_componentWillMount() {
        const { AuthState } = this.props;
        const { isAuthenticated } = AuthState;
        if (isAuthenticated) {
            this.redirect();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isAuthenticated } = nextProps.AuthState;
        const { isAuthenticated: wasAuthenticated } = this.props.AuthState;

        if (!wasAuthenticated && isAuthenticated) {
            this.redirect(nextProps);
        }
    }

    handleSubmit = async (email: string, password: string) => {
        const { AuthAction } = this.props;
        await AuthAction.login(email, password);
    };

    render() {
        if (config.appProfile?.profile === 'davey') {
            return <PageVariantDavey {...this.props} onSubmit={this.handleSubmit} />;
        }
        return <PageVariantDefault {...this.props} onSubmit={this.handleSubmit} />;
    }
}

export default compose(connectWithAuth(), connectWithTheme(), withAppNavigator(), withAppConfig())(LoginForm);
