import * as React from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMobileAppointmentCtx } from '../../Root';
import { fromEdges, NodeType } from '@poolware/api';
import { Frame } from '../../components';
import { Button, Icon } from '@ez/components';
import { PhotoSlidesHorizontal } from './PhotoSlidesHorizontal';

const photoGroupTypeToLabel = {
    [NodeType.FileUploadBeforeAfterEnum.BEFORE]: 'Before service',
    [NodeType.FileUploadBeforeAfterEnum.AFTER]: 'After service',
    OTHER: 'Photos',
};

export const PageSectionPhotos: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { workOrder } = useMobileAppointmentCtx();

    const files = fromEdges(workOrder?.files);
    const beforeFiles = files.filter((f) => f.beforeAfterStatus === NodeType.FileUploadBeforeAfterEnum.BEFORE);
    const afterFiles = files.filter((f) => f.beforeAfterStatus === NodeType.FileUploadBeforeAfterEnum.AFTER);
    const otherFiles = files.filter((f) => !f.beforeAfterStatus);

    const onAddPhoto = () => {
        AppNavigator.navigateRelative('/photos');
    };
    return (
        <Frame bordered={true}>
            <div className={'flex flex-row justify-between'}>
                <strong>
                    <Icon name={'camera'} />
                    Photo Attachments
                </strong>
                <Button variant={'tertiary'} icon={'plus'} onClick={onAddPhoto} />
            </div>
            {beforeFiles.length > 0 && (
                <PhotoSlidesHorizontal
                    files={beforeFiles}
                    label={photoGroupTypeToLabel[NodeType.FileUploadBeforeAfterEnum.BEFORE]}
                />
            )}
            {afterFiles.length > 0 && (
                <PhotoSlidesHorizontal
                    files={afterFiles}
                    label={photoGroupTypeToLabel[NodeType.FileUploadBeforeAfterEnum.AFTER]}
                />
            )}
            {otherFiles.length > 0 && (
                <PhotoSlidesHorizontal files={otherFiles} label={photoGroupTypeToLabel['OTHER']} />
            )}
        </Frame>
    );
};
