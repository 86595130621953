import * as React from 'react';
import { useState } from 'react';
import {
    DateRange,
    DateRangeOptionItem,
    DateRangePickerButton,
    FormFieldSegmentedSelectButtons,
    FormLabel,
    HStack,
    MenuBarItem,
    MenuBarSection,
    PageLayout,
    SectionHeader,
    Segment,
    StickyMenuBar,
} from '@ez/components';
import moment from 'moment';

import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { QueryStatsNewCustomers } from '../Queries/QueryNewCustomersStats';
import { NodeType } from '@poolware/api';
import { defaultDateRange, getDateRangeSelectOptions } from './dateRangeSelectOptions';
import { StatsGraph } from '../components/StatsGraph';
import { StatsTable } from '../components/StatsTable';
import { DataPoint } from '../components/graph-helpers';
import { getDateFormatterForResolution, getResolutionOptions } from './helpers';

const dateRangeSelectOptions: DateRangeOptionItem[] = getDateRangeSelectOptions();
const resolutionOptions = getResolutionOptions();

const DashboardPage: React.FC<IAppNavigatorProps> = ({ AppNavigator }) => {
    const [dateRange, setDateRange] = useState(defaultDateRange);

    const [resolution, setResolution] = useState<NodeType.DateRangeResolutionEnum>(
        NodeType.DateRangeResolutionEnum.Day
    );

    const goBack = () => {
        AppNavigator.navigate('/', { relativeToModule: true });
    };

    const handleResolutionChange = (option) => {
        setResolution(option?.value);
    };

    const handleOnDateRangeChange = (newDaterange: DateRange) => {
        setDateRange(newDaterange);
    };

    const variables = {
        ...dateRange,
        resolution: resolution,
    };

    const dateFormat = getDateFormatterForResolution(resolution);

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Dashboard'} />
                </MenuBarSection>
            </StickyMenuBar>

            <Segment>
                <SectionHeader>Customers over time</SectionHeader>

                <HStack style={{ display: 'inline-flex' }}>
                    <div style={{ marginBottom: '0.5em' }}>
                        <FormLabel>Date range</FormLabel>
                        <DateRangePickerButton
                            dateRangeSelectOptions={dateRangeSelectOptions}
                            initialDateRange={dateRange}
                            onChange={handleOnDateRangeChange}
                        />
                    </div>
                    <FormFieldSegmentedSelectButtons
                        label={'Group by'}
                        options={resolutionOptions}
                        onChange={handleResolutionChange}
                        value={resolution}
                        fluid={false}
                    />
                </HStack>
            </Segment>

            <QueryStatsNewCustomers {...variables}>
                {({ activitySamples, loading, error }) => {
                    if (error) {
                        return <div>Error</div>;
                    }

                    const dataPoints: DataPoint[] = activitySamples.map((i) => ({
                        time: moment(i.date).valueOf(),
                        value: i.count,
                    }));

                    return (
                        <>
                            <Segment>
                                <SectionHeader size={'small'}>New Customers</SectionHeader>
                                <StatsGraph loading={loading} dateFormat={dateFormat} data={dataPoints} />
                            </Segment>
                            <StatsTable data={activitySamples} dateFormat={dateFormat} loading={loading} />
                        </>
                    );
                }}
            </QueryStatsNewCustomers>
        </PageLayout>
    );
};

export default withAppNavigator()(DashboardPage);
