import { NodeType } from '@poolware/api';

export type DeviceStateType<ShadowType = any> = {
    actionQueue: any[];
    desired: Partial<ShadowType>;
    reported: ShadowType;
    pending: Partial<ShadowType>;
    isAlive: boolean;
    heartbeatTimestamp: number;
};

export enum DeviceAlarmStatus {
    UNKNOWN = 'UNKNOWN',
    OFFLINE = 'OFFLINE',
    NORMAL = 'NORMAL',
    WARNING = 'WARNING',
    ALERT = 'ALERT',
    FAULT = 'FAULT',
}

export enum DeviceAttributeAlarmStatus {
    NORMAL = 'NORMAL',
    ALERT = 'ALERT',
    WARNING = 'WARNING',
    FAULT = 'FAULT',
}

export interface EZCDevicesLinkWithState extends NodeType.EZCDeviceLink {
    deviceState?: NodeType.EZCDeviceState;
    coordinates?: NodeType.Coordinate;
    alarmStatus?: DeviceAlarmStatus;
    isAlive?: boolean;
    isLoadingShadow?: boolean;
}
