import { getApiClient, NodeType } from '@poolware/api';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useWorkOrderMutators } from '../../queries/mutators-work-order';
import invariant from 'invariant';
import { useJobTodoMutators } from '../../queries/mutators-job-todos';
import { FileHandle, FileUploadFn } from '../../Scheduler';
import { wait } from '@ez/tools';

const apiClient = getApiClient();

export interface WorkOrderViewContextType {
    workOrder: NodeType.WorkOrder;
    refetchQueries: any[];
}

export const WorkOrderViewContext = React.createContext<WorkOrderViewContextType>({
    workOrder: null,
    refetchQueries: [],
});

export const useWorkOrderViewContext = () => {
    const ctx = React.useContext(WorkOrderViewContext);
    if (!ctx) {
        invariant(false, 'useWorkOrderViewContext must be used within a WorkOrderViewContextProvider');
    }
    const workOrderMutators = useWorkOrderMutators({
        refetchQueries: ctx.refetchQueries,
        awaitRefetchQueries: true,
    });

    const jobTodoMutators = useJobTodoMutators({
        refetchQueries: ctx.refetchQueries,
        awaitRefetchQueries: true,
    });

    const addFilesToWO: FileUploadFn = useCallback(
        async (files) => {
            if (!ctx.workOrder) {
                throw new Error('Work order is missing');
            }

            const success: { file: FileHandle; error?: null; fileId: string }[] = [];
            const failed: { file: FileHandle; error: Error }[] = [];
            for (const file of files) {
                try {
                    const res = await apiClient.uploadFile(file.file, { beforeAfterStatus: file.beforeAfterStatus });
                    success.push({ file, fileId: res.id, error: null });
                } catch (e) {
                    failed.push({ file, error: e });
                }
            }
            const fileIds = success.map((res) => res.fileId);
            const res = await workOrderMutators.addFiles(ctx.workOrder, fileIds);
            if (res.errors) {
                //mark success as failed
                failed.push(...success.map((file) => ({ ...file, error: new Error('Failed to add file') })));
            }
            return {
                success,
                failed,
            };
        },
        [ctx.workOrder, workOrderMutators]
    );

    return useMemo(
        () => ({
            ...ctx,
            workOrderMutators,
            jobTodoMutators,
            addFilesToWO: addFilesToWO,
        }),
        [ctx, workOrderMutators]
    );
};
