import React, { useMemo } from 'react';

import {
    Area,
    AreaChart,
    Brush,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    XAxisProps,
    YAxis,
    YAxisProps,
} from 'recharts';
import { DataChartType } from './prepare-graph-data';
import moment from 'moment/moment';
import { DateRange } from '@ez/components';
import { CurveType } from 'recharts/types/shape/Curve';
import { TooltipProps } from 'recharts/types/component/Tooltip';

const DAVEY_YELLOW = '#ffd200';

const tickFormatter = (dateFormatter?: string) => (time: number | string) => {
    return moment(time).format(dateFormatter || 'DD-MMM-YY HH:mm');
};

function getTickFormatter(dateRange?: DateRange) {
    if (!dateRange) {
        return tickFormatter('DD-MMM-YY');
    }
    const startDate = moment(dateRange.startDate);
    const endDate = moment(dateRange.endDate);
    const duration = moment.duration(endDate.diff(startDate));
    const days = duration.asDays();

    if (days >= 7) {
        return tickFormatter('DD-MMM-YY');
    }
    if (days < 2) {
        return tickFormatter('HH:mm');
    } else if (days < 7) {
        return tickFormatter('DD-MMM-YY HH:mm');
    } else {
        return tickFormatter('HH:mm');
    }
}

const AxisTickMultiRow = (props: {
    x: number;
    y: number;
    payload: {
        value: string;
    };
}) => {
    const { x, y, payload } = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={12} textAnchor="middle" fontSize={'0.8em'} fillOpacity={1} fill={DAVEY_YELLOW}>
                {tickFormatter('HH:mm')(payload.value)}
            </text>
            <text x={0} y={0} dy={24} textAnchor="middle" fontSize={'0.6em'} fillOpacity={0.5} fill={DAVEY_YELLOW}>
                {tickFormatter('DD-MMM-YY')(payload.value)}
            </text>
        </g>
    );
};

const AxisTickSingle = (props: {
    x: number;
    y: number;
    tickFormatter: (value: number | string) => string;
    payload: {
        value: string;
    };
}) => {
    const { x, y, payload, tickFormatter } = props;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={12} textAnchor="middle" fontSize={'0.8em'} fillOpacity={1} fill={DAVEY_YELLOW}>
                {tickFormatter(payload.value)}
            </text>
        </g>
    );
};

interface GraphTooltipProps extends TooltipProps<any, any> {
    showOnlyWenKeysPresent?: string[];
}

const GraphTooltip: React.FC<GraphTooltipProps> = (props) => {
    const { active, payload, label, labelFormatter } = props;
    if (!active || !payload || !payload.length) {
        return null;
    }

    if (props.showOnlyWenKeysPresent) {
        const dataKeys = payload.map((p) => p.dataKey);
        let hide = false;
        props.showOnlyWenKeysPresent.forEach((key) => {
            if (!dataKeys.includes(key)) {
                hide = true;
            }
        });

        if (hide) {
            return null;
        }
    }

    return (
        <div className={'bg-[#000000] text-white p-2 rounded border-[#444444] border border-solid'}>
            <div className={'mb-1 text-lg'}>
                {payload.map((p, i) => {
                    if ((p.dataKey as string)?.endsWith('_flow')) {
                        // HACK: Don't show `_flow` values in the tooltip.
                        return null;
                    }
                    return (
                        <div className={''} key={i}>
                            <span>{p.name}</span> : <span className={'text-[#ffd200]'}>{p.value}</span>
                        </div>
                    );
                })}
            </div>
            <div className={'text-xs opacity-80'}>{labelFormatter?.(label, payload)}</div>
        </div>
    );
};

interface DataChartProps {
    chartData: DataChartType;
    dateRange?: DateRange;
    showOnlyWenKeysPresent?: string[];
    conf: {
        yDividingLine?: number;
        legendName?: string;
        hideLegend?: boolean;
        dataKey: string;
        color?: string;
        dotColor?: string;
        fillOpacity?: number;
        interpolationType?: CurveType;
    }[];
    showXAxis?: boolean;
    showBrush?: boolean;
    yAxis?: YAxisProps;
    xAxis?: XAxisProps;
}

const DataChart: React.FC<DataChartProps> = ({
    chartData,
    conf = [],
    showBrush,
    yAxis,
    xAxis,
    dateRange,
    showOnlyWenKeysPresent,
}) => {
    const { dataPoints, dateRangeSpan, twoRowTick, formatter, xDomain, ticks } = useMemo(() => {
        const xDomain = dateRange
            ? [dateRange.startDate.getTime(), dateRange.endDate.getTime()]
            : ['dataMin', 'dataMax'];
        const dateRangeSpan = dateRange ? moment(dateRange.endDate).diff(dateRange.startDate, 'days') : 0;
        const twoRowTick = true; //dateRangeSpan >= 1 && dateRangeSpan < 7;
        const formatter = getTickFormatter(dateRange);
        const dataPoints = chartData?.dataPoints || [];
        const ticks = chartData.ticks;
        return { xDomain, dateRangeSpan, twoRowTick, formatter, dataPoints, ticks };
    }, [chartData, dateRange]);

    const xAxisTick = twoRowTick ? AxisTickMultiRow : AxisTickSingle;

    return (
        <ResponsiveContainer width="100%" height={200} className={'bg-[#222222] rounded'}>
            <AreaChart
                // key={JSON.stringify(dateRange)}
                data={dataPoints}
                syncId="chartSyncId"
                margin={{
                    top: 10,
                    right: 30,
                    // left: 5,
                    bottom: 15,
                }}
            >
                {conf.map((c) => {
                    const domain = chartData?.yRange?.[c.dataKey] ?? ['dataMin', 'dataMax'];
                    const dotColor = c.dotColor || c.color || DAVEY_YELLOW;
                    const dotConf =
                        dateRangeSpan < 2
                            ? { stroke: dotColor, fill: dotColor, strokeWidth: 0, r: 3, fillOpacity: 1 }
                            : { stroke: dotColor, fill: dotColor, strokeWidth: 0, r: 2, fillOpacity: 1 };

                    const off = 0.5;
                    return (
                        <React.Fragment key={c.dataKey}>
                            <YAxis
                                domain={domain}
                                {...(yAxis as any)}
                                label={{ fill: 'red' }}
                                tick={{ fontSize: '0.8em', fillOpacity: 1, fill: DAVEY_YELLOW }}
                                strokeOpacity={1}
                                fillOpacity={1}
                            />
                            <Area
                                legendType={c.hideLegend ? 'none' : undefined}
                                name={c.legendName || c.dataKey}
                                dot={dotConf}
                                connectNulls={false}
                                type={c.interpolationType || 'monotone'}
                                key={c.dataKey}
                                dataKey={c.dataKey}
                                stroke={c.color}
                                fill={c.color || '#8884d8'}
                                fillOpacity={c.fillOpacity || 0.8}
                                strokeOpacity={1}
                                xAxisId={12}
                                isAnimationActive={false}
                                animationDuration={0}
                            />
                        </React.Fragment>
                    );
                })}
                <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />
                <Tooltip
                    isAnimationActive={false}
                    labelFormatter={tickFormatter('lll')}
                    content={<GraphTooltip showOnlyWenKeysPresent={showOnlyWenKeysPresent} />}
                />
                <Legend verticalAlign="top" strokeOpacity={1} fillOpacity={1} height={24} />

                <XAxis
                    xAxisId={12}
                    hide={false}
                    dataKey="TIMESTAMP"
                    domain={xDomain}
                    type="number"
                    scale={'time'}
                    allowDataOverflow={true}
                    ticks={ticks}
                    tick={xAxisTick}
                    tickFormatter={formatter}
                    {...(xAxis as any)}
                />
                {showBrush && (
                    <Brush
                        fill={DAVEY_YELLOW}
                        type="number"
                        scale={'time'}
                        dataKey={'TIMESTAMP'}
                        // tickFormatter={formatter}
                    />
                )}
            </AreaChart>
        </ResponsiveContainer>
    );
};

const OnOffChart: React.FC<DataChartProps> = (props) => {
    return (
        <DataChart
            yAxis={{
                type: 'category',
                domain: [0, 1],
                tickFormatter: (value) => (value ? 'ON' : 'OFF'),
                ticks: [0, 1],
                padding: { top: 20 },
            }}
            {...props}
        />
    );
};

export const DeviceLogsChart: React.FC<{ chartData?: DataChartType; dateRange: DateRange }> = ({
    chartData,
    dateRange,
}) => {
    return (
        <div>
            <div className={'w-full flex flex-col items-stretch gap-2'}>
                <DataChart
                    dateRange={dateRange}
                    chartData={chartData}
                    showOnlyWenKeysPresent={['PH', 'PH_flow']}
                    conf={[
                        {
                            dataKey: 'PH',
                            color: '#00a0ff',
                            fillOpacity: 0.25,
                            legendName: 'pH',
                            dotColor: 'transparent',
                        },
                        { dataKey: 'PH_flow', color: '#00a0ff', fillOpacity: 0.5, hideLegend: true },
                    ]}
                />
                <DataChart
                    dateRange={dateRange}
                    chartData={chartData}
                    showOnlyWenKeysPresent={['ORP', 'ORP_flow']}
                    conf={[
                        { dataKey: 'ORP', color: DAVEY_YELLOW, fillOpacity: 0.25, dotColor: 'transparent' },
                        { dataKey: 'ORP_flow', color: DAVEY_YELLOW, fillOpacity: 0.5, hideLegend: true },
                    ]}
                />
                <DataChart
                    dateRange={dateRange}
                    chartData={chartData}
                    showOnlyWenKeysPresent={['SALINITY', 'SALINITY_flow']}
                    conf={[
                        {
                            dataKey: 'SALINITY',
                            color: '#4bfae0',
                            fillOpacity: 0.25,
                            legendName: 'Salinity',
                            dotColor: 'transparent',
                        },
                        { dataKey: 'SALINITY_flow', color: '#4bfae0', fillOpacity: 0.5, hideLegend: true },
                    ]}
                />
                <DataChart
                    dateRange={dateRange}
                    chartData={chartData}
                    conf={[{ dataKey: 'TEMP', color: '#0ae800', legendName: 'Temperature' }]}
                />
                <OnOffChart
                    dateRange={dateRange}
                    chartData={chartData}
                    conf={[{ dataKey: 'FLOW', color: '#ffffff', interpolationType: 'step', legendName: 'Flow' }]}
                />
                <DataChart
                    dateRange={dateRange}
                    chartData={chartData}
                    conf={[
                        {
                            dataKey: 'VSDW',
                            legendName: 'Davey VSD Power (W)',
                            color: '#ff2000',
                            interpolationType: 'step',
                        },
                    ]}
                />
                {/*<JSONView data={chartData?.dataPoints?.map(d=>tickFormatter()(d['TIMESTAMP']))} />*/}
                {/*<JSONView data={chartData} />*/}
                {/*/!*<JSONView data={chartData.dataPoint} />*!/*/}
            </div>
        </div>
    );
};
