import _merge from 'lodash/merge';

export const deepMerge = _merge;

// export const hsl = (hue, sat, lum, alpha = 1) => {
//     return `hsl(${hue}, ${sat}%, ${lum}%, ${alpha})`;
// };

export const hslParams = (hue: number, sat: number, lum: number) => {
    return `hsl(${hue}, ${sat}%, ${lum}%)`;
};

export const hsl = (hue: number, sat: number, lum: number, alpha: number = 1) => {
    return `hsl(${hue}deg ${sat}% ${lum}% / ${alpha})`;
};
