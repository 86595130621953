'use client';

import * as React from 'react';
import { PropsWithChildren } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { clsxm } from '../../utils';

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
    React.ElementRef<typeof TooltipPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, children, ...props }, ref) => (
    <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
            ref={ref}
            sideOffset={sideOffset}
            className={clsxm('rounded-lg border bg-popover p-2 shadow', 'z-50 overflow-hidden', className)}
            {...props}
        >
            {children}
            <TooltipPrimitive.Arrow className="fill-popover-foreground" />
        </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const Tooltip: React.FC<PropsWithChildren<{ trigger: React.ReactNode }>> = ({ trigger, children }) => {
    return (
        <TooltipProvider delayDuration={100}>
            <TooltipRoot>
                <TooltipTrigger asChild>{trigger}</TooltipTrigger>
                <TooltipContent>{children}</TooltipContent>
            </TooltipRoot>
        </TooltipProvider>
    );
};

export { Tooltip, TooltipRoot, TooltipTrigger, TooltipContent, TooltipProvider };
