import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, clsxm, Icon, toastError } from '@ez/components';
import { NodeType, useMutationFileUpload } from '@poolware/api';
import Lightbox from 'yet-another-react-lightbox';
import { Frame, SectionHeader } from '../components';
import { PhotoItem, PhotoItemPreview } from './PhotoItem';
import { useDrop } from 'react-dnd';
import { FileDropzoneContainer } from './FileDropzoneContainer';
import { FileHandle, FileStatus, FileUploadFn } from '../../types';

const randomID = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};

const FileInternalDropzoneContainer: React.FC<{ onDrop: (item: any) => any }> = (props) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ['PHOTO'],
        drop: props.onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isDragActive = isOver && canDrop;

    return (
        <Frame bordered={true} tertiary={true} ref={drop} className={'relative'}>
            {props.children}

            <div
                className={clsxm([
                    'absolute inset-0 bg-[rgba(255,255,255,0.8)]',
                    'rounded p-4 border-dashed border-primary border-4',
                    'flex items-start justify-center',
                    isDragActive ? 'block' : 'hidden',
                    isDragActive && canDrop ? 'border-green' : 'border-red',
                ])}
            ></div>
        </Frame>
    );
};

const AddPhotosButton: React.FC<{ onClick: () => any }> = ({ onClick }) => {
    return (
        <button
            className={
                '!size-[110px] border border-solid border-gray-100 text-sm text-tertiary flex flex-col items-center justify-center rounded hover:bg-tertiary'
            }
            onClick={onClick}
        >
            <Icon name={'plus'} size={'large'} />
            <span className={'pt-2'}>Add files</span>
            <small>or drag & drop here</small>
        </button>
    );
};

export const PhotoGroup: React.FC<{
    label: React.ReactNode;
    files?: NodeType.FileUpload[];
    beforeAfterStatus?: NodeType.FileUploadBeforeAfterEnum;
    onDeleteFiles?: (filesToDelete: NodeType.FileUpload[]) => Promise<any>;
    onFileUploadRequest: FileUploadFn;
    refetchQueries?: any[];
}> = ({ label, files, onDeleteFiles, onFileUploadRequest, beforeAfterStatus, refetchQueries }) => {
    const { update: updateFile } = useMutationFileUpload({ refetchQueries });

    const [isSelecting, setIsSelecting] = useState<boolean>(false);
    const [openIndex, setOpenIndex] = useState(-1);
    const [selectedFiles, setSelectedFiles] = useState<NodeType.FileUpload[]>([]);

    const onDrop = async (item: NodeType.FileUpload) => {
        try {
            if (item.beforeAfterStatus !== beforeAfterStatus) {
                await updateFile({ id: item.id, beforeAfterStatus: beforeAfterStatus ?? null });
            }
        } catch (error) {
            console.error(error);
            toastError({ title: 'Error', description: error.message });
        }
    };

    const [tempFiles, setTempFiles] = useState<FileHandle[]>([
        // { tempId: '23', status: FileStatus.UPLOADED, file: { name: 'Test1-Test1-Test1-Test1-', size: 3444 } },
        // { tempId: '22', status: FileStatus.FAILED, file: { name: 'Test3', size: 12000000 }, error: "Too Many Requests" },
        // { tempId: '24', status: FileStatus.PENDING, file: { name: 'Test3', size: 123 } },
        // { tempId: '25', status: FileStatus.UPLOADING, file: { name: 'Test3', size: 123 } },
    ]);

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => tempFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    }, [tempFiles]);

    useEffect(() => {
        setSelectedFiles([]);
    }, [isSelecting]);

    const onFileSelectClick = (file: NodeType.FileUpload, index: number) => {
        if (selectedFiles.includes(file)) {
            return setSelectedFiles(selectedFiles.filter((f) => f !== file));
        } else {
            return setSelectedFiles([...selectedFiles, file]);
        }
    };

    const onFileOpenClick = (file: NodeType.FileUpload, index: number) => {
        setOpenIndex(index);
    };

    const handleOnDeleteFiles = async () => {
        if (!onDeleteFiles) {
            return;
        }
        if (!selectedFiles.length) {
            return;
        }
        await onDeleteFiles?.(selectedFiles);
        setSelectedFiles([]);
        setIsSelecting(false);
    };

    const hasSelection = selectedFiles.length > 0;

    const showEditButton = files?.length > 0;

    const startUpload = async (newFiles: FileHandle[]) => {
        setTempFiles((files) => [...files.filter((f) => f.status !== FileStatus.FAILED), ...newFiles]);

        const { success, failed } = await onFileUploadRequest(newFiles);
        setTempFiles((files) => {
            // mark failed files as failed or uploaded
            return files
                .map((f) => {
                    const failedFile = failed.find((ff) => ff.file.tempId === f.tempId);
                    if (failedFile) {
                        return { ...f, status: FileStatus.FAILED, error: failedFile.error.message };
                    }
                    const successFile = success.find((sf) => sf.file.tempId === f.tempId);
                    if (successFile) {
                        return { ...f, status: FileStatus.UPLOADED };
                    }
                    return f;
                })
                .filter((f) => f !== null);
        });
    };

    const onExternalFilesDrop = async (files: File[]) => {
        const newFiles = files.map<FileHandle>((file) => ({
            tempId: randomID(),
            status: FileStatus.PENDING,
            file: file,
            beforeAfterStatus,
            preview: URL.createObjectURL(file),
        }));
        await startUpload(newFiles);
    };

    const uploadingFiles = tempFiles.filter((f) => f.status !== FileStatus.UPLOADED);

    const lightboxSlides = files?.map((file) => ({
        src: file.url,
    }));

    return (
        <>
            <FileDropzoneContainer onDrop={onExternalFilesDrop}>
                {({ open }) => {
                    return (
                        <FileInternalDropzoneContainer onDrop={onDrop}>
                            <SectionHeader className={'flex flex-row gap-2 w-full px-0'}>
                                <span className={'flex-grow'}>{label}</span>
                                {showEditButton && (
                                    <Button
                                        size={'sm'}
                                        variant={'tertiary'}
                                        icon={'edit'}
                                        onClick={() => setIsSelecting(!isSelecting)}
                                    />
                                )}
                            </SectionHeader>

                            <div
                                className={
                                    'bg-panel-section rounded p-4 flex flex-row flex-wrap gap-x-2 gap-y-4 w-full pb-4 items-start justify-start'
                                }
                            >
                                {files?.map((file, index) => {
                                    const isSelected = selectedFiles.includes(file);
                                    return (
                                        <PhotoItem
                                            key={file.id}
                                            file={file}
                                            onClick={() =>
                                                isSelecting
                                                    ? onFileSelectClick(file, index)
                                                    : onFileOpenClick(file, index)
                                            }
                                            isSelected={isSelected}
                                            mode={isSelecting ? 'select' : 'view'}
                                        />
                                    );
                                })}
                                {uploadingFiles.map((file) => (
                                    <PhotoItemPreview file={file} key={file.tempId} />
                                ))}
                                {!isSelecting && <AddPhotosButton onClick={() => open()} />}
                            </div>

                            {isSelecting && (
                                <Button
                                    size={'md'}
                                    disabled={!hasSelection}
                                    icon={'trash'}
                                    onClick={handleOnDeleteFiles}
                                    color={'orange'}
                                >
                                    Delete Files
                                </Button>
                            )}
                        </FileInternalDropzoneContainer>
                    );
                }}
            </FileDropzoneContainer>
            <Lightbox
                index={openIndex}
                open={openIndex >= 0}
                close={() => {
                    setOpenIndex(-1);
                }}
                slides={lightboxSlides}
                // plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
            />
        </>
    );
};
