import React from 'react';
import { FormikConsumer } from 'formik';
import { DebugJSON, DevOnly } from '../dev-tools';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';

export const FormikFormDebug: React.FC<{ hidden?: boolean }> = ({ hidden = true }) => {
    const jsonExpandLevel = 2;

    if (hidden) {
        return null;
    }

    return (
        <DevOnly hidden={hidden}>
            <div className={'w-full bg-panel my-4 shadow clear-both'}>
                <div
                    style={{
                        textTransform: 'uppercase',
                        fontSize: 11,
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        fontWeight: 500,
                        padding: '.5rem',
                        background: '#555',
                        color: '#fff',
                        letterSpacing: '1px',
                    }}
                >
                    Formik State
                </div>
                <FormikConsumer>
                    {({ validationSchema, validate, ...rest }) => (
                        <>
                            {!_isEmpty(rest.errors) && (
                                <div className={'p-2'}>
                                    <div>Errors:</div>
                                    <pre className={'bg-orange-500 text-black text-sm p-1 rounded'}>
                                        {JSON.stringify(rest.errors, null, 2)}
                                    </pre>
                                </div>
                            )}
                            <div className={'p-2 text-sm'}>
                                <DebugJSON
                                    sortObjectKeys={(a, b) => {
                                        return a > b ? 1 : -1;
                                    }}
                                    data={{ values: rest.values, bag: rest, initialValues: rest.initialValues }}
                                    shouldExpandNode={(key, data, level) => {
                                        if (_isArray(key) && key[0] === 'bag') return false;
                                        return level < jsonExpandLevel;
                                    }}
                                />
                            </div>
                        </>
                    )}
                </FormikConsumer>
            </div>
        </DevOnly>
    );
};
