import { NodeType } from '@poolware/api';
import * as React from 'react';
import { DocumentNode } from 'graphql';
import { deepMerge, FormFieldSuggestInput, FormFieldSuggestInputProps, Optionable } from '@ez/components';
import { useDefaultConnectionState, useQueryConnection } from '@ez/api-core';
import { ConnectedSuggestInputBaseConfig } from './types';

export type CreateSuggestInputWithQueryConfig<
    NodeItemType extends NodeType.AnyNode,
    SearchType = void,
    ExtProps = void
> = ConnectedSuggestInputBaseConfig<NodeItemType, SearchType, ExtProps> & {
    gqlQuery: DocumentNode;
    gqlQueryConnectionPath: string;
};

export const createSuggestInputWithQuery = <
    NodeItemType extends NodeType.AnyNode,
    ApiSearchType = void,
    ExtProps = any
>(
    config: CreateSuggestInputWithQueryConfig<NodeItemType, ApiSearchType, ExtProps>
) => {
    const ConnectedSuggestInput: React.FC<
        FormFieldSuggestInputProps<NodeItemType> & {
            search?: ApiSearchType;
            customProps?: ExtProps;
        }
    > = ({ search: extSearch, customProps, ...props }) => {
        const [queryString, setQueryString] = React.useState<string>('');

        const _search: ApiSearchType = deepMerge(
            extSearch,
            config.mapQueryToSearch?.(queryString),
            config.mapCustomPropsToSearch?.(customProps)
        );

        const { variables, connectionState } = useDefaultConnectionState<ApiSearchType>({
            defaultPageSize: 100,
            search: _search,
            ...props,
        });

        const { connectionState: cs, connectionData } = useQueryConnection<NodeItemType>({
            query: config.gqlQuery,
            connectionPath: config.gqlQueryConnectionPath,
            connectionConfig: connectionState,
            variables: variables,
        });

        const options: Optionable<NodeItemType>[] =
            connectionData?.map((item) => ({
                value: item,
                text: config.getOptionLabel(item),
            })) || [];

        return (
            <FormFieldSuggestInput
                getOptionValue={(value: Optionable<any>['value']) => value?.id}
                formatOptionLabel={config.formatOptionLabel}
                loading={cs?.loading}
                options={options}
                onInputChange={setQueryString}
                {...props}
            />
        );
    };

    return ConnectedSuggestInput;
};
