import { OperationVariables } from '@apollo/react-common';
import { NodeType, QueryConnectionExternalProps } from '@poolware/api';
import { QueryHookOptions } from '@apollo/react-hooks';
import { useQueryEZCDeviceState } from './use-query-ezc-device-state';
import { resolveDeviceAlarmStatus } from './utils';
import { EZCDevicesLinkWithState } from './types';
import { useQueryEzcDeviceLink } from './use-query-ezc-device-link';
import { useQueryEzcDeviceStates } from './use-query-ezc-device-states';
import { useQueryEzcDeviceLinksConnection } from './use-query-ezc-device-link-connection';

const createDeviceWithState = (input: {
    deviceLink: NodeType.EZCDeviceLink;
    deviceState: NodeType.EZCDeviceState;
    deviceStateLoading?: boolean;
}): EZCDevicesLinkWithState => {
    const { deviceLink, deviceState, deviceStateLoading } = input;
    const alarmStatus = resolveDeviceAlarmStatus(deviceLink, deviceState);

    const components = Object.keys(deviceState?.state || {});
    const isAlive = components.some((component) => deviceState?.state[component]?.isAlive);

    return {
        ...deviceLink,
        deviceState,
        alarmStatus,
        isAlive,
        isLoadingShadow: deviceStateLoading,
    };
};

const POLL_INTERVAL = 15000;

export function useQueryDevice<TVariables = OperationVariables>(
    id: NodeType.ID,
    options?: QueryHookOptions<any, TVariables>
) {
    const { node: deviceLink, ...restDeviceLink } = useQueryEzcDeviceLink(id, options);

    const { deviceState, loading, error, ...restState } = useQueryEZCDeviceState(deviceLink, {
        pollInterval: POLL_INTERVAL,
    });

    const deviceWithState = createDeviceWithState({
        deviceLink,
        deviceState,
        deviceStateLoading: loading,
    });

    return {
        device: deviceWithState,
        refetchQueries: [restDeviceLink.refetchQuery, restState.refetchQuery],
        ...restDeviceLink,
    };
}

export function useQueryDevices(
    props?: QueryConnectionExternalProps<NodeType.EZCDeviceLink, NodeType.EZCDeviceLinkViewerEzcDeviceLinksSearch>
) {
    const { connectionData, connectionState, refetchQuery } = useQueryEzcDeviceLinksConnection(props);
    const { deviceStates, loading } = useQueryEzcDeviceStates(connectionData, { pollInterval: POLL_INTERVAL });

    const deviceLinksWithState = connectionData?.map<EZCDevicesLinkWithState>((deviceLink) => {
        const deviceState = deviceStates?.find((state) => state.device?.id === deviceLink.device?.id);
        return createDeviceWithState({
            deviceLink,
            deviceState,
            deviceStateLoading: loading,
        });
    });

    return {
        connectionData: deviceLinksWithState,
        connectionState,
        refetchQuery,
    };
}
