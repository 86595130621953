import * as React from 'react';
import { useState } from 'react';
import { useField } from 'formik';
import { InvoiceDoc } from '../constants';
import { FormGroup, FormikInputField, FormSectionBody, FormSectionHeader, PageLayout } from '@ez/components';
import { PanelItem } from '../View';

export const FormikInvoiceSender: React.FC = () => {
    const [field] = useField<InvoiceDoc.DocContent['sender']>('sender');
    const sender = field.value;
    const [edit, setEdit] = useState(false);

    const onEditPayment = () => {
        setEdit(true);
    };

    const isEditMode = edit || !sender;

    return (
        <>
            <FormSectionHeader button={!edit && { content: 'Edit', onClick: onEditPayment }}>Sender</FormSectionHeader>
            {isEditMode ? (
                <FormSectionBody>
                    <FormGroup>
                        <FormikInputField label={'Company Name'} name={'sender.company.name'} />
                        <FormikInputField label={'ABN'} name={'sender.company.abn'} />
                    </FormGroup>
                    <FormGroup>
                        <FormikInputField label={'Phone'} name={'sender.company.phone'} />
                        <FormikInputField label={'Email'} name={'sender.company.email'} />
                    </FormGroup>
                    <FormikInputField label={'Address'} name={'sender.company.address'} />
                </FormSectionBody>
            ) : (
                <PageLayout.BodySection>
                    <div>{sender?.company?.name}</div>
                    <div>ABN: {sender?.company?.abn}</div>
                    <div>{sender?.company?.address}</div>
                    <div className={'py-2'} />
                    <div>Ph: {sender?.company?.phone}</div>
                    <div>Email: {sender?.company?.email}</div>
                </PageLayout.BodySection>
            )}
        </>
    );
};

export const FormikInvoicePaymentMethod: React.FC = () => {
    const [field] = useField<InvoiceDoc.DocContent['paymentOption']>('paymentOption');
    const paymentOption = field.value;
    const [edit, setEdit] = useState(false);

    const onEditPayment = () => {
        setEdit(true);
    };

    return (
        <>
            <FormSectionHeader button={!edit && { content: 'Edit', onClick: onEditPayment }}>
                Payment Option
            </FormSectionHeader>
            {edit ? (
                <FormSectionBody>
                    <FormGroup>
                        <FormikInputField label={'Bank'} name={'paymentOption.bank.bankName'} />
                        <FormikInputField label={'BSB'} name={'paymentOption.bank.bankBSB'} />
                    </FormGroup>
                    <FormGroup>
                        <FormikInputField label={'Account No'} name={'paymentOption.bank.accountNumber'} />
                        <FormikInputField label={'Account Name'} name={'paymentOption.bank.accountName'} />
                    </FormGroup>
                </FormSectionBody>
            ) : (
                <PageLayout.BodySection>
                    <div>Bank details</div>
                    <PanelItem label={'Bank'}>{paymentOption?.bank?.bankName}</PanelItem>
                    <PanelItem label={'BSB'}>{paymentOption?.bank?.bankBSB}</PanelItem>
                    <PanelItem label={'Account No'}>{paymentOption?.bank?.accountNumber}</PanelItem>
                    <PanelItem label={'Account Name'}>{paymentOption?.bank?.accountName}</PanelItem>
                </PageLayout.BodySection>
            )}
        </>
    );
};
