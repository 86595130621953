import * as React from 'react';
import {
    ConnectionErrorMessage,
    FormGroup,
    FormikInputField,
    FormikPanelForm,
    FormSectionHeader,
    PageSkeletonLoader,
    toastError,
} from '@ez/components';
import { InvoiceDoc } from '../constants';
import {
    useMutateInvoiceDefaultSenderSet,
    useQueryInvoiceDefaultSender,
} from '../queries/use-query-invoice-default-settings';

export const InvoiceSettingsDefaultSender: React.FC = ({}) => {
    const { data, loading, error, refetchQuery } = useQueryInvoiceDefaultSender();
    const setSender = useMutateInvoiceDefaultSenderSet({ refetchQueries: [refetchQuery] });
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    }

    let initialValues: InvoiceDoc.DocContent['sender'] = {
        company: {
            name: '',
            abn: '',
            address: '',
            email: '',
            phone: '',
        },
        person: {
            name: '',
            email: '',
            phone: '',
        },
        ...data,
    };

    const onUpdate = async (values: typeof initialValues) => {
        try {
            await setSender(values);
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <FormikPanelForm debug={true} header={'Default Sender'} onSubmit={onUpdate} initialValues={initialValues}>
            <FormSectionHeader>Company</FormSectionHeader>
            <FormGroup>
                <FormikInputField label={'Company Name'} name={'company.name'} />
                <FormikInputField label={'ABN'} name={'company.abn'} />
            </FormGroup>
            <FormikInputField label={'Address'} name={'company.address'} />
            <FormGroup>
                <FormikInputField label={'Email'} name={'company.email'} />
                <FormikInputField label={'Phone'} name={'company.phone'} />
            </FormGroup>
            <FormSectionHeader>Person</FormSectionHeader>
            <FormikInputField label={'Name'} name={'person.name'} />
            <FormGroup>
                <FormikInputField label={'email'} name={'person.email'} />
                <FormikInputField label={'phone'} name={'person.phone'} />
            </FormGroup>
        </FormikPanelForm>
    );
};
