import * as React from 'react';
import {
    Button,
    FormikDefaultFormBase,
    FormikFormDebug,
    FormikInputField,
    MenuBar,
    PageLayout,
    Panel,
    StickyMenuBar,
    toastError,
} from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { FormikPanelEZCDevicePicker } from './EZCDevicePicker';
import { FormikCustomerAndLocationInputFields } from '@poolware/app-shell';
import { NodeType, useMutationEZCDeviceLink } from '@poolware/api';

import * as Yup from 'yup';
import { useFormikContext } from 'formik';

const validationSchema = Yup.object().shape({
    device: Yup.mixed().required('Required'),
    customer: Yup.mixed().notRequired().nullable(),
    pool: Yup.mixed().notRequired().nullable(),
    label: Yup.string().notRequired().nullable().min(1).max(256),
});

type WizardFormikValue = {
    step: WizardStep;
    device: NodeType.EZCDevice | null;
    customer: NodeType.Customer | null;
    pool: NodeType.Pool | null;
    label?: string;
};

enum WizardStep {
    DevicePicker = 'DevicePicker',
    CustomerAndLocation = 'CustomerAndLocation',
}

const WizardContent: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
    const formik = useFormikContext<WizardFormikValue>();
    const wizardStep = formik.values.step;

    const setNextStep = (step: WizardStep) => {
        formik.setFieldValue('step', step);
    };

    return (
        <Panel>
            <Panel.Header content={'Register Device'} />
            {wizardStep === WizardStep.DevicePicker && (
                <Panel.Body>
                    <FormikPanelEZCDevicePicker
                        name={'device'}
                        onCancel={onCancel}
                        onDidSelect={(device) => {
                            formik.setFieldValue('label', device.title);
                            setNextStep(WizardStep.CustomerAndLocation);
                        }}
                    />
                </Panel.Body>
            )}
            {wizardStep === WizardStep.CustomerAndLocation && (
                <>
                    <Panel.Body>
                        <Panel.Item label={'Device'}>
                            <div className={'flex flex-row justify-between w-full items-center'}>
                                <div>{formik.values.device?.title}</div>
                                <Button
                                    content={'Change'}
                                    color={'tertiary'}
                                    variant={'secondary'}
                                    size={'xs'}
                                    onClick={() => {
                                        formik.setFieldValue('device', null);
                                        setNextStep(WizardStep.DevicePicker);
                                    }}
                                />
                            </div>
                        </Panel.Item>
                        <Panel.Divider />
                        <FormikInputField label={'Label'} name={'label'} />
                        <FormikCustomerAndLocationInputFields />
                    </Panel.Body>
                    <Panel.Footer>
                        <Button content={'Cancel'} onClick={onCancel} />
                        <Button
                            content={'Register'}
                            variant={'primary'}
                            onClick={(e) => formik.handleSubmit(e as any)}
                        />
                    </Panel.Footer>
                </>
            )}
        </Panel>
    );
};

export const NewPage: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { create } = useMutationEZCDeviceLink();
    const initialValues: WizardFormikValue = {
        step: WizardStep.DevicePicker,
        device: null,
        customer: null,
        pool: null,
        label: '',
    };
    const onSubmit = async (values: WizardFormikValue) => {
        try {
            await create({
                label: values.label?.trim(),
                device: values.device?.id,
                owner: values.customer?.id,
                pool: values.pool?.id,
            });
            AppNavigator.navigateToOrigin('/', { relativeToModule: true });
        } catch (e) {
            toastError(e);
        }
    };

    const onCancel = () => {
        AppNavigator.navigateToOrigin('/', { relativeToModule: true });
    };

    return (
        <PageLayout sectionWidth={'screen-md'}>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item
                        icon={'chevron left'}
                        content={'Cancel'}
                        onClick={() => AppNavigator.navigateToOrigin('/', { relativeToModule: true })}
                    />
                    <MenuBar.HeaderItem content={'Register Device'} />
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>{/*<MenuBar.Item content={'Item 1'}/>*/}</MenuBar.Section>
            </StickyMenuBar>
            <PageLayout.BodySection>
                <FormikDefaultFormBase
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    <WizardContent onCancel={onCancel} />
                    <FormikFormDebug hidden={false} />
                </FormikDefaultFormBase>
            </PageLayout.BodySection>
        </PageLayout>
    );
};
