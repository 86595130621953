import * as React from 'react';
import { defaultOptionValueComparatorFn } from '../optionable';
import { cn, useId } from '../utils';
import { SegmentedButtonRadixProps, SegmentedSelectButtons } from '../buttons';
import { FormFieldLabel } from './FormFieldLabel';
import { FormFieldBaseProps } from './FormFieldTypes';
import { FormField } from './FormField';

export interface FormFieldSegmentedSelectButtons<V = any> extends SegmentedButtonRadixProps<V>, FormFieldBaseProps {}

export const FormFieldSegmentedSelectButtons: React.FC<FormFieldSegmentedSelectButtons> = (props) => {
    const {
        label,
        fluid = true,
        className,
        readOnly,
        required,
        id,
        error,
        disabled,
        valueComparator = defaultOptionValueComparatorFn,
        ...rest
    } = props;
    const fieldId = useId(id);

    return (
        <FormField className={cn([fluid ? 'w-full' : 'w-fit', className])}>
            <FormFieldLabel
                readOnly={readOnly}
                disabled={disabled}
                required={required}
                label={label}
                errorLabel={error}
                htmlFor={fieldId}
            />
            <SegmentedSelectButtons //
                aria-invalid={!!error}
                disabled={disabled || readOnly}
                valueComparator={valueComparator}
                fluid={fluid}
                {...rest}
            />
        </FormField>
    );
};
