import * as React from 'react';
import { Panel, toastError } from '@ez/components';
import { DropdownStaff } from '@poolware/app-shell';
import { useWorkOrderViewContext } from './WorkOrderPageContext';
import { NodeType } from '@poolware/api';

export interface WorkOrderStatusPanelProps {
    readonly?: boolean;
}

export const WorkOrderStaffPanel: React.FC<WorkOrderStatusPanelProps> = ({ readonly }) => {
    const { workOrderMutators, workOrder } = useWorkOrderViewContext();

    const onChangeStaff = async (newStaff: NodeType.Staff) => {
        try {
            await workOrderMutators.update(
                {
                    id: workOrder.id,
                    assignedTo: newStaff?.id || null,
                },
                { awaitRefetchQueries: true }
            );
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <Panel>
            <Panel.Header icon={'spy'} content={'Staff'} />
            <Panel.Body>
                <Panel.Item label={'Assigned To'} labelWidth={130}>
                    <DropdownStaff readonly={readonly} value={workOrder?.assignedTo?.id} onChange={onChangeStaff} />
                </Panel.Item>
            </Panel.Body>
        </Panel>
    );
};
