import moment from 'moment';
import { NodeTypeAddress, NodeTypeUser } from '../external-types';
import _truncate from 'lodash/truncate';

export const formatUserName = (user: NodeTypeUser, defaultString: string = '--'): string => {
    if (!user) return defaultString;
    if (!user.firstName && !user.lastName) return defaultString;

    const title = user.title;
    const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;

    if (title) {
        return `${title} ${fullName}`;
    } else {
        return fullName;
    }
};

export const formatEntityName = (entity: any, defaultString: string = '--', truncateLength?: number): string => {
    if (!entity) return defaultString;

    let name;
    if (entity.user) {
        name = formatUserName(entity.user, defaultString);
    } else {
        name = formatUserName(entity, defaultString);
    }
    if (truncateLength) {
        return _truncate(name, { length: truncateLength });
    } else {
        return name;
    }
};

export const formatPhoneNumber = (phone: string, separator: string = ' '): string => {
    if (!phone) {
        return '';
    }

    try {
        // remove spaces
        phone = phone.replace(/\s/g, '');
        phone = phone.trim();

        if (phone.length == 12 && phone.startsWith('+')) {
            // Handle +61xxxxx type phone numbers
            // +61412345678=> +61-412-345-678
            return (
                `${phone.substr(0, 3)}` +
                `${separator}${phone.substr(3, 3)}${separator}${phone.substr(6, 3)}${separator}${phone.substr(9, 3)}`
            );
        }

        const isnum = /^\d+$/.test(phone);
        if (!isnum) {
            // Don't format numbers if there is at least one non-digit symbol in the number.
            // Some phone numbers are formatted as '1234.456.890'. Ignoring this case.
            return phone;
        }

        if (phone.length == 6) {
            return `${phone.substr(0, 3)}${separator}${phone.substr(3, 3)}`;
        } else if (phone.length == 7) {
            return `${phone.substr(0, 3)}${separator}${phone.substr(3, 4)}`;
        } else if (phone.length == 8) {
            return `${phone.substr(0, 4)}${separator}${phone.substr(4, 4)}`;
        } else if (phone.length == 9) {
            return `${phone.substr(0, 3)}${separator}${phone.substr(3, 3)}${separator}${phone.substr(6, 3)}`;
        } else if (phone.length === 10) {
            if (phone.startsWith('02')) {
                return `${phone.substr(0, 2)}${separator}${phone.substr(2, 4)}${separator}${phone.substr(6, 4)}`;
            } else {
                return `${phone.substr(0, 4)}${separator}${phone.substr(4, 3)}${separator}${phone.substr(7, 3)}`;
            }
        } else if (phone.length === 11) {
            if (phone.startsWith('014')) {
                // Satellite phone numbers
                return `${phone.substr(0, 4)}${separator}${phone.substr(4, 4)}${separator}${phone.substr(8, 3)}`;
            } else {
                return `${phone.substr(0, 3)}${separator}${phone.substr(3, 4)}${separator}${phone.substr(7, 4)}`;
            }
        } else if (phone.length === 12) {
            return `${phone.substr(0, 4)}${separator}${phone.substr(4, 4)}${separator}${phone.substr(8, 4)}`;
        }
    } catch (e) {
        console.error(e);
        return phone;
    }

    return phone;
};

export const formatAddress = (address: NodeTypeAddress, defaultString: string = '--'): string => {
    if (!address) return defaultString;

    const POBox = address['poBoxNumber'];

    let addressComponents = [
        'destinationName',
        'buildingName',
        'streetNumber',
        'streetName',
        'suburb',
        'city',
        'state',
        'postCode',
        'country',
    ]
        .filter((key) => address[key] != null)
        .map((key) => address[key]?.trim())
        .filter((i) => !!i); // remove empty strings

    let addressString = addressComponents.join(', ');
    if (POBox) {
        return `PO Box: ${POBox}, ${addressString}`;
    }
    return addressString;
};

export const formatDate = (date: Date, format: string = 'LLL') => {
    return moment(date).format(format);
};
