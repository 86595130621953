import * as React from 'react';
import { LifeguardTimerDays, LifeGuardTimerType } from '../../../../common';
import { AttributeModel } from '../AttributeRenderer';

const DisplayTimerItem: React.FC<{ label: React.ReactNode; value: React.ReactNode }> = ({ label, value }) => {
    return (
        <div
            className={
                'flex flex-row gap-2 items-center flex-nowrap bg-panel-section text-panel-foreground rounded px-1'
            }
        >
            <div className={'font-bold'}>{label}</div>
            <div className={'text-accent'}>{value}</div>
        </div>
    );
};

export const formatLGTime = (time?: { hour?: number; minute?: number }) => {
    // format hour and minute with leading zero
    const hour = time?.hour?.toString().padStart(2, '0') || '--';
    const minute = time?.minute?.toString().padStart(2, '0') || '--';
    return `${hour}:${minute}`;
};

const AllDays: LifeguardTimerDays[] = [
    LifeguardTimerDays.MONDAY,
    LifeguardTimerDays.TUESDAY,
    LifeguardTimerDays.WEDNESDAY,
    LifeguardTimerDays.THURSDAY,
    LifeguardTimerDays.FRIDAY,
    LifeguardTimerDays.SATURDAY,
    LifeguardTimerDays.SUNDAY,
];

const DisplayDays: React.FC<{ days: LifeguardTimerDays[] }> = ({ days }) => {
    return (
        <div className={'flex flex-row flex-nowrap gap-1'}>
            {AllDays.map((day) => {
                // NOTE: days are not implemented in the Lifeguard V1
                // Treat all days as present
                const isPresent = true; /// days.includes(day);
                return (
                    <span key={day} className={isPresent ? '' : 'opacity-40 line-through'}>
                        {day.slice(0, 3)}
                    </span>
                );
            })}
        </div>
    );
};

export const DisplayLGTimer: React.FC<{ timerAttr: AttributeModel<LifeGuardTimerType> }> = ({ timerAttr }) => {
    const timer = timerAttr?.attribute?.reported;
    if (!timer) return null;
    const onTime = formatLGTime(timer.from);
    const offTime = formatLGTime(timer.to);
    const days = timer.days;
    return (
        <div className={'flex flex-row gap-x-4 flex-wrap text-sm'}>
            <DisplayTimerItem label={'ON'} value={onTime} />
            <DisplayTimerItem label={'OFF'} value={offTime} />
            <DisplayTimerItem label={'Speed'} value={timer.pumpSpeed || '--'} />
            <DisplayTimerItem label={'Days'} value={<DisplayDays days={days} />} />
        </div>
    );
};
