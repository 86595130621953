import { NodeType } from '@poolware/api';
import * as React from 'react';
import { deepMerge, FormFieldSuggestInput, FormFieldSuggestInputProps, Optionable } from '@ez/components';
import { ConnectedSuggestInputBaseConfig } from './types';

export type SuggestInputWithQueryConnectionHookConfig<
    NodeItemType extends NodeType.AnyNode,
    SearchType = void,
    ExtProps = void,
    Hook extends Function = any
> = ConnectedSuggestInputBaseConfig<NodeItemType, SearchType, ExtProps> & {
    useQueryConnectionHook: Hook;
};

export const createSuggestInputWithQueryConnectionHook = <
    NodeItemType extends NodeType.AnyNode,
    ApiSearchType = void,
    ExtProps = any,
    Hook extends Function = any
>(
    config: SuggestInputWithQueryConnectionHookConfig<NodeItemType, ApiSearchType, ExtProps, Hook>
) => {
    const ConnectedSuggestInput: React.FC<
        FormFieldSuggestInputProps<NodeItemType> & {
            search?: ApiSearchType;
            customProps?: ExtProps;
        }
    > = ({ search: extSearch, customProps, ...props }) => {
        const [queryString, setQueryString] = React.useState<string>('');

        const _search: ApiSearchType = deepMerge(
            extSearch,
            config.mapQueryToSearch?.(queryString),
            config.mapCustomPropsToSearch?.(customProps)
        );

        // TODO: fix typings
        // @ts-ignore
        const { connectionState: cs, connectionData } = config.useQueryConnectionHook({
            search: _search,
        });

        const options: Optionable<NodeItemType>[] =
            connectionData?.map((item) => ({
                value: item,
                text: config.getOptionLabel(item),
            })) || [];

        return (
            <FormFieldSuggestInput
                getOptionValue={(value: Optionable<any>['value']) => value?.id}
                formatOptionLabel={config.formatOptionLabel}
                loading={cs?.loading}
                options={options}
                onInputChange={setQueryString}
                {...props}
            />
        );
    };

    return ConnectedSuggestInput;
};

/**
 * Usage example:
 *
 * export const SuggestStaffFromHook = createSuggestInputWithQueryConnection<NodeType.Staff, NodeType.StaffViewerStaffSearch>({
 *     useQueryConnectionHook: useQueryStaffConnection,
 *     mapQueryToSearch: (query) => {
 *         return {
 *             user: {
 *                 anyString: [query],
 *             },
 *         };
 *     },
 *     getOptionLabel: (item) => stringFormatters.formatEntityName(item),
 * })
 *
 */
