import * as React from 'react';
import { compose, mapProps } from '@ez/tools';
import {
    ConfirmButton,
    ConnectionTableDef,
    DefaultConnectionTable,
    Form,
    FormikInputField,
    Icon,
    PageLayout,
    Panel,
    SectionHeader,
    toastError,
    withApolloLoading,
} from '@ez/components';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { NodeType } from '@poolware/api';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FranchiseEditableItem, ProductCompanyEditableItem } from '../Products/View/EditableItems';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { brandFragment } from '../../queries/QueryBrandConnection';
import {
    Brand_UpdateInput,
    IProductCatalogMutators,
    QueryProductConnection,
    withProductCatalogMutators,
} from '../../queries';

const BrandDescriptionFormPanel: React.FC<{ brand: NodeType.Brand; ProductCatalogMutator }> = ({
    brand,
    ProductCatalogMutator,
}) => {
    const initialValues = brand;

    const onSubmit = async (values: typeof initialValues, actions: FormikActions<typeof initialValues>) => {
        try {
            actions.setSubmitting(true);
            const updateFields = {
                name: values.name,
                identification: values.identification,
            };
            await ProductCatalogMutator.updateBrand(brand, updateFields);
            actions.resetForm({ values: { ...brand, ...updateFields } });
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e });
            toastError({ title: 'Failed to update', description: e.message });
        }
        actions.setSubmitting(false);
    };

    return (
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting, resetForm, dirty }) => {
                const buttons = [];
                if (dirty) {
                    buttons.push({
                        content: 'Cancel',
                        disabled: isSubmitting,
                        onClick: () => resetForm(),
                    });
                    buttons.push({
                        type: 'submit',
                        content: 'Save',
                        loading: isSubmitting,
                        disabled: isSubmitting,
                        onClick: handleSubmit,
                    });
                }
                return (
                    <Form>
                        <Panel>
                            <Panel.Header content={'Brand'} button={buttons} />
                            <Panel.Body>
                                <FormikInputField fluid label="Brand Name" name={'name'} />
                                <FormikInputField fluid label="Brand Identification" name={'identification'} />
                            </Panel.Body>
                        </Panel>
                    </Form>
                );
            }}
        </Formik>
    );
};

class ViewBrand extends React.Component<PageControlProps> {
    handleOnDelete = async (brand: NodeType.Brand) => {
        try {
            await this.props.mutateBrand.delete({ id: brand.id });
            this.props.onDelete && this.props.onDelete();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    onUpdate = (name: keyof Brand_UpdateInput) => async (newValue: string) => {
        const { brand } = this.props;
        try {
            const conf = {
                [name]: newValue,
            };
            await this.props.ProductCatalogMutator.updateBrand(brand, conf);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    render() {
        const { brand, onDelete } = this.props;
        const { brandId } = this.props.params;

        const button = onDelete && (
            <ConfirmButton
                icon={'trash'}
                onClick={() => this.handleOnDelete(brand)}
                confirmMessage={{
                    header: 'Delete Brand?',
                }}
                negative={true}
                confirmButton={{
                    icon: 'trash',
                    content: 'Delete',
                }}
            />
        );

        const tableDef: ConnectionTableDef<NodeType.Product> = [
            {
                header: 'SKU',
                cellProps: {
                    width: 2,
                },
                cell: (pd) => _.get(pd, 'sku', '--'),
            },
            {
                header: 'Name',
                cell: (pd) => <Link to={`/product-catalog/products/${pd.id}`}>{_.get(pd, 'name', '--')}</Link>,
            },
            {
                header: '',
                cell: (pd) => {
                    const { franchise } = pd;
                    if (franchise) {
                        return (
                            <div>
                                <Icon name={'building'} />
                                {franchise.name}
                            </div>
                        );
                    }
                    return null;
                },
            },
        ];

        return (
            <PageLayout>
                <PageLayout.TwoColumns>
                    <BrandDescriptionFormPanel brand={brand} ProductCatalogMutator={this.props.ProductCatalogMutator} />
                    <Panel>
                        <Panel.Header content={`Owner`} button={button} />
                        <Panel.Body>
                            <Panel.Item label={'Product Company'}>
                                <ProductCompanyEditableItem
                                    value={brand.company}
                                    onChange={(value) => this.onUpdate('company')(value)}
                                />
                            </Panel.Item>
                            <Panel.Item label={'Franchise'}>
                                <div style={{ maxWidth: '400px' }}>
                                    <FranchiseEditableItem
                                        value={brand.franchise}
                                        onChange={(value) => this.onUpdate('franchise')(value)}
                                    />
                                </div>
                            </Panel.Item>
                        </Panel.Body>
                    </Panel>
                </PageLayout.TwoColumns>

                <SectionHeader size={'small'} content={`Products associated with "${brand.name}"`} />

                <QueryProductConnection brandId={brandId}>
                    {({ connectionData, connectionState }) => {
                        return (
                            <DefaultConnectionTable
                                connectionData={connectionData}
                                connectionState={connectionState}
                                tableDef={tableDef}
                                noDataComponent={<span>No products</span>}
                            />
                        );
                    }}
                </QueryProductConnection>
            </PageLayout>
        );
    }
}

const QL = gql`
    query QueryBrandNode($id: ID!) {
        brand: node(id: $id) {
            ... on Brand {
                id
                ...BrandFragment
            }
        }
    }
    ${brandFragment}
`;

export default compose(
    withAppNavigator(),
    graphql(QL, {
        options: (props: IAppNavigatorProps) => {
            return {
                variables: { id: props.params.brandId },
            };
        },
    }),
    withApolloLoading({ show404ForPath: 'data.brand.id' }),
    mapProps(({ data, ...props }) => {
        const brand = _.get(data, 'brand');
        return {
            ...props,
            brand,
        };
    }),
    withProductCatalogMutators(['BrandsList', 'QueryBrandNode'])
)(ViewBrand);

interface PageControlProps extends IAppNavigatorProps, IProductCatalogMutators {
    brand: NodeType.Brand;
    onDelete: () => any;
}
