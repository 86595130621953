import * as React from 'react';
import invariant from 'invariant';
import moment from 'moment';

import { formatAddress, formatDate, formatEntityName, formatPhoneNumber } from '../string-formatters';
import _round from 'lodash/round';
import { GoogleMapIcon } from './GoogleMapIcon';
import {
    NodeTypeAddress,
    NodeTypeContact,
    NodeTypeContactType,
    NodeTypeCustomer,
    NodeTypeEntity,
} from '../external-types';
import { Icon } from '../Icon';

export interface SpanProps<P> extends React.HTMLAttributes<HTMLSpanElement> {
    value?: P;
    defaultString?: string;
}

const createSpan =
    <P extends any = any>(formatter) =>
    (input: SpanProps<P>) => {
        const { value, defaultString, ...rest } = input;
        const formattedValue = formatter(value) || defaultString;
        return <span {...rest}>{formattedValue}</span>;
    };

export const DisplaySpan: React.FC<SpanProps<any>> = createSpan((value) => value);

export type SpanStringValueType = SpanProps<string>;

export interface DisplayEmailProps extends SpanStringValueType {
    noLink?: boolean;
}

export const DisplayEmail: React.FC<DisplayEmailProps> = ({ value, noLink, ...rest }) => {
    const formattedValue = value;
    if (!formattedValue) {
        return null;
    }
    return <span {...rest}>{noLink ? formattedValue : <a href={`mailto:${value}`}>{value}</a>}</span>;
};

export interface DisplayPhoneProps extends SpanStringValueType {
    noLink?: boolean;
}

export const DisplayPhone: React.FC<DisplayPhoneProps> = ({ value, noLink, ...rest }) => {
    const formattedValue = formatPhoneNumber(value);
    if (!formattedValue) {
        return null;
    }
    return (
        <span {...rest}>
            {noLink ? (
                formattedValue
            ) : (
                <a className={'text-nowrap'} href={`tel:${value}`}>
                    {formattedValue}
                </a>
            )}
        </span>
    );
};

export interface DisplayEntityProps {
    value: Partial<NodeTypeEntity | NodeTypeCustomer>;
    defaultString?: string;
    truncateLength?: number;
    className?: string;
}

export const DisplayEntity: React.FC<DisplayEntityProps> = ({ value, defaultString, truncateLength, ...rest }) => {
    const formattedValue = formatEntityName(value, defaultString, truncateLength);
    return <span {...rest}>{formattedValue}</span>;
};

export const DisplayAddress: React.FC<SpanProps<NodeTypeAddress> & { showMapLink?: boolean }> = ({
    value,
    showMapLink = false,
    defaultString,
    ...rest
}) => {
    const formattedValue = formatAddress(value, defaultString);
    if (!formattedValue) {
        return null;
    }

    const googleMapLink = showMapLink && value && encodeURIComponent(formattedValue);

    return (
        <span {...rest}>
            {formattedValue}
            {googleMapLink && (
                <a
                    className={
                        'no-print bg-tertiary text-sm hover:bg-primary-25 mx-1 border rounded border-tertiary px-1 break-normal whitespace-nowrap print:hidden'
                    }
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    href={`https://www.google.com/maps/search/?api=1&query=${googleMapLink}`}
                >
                    MAP <GoogleMapIcon style={{ height: '1.1em', display: 'inline-block' }} />
                </a>
            )}
        </span>
    );
};

export const DisplayContactItem: React.FC<{ noLink?: boolean } & SpanProps<Partial<NodeTypeContact>>> = ({
    value,
    noLink,
    ...rest
}) => {
    const lookupFormatter = (contactType: NodeTypeContactType) => {
        let { validatorTag = 'default' } = contactType;

        const t: any = {
            PHONE: DisplayPhone,
            EMAIL: DisplayEmail,
            default: DisplaySpan,
        };

        return t[validatorTag] || t['default'];
    };

    if (!value || !value.type || !value.data) {
        return <DisplaySpan {...rest} />;
    }

    const C = lookupFormatter(value.type);
    return <C noLink={noLink} value={value.data} {...rest} />;
};

export interface DateProps extends React.HTMLAttributes<HTMLSpanElement> {
    value?: Date | null;
    format?: string;
    defaultString?: string | React.ReactNode;
}

export const DisplayDate: React.FC<DateProps> = (input) => {
    const { value, format = 'LLL', defaultString = '', ...rest } = input;
    const formatted = value ? formatDate(value, format) : defaultString;
    return <span {...rest}>{formatted}</span>;
};

export interface TimeRangeFormatterInput {
    startDate: Date;
    end?: Date;
    duration?: number;
    dayFormat?: string;
    timeFormat?: string;
    separator?: any;
}

const timeRangeFormatter = (input: TimeRangeFormatterInput) => {
    invariant(input, "Expected 'input' to be provided");

    let { startDate, end, duration, dayFormat, timeFormat, separator } = input;

    dayFormat = dayFormat === undefined ? 'll' : dayFormat;
    timeFormat = timeFormat === undefined ? 'LT' : timeFormat;

    invariant(startDate, "Expected 'start' to be provided");
    invariant(!(end && duration), 'Expected either "end" or "duration" but not both');

    const arrowComp = separator || <Icon name="long arrow alternate right" />;
    const startMoment = moment(startDate);

    if (!end && !duration) {
        return (
            <span>
                {startMoment.format(dayFormat)} {startMoment.format(timeFormat)}
            </span>
        );
    }

    const endMoment = end ? moment(end) : moment(startDate).add(duration, 'm');

    if (startMoment.isSame(endMoment, 'day')) {
        return (
            <span>
                {dayFormat && startMoment.format(dayFormat)}
                {dayFormat && timeFormat && ', '}
                {timeFormat && (
                    <>
                        {startMoment.format(timeFormat)}
                        {arrowComp}
                        {endMoment.format(timeFormat)}
                    </>
                )}
            </span>
        );
    } else {
        const f = dayFormat === null ? 'LL' : dayFormat;
        return (
            <span>
                {startMoment.format(f)}
                {arrowComp}
                {endMoment.format(f)}
            </span>
        );
    }
};

export interface DateRangeProps extends TimeRangeFormatterInput, React.HTMLAttributes<HTMLSpanElement> {
    endDate?: Date;
}

export const DisplayDateRange: React.FC<DateRangeProps> = (props) => {
    const { startDate, endDate, duration, dayFormat, timeFormat, separator, ...rest } = props;
    if (!startDate) {
        return <span {...rest}>{'--'}</span>;
    }

    return (
        <span {...rest}>
            {timeRangeFormatter({ startDate, end: endDate, duration, dayFormat, timeFormat, separator })}
        </span>
    );
};

export interface DisplayNumProps extends React.HTMLAttributes<HTMLSpanElement> {
    value: number | null;
    round?: number;
    roundToFixed?: boolean;
    unit?: string;
    unitPrefix?: string;
    defaultString?: string;
}

export const numFormatter = (value: number = 0, round: number = 2, roundToFixed?: boolean) => {
    const res = _round(value, round);
    return roundToFixed ? res.toFixed(round) : res;
};

export const DisplayNum: React.FC<DisplayNumProps> = (input) => {
    const { value, round = 2, defaultString = '0', unit, unitPrefix, roundToFixed, ...rest } = input;

    if (value === undefined || value === null) {
        return <span>{defaultString}</span>;
    }

    let v = numFormatter(value, round, roundToFixed);

    return (
        <span {...rest}>
            {unitPrefix && `${unitPrefix}`}
            {v}
            {unit && ` ${unit}`}
        </span>
    );
};
