import { NodeType } from '@poolware/api';
import { DeviceAlarmStatus, DeviceAttributeAlarmStatus, DeviceStateType } from './types';
import { AttributeErrorType, LifeGuardShadowType } from './lifeguard-types';
import { getAppConfig } from '@poolware/app-shell';

export const resolveDeviceAlarmStatusLifeguard = (deviceState?: NodeType.EZCDeviceState): DeviceAlarmStatus => {
    const shadowState: DeviceStateType<LifeGuardShadowType> = deviceState?.state?.lifeguard || {};

    const errorKeys: (keyof LifeGuardShadowType)[] = [
        'phError',
        'orpError',
        'temperatureError',
        'nipperError',
        'vsdError',
        'salinityError',
    ];

    // alarm statuses ordered by severity
    const AlarmStatusOrder = [
        { attr: DeviceAttributeAlarmStatus.FAULT, dev: DeviceAlarmStatus.FAULT },
        { attr: DeviceAttributeAlarmStatus.ALERT, dev: DeviceAlarmStatus.ALERT },
        { attr: DeviceAttributeAlarmStatus.WARNING, dev: DeviceAlarmStatus.WARNING },
    ];

    for (const alarmStatus of AlarmStatusOrder) {
        for (const key of errorKeys) {
            if ((shadowState.reported?.[key] as AttributeErrorType)?.type === alarmStatus.attr) {
                return alarmStatus.dev;
            }
        }
    }

    if (shadowState.isAlive) {
        return DeviceAlarmStatus.NORMAL;
    }
    return DeviceAlarmStatus.OFFLINE;
};

export const resolveDeviceAlarmStatusGeneric = (deviceState?: NodeType.EZCDeviceState): DeviceAlarmStatus => {
    // TODO: implement generic device alarm status resolver
    return DeviceAlarmStatus.UNKNOWN;
};

export const resolveDeviceAlarmStatus = (
    deviceLink: NodeType.EZCDeviceLink,
    deviceState?: NodeType.EZCDeviceState
): DeviceAlarmStatus => {
    switch (deviceLink?.device?.definition?.definitionId) {
        case 'DAVEY:DV_LG:DAVEY_LG:DAVEY_LG_IOT':
            return resolveDeviceAlarmStatusLifeguard(deviceState);
        default:
            return resolveDeviceAlarmStatusGeneric(deviceState);
    }
};

export const resolveColorForDeviceAlarmStatus = (alarmStatus: DeviceAlarmStatus) => {
    switch (alarmStatus) {
        default:
        case DeviceAlarmStatus.UNKNOWN:
        case DeviceAlarmStatus.OFFLINE:
            return { bg: 'bg-gray-200', text: 'text-gray-900', bgHex: '#e5e7eb', textHex: '#434343' };
        case DeviceAlarmStatus.NORMAL:
            return { bg: 'bg-green-100', text: 'text-green-900', bgHex: '#3de168', textHex: '#1c6841' };
        case DeviceAlarmStatus.WARNING:
            return { bg: 'bg-orange-100', text: 'text-orange-900', bgHex: '#fb923c', textHex: '#7c2d12' };
        case DeviceAlarmStatus.ALERT:
            return { bg: 'bg-orange-300', text: 'text-orange-900', bgHex: '#fb923c', textHex: '#7c2d12' };
        case DeviceAlarmStatus.FAULT:
            return { bg: 'bg-red-400', text: 'text-red-900', bgHex: '#f87171', textHex: '#7f1d1d' };
    }
};

export const resolveColorForAttributeAlarmStatus = (attributeAlarmStatus: DeviceAttributeAlarmStatus) => {
    switch (attributeAlarmStatus) {
        default:
        case DeviceAttributeAlarmStatus.NORMAL:
            return null;
        case DeviceAttributeAlarmStatus.WARNING:
            return { bg: 'bg-orange-100', text: 'text-orange-800' };
        case DeviceAttributeAlarmStatus.ALERT:
            return { bg: 'bg-orange-300', text: 'text-orange-900' };
        case DeviceAttributeAlarmStatus.FAULT:
            return { bg: 'bg-red-300', text: 'text-red-900' };
    }
};

const config = getAppConfig();

export const getExternalServicesURLs = (device: NodeType.EZCDevice) => {
    const urlLG = config.apps.lifeguardPortal
        ? `${config.apps.lifeguardPortal}/deviceDetails?sn=${device.deviceId}`
        : null;
    const urlEZ = config.apps.ezConnectAdmin ? `${config.apps.ezConnectAdmin}/devices/${device.ezcId}` : null;
    return { urlLG, urlEZ };
};
