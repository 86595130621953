import * as React from 'react';
import { NodeType } from '@poolware/api';
import { ButtonWithConfirm, Icon, Table } from '@ez/components';

export const FileListTable: React.FC<{
    fileAttachments: NodeType.WarrantyClaimFileAttach[];
    readOnly?: boolean;
    onFileAttachmentRemove?: (file: NodeType.WarrantyClaimFileAttach) => any;
}> = ({ fileAttachments, readOnly = true, onFileAttachmentRemove }) => {
    return (
        <Table compact={'very'}>
            <Table.Body>
                {fileAttachments.length == 0 && (
                    <Table.Row>
                        <Table.Cell>
                            <i>No files attached</i>
                        </Table.Cell>
                    </Table.Row>
                )}
                {fileAttachments.map((fileAttachment) => {
                    return (
                        <Table.Row key={fileAttachment.id}>
                            <Table.Cell width={1}>
                                <Icon name={'file alternate outline'} />
                            </Table.Cell>
                            <Table.Cell>
                                <a target={'_blank'} href={fileAttachment.file?.url}>
                                    {fileAttachment.file?.fileName}
                                </a>
                                {'  '}
                                <a
                                    href={fileAttachment.file?.url}
                                    target="_blank"
                                    download={fileAttachment.file?.fileName}
                                >
                                    <Icon name={'download'} />
                                </a>
                            </Table.Cell>
                            {!readOnly && (
                                <Table.Cell width={1} textAlign={'right'}>
                                    <ButtonWithConfirm
                                        popup={{ content: 'Remove file' }}
                                        confirm={{
                                            confirmMessage: {
                                                header: 'Delete file?',
                                                content: <code>{fileAttachment.file?.fileName}</code>,
                                            },
                                            negative: true,
                                            confirmButton: { content: 'Delete', icon: 'trash' },
                                            cancelButton: { content: 'Keep' },
                                        }}
                                        icon={'trash'}
                                        basic
                                        size={'tiny'}
                                        onClick={() => onFileAttachmentRemove?.(fileAttachment)}
                                    />
                                </Table.Cell>
                            )}
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};
