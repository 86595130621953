import { ModulesAccess } from './module-access-default';

export const storeHQAccess: ModulesAccess = {
    SuperAdmin: {
        enabled: false,
    },
    OrgAdmin: {
        enabled: false,
    },
    FranchiseAdmin: {
        enabled: true,
        companyProfile: true,
        staff: true,
        integrationConf: true,
    },
    FieldServices: {
        enabled: true,
        // Allow calendar and appointment
        appointments: true,
        // Allow Recurring Appointments
        recurringAppointment: true,
        // Allows Service Cases (non-Recurring)
        serviceJobs: true,
        // Enables/disables recurring service cases
        recurringCases: true,

        // Shows/hides sidebar link to the recurring services section
        recurringCasesShowPageLink: true,

        // Allow creations of multiple schedules for a single recurring case
        multiScheduledRecurringCases: false,

        // Allow Service Case Templates
        caseTemplates: true,
        // Allow Service Job Task Templates
        serviceJobTaskTemplates: true,

        // Enables/disables booking of unscheduled work orders by
        // drag-and-dropping them to the calendar from a sidebar
        calendarWorkOrderDND: true,
        //if set true, users will only see appointments assigned to them
        calendarSingleStaffMode: false,
        // Show list of aggregated streets on sidebar
        calendarStreetsSummary: false,

        // Automatically create work order for new appointments
        calendarAutoCreateWo: true,

        // Enable doc mode from desctopXL size.
        calendarDockModeScreenSizeXL: false,

        // Add water tests to work orders
        waterTestFromWorkOrder: false,

        // Invoices
        invoices: false,

        // Show/hide customer notes section in appointment print page
        printAppointmentCustomerNotes: false,
    },
    Staff: {
        enabled: true,
        teams: false,
    },
    Support: {
        enabled: true,
    },
    HQAdmin: {
        enabled: false,
    },
};
