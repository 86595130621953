import * as React from 'react';
import { SchedulerPages as Scheduler } from './index';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { NotFoundPage } from '@poolware/app-shell';
import { AppointmentMobilePage } from './PageAppointmentMobileLegacy';
import { CalendarLayoutStateProvider } from './utils/use-calendar-layout-state';
import { Router as MobilePageRouter } from './PageAppointmentMobile/Router';

export const Router = () => {
    return (
        <>
            <CalendarLayoutStateProvider>
                <ModuleRootSwitch notFound={NotFoundPage}>
                    <ModuleRoute path="/" exact component={Scheduler.Main} />
                    <ModuleRoute path="/new" component={Scheduler.NewAppointment} />
                    <ModuleRoute path="/new-wo" component={Scheduler.NewWorkOrderAppointment} />
                    <ModuleRoute path="/where" component={Scheduler.WhereToGo} />
                    {/*<ModuleRoute path="/mobile" component={MobilePageRouter} />*/}
                    <ModuleRoute path="/mobile/:appointmentItem" component={MobilePageRouter} />
                    {/*<ModuleRoute path="/mobile/:appointmentItem/checklist" component={MobilePage.PageChecklist} />*/}
                    {/*<ModuleRoute path="/mobile/:appointmentItem" component={MobilePage.Page} />*/}
                    <ModuleRoute path="/reschedule-confirm" component={Scheduler.PageRescheduleConfirm} />
                    <ModuleRoute path="/appointment-group/new" component={Scheduler.CalendarGroup.New} />
                    <ModuleRoute path="/appointment-group/:id" component={Scheduler.CalendarGroup.Edit} />
                    <ModuleRoute path="/:appointmentItem/edit" component={Scheduler.EditAppointment} />
                    <ModuleRoute path="/:appointmentItem/preview" component={AppointmentMobilePage} />
                </ModuleRootSwitch>
            </CalendarLayoutStateProvider>
        </>
    );
};
