import { useQuery } from 'react-apollo';
import { NodeType } from '@poolware/api';
import gql from 'graphql-tag';
import { DateRange } from '@ez/components';
import moment from 'moment';

const QL = gql`
    query EZCDeviceMetrics($deviceId: ID!, $fromDate: Date, $toDate: Date) {
        viewer {
            resolveEZCDeviceMetrics(device: $deviceId, fromDate: $fromDate, toDate: $toDate) {
                types {
                    tag
                    name
                    unit
                    minValue
                    maxValue
                }
                from
                to
                data
            }
        }
    }
`;

export const useQueryEZCDeviceMetrics = (deviceId: string, dateRange?: DateRange) => {
    let variables = { deviceId: deviceId, fromDate: undefined, toDate: undefined };
    if (dateRange) {
        variables = {
            ...variables,
            // overfetching data by 1 day to avoid missing data
            fromDate: moment(dateRange.startDate).subtract(1, 'd')?.toISOString(),
            toDate: dateRange.endDate?.toISOString(),
        };
    }
    const res = useQuery<NodeType.Query>(QL, {
        variables: variables,
    });

    const metrics = res.data?.viewer?.resolveEZCDeviceMetrics;
    return { ...res, metrics };
};
