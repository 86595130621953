import gql from 'graphql-tag';
import { NodeType } from '@poolware/api';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { OperationVariables } from '@apollo/react-common';

const QL = gql`
    query ($id: ID!) {
        viewer {
            resolveEZCDeviceState(device: $id) {
                state
                definitionId
                device {
                    id
                    deviceId
                    ezcId
                    definition {
                        id
                        definitionId
                    }
                }
            }
        }
    }
`;

export function useQueryEZCDeviceState<TVariables = OperationVariables>(
    deviceLink: NodeType.EZCDeviceLink,
    options?: QueryHookOptions<any, TVariables>
) {
    const variables = {
        id: deviceLink?.device?.id,
    };

    const { data, ...rest } = useQuery<NodeType.Query>(QL, {
        variables: variables,
        skip: !variables.id,
        ...options,
    });

    const refetchQuery = {
        query: options?.query || QL,
        variables: variables,
    };

    const deviceState: NodeType.EZCDeviceState = data?.viewer?.resolveEZCDeviceState;
    return {
        deviceState,
        refetchQuery,
        ...rest,
    };
}
