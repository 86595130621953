import * as React from 'react';
import { CheckboxRadix, CheckboxRadixProps } from '../radix';
import { FormFieldBaseProps } from './FormFieldTypes';
import { FormField } from './FormField';
import { FormFieldLabel } from './FormFieldLabel';
import { cn, useId } from '../utils';

export interface FormFieldCheckboxProps extends CheckboxRadixProps, FormFieldBaseProps {}

export const FormFieldCheckbox: React.FC<FormFieldCheckboxProps> = ({
    label,
    fluid = false,
    className,
    readOnly,
    required,
    id,
    error,
    disabled,
    ...rest
}) => {
    const fieldId = useId(id);

    return (
        <FormField className={cn(['flex-row items-center space-x-1', fluid ? 'w-full' : 'min-w-fit'])}>
            <CheckboxRadix id={fieldId} aria-invalid={!!error} disabled={disabled || readOnly} {...rest} />
            <FormFieldLabel
                readOnly={readOnly}
                disabled={disabled}
                required={required}
                label={label}
                errorLabel={error}
                htmlFor={fieldId}
                className={'hover:cursor-pointer '}
            />
        </FormField>
    );
};
