import { bindActionCreators, combineReducers } from 'redux';
import { connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { NodeType } from '@poolware/api';
import { createStructuredSelector } from 'reselect';
import { ActionsUnion, createAction, createReducer } from '@ez/tools';
import { BOOKING_ACTION, BookingAction } from './reducer-appointment-booking';

//// ***** TYPES ****** ////

export type ServiceJobBookingDetails = {
    customer?: NodeType.Customer;
    staff?: NodeType.Staff;
    pool?: NodeType.Pool;
    address?: NodeType.Address;
    ezcDeviceLink?: NodeType.EZCDeviceLink;
    returnTo?: any;
};

export type ServiceJobBookingMode = {
    sagaMode?: boolean;
    sagaModeName?: string;
};

interface ServiceJobBookingStateType {
    details: ServiceJobBookingDetails;
    mode: ServiceJobBookingMode;
}

const initialState: ServiceJobBookingStateType = {
    details: {},
    mode: {
        sagaMode: false,
        sagaModeName: null,
    },
};

//// ***** ACTIONS ******** ////

export enum SERVICE_JOB_ACTION {
    SET_DETAILS = '@ServiceJobAction/SET_DETAILS',
    NEW_SERVICE_JOB = '@ServiceJobAction/NEW_SERVICE_JOB',
    NEW_SERVICE_JOB_RECUR = '@ServiceJobAction/NEW_SERVICE_JOB_RECUR',
    SERVICE_JOB_CREATED = '@ServiceJobAction/SERVICE_JOB_CREATED',
    SET_MODE = '@ServiceJobAction/SET_MODE',
    RESET = '@ServiceJobAction/RESET',
    ABORT = '@ServiceJobAction/ABORT',
}

export const ServiceJobAction = {
    newServiceJob: (payload?: ServiceJobBookingDetails) => createAction(SERVICE_JOB_ACTION.NEW_SERVICE_JOB, payload),
    newRecurServiceJob: (payload?: ServiceJobBookingDetails) =>
        createAction(SERVICE_JOB_ACTION.NEW_SERVICE_JOB_RECUR, payload),
    setServiceJobDetails: (payload: Partial<ServiceJobBookingDetails>) =>
        createAction(SERVICE_JOB_ACTION.SET_DETAILS, payload),
    serviceJobCreated: (payload?: any) => createAction(SERVICE_JOB_ACTION.SERVICE_JOB_CREATED, payload),
    setMode: (payload: ServiceJobBookingMode) => createAction(SERVICE_JOB_ACTION.SET_MODE, payload),
    setSagaMode: (isSagaMode: boolean, sagaName?: string) =>
        ServiceJobAction.setMode({ sagaMode: isSagaMode, sagaModeName: sagaName }),
    abort: () => createAction(SERVICE_JOB_ACTION.ABORT),
    reset: () => createAction(SERVICE_JOB_ACTION.RESET),
};

export type ServiceJobAction = ActionsUnion<typeof ServiceJobAction>;

////******** REDUCERS **********////

const details = createReducer(initialState.details, (state, action: ServiceJobAction) => {
    switch (action.type) {
        case SERVICE_JOB_ACTION.SET_DETAILS:
            return { ...state, ...action.payload };
        case SERVICE_JOB_ACTION.RESET:
        case SERVICE_JOB_ACTION.ABORT:
        case SERVICE_JOB_ACTION.SERVICE_JOB_CREATED:
            return {};
        default:
            return state;
    }
});

const mode = createReducer(initialState.mode, (state, action: ServiceJobAction) => {
    switch (action.type) {
        case SERVICE_JOB_ACTION.SET_MODE:
            return { ...state, ...action.payload };
        case SERVICE_JOB_ACTION.RESET:
        case SERVICE_JOB_ACTION.ABORT:
            return initialState.mode;
        default:
            return state;
    }
});

export const reducerBooking = combineReducers({
    details,
    mode,
});

// Selectors
type SelectorExtended = {
    isSagaMode: boolean;
} & ServiceJobBookingStateType;

const memoizedSelector = createStructuredSelector<ServiceJobBookingStateType, SelectorExtended>({
    details: (state) => state.details,
    mode: (state) => state.mode,
    isSagaMode: (state) => state.mode.sagaMode,
});

export interface ServiceJobActionProps {
    ServiceJobAction: typeof ServiceJobAction;
    ServiceJobState: ReturnType<typeof memoizedSelector>;
}

export const withServiceJobActions = () =>
    connect(
        (state: RootStateOrAny) => {
            return { ServiceJobState: memoizedSelector(state.serviceJob.booking) };
        },
        (dispatch) => {
            return { ServiceJobAction: bindActionCreators(ServiceJobAction, dispatch) };
        }
    );

export const useServiceJobActions = (): ServiceJobActionProps => {
    const state = useSelector((state: RootStateOrAny) => memoizedSelector(state.serviceJob.booking));
    const dispatch = useDispatch();

    return {
        ServiceJobState: state,
        ServiceJobAction: bindActionCreators(ServiceJobAction, dispatch),
    };
};
