import React from 'react';
import { AttributeMetaType, AttributeModel } from './types';
import { getShadowAttribute, ShadowAttrStatus } from '../../../../common/get-shadow-attribute';
import { DeviceStateType, LifeGuardShadowType } from '../../../../common';
import { clsxm, Icon } from '@ez/components';

export const ValueContainer: React.FC<{ value: string; className?: string; isPending?: boolean }> = ({
    value,
    className,
    isPending,
}) => {
    return (
        <span className={clsxm('min-w-[3rem] text-right', className)}>
            {isPending && <Icon name={'spinner'} loading={true} size={'small'} />}
            <span>{value}</span>
        </span>
    );
};

export const ReadOnlyValueContainer: React.FC<{ value: string }> = ({ value }) => {
    return (
        <div className={'px-3 h-8 inline-flex items-center justify-center pr-8'}>
            <ValueContainer value={value} />
        </div>
    );
};

export const getComponentAttributesModel = (
    deviceState: DeviceStateType<LifeGuardShadowType>,
    attributeKey: keyof LifeGuardShadowType,
    attributeMetaType: AttributeMetaType
) => {
    const attribute = getShadowAttribute(deviceState, attributeKey);
    const att: AttributeModel = {
        attribute,
        attributeKey,
        attributeMetaType,
    };
    return att;
};

export function getAttributeLabels<T>(attrModel: AttributeModel<T>) {
    let value: T | undefined | null;
    let valueStr: string;
    const isReadOnly = attrModel.attributeMetaType?.readOnly || attrModel.attribute.status === ShadowAttrStatus.Unknown;

    const attribute = attrModel.attribute;

    switch (attribute.status) {
        case ShadowAttrStatus.Pending:
            value = attribute.desired;
            valueStr =
                attrModel?.attributeMetaType?.valueToString?.(value, ShadowAttrStatus.Pending) ||
                value?.toString() ||
                '';
            break;
        case ShadowAttrStatus.Unknown:
            value = undefined;
            valueStr = 'N/A';
            break;
        case ShadowAttrStatus.Settled:
            value = attribute.reported;
            valueStr =
                attrModel?.attributeMetaType?.valueToString?.(value, ShadowAttrStatus.Settled) ||
                value?.toString() ||
                '';
            break;
    }

    return { value, valueStr, isReadOnly };
}
