import { NodeType } from '@poolware/api';
import * as React from 'react';
import { clsxm, Display, Icon, Tooltip } from '@ez/components';
import { useDrag } from 'react-dnd';
import { FileHandle, FileStatus } from '../../types';

const fileToPhoto = (file: NodeType.FileUpload) => {
    if (!file) return null;
    return {
        src: file.url,
        isImage: file.isImage,
        urlThumbnail: file.imageUrl + '?size=md',
        file: file,
    };
};

export const PhotoItem: React.FC<{
    isSelected?: boolean;
    file?: NodeType.FileUpload;
    onClick?: () => void;
    mode?: 'view' | 'select';
}> = ({ file, onClick, isSelected, mode }) => {
    const image = fileToPhoto(file);

    const type = 'PHOTO';
    const [{}, drag] = useDrag(
        () => ({
            type,
            item: file,
        }),
        [file, type]
    );

    return (
        <div className={'w-[128px] min-w-fit flex flex-col items-center gap-2 relative rounded'} onClick={onClick}>
            {mode === 'select' && (
                <div
                    className={clsxm(
                        'absolute top-1 right-2 size-[22px] pl-[3px] bg-[rgba(0,0,0,0.2)] shadow rounded-full',
                        isSelected ? 'bg-green text-white' : 'text-white'
                    )}
                >
                    {isSelected && <Icon name={isSelected ? 'check circle' : 'circle outline'} />}
                </div>
            )}
            <button
                ref={drag}
                className={clsxm(
                    'focusable size-[128px] rounded grid items-center justify-center overflow-clip border shadow bg-tertiary',
                    isSelected && 'border-2 p-1 border-green border-solid'
                )}
            >
                {file ? (
                    <img src={image.urlThumbnail} alt={''} className={'object-cover w-full h-full'} />
                ) : (
                    <div className={'text-tertiary text-center'}>No image</div>
                )}
            </button>
            <div className={'text-xs h-[20px] px-1 overflow-hidden'}>
                <Display.Date value={file?.createdAt} format={'lll'} />
            </div>
        </div>
    );
};

export const PhotoItemPreview: React.FC<{ file: FileHandle }> = ({ file }) => {
    const isFailed = file.status === FileStatus.FAILED;
    const isLoading = file.status === FileStatus.PENDING || file.status === FileStatus.UPLOADING;
    return (
        <div key={file.tempId} className={'w-[128px] min-w-fit flex flex-col items-center gap-2 relative rounded'}>
            <div className={'size-[128px] rounded bg-tertiary grid items-center justify-center overflow-clip'}>
                <img
                    src={file.preview}
                    alt={''}
                    className={'object-cover w-full h-full'}
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                    }}
                />
            </div>

            <div className={'text-xs h-[20px] px-1 overflow-hidden'}>
                {isLoading && (
                    <>
                        <Icon color={'blue'} name={'spinner'} loading={true} /> Uploading...
                    </>
                )}
                {isFailed && (
                    <Tooltip
                        trigger={
                            <span>
                                <Icon color={'red'} name={'exclamation triangle'} /> Failed
                            </span>
                        }
                    >
                        {file.error ? file.error : 'Failed to upload'}
                    </Tooltip>
                )}
            </div>
        </div>
    );
};
