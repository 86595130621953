import { createThemeByHue } from '@ez/components';
import moment from 'moment';

let _t = undefined;

export const getTheme = (publicPrefix: string) => {
    if (_t) {
        return _t;
    }

    const huePrim = 206;
    const hueSec = 42;
    _t = createThemeByHue({
        publicPrefix,
        huePrim: huePrim,
        hueSec: hueSec,
        primSat: 62,
        primLum: 42,
        customisation: {
            themeName: 'poolware',
            navbar: {
                separatorColor: 'hsl(48,100%,62%)',
            },
            button: {
                color: {
                    primary: `hsl(${huePrim}, 58%, 42%)`,
                    primaryHover: `hsl(${huePrim}, 58%, 80%)`,
                    secondary: `hsl(${hueSec}, 25%, 50%)`,
                    secondaryHover: `hsl(${hueSec}, 25%, 80%)`,
                    tertiary: `hsl(${huePrim}, 15%, 85%)`,
                    tertiaryHover: `hsl(${huePrim}, 15%, 60%)`,
                },
            },
        },
    });

    const today = moment();
    if (today.date() === 31 && today.month() === 9) {
        // Boo!
        _t.navbar.separatorColor = 'hsl(24, 98%, 63%)';
        _t.sidebar.menuItem.backgroundColorHover = 'hsl(22,100%,36%)';
        _t.logoPath = `${publicPrefix}/logo-halloween.png`;
    }
    return _t;
};
