import { NodeType } from '@poolware/api';
import { useQueryProductCompanyConnection } from '../queries/QueryProductCompanyConnection';
import { createSuggestInputWithQueryConnectionHook } from '@poolware/app-shell';

export const SuggestProductCompanyInput = createSuggestInputWithQueryConnectionHook<
    NodeType.ProductCompany,
    NodeType.ProductCompanyViewerProductCompanySearch
>({
    useQueryConnectionHook: useQueryProductCompanyConnection,
    getOptionLabel: (item) => item.name,
    mapQueryToSearch: (term) => {
        return {
            name: {
                like: term,
            },
        };
    },
});
