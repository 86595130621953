import * as React from 'react';
import { clsxm, Display, Icon, IconButton, TableLineLoader } from '@ez/components';
import { OnlineIcon } from '../common';
import {
    AttributeErrorType,
    DeviceStateType,
    EZCDevicesLinkWithState,
    LifeGuardShadowType,
    resolveColorForAttributeAlarmStatus,
    resolveColorForDeviceAlarmStatus,
} from '../../common';

const CardHeader: React.FC<{
    device: EZCDevicesLinkWithState;
    isFavorite?: boolean;
    onFavoriteChange?: (device: EZCDevicesLinkWithState, isFav: boolean) => void;
}> = ({ device, isFavorite, onFavoriteChange }) => {
    const statusColor = resolveColorForDeviceAlarmStatus(device?.alarmStatus);
    return (
        <div
            className={clsxm(
                'bg-light_gray px-3 p-2 flex flex-row justify-between mb-2 rounded-t flex-nowrap gap-2',
                statusColor.bg,
                statusColor.text
            )}
        >
            <div className={'text-ellipsis text-nowrap overflow-hidden h-6'}>{device?.device?.title}</div>
            <span className={'whitespace-nowrap'}>
                <IconButton
                    size={'xs'}
                    icon={isFavorite ? 'star' : 'star outline'}
                    className={isFavorite ? 'text-yellow-500' : 'text-gray-300'}
                    onClick={(e) => {
                        e.stopPropagation();
                        onFavoriteChange?.(device, !isFavorite);
                    }}
                />
                <OnlineIcon device={device} />
            </span>
        </div>
    );
};

const DeviceStateValueItem: React.FC<{
    label: string;
    value: React.ReactNode;
    setPoint?: React.ReactNode;
    error?: AttributeErrorType;
}> = ({ label, value, setPoint, error }) => {
    const errorColor = resolveColorForAttributeAlarmStatus(error?.type);
    return (
        <div className={'flex flex-row gap-1 items-center justify-center flex-nowrap text-sm px-1 rounded'}>
            <div
                className={clsxm(
                    'w-2/5 text-right text-secondary opacity-80 text-ellipsis text-nowrap overflow-hidden',
                    errorColor?.text
                )}
            >
                {label}
            </div>
            <div className={clsxm('w-3/5 text-center rounded whitespace-nowrap px-1', errorColor?.bg ?? 'bg-gray-100')}>
                <span className={'font-bold'}>{value}</span>
                {setPoint && <span> / {setPoint}</span>}
            </div>
        </div>
    );
};

export const DeviceState: React.FC<{ device?: EZCDevicesLinkWithState }> = ({ device }) => {
    const deviceState = device.deviceState;
    const stateLoading = device.isLoadingShadow;
    const state: DeviceStateType<LifeGuardShadowType> = deviceState?.state?.lifeguard || {};

    if (stateLoading) {
        return (
            <div className={'h-full bg-panel-section p-2 rounded flex flex-col gap-1'}>
                <TableLineLoader />
                <TableLineLoader />
                <TableLineLoader />
            </div>
        );
    }

    const stateReported = state.reported;
    if (!stateReported) {
        return null;
    }

    return (
        <div className={'h-full bg-panel-section p-1 gap-x-0.5 gap-y-0.5 rounded grid grid-cols-[45%_55%]'}>
            <DeviceStateValueItem
                label={'pH'}
                value={stateReported?.phSensorValue || '--'}
                setPoint={stateReported?.phSetpoint}
                error={stateReported?.phError}
            />
            <DeviceStateValueItem
                label={'Salinity'}
                value={stateReported?.salinitySensorValue || '--'}
                setPoint={stateReported?.salinitySetpoint}
                error={stateReported?.salinityError}
            />
            <DeviceStateValueItem
                label={'Temp'}
                value={stateReported?.temperatureSensorValue || '--'}
                setPoint={stateReported?.temperatureSetpoint}
                error={stateReported?.temperatureError}
            />
            <DeviceStateValueItem
                label={'ORP'}
                value={stateReported?.orpSensorValue || '--'}
                setPoint={stateReported?.orpSetpoint}
                error={stateReported?.orpError}
            />
        </div>
    );
};

export const DeviceCard: React.FC<{
    device: EZCDevicesLinkWithState;
    onView?: () => void;
    admin?: boolean;
    onFavoriteChange?: (device: EZCDevicesLinkWithState, isFav: boolean) => void;
    isFavorite?: boolean;
}> = ({ device, admin, onView, onFavoriteChange, isFavorite }) => {
    const address = device.pool?.address || device.owner?.primaryAddress;
    return (
        <div
            className={'h-64 w-full rounded bg-panel  shadow hover:cursor-pointer hover:shadow-lg flex flex-col'}
            onClick={onView}
        >
            <CardHeader device={device} onFavoriteChange={onFavoriteChange} isFavorite={isFavorite} />
            <div className={'flex-1 text-sm flex-col flex gap-1 p-2'}>
                <div className={'text-ellipsis text-nowrap overflow-hidden'}>
                    <Icon name={'user'} /> {device.owner ? <Display.Entity value={device.owner} /> : '--'}
                </div>

                <div className={'text-ellipsis text-nowrap overflow-hidden'}>
                    <Icon name={'map marker'} />
                    {address ? <Display.Address value={address} /> : '--'}
                </div>

                {device.pool && (
                    <div className={'text-ellipsis text-nowrap overflow-hidden'}>
                        <Icon name={'life ring'} /> {device.pool?.name} ({device.pool?.volume} L)
                    </div>
                )}

                {admin && (
                    <div className={'text-ellipsis text-nowrap overflow-hidden'}>
                        <Icon name={'building'} /> {device.franchise?.name}
                    </div>
                )}
            </div>
            <div className={'flex-1 flex-grow p-2'}>
                <DeviceState device={device} />
            </div>
        </div>
    );
};

export const DeviceCardLoadingPlaceholder: React.FC = () => {
    return (
        <div
            className={
                'h-64 w-full rounded bg-panel border border-solid border-light_gray hover:cursor-pointer hover:shadow-lg flex flex-col'
            }
        >
            <div className={'bg-accent p-2 flex flex-row justify-between mb-2 rounded-t flex-nowrap gap-2'}>
                <div className={'text-ellipsis text-nowrap overflow-hidden h-6'}></div>
            </div>
            <div className={'flex-1 text-sm flex-col flex gap-1 p-2'}>
                <div className={'flex flex-row flex-nowrap gap-1'}>
                    <Icon name={'user'} className={'!opacity-20'} />
                    <TableLineLoader />
                </div>

                <div className={'flex flex-row flex-nowrap gap-1'}>
                    <Icon name={'map marker'} className={'!opacity-20'} />
                    <TableLineLoader />
                </div>
                <div className={'flex flex-row flex-nowrap gap-1'}>
                    <Icon name={'life ring'} className={'!opacity-20'} />
                    <TableLineLoader />
                </div>
            </div>
            <div className={'flex-1 flex-grow p-2'}>
                <div className={'h-full bg-panel-section p-2 rounded flex flex-col gap-1'}>
                    <TableLineLoader />
                    <TableLineLoader />
                    <TableLineLoader />
                </div>
            </div>
        </div>
    );
};
