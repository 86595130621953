import * as React from 'react';
import {
    ConnectionErrorMessage,
    DebugJsonButton,
    MenuBar,
    Optionable,
    PageLayout,
    PageSkeletonLoader,
    Panel,
    SegmentedSelectButtons,
    StickyMenuBar,
    toastError,
    VStack,
    Segment,
    useStateSidebar,
} from '@ez/components';
import { ModuleColorNames, ModuleIconNames } from '../../../constants';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { InvoiceDocView } from './InvoiceDocView';
import { useMutationAbstractDocument } from '@poolware/api';
import { useQueryAbstractDoc } from '../queries/use-query-abstract-doc';
import { AppErrorBoundary, NotFoundPage } from '@poolware/app-shell';
import { InvoiceStatus, invoiceStatusOptions } from '../constants';
import * as _ from 'lodash';
import { InvoiceLinkEntitiesPanel } from '../components/InvoiceLinkEntitiesPanel';

const MenuDropdownItemDeleteInvoice: React.FC<{ onClick }> = ({ onClick }) => {
    return (
        <MenuBar.DropdownItemWithConfirm
            icon={'trash'}
            color="orange"
            content="Delete"
            onClick={onClick}
            popup={{ content: 'Will be deleted' }}
            confirm={{
                confirmMessage: {
                    header: 'Delete?',
                    content: 'Will be deleted forever!',
                },
                cancelButton: {
                    content: 'Keep',
                },
                negative: true,
                confirmButton: {
                    content: 'Delete',
                    icon: 'trash',
                },
            }}
        />
    );
};

export const InvoicePageView: React.FC = () => {
    const { AppNavigator, params } = useAppNavigator();
    const { loading, error, node: doc, refetchQuery } = useQueryAbstractDoc(params.id);
    const mutations = useMutationAbstractDocument({ refetchQueries: [refetchQuery] });

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !doc) {
        return <NotFoundPage />;
    }

    const onPrintWO = () => {
        AppNavigator.navigate(`/print/service-jobs/invoice/${doc.id}`, { setOrigin: true });
    };

    const onEdit = () => {
        AppNavigator.navigateRelative(`/${doc.id}/edit`, { setOrigin: true });
    };

    const onDelete = async () => {
        try {
            await mutations.delete({ id: params.id });
            AppNavigator.replaceToOrigin('/', { relativeToModule: true });
        } catch (e) {
            toastError(e);
        }
    };

    const onChangeStatus = (o: Optionable<InvoiceStatus>) => {
        try {
            mutations.update({ id: doc.id, statusTag: o.value });
        } catch (e) {
            toastError(e);
        }
    };

    const { workOrder, serviceJob } = doc;
    const woTitle = _.truncate(workOrder?.title, { length: 40 });

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item
                        icon={'chevron left'}
                        title={'Back'}
                        onClick={() => AppNavigator.navigateToOrigin('/', { relativeToModule: true })}
                    />
                    <MenuBar.HeaderItem color={ModuleColorNames.Invoice} icon={ModuleIconNames.Invoice}>
                        Invoice - {doc.refNumber}
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <DebugJsonButton data={doc} />
                    <MenuBar.Item onClick={onEdit} color={'blue'} title={'Edit'} icon={'edit'} />
                    <MenuBar.Item onClick={onPrintWO} color={'blue'} title={'Print'} icon={'print'} />
                    <MenuBar.Dropdown icon={'bars'} color={'red'}>
                        <MenuDropdownItemDeleteInvoice onClick={onDelete} />
                    </MenuBar.Dropdown>
                </MenuBar.Section>
            </StickyMenuBar>

            <PageLayout.ThreeColumnManaged
                main={
                    <VStack>
                        <Segment color={'purple'}>
                            <SegmentedSelectButtons
                                fluid={true}
                                separated={true}
                                onChange={onChangeStatus}
                                value={doc.statusTag}
                                options={invoiceStatusOptions}
                            />
                        </Segment>
                        <PageLayout.BodySection
                            width={'screen-lg'}
                            className={'bg-gray-50 px-8 py-8 mb-16 shadow-lg rounded'}
                        >
                            <AppErrorBoundary>
                                <InvoiceDocView doc={doc} />
                            </AppErrorBoundary>
                        </PageLayout.BodySection>
                    </VStack>
                }
                layoutColumns={3}
                showLeft={false}
                showRight={true}
                rightSidebar={
                    <VStack className={'max-w-xl w-full'}>
                        <InvoiceLinkEntitiesPanel doc={doc} />
                    </VStack>
                }
            />
        </PageLayout>
    );
};
