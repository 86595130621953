import * as React from 'react';
import { LinkButton, Sidebar } from '@ez/components';
import { NavLink } from 'react-router-dom';
import { useAppUpdater } from '../AppUpdater';

// export type Maybe<P> = P | null | undefined | false;

// export interface AppSidebarMenuItem {
//     to: string;
//     exact?: boolean;
//     icon: SemanticICONS;
//     secondaryIcon?: SemanticICONS;
//     label: string | React.ReactNode;
//     as: typeof SidebarMenuItemExternalLink | typeof SidebarMenuItemNavLink | typeof SidebarMenuItemButton;
//     sub?: boolean;
//     [key: string]: any;
// }

// export interface AppSidebarMenuGroup {
//     header: string;
//     items: Maybe<AppSidebarMenuItem>[];
// }

// export interface AppSidebarConfig {
//     logo?: {
//         image: string;
//         icon: string;
//     };
//     menu: Maybe<AppSidebarMenuGroup>[];
// }

const ExternalLinkComp: React.FC<{ to; className }> = ({ to, children, className }) => (
    <a href={to} className={className} children={children} />
);

const ReloadableLinkComp = React.forwardRef<any, any>(({ to, router, onClick, exact = false, ...rest }, ref) => {
    const appUpdater = useAppUpdater();
    return (
        <NavLink
            ref={ref}
            to={to}
            exact={exact}
            {...rest}
            onClick={(e) => {
                try {
                    if (appUpdater.pendingReload && appUpdater.reloadOnNavigation) {
                        e.preventDefault();
                        return appUpdater.reloadPage(to);
                    }
                } catch (e) {
                    console.error(e);
                }
                onClick && onClick();
            }}
        />
    );
});

export const SidebarMenuItemExternalLink = Sidebar.createMenuItem(ExternalLinkComp);
export const SidebarMenuItemNavLink = Sidebar.createMenuItem(ReloadableLinkComp);
export const SidebarMenuItemButton = Sidebar.createMenuItem(LinkButton);
