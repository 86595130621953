import * as React from 'react';
import { useApolloClient } from 'react-apollo';
import { Icon, Navbar, SemanticICONS } from '@ez/components';
import _truncate from 'lodash/truncate';
import { useAppConfig, useViewer } from '@poolware/app-shell';
import * as URLBuilder from '../routes/url-builder';
import { IAppNavigatorNavigateOptions, useAppNavigator } from '@poolware/react-app-navigator';
import { NavbarDropdownMenuItemLabel } from '@ez/components/dist/app-layout/Navbar/NavbarDropdownMenu';

type NavigationInternalType = IAppNavigatorNavigateOptions & { to: string };

type NavigationType =
    | string // external link
    | NavigationInternalType; // internal link

interface DropdownMenuMenubarItemProps {
    icon?: string | React.ReactElement;
    label?: string | React.ReactNode;
    nav?: NavigationType;
    onClick?: () => void;
    hidden?: boolean;
}

const DropdownMenuContent: React.FC<{ items: DropdownMenuMenubarItemProps[]; header?: React.ReactNode }> = ({
    items,
    header,
}) => {
    const { AppNavigator } = useAppNavigator();
    const visibleItems = items.filter((item) => !item.hidden);
    if (visibleItems.length === 0) return null;
    return (
        <>
            <Navbar.Dropdown.Separator />
            {header && <NavbarDropdownMenuItemLabel>{header}</NavbarDropdownMenuItemLabel>}

            {visibleItems.map((item, index) => {
                const icon = item.icon ?? 'circle outline';
                const iconComponent = typeof icon === 'string' ? <Icon name={icon as SemanticICONS} /> : icon;
                if (item.nav) {
                    if (typeof item.nav === 'string') {
                        return (
                            <Navbar.Dropdown.Item key={index} icon={iconComponent}>
                                <a href={item.nav}>{item.label}</a>
                            </Navbar.Dropdown.Item>
                        );
                    } else {
                        const { to, ...options } = item.nav as NavigationInternalType;
                        return (
                            <Navbar.Dropdown.Item
                                key={index}
                                icon={iconComponent}
                                onClick={() => {
                                    AppNavigator.navigate(to, options);
                                }}
                            >
                                {item.label}
                            </Navbar.Dropdown.Item>
                        );
                    }
                }

                return (
                    <Navbar.Dropdown.Item key={index} icon={iconComponent} onClick={item.onClick}>
                        {item.label}
                    </Navbar.Dropdown.Item>
                );
            })}
        </>
    );
};

export const DropdownMenuAccount = () => {
    const client = useApolloClient();
    const appConfig = useAppConfig();
    const { checkingAuthState, Auth, viewer, modulesAccess } = useViewer();
    const profileLInk = appConfig?.apps?.account || '/profile';

    const logOut = async () => {
        try {
            await Auth.AuthAction.logout();
            await client.resetStore();
        } catch (e) {
            console.error(e);
        }
    };

    const label = _truncate(Auth.AuthState.user.username, { length: 20 });
    const labelItem = _truncate(Auth.AuthState.user.username, { length: 40 });

    const franchiseAdminItems: DropdownMenuMenubarItemProps[] = [
        {
            nav: { to: URLBuilder.FranchiseAdmin().Franchise().view },
            icon: 'building outline',
            label: 'Company Profile',
            hidden: !modulesAccess.FranchiseAdmin?.enabled,
        },
        {
            nav: { to: URLBuilder.Staff().all },
            icon: 'spy',
            label: 'Staff',
            hidden: !modulesAccess.Staff?.enabled,
        },
        {
            nav: { to: '/settings' },
            icon: 'cog',
            label: 'Settings',
            hidden: !modulesAccess.FranchiseAdmin?.enabled,
        },
    ];

    const hqAdminItems: DropdownMenuMenubarItemProps[] = [
        {
            nav: { to: URLBuilder.HQAdmin().Franchise().all },
            icon: 'building',
            label: 'Franchises',
            hidden: !modulesAccess.HQAdmin?.enabled,
        },
    ];

    return (
        <>
            <Navbar.Dropdown.Menu
                icon={<Icon circular={true} name={'spy'} loading={checkingAuthState} />}
                label={label}
            >
                <Navbar.Dropdown.Label>{labelItem}</Navbar.Dropdown.Label>
                <Navbar.Dropdown.Item icon={<Icon name={'user'} />}>
                    <a href={profileLInk} className={'group-hover:text-accent-foreground'}>
                        My Profile
                    </a>
                </Navbar.Dropdown.Item>
                <DropdownMenuContent items={franchiseAdminItems} header={'Admin'} />
                <DropdownMenuContent items={hqAdminItems} header={'HQ Admin'} />
                <Navbar.Dropdown.Separator />
                <Navbar.Dropdown.Item icon={<Icon name={'log out'} />} content="Log Out" onClick={logOut} />
            </Navbar.Dropdown.Menu>
        </>
    );
};
