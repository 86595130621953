import * as React from 'react';
import { useMemo } from 'react';
import _groupBy from 'lodash/groupBy';
import { NodeType, QueryConnectionResult } from '@poolware/api';
import { Button, ColorPill, Display, Icon, SlideInDock, stringFormatters, useModalCtrl } from '@ez/components';
import * as URLBuilder from '../../../../../routes/url-builder';
import { Link } from 'react-router-dom';
import {
    AppointmentViewDock,
    getLabelForAppointmentStatus,
    IconAppointmentStatus,
    useCalendarActions,
} from '@poolware/app-service-jobs';
import styled from 'styled-components';
import { SectionedTable, TableColumnDef, TableDef } from './SectionedTable';

const TrimmedOneLiner = styled.div`
    white-space: nowrap;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
`;

const SearchResultsList: React.FC<PageControlProps> = (props) => {
    const { connectionState, connectionData } = props;
    const modalCtrl = useModalCtrl();
    const { CalendarAction } = useCalendarActions();

    const onAppointmentView = (appointment: NodeType.AppointmentItem) => {
        modalCtrl.onOpen(appointment);
    };

    const tableDef = useMemo<TableDef<NodeType.AppointmentItem>>(() => {
        const count = connectionData.length;
        const countLabel = connectionState.loading || count === 0 ? '' : `${count}`;

        const columns: TableColumnDef<NodeType.AppointmentItem>[] = [
            {
                header: countLabel,
                headerCellProps: {
                    textAlign: 'center',
                },
                cellProps: {
                    // width: 1,
                    textAlign: 'center',
                    singleLine: true,
                },
                cell: (pd) => {
                    return (
                        <Button variant={'tertiary'} icon={'info'} size={'sm'} onClick={() => onAppointmentView(pd)} />
                    );
                },
            },
            {
                header: 'Date ',
                cellProps: {
                    width: 3,
                    textAlign: 'left',
                    singleLine: true,
                },
                cell: (pd) => {
                    const iconName = pd.isRecurring ? 'refresh' : null;
                    return (
                        <TrimmedOneLiner>
                            <Icon name={iconName} /> <Display.Date format={'llll'} value={pd?.startDate} />
                        </TrimmedOneLiner>
                    );
                },
            },
            {
                header: 'Status ',
                width: 2,
                cell: (pd) => {
                    return (
                        <TrimmedOneLiner>
                            <IconAppointmentStatus status={pd.status} /> {getLabelForAppointmentStatus(pd.status)}
                        </TrimmedOneLiner>
                    );
                },
            },
            {
                header: 'Sale',
                singleLine: true,
                width: 2,
                cell: (pd) => {
                    const saleUrl = pd?.vendSale?.historyUrl;
                    if (!saleUrl) return null;
                    const iconName = saleUrl ? 'tag' : null;
                    return (
                        <TrimmedOneLiner>
                            <a href={saleUrl} target={'_blank'} rel="noopener noreferrer">
                                <Icon name={iconName} /> Sale Created
                            </a>
                        </TrimmedOneLiner>
                    );
                },
            },
            {
                header: 'Staff',
                width: 3,
                singleLine: true,
                cell: (pd) => (
                    <TrimmedOneLiner>
                        <Display.Entity value={pd.staff} />
                    </TrimmedOneLiner>
                ),
            },
            {
                header: 'Customer',
                cellProps: {
                    singleLine: true,
                },
                cell: (pd) => {
                    const { customer } = pd;
                    return (
                        customer && (
                            <>
                                <Link to={URLBuilder.Customer(customer.id).view}>
                                    {customer.crn} - {stringFormatters.formatEntityName(customer)}
                                </Link>
                            </>
                        )
                    );
                },
            },
            {
                header: 'Color Tag',
                headerCellProps: {
                    singleLine: true,
                },
                cell: (pd) => {
                    const { color } = pd;
                    return color && <ColorPill value={color} />;
                },
            },
            {
                header: 'Group',
                cellProps: {
                    singleLine: true,
                },
                cell: (pd) => {
                    const { group } = pd;
                    return <TrimmedOneLiner>{group === null ? 'Default Group' : group.title}</TrimmedOneLiner>;
                },
            },
        ];

        const groupedData = _groupBy(connectionData, (item: NodeType.AppointmentItem) => {
            return new Date(item.startDate).toDateString();
        });

        return {
            sections: Object.keys(groupedData).map((key) => {
                return { sectionTitle: key, data: groupedData[key] };
            }),
            columns: columns,
        };
    }, [connectionState, connectionData]);

    return (
        <>
            <SectionedTable tableDef={tableDef} connectionState={connectionState} />
            <SlideInDock onClose={modalCtrl.onClose} open={modalCtrl.open}>
                <AppointmentViewDock
                    onDidDelete={() => {
                        connectionState.refetch();
                    }}
                    onClose={modalCtrl.onClose}
                    onShowInCalendar={CalendarAction.showAppointmentInCalendar}
                    appointmentItemId={modalCtrl.payload?.id}
                    showServiceJobLink={false}
                />
            </SlideInDock>
        </>
    );
};

export default SearchResultsList;

export interface PageControlProps extends QueryConnectionResult<NodeType.AppointmentItem> {}
