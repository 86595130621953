import * as React from 'react';
import { MenuBarHeaderItem, MenuBarSection, PageLayout, StickyMenuBar, VStack } from '@ez/components';
import { WaterTestPanel } from '@poolware/waterlink';
import { Segment } from '@ez/components';

export const PageWaterlink = () => {
    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem content={'Waterlink'} />
                </MenuBarSection>
            </StickyMenuBar>
            <Segment>
                <WaterTestPanel />
            </Segment>
        </PageLayout>
    );
};
