import * as React from 'react';
import { JSONView } from '@ez/components';
import { AttributeMetaType, AttributeModel } from './types';
import { AttributeRendererFloat } from './AttributeRendererFloat';
import { AttributeRendererBool } from './AttributeRendererBool';
import { AttributeRendererEnum } from './AttributeRendererEnum';
import { AttributeRenderUnknown } from './AttributeRendererUnknown';

export const getAttributeRenderer = (meta: AttributeMetaType) => {
    switch (meta.type) {
        case 'ENUM':
            return AttributeRendererEnum;
        case 'BOOL':
            return AttributeRendererBool;
        case 'FLOAT':
            return AttributeRendererFloat;
        default:
            return AttributeRenderUnknown;
    }
};

export const AttributeRenderer: React.FC<{
    attrModel?: AttributeModel;
    onChange: (attrModel: AttributeModel, newValue: any) => Promise<void>;
    debug?: boolean;
}> = ({ attrModel, onChange, debug }) => {
    if (!attrModel) return null;
    const Renderer = getAttributeRenderer(attrModel.attributeMetaType);

    return (
        <>
            <Renderer attrModel={attrModel} onChange={(newValue) => onChange(attrModel, newValue)} />
            {debug && <JSONView data={attrModel} />}
        </>
    );
};
