import * as React from 'react';
import { useField } from 'formik';
import { CheckboxRadixProps } from '../radix';
import { FormFieldCheckbox } from '../form-components';
import { FormikFieldProps } from './FormikTypes';

export const FormikCheckboxField: React.FC<CheckboxRadixProps & FormikFieldProps> = ({ name, ...rest }) => {
    const [field, meta, helpers] = useField<boolean>({ name: name });
    let value = field?.value;
    const touched = meta.touched;
    const error = meta.error;
    let hasError = touched && !!error;

    const handleChange = async (checked: boolean) => {
        // await onDidChange?.(checked);
        await helpers.setValue(checked);
    };

    return (
        <FormFieldCheckbox //
            checked={value}
            onCheckedChange={handleChange}
            error={hasError && error}
            {...rest}
        />
    );
};
