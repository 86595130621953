import * as React from 'react';
import { Frame, SectionHeader, WaterTestSection } from '../components';
import { Button, Icon, PageLayout } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMobileAppointmentCtx } from '../Root';
import { NotesFeed } from '../../../components/NotesFeed';
import { PageSectionPhotos } from './PageSectionPhotos/PageSectionPhotos';

interface PageSectionProps {
    expanded?: boolean;
    onToggle?: () => void;
    appointmentItem?: NodeType.AppointmentItem;
}

export const JobTechNotesPreview = () => {
    const { AppNavigator } = useAppNavigator();
    const { workOrder } = useMobileAppointmentCtx();

    const onAddNotes = () => {
        AppNavigator.navigateRelative('/notes', { setOrigin: true });
    };
    const notes = fromEdges(workOrder?.notes);
    return (
        <Frame bordered={true}>
            <div className={'flex flex-row justify-between'}>
                <strong>
                    <Icon name={'sticky note outline'} />
                    Job Notes
                </strong>
                <Button variant={'tertiary'} icon={'plus'} onClick={onAddNotes} />
            </div>
            {notes.length > 0 && <NotesFeed mode={'inputbottom'} maxHeight={2000} notes={notes} />}
        </Frame>
    );
};

const WaterTestsSection: React.FC = () => {
    const { workOrder, goToNewWaterTest } = useMobileAppointmentCtx();

    return (
        <Frame bordered={true}>
            <div className={'flex flex-row justify-between'}>
                <strong>
                    <Icon name={'lab'} />
                    Water Test
                </strong>
                <Button className={'justify-end'} variant={'tertiary'} icon={'plus'} onClick={goToNewWaterTest} />
            </div>
            {fromEdges(workOrder?.testReports).map((testReport) => {
                return <WaterTestSection testReport={testReport} key={testReport.id} />;
            })}
        </Frame>
    );
};

export const PageSectionNotesAndPhotos: React.FC<PageSectionProps> = () => {
    const { isWorkOrderPending, workOrder, serviceJob } = useMobileAppointmentCtx();
    const isActivated = !isWorkOrderPending && !!workOrder;

    if (!serviceJob) {
        // Not applicable if no service job
        return null;
    }
    return (
        <>
            <SectionHeader className={!isActivated && 'opacity-50'}>All Notes and Attachments</SectionHeader>
            <PageLayout.BodySection vStack={true} className={!isActivated && 'opacity-50 pointer-events-none'}>
                <PageSectionPhotos />
                <WaterTestsSection />
                <JobTechNotesPreview />
            </PageLayout.BodySection>
        </>
    );
};
