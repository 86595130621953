import * as React from 'react';
import { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Optionable } from '../optionable';
import { cn, useId } from '../utils';
import { ClassNamesConfig, StylesConfig } from 'react-select/dist/declarations/src/styles';
import { useDebouncedCallback, useSafeState } from '@ez/tools';
import { JSONView } from '../dev-tools';
import { ActionMeta, InputActionMeta } from 'react-select/dist/declarations/src/types';
import { FormField } from './FormField';
import { FormFieldLabel } from './FormFieldLabel';
import _isEmpty from 'lodash/isEmpty';

export interface FormFieldSuggestInputProps<V = any> {
    onChange?: (value: V | null) => any;
    value?: V | null;
    options?: Optionable<V>[];

    label?: string | React.ReactNode;
    loading?: boolean;
    // TODO: deprecated, use onChange instead.
    onItemSelect?: (item: V) => void;
    placeholder?: string;
    onInputChange?: (query: string) => void;
    getOptionValue?: (item: V) => any;
    formatOptionLabel?: (item: V) => React.ReactNode;
    isClearable?: boolean;
    disabled?: boolean;
    id?: string;
    className?: string;

    readOnly?: boolean;
    required?: boolean;
    error?: React.ReactNode;
}

const defaultGetOptionValue = (option: Optionable<any>) => option?.value;

type InternalOption<T = any> = {
    label: string;
    value: any;
    scrOption: Optionable<T>;
};

export const FormFieldSuggestInput: React.FC<FormFieldSuggestInputProps> = ({
    loading,
    options: extOptions,
    value,
    onChange,
    onInputChange,
    onItemSelect,
    getOptionValue = defaultGetOptionValue,
    isClearable = true,
    disabled = false,
    placeholder,
    formatOptionLabel,
    label,
    id,

    className,
    readOnly,
    required,
    error,
}) => {
    const fieldId = useId(id);

    const [_options, setOptions] = useSafeState([]);

    const [debouncedOnSearchChange] = useDebouncedCallback((value: string, actionMeta: InputActionMeta) => {
        // console.log('debouncedOnSearchChange', value, actionMeta);
        onInputChange?.(value);
    }, 500);

    const extOptionToInternalOption = (i: Optionable<any>) => {
        const value = getOptionValue(i?.value);
        if (!value) {
            return null;
        }
        return {
            label: i.text,
            value: value,
            scrOption: i,
        } as InternalOption;
    };

    const prepareOptions = (extOptions: Optionable<any>[]) => {
        let opts = extOptions?.map(extOptionToInternalOption) || [];

        if (!_isEmpty(value) && opts.findIndex((o) => o?.value === getOptionValue(value)) === -1) {
            opts = [extOptionToInternalOption({ value, text: value.toString() }), ...opts];
        }
        return opts.filter((o) => !!o);
    };

    useEffect(() => {
        setOptions(prepareOptions(extOptions));
    }, [extOptions, value]);

    const handleOnSearchChange = (value: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            debouncedOnSearchChange(value, actionMeta);
        }
    };

    const handleOnChange = (option: InternalOption, actionMeta: ActionMeta<any>) => {
        if (actionMeta.action === 'clear') {
            onInputChange?.('');
            onChange?.(null);
            onItemSelect?.(null);
        } else {
            onChange?.(option.scrOption?.value || null);
            onItemSelect?.(option.scrOption?.value || null);
        }
    };

    const selectValue = useMemo(() => extOptionToInternalOption(value), [value]);

    const foundValue = useMemo(() => {
        const activeValue = value;
        const foundValue = _options.filter((i) => i?.value === getOptionValue(activeValue))?.[0];
        if (!foundValue) {
            // if value is not found in the list of option, then present the activeValue.
            return extOptionToInternalOption(activeValue);
        }
        return foundValue;
    }, [value, _options]);

    const classNames = useMemo(() => getSuggestInputClassNames(!!error), [error]);

    return (
        <FormField className={className}>
            <FormFieldLabel
                readOnly={readOnly}
                disabled={disabled}
                required={required}
                label={label}
                errorLabel={error}
                htmlFor={fieldId}
            />
            <Select
                // menuIsOpen={true}
                aria-invalid={!!error}
                id={fieldId}
                placeholder={placeholder}
                value={foundValue}
                onChange={handleOnChange}
                onInputChange={handleOnSearchChange}
                isSearchable={true}
                isClearable={isClearable}
                isDisabled={readOnly || disabled}
                options={_options}
                isLoading={loading}
                // getOptionValue={(option) => option.value}
                formatOptionLabel={(option: InternalOption) =>
                    formatOptionLabel?.(option.scrOption?.value) || option.label
                }
                unstyled={true}
                styles={selectStyles}
                className={error && 'input-invalid-error'}
                classNames={classNames}
            />
            <JSONView
                hidden={true}
                data={{
                    value,
                    selectValue,
                    extOptions,
                    _options,
                }}
            />
        </FormField>
    );
};

export const getSuggestInputClassNames = (error?: boolean): ClassNamesConfig => ({
    container: (state) => 'h-input-base',
    control: (state) =>
        cn([
            'rounded border border-input bg-input px-2.5 text-[16px] md:text-base h-input-base pl-2.5',
            state.isFocused && 'outline outline-2 outline-offset-1 outline-ring',
            error && 'input-invalid-error',
        ]),
    menu: (state) => 'p-2 rounded border bg-popover text-popover-foreground shadow mt-1',
    placeholder: (state) => 'text-tertiary',
    option: (state) =>
        cn([
            'py-1.5 pl-2.5 pr-2 border border-transparent text-base rounded cursor-pointer',
            state.isSelected ? 'bg-accent text-accent-foreground' : '',
            state.isFocused ? 'border border-ring' : '',
        ]),
});

const selectStyles: StylesConfig = {
    control: (base) => {
        // drop certain styles.
        const { outline, transition, minHeight, ...rest } = base;
        return rest;
    },
};

// alias
// export const SuggestInputNew = FormFieldSuggestInput;
