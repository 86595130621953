import * as React from 'react';
import {
    ModuleNavLink,
    ModuleNavLinkProps,
    ModuleRoot,
    ModuleRoute,
    ModuleSwitch,
    useAppNavigator,
} from '@poolware/react-app-navigator';
import { PageList } from './Page.List';
import { PageView } from './View';
import { PageNew } from './Page.New';
import { PageEdit } from './Page.Edit';
import { useViewer } from '@poolware/app-shell';
import * as Message from './MessageInbox';
import { Icon, MenuBar } from '@ez/components';

export const MenuItemNavLink: React.FC<ModuleNavLinkProps> = (props) => {
    return (
        <div className={'m-1 p-1 px-4 has-[.active]:bg-tertiary rounded'}>
            <ModuleNavLink className={'p-2'} {...props} />
        </div>
    );
};

const SectionsNavbar = () => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess } = useViewer();
    if (AppNavigator.isModal || !modulesAccess.Warranties.franchiseMsgInbox) {
        return null;
    }

    return (
        <div className={'p-2'}>
            <MenuBar.Root>
                <MenuBar.Section position={'left'}>
                    <ModuleNavLink to={'/'} exact={true}>
                        <Icon name={'certificate'} /> Warranty Claims
                    </ModuleNavLink>
                    <ModuleNavLink to={'/messages'} exact={true}>
                        <Icon name={'mail'} />
                        Inbox
                    </ModuleNavLink>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <ModuleNavLink to={'/new'} exact={true}>
                        <Icon name={'plus'} color={'green'} />
                        New Claim
                    </ModuleNavLink>
                </MenuBar.Section>
            </MenuBar.Root>
        </div>
    );
};

export const Router: React.FC = () => {
    return (
        <ModuleRoot>
            <ModuleRoute path={'/'} exact={true} component={SectionsNavbar} />
            <ModuleRoute path={'/messages'} exact={true} component={SectionsNavbar} />
            <ModuleSwitch>
                <ModuleRoute path={'/'} exact={true} component={PageList} />
                <ModuleRoute path={'/new'} component={PageNew} />
                <ModuleRoute path={'/messages'} component={Message.List} />
                <ModuleRoute path={'/:id/edit'} component={PageEdit} />
                <ModuleRoute path={'/:id'} component={PageView} />
            </ModuleSwitch>
        </ModuleRoot>
    );
};
