import { useMobileAppointmentCtx } from '../Root';
import { AppointmentStatusUiConf, mapConfFromStatusMap } from '../../utils';
import { Button, Icon, Modal, useModalCtrl } from '@ez/components';
import * as React from 'react';
import { NodeType } from '@poolware/api';

export const StagePicker: React.FC<{
    open?: boolean;
    onClose?: () => void;
    stages: AppointmentStatusUiConf[];
    onChangeStatus: (type: NodeType.AppointmentStatusEnum) => Promise<void> | void;
}> = ({ open, onClose, onChangeStatus, stages }) => {
    return (
        <Modal open={open} centered={false} onClose={onClose}>
            <div className={'flex-grow flex flex-col gap-4 p-4 bg-white rounded shadow'}>
                {stages.map((conf, index) => {
                    return (
                        <Button
                            key={index}
                            variant={conf.active ? 'primary' : 'secondary'}
                            color={conf.active ? 'primary' : 'tertiary'}
                            onClick={() => onChangeStatus(conf.type)}
                            size={'lg'}
                            iconLeft={<Icon {...conf.icon} />}
                            className={'px-1 py-1 align-middle gap-0'}
                            content={conf.title}
                        />
                    );
                })}
            </div>
        </Modal>
    );
};

export const StageButton = () => {
    const modalCtrl = useModalCtrl();
    const { appointmentItem, appointmentItemMutator, workOrderMutator, changeAppointmentStatus } =
        useMobileAppointmentCtx();
    const { status } = appointmentItem;

    const onChangeStatus = async (type: NodeType.AppointmentStatusEnum) => {
        await changeAppointmentStatus(type);
        modalCtrl.onClose();
    };
    const stages = mapConfFromStatusMap(status);
    const activeButton = stages.find((c) => c.active);

    return (
        <div className={'p-4'}>
            <Button
                className={'w-full !h-[48px] border border-green flex flex-row items-center justify-between'}
                onClick={modalCtrl.onOpen}
            >
                <div className={'w-[48px] text-center'}>
                    <Icon name={'chevron left'} />
                </div>
                <div>
                    <Icon {...activeButton.icon} /> {activeButton?.title}
                </div>
                <div className={'w-[48px] text-center'}>
                    <Icon name={'chevron right'} />
                </div>
            </Button>
            <StagePicker {...modalCtrl} stages={stages} onChangeStatus={onChangeStatus} />
        </div>
    );
};
