import { NodeType } from '@poolware/api';
import { withFormikSuggestInput } from '@ez/components';
import { createSuggestInputWithQuery } from './create-connected-suggest-input/create-suggest-input-with-query';
import gql from 'graphql-tag';

const QL = gql`
    query QueryOrganisationTypesConnection {
        viewer {
            organisationTypes {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const SuggestInputOrganisationType = createSuggestInputWithQuery<NodeType.OrganisationType, string>({
    formatOptionLabel: (item) => item.name,
    getOptionLabel: (item) => item.name,
    gqlQuery: QL,
    gqlQueryConnectionPath: 'viewer.organisationTypes',
});

export const FormikSuggestInputOrganisationType = withFormikSuggestInput(SuggestInputOrganisationType);
