import { ThemeInterface } from '../app-theme';
import { createThemeByHue } from '@ez/components';
import { hslParams } from '@ez/components/dist/app-theme/utils';

let _t: ThemeInterface = undefined;

export const getTheme = (publicPrefix: string) => {
    if (_t) {
        return _t;
    }

    const huePrim = 180;
    _t = createThemeByHue({
        publicPrefix,
        huePrim: huePrim,
        hueSec: 28,
        primLum: 20,
        customisation: {
            logoPath: `${publicPrefix}/logo.png`,
            themeName: 'aquatest',
            // @ts-ignore
            navbar: {
                separatorColor: 'hsl(48,100%,62%)',
                logo: {
                    backgroundColor: hslParams(huePrim, 45, 22),
                },
            },
            sidebar: {
                backgroundColor: hslParams(huePrim, 45, 20),
            },
            border: {
                radiusSm: '0.375rem',
                radius: '0.5rem',
                radiusMd: '0.75rem',
                radiusXl: '1.0rem',
                radius2xl: '1.5rem',
                radius3xl: '2rem',
            },
        },
    });

    return _t;
};
