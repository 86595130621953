import * as React from 'react';
import { useMemo } from 'react';
import * as _ from 'lodash';
import { NodeType, useQueryStaffConnection } from '@poolware/api';
import {
    Button,
    cn,
    Display,
    Dropdown,
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
    stringFormatters,
} from '@ez/components';
import { useSafeState } from '@ez/tools';

const STAFF_UNSET = '____STAFF_UNSET____';

const prepareOptions = (staffList: NodeType.Staff[], truncateLength?: number) => {
    let options = staffList.map((staff) => {
        let name = stringFormatters.formatEntityName(staff);
        if (truncateLength) {
            name = _.truncate(name, { length: truncateLength });
        }
        return {
            key: staff.id,
            text: name,
            value: staff.id,
            staff,
        };
    });

    return [{ key: '0', text: '-----', value: STAFF_UNSET, staff: null }].concat(options);
};

export interface DropdownStaffProps {
    onChange: (value: NodeType.Staff) => any | Promise<any>;
    value: NodeType.ID;
    component?: any;
    showDisabled?: boolean;
    showDeleted?: boolean;
    truncateLength?: number;
    readonly?: boolean;
    className?: string;
}

export const DropdownStaff: React.FC<DropdownStaffProps> = (props) => {
    const {
        value, //
        onChange,
        truncateLength = 0,
        showDeleted = false,
        showDisabled = false,
        readonly = false,
        className,
        ...rest
    } = props;

    const { connectionState, connectionData } = useQueryStaffConnection({
        showDeleted: showDeleted,
        showDisabled: showDisabled,
    });

    const [isUpdating, setUpdating] = useSafeState(false);

    const options = useMemo(() => {
        return prepareOptions(connectionData, truncateLength);
    }, [connectionData]);

    const handleChange = async (value) => {
        setUpdating(true);
        const option = _.find(options, { value: value }) || { staff: null };
        await props.onChange?.(option.staff);
        setUpdating(false);
    };

    if (readonly) {
        const opt = options.find((o) => o.value === value);
        return <Display.Entity value={opt?.staff} defaultString={'--'} />;
    }

    return (
        <Select value={value || STAFF_UNSET} onValueChange={handleChange}>
            <SelectTrigger className={cn('!h-6 w-fit', className)}>
                <SelectValue />
            </SelectTrigger>
            <SelectContent>
                {options.map((op, index) => {
                    return (
                        <SelectItem key={index} value={op.value}>
                            {op.text}
                        </SelectItem>
                    );
                })}
            </SelectContent>
        </Select>
    );
};
