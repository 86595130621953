import { useAppNavigator } from '@poolware/react-app-navigator';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { WorkOrderDetailsPanelForm } from './WorkOrderDetailsPanelForm';
import { useQueryWorkOrder } from './use-query-work-order';
import { NotFoundPage, useViewer } from '@poolware/app-shell';
import { WorkOrderAppointmentPanel } from './WorkOrderAppoinmentPanel';
import { WorkOrderCustomerAndLocationPanelForm } from './WorkOrderCustomerAndLocationPanelForm';
import {
    ConnectionErrorMessage,
    PageLayout,
    PageSkeletonLoader,
    Panel,
    SectionHeader,
    SlideInDock,
    VStack,
} from '@ez/components';
import { PageHeaderToolbar } from './PageHeaderToolbar';
import { JobTodosListEditMode } from './JobTodosListEditMode';
import { JobTodosListViewMode } from './JobTodosListViewMode';
import { PageMenuBar } from './PageMenuBar';
import { NodeType } from '@poolware/api';
import { WorkOrderStaffPanel } from './WorkOrderStaffPanel';
import { ModuleColorNames } from '../../constants';
import { WorkOrderNotesPanel } from './WorkOrderNotesPanel';
import { PanelFileAttachments } from './PanelFileAttachments/PanelFileAttachments';
import { WorkOrderWaterTestReportsPanel } from './WorkOrderWaterTestReportsPanel';
import { FileAttachmentEditor } from './PanelFileAttachments/FileAttachmentEditor';
import { WorkOrderViewContext, WorkOrderViewContextType } from './WorkOrderPageContext';

interface WorkOrderViewProps {
    id?: NodeType.ID;
    embeddedMode?: boolean;
}

export const WorkOrderView: React.FC<WorkOrderViewProps> = ({ id, embeddedMode = false }) => {
    const { params, query } = useAppNavigator();
    const workOrderId = params.woId || id;
    const [editList, setEditList] = useState(false);
    const [editFiles, setEditFiles] = useState(false);
    const isViewingFromAppt = !!query['return-appt'];

    const { modulesAccess } = useViewer();
    const canChangeStaff = !modulesAccess.FieldServices?.calendarSingleStaffMode;
    const canDeleteAppt = !modulesAccess.FieldServices.calendarSingleStaffMode;
    const canChangeJobDescription = !modulesAccess.FieldServices?.calendarSingleStaffMode;

    const {
        loading,
        error,
        refetchQuery,
        node: workOrder,
    } = useQueryWorkOrder(workOrderId, {
        fetchPolicy: 'cache-and-network',
    });

    const ctx = useMemo<WorkOrderViewContextType>(
        () => ({ workOrder, refetchQueries: [refetchQuery] }),
        [workOrder, refetchQuery]
    );

    useEffect(() => {
        setEditList(false);
        setEditFiles(false);
    }, [workOrderId]);

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !workOrder) {
        return <NotFoundPage />;
    }

    const onEditJobItems = () => {
        setEditList(true);
    };

    let PageContent: React.ReactNode;
    if (editFiles) {
        PageContent = (
            <>
                <FileAttachmentEditor onDone={() => setEditFiles(false)} />
            </>
        );
    }

    const showMenuBar = !embeddedMode || !PageContent;

    return (
        <WorkOrderViewContext.Provider value={ctx}>
            <PageLayout sectionWidth={'screen-lg'}>
                {!showMenuBar && <PageMenuBar workOrder={workOrder} />}

                {PageContent}

                {!PageContent && (
                    <PageLayout.BodySection vStack={true}>
                        <VStack>
                            <PageHeaderToolbar embeddedMode={embeddedMode || isViewingFromAppt} />
                            <PageLayout.TwoColumns mobileColReverse={true}>
                                <VStack>
                                    <WorkOrderStaffPanel readonly={!canChangeStaff} />
                                    <WorkOrderAppointmentPanel canDelete={canDeleteAppt} />
                                </VStack>
                                <VStack>
                                    <WorkOrderCustomerAndLocationPanelForm locked={!canChangeJobDescription} />
                                </VStack>
                            </PageLayout.TwoColumns>
                        </VStack>

                        <VStack space={3}>
                            <div>
                                <SectionHeader>Work Order - {workOrder.title}</SectionHeader>
                                <WorkOrderDetailsPanelForm readonly={!canChangeJobDescription} />
                            </div>
                            <div>
                                {/*<SectionHeader>Job Tasks</SectionHeader>*/}
                                <Panel>
                                    <Panel.Header
                                        color={ModuleColorNames.WorkOrderItem}
                                        button={{
                                            content: 'Edit',
                                            icon: 'edit',
                                            autoHideContent: true,
                                            onClick: onEditJobItems,
                                        }}
                                    >
                                        Job Tasks
                                    </Panel.Header>
                                    <Panel.Body>
                                        <JobTodosListViewMode workOrder={workOrder} />
                                    </Panel.Body>
                                </Panel>
                            </div>

                            {modulesAccess.FieldServices?.waterTestFromWorkOrder && (
                                <div>
                                    {/*<SectionHeader>Water Test</SectionHeader>*/}
                                    <WorkOrderWaterTestReportsPanel workOrder={workOrder} />
                                </div>
                            )}
                            <div>
                                {/*<SectionHeader>Files</SectionHeader>*/}
                                <PanelFileAttachments readOnly={false} onEdit={() => setEditFiles(true)} />
                            </div>
                            <div>
                                {/*<SectionHeader>Job Notes</SectionHeader>*/}
                                <WorkOrderNotesPanel workOrder={workOrder} />
                            </div>
                        </VStack>
                    </PageLayout.BodySection>
                )}
                <div className={'mb-16 w-full'} />
                <SlideInDock open={editList} preferredWidth={'800px'} onClose={() => setEditList(false)}>
                    <JobTodosListEditMode onDone={() => setEditList(false)} />
                </SlideInDock>
                <div className={'pb-16'} />
            </PageLayout>
        </WorkOrderViewContext.Provider>
    );
};
