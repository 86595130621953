import { DeviceAttributeAlarmStatus } from './types';

export enum LifeguardTimerDays {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export interface LifeGuardTimerTimeType {
    hour: number;
    minute: number;
}
export interface LifeGuardTimerType {
    pumpSpeed: number;
    days: LifeguardTimerDays[];
    from: LifeGuardTimerTimeType;
    to: LifeGuardTimerTimeType;
}

export enum LifeGuardMode {
    MANUAL = 'MANUAL',
    SCHEDULE = 'SCHEDULE',
    BOOST = 'BOOST',
    SPA = 'SPA',
    COVER = 'COVER',
    WINTER = 'WINTER',
    MAINTENANCE = 'MAINTENANCE',
    SERVICE = 'SERVICE',
    BACKWASH = 'BACKWASH',
}

export type AttributeErrorType = {
    type: DeviceAttributeAlarmStatus;
    tag: string;
    title: string;
    message: string;
};

export type LifeGuardShadowType = {
    phSensorValue?: number | null;
    phSetpoint?: number | null;
    phSensorConnected?: boolean | null;
    temperatureSensorValue?: number | null;
    temperatureSensorConnected?: boolean | null;
    temperatureSetpoint?: number | null;
    orpSensorValue?: number | null;
    orpSensorConnected?: boolean | null;
    orpSetpoint?: number | null;
    firmwareVersion?: string | null;
    nipperVersion?: string | null;
    poolSize?: number | null;
    boostDuration?: number | null;
    backwashDuration?: number | null;
    activeAlarmCount?: number | null;
    vsdPumpSpeed?: number | null;
    vsdPumpConnected?: boolean | null;
    salinitySensorValue?: number | null;
    salinitySetpoint?: number | null;
    salinitySensorConnected?: boolean | null;

    nipperStatus?: 'Connected' | 'NotConnected' | 'BootLoad' | 'Updating' | string | null;
    nipperCellSize?: number | null;
    nipperCellOutputValue?: number | null;
    nipperCellOutputSetpoint?: number | null;

    heatPumpConnected?: boolean | null;
    acidPumpConnected?: boolean | null;

    targetCellOutput?: number | null;
    currentCellOutput?: number | null;

    phError?: AttributeErrorType | null;
    orpError?: AttributeErrorType | null;
    vsdError?: AttributeErrorType | null;
    temperatureError?: AttributeErrorType | null;
    salinityError?: AttributeErrorType | null;
    nipperError?: AttributeErrorType | null;

    modes?: LifeGuardMode[];

    timer0?: LifeGuardTimerType;
    timer1?: LifeGuardTimerType;
};
