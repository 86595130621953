import * as React from 'react';
import { Button, ButtonWithPopupProps } from '../buttons';
import { Label } from '../radix';

export interface FormFieldButtonProps extends ButtonWithPopupProps {}

export const FormFieldButton: React.FC<FormFieldButtonProps> = (props) => {
    return (
        <div className={'my-2 flex flex-col items-stretch gap-1.5'}>
            <Label aria-hidden={'true'} className="invisible">
                {'_'}
            </Label>
            <Button className={'w-fit h-input-base'} {...props} />
        </div>
    );
};
