import Page from './Page';
import gql from 'graphql-tag';
import { compose, mapProps } from '@ez/tools';
import { graphql } from 'react-apollo';
import { fromEdges, NodeType, staffFragment, StaffMutatorProps, withStaffMutators } from '@poolware/api';
import { withApolloLoading } from '@ez/components';
import { IWithPageProps, withPageProps } from '../../../components/withPageProps';

const StaffDetailsQuery = gql`
    query StaffDetailsQuery($staffId: ID!) {
        staff: node(id: $staffId) {
            ...StaffFragment
        }
    }
    ${staffFragment}
`;

export default compose(
    withPageProps(),
    graphql(StaffDetailsQuery, {
        options: (props: any) => ({
            variables: { staffId: props.staffId || props.params.staffId },
            fetchPolicy: 'network-only',
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.staff' }),
    withStaffMutators(['StaffDetailsQuery', 'StaffQuery']),
    mapProps((props) => {
        const staff = props?.data?.staff;

        return {
            ...props,
            staff,
        };
    })
)(Page);

export interface PageControlProps extends IWithPageProps, StaffMutatorProps {
    staff: NodeType.Staff;
}
