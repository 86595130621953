import * as React from 'react';
import { useMemo } from 'react';
import { NodeType, useQueryConnectionRoles } from '@poolware/api';
import { Optionable, FormFieldSuggestInput, FormFieldSuggestInputProps, withFormikSuggestInput } from '@ez/components';
import { useViewer } from '../Viewer';

export const SuggestInputRole: React.FC<FormFieldSuggestInputProps<NodeType.Role>> = ({ onChange, ...rest }) => {
    const { isAdmin } = useViewer();
    const { connectionState, connectionData } = useQueryConnectionRoles();

    const filteredRoles = useMemo(() => {
        return connectionData.filter((node) => {
            if (isAdmin) return true;
            return node.name !== 'Admin' && node.name !== 'Head Office' && node.name !== 'Organisation Admin';
        });
    }, [isAdmin, connectionData]);

    const options: Optionable<NodeType.Role>[] =
        filteredRoles?.map((item) => ({
            value: item,
            text: item.name,
        })) || [];

    return (
        <FormFieldSuggestInput
            formatOptionLabel={(o) => o.name}
            getOptionValue={(value: Optionable<any>['value']) => value?.id}
            options={options}
            loading={connectionState.loading}
            onChange={onChange}
            {...rest}
        />
    );
};

export const FormikSuggestInputRole = withFormikSuggestInput(SuggestInputRole);
