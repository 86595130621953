import * as React from 'react';
import { Alert, PageLayout, Panel, toastError } from '@ez/components';
import { PoolForm } from '../Components/PoolForm';

import { useMutationPool } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useQuerySiteDetails } from './use-query-site-details';
import { pageSpinner, useViewer } from '@poolware/app-shell';

const Page: React.FC = () => {
    const { modulesAccess } = useViewer();
    const { AppNavigator, query, params } = useAppNavigator();
    const { create } = useMutationPool({ refetchQueries: ['CustomerSitesList', 'SiteQuery'] });

    const siteId = query?.siteId || params?.siteId;

    const { loading, error, site, defaultPoolSanitiser, defaultPoolType } = useQuerySiteDetails(siteId);

    const spinner = pageSpinner(loading, error, site);
    if (spinner) return spinner;

    const organisationId = site?.entity?.franchise?.organisationType?.id;

    // if water tests a disabled, use default pool type and sanitiser
    const useDefaultPoolType = !modulesAccess?.WaterTest?.enabled;

    const initialValues = {
        name: 'Main Pool',
        volume: '',
        type: useDefaultPoolType ? defaultPoolType : null,
        sanitiser: useDefaultPoolType ? defaultPoolSanitiser : null,
    };

    const showWarning = useDefaultPoolType && !defaultPoolType && !defaultPoolSanitiser;

    const onSubmit = async (formValues: typeof initialValues) => {
        try {
            await create({
                name: formValues.name?.trim(),
                volume: Number(formValues.volume),
                type: formValues.type?.id,
                sanitiser: formValues.sanitiser?.id,
                site: { id: site?.id },
                entity: site.entity?.id,
            });
            AppNavigator.replaceToOrigin();
        } catch (error) {
            console.error('there was an error sending the query', error);
            toastError(error);
        }
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin();
    };

    return (
        <PageLayout.BodySection width={'screen-md'}>
            <PoolForm
                header={'New Pool'}
                icon={'life ring'}
                onCancel={onCancel}
                onSubmit={onSubmit}
                initialValues={initialValues}
                organisationId={organisationId}
                hidePoolTypeFields={useDefaultPoolType}
                submitButton={{ content: 'Create' }}
            >
                <Panel.Item label="Site Name" content={site.name} />
                <Panel.ItemAddress label="Address" content={site.address} />
                <Panel.ItemEntity label="Customer" content={site.entity.user} />
                {showWarning && (
                    <Alert type={'error'}>
                        Failed to resolve default pool and sanitiser types. Please contact support to resolve this
                        issue.
                    </Alert>
                )}
                <Panel.Divider />
            </PoolForm>
        </PageLayout.BodySection>
    );
};

export default Page;
