import * as React from 'react';
import { useMemo } from 'react';
import { clsxm, ConnectionTableDef, DefaultConnectionTable, Display, Icon, ScrollableLayout } from '@ez/components';
import { NodeType } from '@poolware/api';
import { NoDeviceMessage, OnlineIcon } from '../common';
import { resolveCoordinates } from './data';
import { LayoutViewProps } from '../types';
import { EZCDevicesLinkWithState, resolveColorForDeviceAlarmStatus } from '../../common';

const useTableDef = (selectedDevice: EZCDevicesLinkWithState) => {
    return useMemo(() => {
        const tableDef: ConnectionTableDef<EZCDevicesLinkWithState> = [
            {
                header: 'Devices',
                cell: (item) => {
                    const address = item.pool?.address || item.owner?.primaryAddress;
                    const isSelected = selectedDevice?.id === item.id;
                    const coordinates = resolveCoordinates(item);
                    const statusColor = resolveColorForDeviceAlarmStatus(item?.alarmStatus);
                    return (
                        <div
                            className={clsxm(
                                'px-2 py-1 rounded',
                                statusColor.bg,
                                statusColor.text,
                                isSelected && 'border-2 border-gray-400 px-1'
                            )}
                        >
                            <div className={'flex flex-row items-center gap-1'}>
                                <OnlineIcon device={item} />
                                <span>{item.device?.title || '--'}</span>
                            </div>
                            <div className={'flex flex-row items-center gap-1'}>
                                <Icon name={'user'} />
                                <Display.Entity value={item.owner} />
                            </div>
                            <div className={'flex flex-row items-center gap-1'}>
                                <Icon name={'map marker'} />
                                <Display.Address
                                    value={address}
                                    className={'text-ellipsis text-nowrap overflow-hidden max-w-80'}
                                />
                                {coordinates && <Icon name={'map pin'} color={'green'} />}
                            </div>
                        </div>
                    );
                },
            },
        ];
        return tableDef;
    }, [selectedDevice]);
};

export interface DeviceListProps extends LayoutViewProps {
    selectedDevice?: EZCDevicesLinkWithState;
}

export const DeviceList: React.FC<DeviceListProps> = ({
    onView,
    connectionState,
    connectionData,
    selectedDevice,
    onNew,
}) => {
    const tableDef = useTableDef(selectedDevice);
    const onRowClick = (device: NodeType.EZCDeviceLink) => {
        onView(device);
    };

    return (
        <ScrollableLayout>
            <ScrollableLayout.BodyScroll>
                <DefaultConnectionTable
                    onRowClick={onRowClick}
                    tableDef={tableDef}
                    connectionData={connectionData}
                    connectionState={connectionState}
                    noDataComponent={<NoDeviceMessage onNew={onNew} />}
                />
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );
};
