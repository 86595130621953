import * as React from 'react';
import { useMemo } from 'react';
import PoolDetails from '../../../CommonComponents/PoolDetails';
import {
    Form,
    FormikDefaultFormBase,
    FormikFormDebug,
    FormikTextareaField,
    HeaderButtonProps,
    Icon,
    Panel,
    SectionHeader,
    Segment,
    toastError,
    VStack,
} from '@ez/components';
import { Checkbox } from '@ez/components';
import { NodeType } from '@poolware/api';
import { useWaterlink } from '@poolware/waterlink';
import { prepareInputConf } from './preapare-input-conf';
import { WaterlinkPanel } from './WaterlinkPanel';
import { ManualProblems } from './ManualProblems';
import { WaterTestFields } from './WaterTestInputFields';
import { isMobileDevice, useIsMounted } from '@ez/tools';

import './styles.scss';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import styled from 'styled-components';
import { usePersistedFormState } from './hooks';
import { Button } from '@ez/components';

interface WaterTestFormProps {
    initialValues;
    chemicalTargets;
    onSubmit;
    onCancel;
    header;
    pool;
    prevSampleSets?: NodeType.SampleSet[];
    waterlinkAllowed: boolean;
    showOpenPDFCheckbox: boolean;
}

const isMobile = isMobileDevice();

const WaterlinkOnlineIcon = <Icon color="green" name="lab" />;

const WaterlinkOfflineIcon = <Icon color="red" name="dont" />;

export const WaterTestForm: React.FC<WaterTestFormProps> = ({
    chemicalTargets,
    initialValues,
    onSubmit,
    onCancel,
    pool,
    header,
    prevSampleSets,
    waterlinkAllowed,
    showOpenPDFCheckbox,
}) => {
    const {
        autoSubmitReport,
        expandWaterlinkPanel,
        setExpandWaterlinkPanel,
        setAutoSubmitReport,
        setAutoOpenPDF,
        autoOpenPDF,
    } = usePersistedFormState();

    const isMounted = useIsMounted();

    const inputFieldsConf = useMemo(
        () =>
            prepareInputConf({
                name: 'measurements',
                chemicalTargets,
                prevSampleSets,
            }),
        [chemicalTargets, prevSampleSets]
    );

    const waterlink = useWaterlink();
    const isServiceEnabled = waterlink.serviceEnabled;
    const isServerOnline = waterlink.server.online;
    const isDeviceOnline = waterlink.device.online;

    const showWaterlinkPanel = expandWaterlinkPanel && waterlinkAllowed && isServerOnline && isServiceEnabled;

    const waterlinkButton = useMemo((): HeaderButtonProps => {
        if (!waterlinkAllowed || isMobile) {
            return null;
        }

        if (!isServiceEnabled) {
            return {
                color: 'grey',
                popup: {
                    wide: true,
                    content: (
                        <div>
                            Waterlink Service Integration is disabled.
                            <br /> Check "Integrations &gt; Waterlink" settings
                        </div>
                    ),
                },
                content: 'Waterlink Disabled',
                icon: WaterlinkOfflineIcon,
            };
        }

        if (!isServerOnline) {
            return {
                color: 'grey',
                popup: {
                    content: 'Waterlink Connect 2 service is unreachable',
                },
                content: 'Waterlink Offline',
                icon: WaterlinkOfflineIcon,
                onClick: () => setExpandWaterlinkPanel(false),
            };
        } else if (!isDeviceOnline) {
            return {
                color: 'grey',
                popup: {
                    content: 'Waterlink device is offline',
                },
                content: 'Waterlink Offline',
                icon: WaterlinkOfflineIcon,
                onClick: () => setExpandWaterlinkPanel(!expandWaterlinkPanel),
            };
        } else {
            if (expandWaterlinkPanel) {
                return {
                    color: 'green',
                    content: 'Hide Waterlink',
                    icon: 'cancel',
                    onClick: () => setExpandWaterlinkPanel(!expandWaterlinkPanel),
                };
            } else {
                return {
                    color: 'green',
                    content: 'Use Waterlink',
                    popup: { content: 'Waterlink is available' },
                    icon: WaterlinkOnlineIcon,
                    onClick: () => setExpandWaterlinkPanel(!expandWaterlinkPanel),
                };
            }
        }
    }, [
        isServerOnline,
        isDeviceOnline,
        setExpandWaterlinkPanel,
        expandWaterlinkPanel,
        waterlinkAllowed,
        isServiceEnabled,
    ]);

    const handleOnSubmit = async (values: any, actions: FormikActions<any>) => {
        actions.setSubmitting(true);
        try {
            const newValues = { ...values, openPDFAfterSubmit: autoOpenPDF };
            await onSubmit(newValues, actions);
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e });
            toastError({ title: 'Failed', description: e.message });
        }
        if (isMounted()) {
            actions.setSubmitting(false);
        }
    };

    return (
        <div className={'water-test-form'}>
            <FormikDefaultFormBase initialValues={initialValues} enableReinitialize={false} onSubmit={handleOnSubmit}>
                {(formikBag) => {
                    const { isSubmitting } = formikBag;
                    const onWaterlinkTestFinish = () => {
                        if (autoSubmitReport) {
                            formikBag.submitForm();
                        }
                    };

                    return (
                        <VStack>
                            <>
                                <Panel>
                                    <Panel.Header>{header}</Panel.Header>
                                    <Panel.Body>
                                        <PoolDetails pool={pool} />
                                        <div className={'grid gap-4 mt-4'}>
                                            <Segment color="grey">
                                                <SectionHeader size={'small'} button={waterlinkButton}>
                                                    Measurements
                                                </SectionHeader>
                                                {showWaterlinkPanel && (
                                                    <Segment color={'purple'}>
                                                        <VStack space={1}>
                                                            <WaterlinkPanel
                                                                inputFieldsConf={inputFieldsConf}
                                                                onTestFinish={onWaterlinkTestFinish}
                                                            />
                                                            <div>
                                                                <Checkbox
                                                                    checked={autoSubmitReport}
                                                                    onChange={(_, { checked }) =>
                                                                        setAutoSubmitReport(checked)
                                                                    }
                                                                    label={'Auto Submit report on test finish'}
                                                                />
                                                                {showOpenPDFCheckbox && (
                                                                    <>
                                                                        <br />
                                                                        <Checkbox
                                                                            checked={autoOpenPDF}
                                                                            onChange={(_, { checked }) =>
                                                                                setAutoOpenPDF(checked)
                                                                            }
                                                                            label={'Open PDF on Submit'}
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                        </VStack>
                                                    </Segment>
                                                )}
                                                <WaterTestFields
                                                    inputFieldsConf={inputFieldsConf}
                                                    prevSampleSets={prevSampleSets}
                                                    chemicalTargets={chemicalTargets}
                                                />
                                            </Segment>

                                            <Segment color="grey">
                                                <SectionHeader size={'small'}>Observations</SectionHeader>
                                                <ManualProblems name={'manualProblems'} />
                                            </Segment>

                                            <Segment color="grey">
                                                <FormikTextareaField
                                                    dataTestId={'notes'}
                                                    label={'Notes'}
                                                    name={'note'}
                                                    iconLeft={false}
                                                    placeholder={'Additional notes...'}
                                                    rows={8}
                                                />
                                            </Segment>
                                        </div>
                                    </Panel.Body>
                                    <Panel.Footer className={'flex flex-row gap-1 items-center p-4'}>
                                        <Button variant={'secondary'} disabled={isSubmitting} onClick={onCancel}>
                                            Cancel
                                        </Button>
                                        <div className={'flex-grow'} />
                                        {showOpenPDFCheckbox && (
                                            <div className={'hidden md:block px-2'}>
                                                <Checkbox
                                                    checked={autoOpenPDF}
                                                    onChange={(_, { checked }) => setAutoOpenPDF(checked)}
                                                    label={'Open PDF on Submit'}
                                                />
                                            </div>
                                        )}

                                        <Button
                                            variant={'primary'}
                                            onClick={(e) => formikBag.handleSubmit(e as any)}
                                            loading={isSubmitting}
                                            disabled={isSubmitting}
                                            content={'Submit'}
                                        />
                                    </Panel.Footer>
                                </Panel>
                                <FormikFormDebug hidden={false} />
                            </>
                        </VStack>
                    );
                }}
            </FormikDefaultFormBase>
            {initialValues?.refId && (
                <span className={'my-4 text-sm text-gray-400'}>Ref. ID: {initialValues?.refId}</span>
            )}
        </div>
    );
};
