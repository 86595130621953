import * as React from 'react';
import { useCallback } from 'react';
import { useAppointmentItemQuery } from '../Queries/use-appointment-item-query';
import { useAppNavigator } from '@poolware/react-app-navigator';
import {
    Alert,
    AlertHeader,
    DisplayApolloError,
    Icon,
    PageLayout,
    PageSkeletonLoader,
    ThemeInterface,
    toastError,
    toastWarning,
} from '@ez/components';
import { getApiClient, NodeType } from '@poolware/api';
import { ApolloError } from 'apollo-client';
import { AppErrorBoundary } from '@poolware/app-shell';
import { AppointmentItemDockViewQuery } from '../AppointmentDock/query-appointment-item-dock-view';
import { useAppointmentMutators } from '../../queries/use-appointment-mutators';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { useWorkOrderMutators } from '../../queries/mutators-work-order';
import { createGlobalStyle } from 'styled-components';
import { wait } from '@ez/tools';
import { FileHandle, FileUploadFn } from '../types';

const apiClient = getApiClient();

const LoadErrorMessage: React.FC<{ error?: ApolloError }> = ({ error }) => {
    return (
        <div className={'p-2'}>
            <Alert>
                <AlertHeader>
                    <Icon name={'frown'} /> Failed to load appointment details.
                </AlertHeader>
            </Alert>
            {error && <DisplayApolloError error={error} />}
        </div>
    );
};

export interface AppointmentRootContextType {
    appointmentItem?: NodeType.AppointmentItem;
    refetchQueries?: any[];
}

const AppointmentRootContext = React.createContext<AppointmentRootContextType>({
    appointmentItem: null,
});

export const useMobileAppointmentCtx = (refetchQueries?: any[], awaitRefetchQueries?: boolean) => {
    const { AppNavigator } = useAppNavigator();
    const ctx = React.useContext(AppointmentRootContext);
    const rq = [...ctx.refetchQueries, ...(refetchQueries || [])];
    const appointmentItemMutator = useAppointmentMutators(rq);
    const serviceJobMutator = useServiceJobMutators({
        refetchQueries: rq,
    });
    const workOrderMutator = useWorkOrderMutators({
        refetchQueries: rq,
    });
    const isWorkOrderPending = ctx.appointmentItem?.isWorkOrderPending;
    const workOrder = ctx.appointmentItem?.workOrder;
    const serviceJob = ctx.appointmentItem?.serviceJob;

    const startWorkOrder = useCallback(async () => {
        try {
            if (!ctx.appointmentItem?.id) {
                throw new Error('Appointment item ID is missing');
            }
            await appointmentItemMutator.mutateAppointmentItem.startWorkOrder({
                id: ctx.appointmentItem.id,
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    }, [appointmentItemMutator, ctx.appointmentItem?.id]);

    const canAddWaterTest = !isWorkOrderPending && !!workOrder?.pool?.id && !!workOrder?.id;

    const goToNewWaterTest = useCallback(() => {
        if (!workOrder || !workOrder.pool) {
            toastWarning({ title: 'Unable to add water test', description: 'Pool is required' });
            return;
        }
        AppNavigator.navigate('/new', {
            moduleId: 'WATER_TEST',
            setOrigin: true,
            query: {
                poolId: workOrder?.pool?.id,
                woId: workOrder?.id,
            },
            state: {
                workOrder: workOrder,
            },
        });
    }, [workOrder, AppNavigator]);

    const changeAppointmentStatus = useCallback(
        async (newStatus: NodeType.AppointmentStatusEnum) => {
            const appointmentItem = ctx.appointmentItem;
            try {
                await appointmentItemMutator.mutateAppointmentItem.update({
                    id: appointmentItem?.id,
                    status: newStatus,
                });

                /// HACK: move this logic to backend.
                const wo = appointmentItem?.workOrder;
                if (!wo?.stage) {
                    return;
                }
                if (
                    wo?.stage?.type === NodeType.ServiceJobStageType.Opened ||
                    wo?.stage?.type !== NodeType.ServiceJobStageType.InProgress
                ) {
                    // Change WO status only if appointment status is changed.
                    const smap = {
                        finished: NodeType.ServiceJobStageType.Finished,
                        failed: NodeType.ServiceJobStageType.Canceled,
                    };
                    const woStatus = smap[newStatus];
                    if (woStatus !== undefined) {
                        await workOrderMutator.update({ id: wo?.id, stageType: woStatus });
                    }
                }
            } catch (error) {
                console.error(error);
                toastError({ title: 'Failed to change status', description: error.message });
            }
        },
        [appointmentItemMutator, ctx.appointmentItem?.id]
    );

    const checkIn = useCallback(async () => {
        if (ctx.appointmentItem?.isWorkOrderPending) {
            await startWorkOrder();
        }
        return changeAppointmentStatus(NodeType.AppointmentStatusEnum.InProgress);
    }, [appointmentItemMutator, ctx.appointmentItem?.id]);

    const checkOut = useCallback(
        async (status: NodeType.AppointmentStatusEnum) => {
            return changeAppointmentStatus(status);
        },
        [appointmentItemMutator, ctx.appointmentItem?.id]
    );

    const uploadFiles: FileUploadFn = async (files: FileHandle[]) => {
        if (!workOrder) {
            throw new Error('Work order is missing');
        }

        const success: { file: FileHandle; error?: null; fileId: string }[] = [];
        const failed: { file: FileHandle; error: Error }[] = [];
        for (const file of files) {
            try {
                const res = await apiClient.uploadFile(file.file, { beforeAfterStatus: file.beforeAfterStatus });
                success.push({ file, fileId: res.id, error: null });
            } catch (e) {
                failed.push({ file, error: e });
            }
        }
        const fileIds = success.map((res) => res.fileId);
        const res = await workOrderMutator.addFiles(workOrder, fileIds);
        if (res.errors) {
            //mark success as failed
            failed.push(...success.map((file) => ({ ...file, error: new Error('Failed to add file') })));
        }
        return {
            success,
            failed,
        };
    };

    return {
        ...ctx,
        workOrder,
        serviceJob,
        isWorkOrderPending,
        appointmentItemMutator,
        serviceJobMutator,
        workOrderMutator,
        startWorkOrder,
        checkIn,
        checkOut,
        changeAppointmentStatus,
        goToNewWaterTest,
        canAddWaterTest,
        uploadFiles,
    };
};

export const MobileAppointmentViewRoot: React.FC = (props) => {
    const { params } = useAppNavigator();
    const id = params.appointmentItem;
    const queryRes = useAppointmentItemQuery(id, {
        query: AppointmentItemDockViewQuery,
    });

    if (queryRes.isLoading && !queryRes.appointmentItem) {
        return (
            <div className={'p-2'}>
                <PageSkeletonLoader lineCount={5} />
            </div>
        );
    } else if (!queryRes.isLoading && !queryRes.appointmentItem) {
        return <LoadErrorMessage />;
    }

    const appointmentItem = queryRes.appointmentItem;
    const ctx = {
        appointmentItem,
        refetchQueries: [queryRes.refetchQuery],
    };

    return (
        <AppErrorBoundary>
            <AppointmentRootContext.Provider value={ctx}>
                <PageLayout
                    className={'text-[15px] flex-grow-1 bg-panel pb-40 rounded-none sm:rounded'}
                    width={'screen-sm'}
                    padding={false}
                    sectionPadding={'md'}
                    sectionWidth={'screen-sm'}
                >
                    {props.children}
                </PageLayout>
            </AppointmentRootContext.Provider>
            <MobileStyles />
        </AppErrorBoundary>
    );
};

// HACK: temporary fix for mobile styles. Overriding the main background color.
export const MobileStyles = createGlobalStyle`
    :root {
        --ez-main-background-color: ${(props: { theme: ThemeInterface }) =>
            props.theme.isDark ? props.theme.main?.background || 'black' : 'white'};
    }
`;
