import * as React from 'react';
import { useMemo, useState } from 'react';
import { Checkbox } from '../semantic-ui';
import { ConnectionTableDef, ConnectionTableProps, DefaultConnectionTable } from '../connection-table';
import { Panel, PanelHeaderProps } from '../panel';
import { useSafeState } from '@ez/tools';
import { Button } from '../buttons';

export interface PickerItem {
    id: string;
}

export interface TablePickerPanelProps<Item extends PickerItem> extends ConnectionTableProps<Item> {
    onSubmit: (selectedItems: Item[]) => any;
    onCancel: () => any;
    selectedItems?: Item[];
    panelHeader?: PanelHeaderProps;
    multiSelect?: Boolean;
    filterComp?: React.ReactNode;
}

export const TablePickerPanel: React.FC<TablePickerPanelProps<any>> = ({
    connectionData,
    connectionState,
    onSubmit,
    onCancel,
    selectedItems: externalSelectedItems = [],
    tableDef: externalTableDef,
    panelHeader,
    multiSelect = true,
    isRowSelectable = () => true,
    filterComp,
}) => {
    const [submitting, setSubmitting] = useSafeState(false);
    const [selectedItems, setSelectedItems] = useState<PickerItem[]>([]);

    const filteredConnectionData = connectionData.filter((t) => {
        return externalSelectedItems.findIndex((et) => et.id === t.id) === -1;
    });

    const handleOnCancel = () => {
        onCancel?.();
    };
    const handleOnSubmit = async () => {
        setSubmitting(true);
        await onSubmit?.(selectedItems);
        setSubmitting(false);
    };
    const onRowClick = (item: PickerItem) => {
        const isSelected = selectedItems.findIndex((p) => p.id === item.id) !== -1;

        if (multiSelect) {
            if (isSelected) {
                // remove clicked row from the list
                setSelectedItems((selectedItems) => {
                    return selectedItems.filter((i) => i.id !== item.id);
                });
            } else {
                // add clicked row to the list
                setSelectedItems((selectedItems) => {
                    return [...selectedItems, item];
                });
            }
        } else {
            if (isSelected) {
                // deselect all
                setSelectedItems([]);
            } else {
                // select only one
                setSelectedItems([item]);
            }
        }
    };

    const memoizedTableDef: ConnectionTableDef<PickerItem> = useMemo(() => {
        const tableDef: ConnectionTableDef<PickerItem> = [
            {
                header: '',
                width: 1,
                cell: (item) => {
                    const isSelected = selectedItems.findIndex((p) => p.id === item.id) !== -1;
                    return <Checkbox checked={isSelected} disabled={!isRowSelectable?.(item)} />;
                },
            },
            ...externalTableDef,
        ];
        return tableDef;
    }, [selectedItems, externalTableDef, externalSelectedItems, connectionData]);

    return (
        <Panel>
            <Panel.Header {...panelHeader} />
            <Panel.Body className={'bg-panel-section'}>
                {filterComp && <div className={'mb-4'}>{filterComp}</div>}
                <DefaultConnectionTable
                    isRowSelectable={isRowSelectable}
                    onRowClick={onRowClick}
                    connectionData={filteredConnectionData}
                    connectionState={connectionState}
                    tableDef={memoizedTableDef}
                />
            </Panel.Body>
            <Panel.Footer className={'flex flex-row justify-between'}>
                <Button onClick={handleOnCancel} content={'Cancel'} basic={true} disabled={submitting} />
                <Button
                    loading={submitting}
                    onClick={handleOnSubmit}
                    content={'Select'}
                    variant={'primary'}
                    disabled={submitting || selectedItems.length === 0}
                />
            </Panel.Footer>
        </Panel>
    );
};
