import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import {
    Checkbox,
    ConnectionTableDef,
    DefaultConnectionTable,
    Icon,
    PageLayout,
    Popup,
    TableDefColumn,
} from '@ez/components';
import * as _ from 'lodash';
import { fromEdges, NodeType } from '@poolware/api';
import { useProductCatalogActions } from '../../../redux';
import { PageMenuBar } from './PageMenuBar';
import { ActiveFiltersPillsBar } from '../../../components-redux-connected/ActiveFiltersPillsBar';
import { TruncatedDiv } from '../../../components/TruncatedDiv';

import { useConnectedQueryProductConnection, useQueryProductSearchAuxData } from '../../../queries';

const createColumnForTraitField =
    (productTraitDeclaration: NodeType.ProductTraitDeclaration, fieldsKey, valueKey: string) =>
    (traitDeclarationField): TableDefColumn<NodeType.Product> => {
        return {
            header: traitDeclarationField.name as string,
            cellProps: {
                textAlign: 'center',
            },
            cell: (pd: NodeType.Product) => {
                const productTrait = fromEdges(pd?.traits).find(
                    (t) => t?.declaration?.id === productTraitDeclaration.id
                );
                if (!productTrait) {
                    return null;
                }

                const fields = productTrait[fieldsKey];
                if (!fields) {
                    return null;
                }

                const productField = fields.find((ff) => _.get(ff, 'field.id') === traitDeclarationField.id);
                const fieldValue = _.get(productField, valueKey, '');
                const fieldUnit = _.get(traitDeclarationField, 'unit', '');
                if (fieldValue) {
                    return `${fieldValue} ${fieldUnit}`;
                } else {
                    return null;
                }
            },
        };
    };

const declarationValuesColumns = (traitDeclaration: NodeType.ProductTraitDeclaration): TableDefColumn[] => {
    if (!traitDeclaration) {
        return [];
    }

    if (!traitDeclaration.floatFields) {
        console.error(`traitDeclaration.floatFields is null`);
        return [];
    }
    if (!traitDeclaration.integerFields) {
        console.error(`traitDeclaration.integerFields is null`);
        return [];
    }
    if (!traitDeclaration.stringFields) {
        console.error(`traitDeclaration.stringFields is null`);
        return [];
    }
    if (!traitDeclaration.selectionFields) {
        console.error(`traitDeclaration.floatFields is null`);
        return [];
    }

    const cols: TableDefColumn<NodeType.Product>[] = [];

    cols.push(...traitDeclaration.floatFields.map(createColumnForTraitField(traitDeclaration, 'floatValues', 'value')));
    cols.push(
        ...traitDeclaration.integerFields.map(createColumnForTraitField(traitDeclaration, 'integerValues', 'value'))
    );
    cols.push(
        ...traitDeclaration.stringFields.map(createColumnForTraitField(traitDeclaration, 'stringValues', 'value'))
    );
    cols.push(
        ...traitDeclaration.selectionFields.map(
            createColumnForTraitField(traitDeclaration, 'selectionValues', 'value.name')
        )
    );

    return cols;
};

const declarationsListColumn = (): TableDefColumn<NodeType.Product>[] => {
    return [
        {
            header: 'Categories',
            style: { width: '280px' },
            cellProps: {
                singleLine: true,
            },
            cell: (pd) => {
                const traits = fromEdges(pd?.traits);
                const text = traits.map((t) => t.declaration?.name || '').join(', ');
                return <TruncatedDiv maxWidth={280}>{text}</TruncatedDiv>;
            },
        },
    ];
};

export const ProductSearchResultsTable: React.FC<{ showSidebar; setShowSidebar }> = ({
    showSidebar,
    setShowSidebar,
}) => {
    const { AppNavigator } = useAppNavigator();
    const pca = useProductCatalogActions();

    const { traitDeclarations, relatedBrands, relatedCompanies, flattenedDeclarations } = useQueryProductSearchAuxData(
        pca.ProductCatalogState
    );
    const { connectionData, connectionState } = useConnectedQueryProductConnection(pca);
    const [selectedItems, setSelectedItems] = useState<NodeType.Product[]>([]);

    useEffect(() => {
        setSelectedItems([]);
        return () => {
            setSelectedItems([]);
        };
    }, [pca.ProductCatalogState]);

    const tableDef: ConnectionTableDef<any> = useMemo(() => {
        const handleSingleCheckbox = (pd) => (e, data) => {
            if (data.checked) {
                setSelectedItems((selectedItems) => {
                    return [...selectedItems, pd];
                });
            } else {
                setSelectedItems((selectedItems) => {
                    return selectedItems.filter((i) => i.id !== pd.id);
                });
            }
        };

        const handleAllCheckbox = (e, data) => {
            if (data.checked) {
                setSelectedItems(connectionData);
            } else {
                setSelectedItems([]);
            }
        };

        const isIndeterminate = selectedItems.length > 0 && selectedItems.length !== connectionData.length;
        const isChecked = selectedItems.length === connectionData.length && connectionData.length > 0;
        const isDisabled = connectionData.length === 0;

        const tableDef: ConnectionTableDef<NodeType.Product> = [];

        if (0) {
            tableDef.push({
                header: (
                    <Checkbox
                        disabled={isDisabled}
                        onChange={handleAllCheckbox}
                        checked={isChecked}
                        indeterminate={isIndeterminate}
                    />
                ),
                cell: (pd) => {
                    const isSelected = selectedItems.findIndex((p) => p.id === pd.id) !== -1;
                    return <Checkbox checked={isSelected} onChange={handleSingleCheckbox(pd)} />;
                },
            });

            tableDef.push({
                header: '',
                cell: (pd) => {
                    const { franchise } = pd;
                    if (franchise) {
                        return (
                            <Popup position={'top center'} trigger={<Icon name={'building'} />}>
                                {franchise.name}
                            </Popup>
                        );
                    }
                    return null;
                },
            });
        }

        tableDef.push(
            {
                header: 'SKU',
                width: 2,
                // sortKey: 'sku',
                cell: (pd) => pd?.sku || '--',
            },
            {
                header: 'Name',
                width: 5,
                sortKey: 'name',
                cell: (pd) => {
                    const name = pd?.name || '--';
                    return <TruncatedDiv expandable={false}>{name}</TruncatedDiv>;
                },
            },
            {
                header: 'Brand',
                cell: (pd) => {
                    const brandName = pd?.brand?.name;
                    const brandCompany = pd?.brand?.company?.name;
                    return `${brandCompany ? `${brandCompany} > ` : ''} ${brandName || ''} `;
                },
            }
        );

        flattenedDeclarations.map((traitDeclaration) => {
            tableDef.push(...declarationValuesColumns(traitDeclaration.trait));
        });

        tableDef.push(...declarationsListColumn());

        return tableDef;
    }, [selectedItems, traitDeclarations]);

    const onViewProduct = (product: NodeType.Product) => {
        AppNavigator.navigateRelative(`/${product.id}`);
    };

    return (
        <PageLayout sectionWidth={'full'} width={'full'} className={'p-0'}>
            <PageMenuBar selectedItems={selectedItems} setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
            <ActiveFiltersPillsBar
                flattenedDeclarations={flattenedDeclarations}
                relatedBrands={relatedBrands}
                relatedCompanies={relatedCompanies}
            />
            <DefaultConnectionTable
                connectionData={connectionData}
                connectionState={connectionState}
                tableDef={tableDef}
                onRowClick={onViewProduct}
            />
        </PageLayout>
    );
};
