import { loadManifest } from './server-manifest';
import { makeDebugger } from '@ez/tools';
import invariant from 'invariant';

let URL = require('url');

let debug = makeDebugger('App:Config');
if (process.env.NODE_ENV === 'test') {
    // debug = console.log
}

let defaultWindowHostname = 'localhost';
try {
    defaultWindowHostname = window.location.hostname;
} catch (err) {}

const defaultBackendURL = (windowHostname) => {
    let enforceHTTP = process.env.REACT_APP_ENFORCE_HTTP || false;
    const appHostname = process.env.REACT_APP_HOSTNAME || windowHostname;
    const useDefaultAPIFormat = !process.env.REACT_APP_BACKEND_URL;
    let backendURL = process.env.REACT_APP_BACKEND_URL || appHostname;

    debug('Backend url: ' + backendURL);

    if (!(backendURL.indexOf('http://') === 0 || backendURL.indexOf('https://') === 0)) {
        debug('Provided backend url does not have http or https prefix. Attempting to fix.');
        backendURL = 'http://' + backendURL;
        debug('Fixed url: ' + backendURL);
    }

    let parsedUrl = URL.parse(backendURL);
    if (!parsedUrl.protocol) {
        // prepend 'https' and parse it again
        parsedUrl = URL.parse(`https://${backendURL}`);
    }

    debug('Parsed backend url');
    debug(parsedUrl);

    // set 'https' by default if not provided
    debug("Enforcing 'https:' protocol by default");
    parsedUrl.protocol = 'https:';

    // remove trailing slash
    parsedUrl.hostname = parsedUrl.hostname.replace(/\/$/, '');

    // remove 'www'
    if (useDefaultAPIFormat) {
        parsedUrl.hostname = parsedUrl.hostname.replace(/^www\./, '');
    }

    if (enforceHTTP) {
        debug("REACT_APP_ENFORCE_HTTP flag is set. Enforcing 'http'");
        parsedUrl.protocol = 'http:';
    }

    // localhost is a special case, use 'http'
    if (parsedUrl.hostname === 'localhost') {
        debug("Localhost. Enforcing 'http'");
        parsedUrl.protocol = 'http:';
    }

    // assemble final url

    let url = parsedUrl.hostname;
    if (useDefaultAPIFormat) {
        url = `api.${url}`;
    }
    if (parsedUrl.port) {
        url = `${url}:${parsedUrl.port}`;
    }

    url = `${parsedUrl.protocol}//${url}`;

    return url;
};

export interface AppConfigInterface {
    appEnv: {
        node_env: string;
        isProduction: boolean;
        app_env: string;
    };
    api: {
        backendURL: string;
        manifestJsonURL: string;
        passwordResetURL: string;
        restAPIBaseUrl: string;
        graphqlAPIURL: string;
    };
    manifest: any;
    appName: string;
    themeName: string;
    themeNameAdmin?: string;
    codeVersion: string;
    appProfile: {
        variant?: 'franchises' | 'dealers' | string;
        profile: string;
    };
    autoUpdater?: {
        /** If true, the app will force reload page on navigation if an update is pending */
        reloadOnNavigation?: boolean;
    };
    hotjar: {
        enabled: boolean;
    };
    zendesk: {
        enabled: boolean;
    };
    rollbar: {
        enabled: boolean;
        code_version?: string;
        accessToken?: string;
        environment?: string;
    };
    google_maps: {
        enabled: boolean;
        apiToken?: string;
    };
    google_analytics: {
        enabled: boolean;
        analyticsId?: string;
    };
    apps: {
        frontend: string;
        admin: string;
        configurator: string;
        account: string;
        warrantyHQ: string;
        ezConnectAdmin?: string;
        lifeguardPortal?: string;
    };
    disabledOrgs: string[];
}

export const loadConfig = (windowHostname = defaultWindowHostname): AppConfigInterface => {
    const manifest = loadManifest();

    const backendBaseURL = manifest.REACT_APP_BACKEND_URL || 'REACT_APP_BACKEND_URL is not defined';
    invariant(backendBaseURL, 'REACT_APP_BACKEND_URL is not defined');

    const api = {
        backendURL: backendBaseURL,
        passwordResetURL: backendBaseURL + '/public/reset-password.html',
        restAPIBaseUrl: backendBaseURL + '/api/v1',
        graphqlAPIURL: backendBaseURL + '/graphql',
        manifestJsonURL: backendBaseURL + '/manifest/frontend.json',
    };

    const appEnv = {
        node_env: manifest.NODE_ENV,
        isProduction: manifest.NODE_ENV === 'production',
        app_env: manifest.APP_ENV,
    };

    const hotjar = {
        enabled: false,
        ...manifest.hotjar,
    };
    const zendesk = {
        enabled: false,
        ...manifest.zendesk,
    };

    const rollbar = {
        enabled: appEnv.isProduction, // rollbar is enabled by default in production
        ...manifest.rollbar,
    };

    const google_maps = {
        enabled: false,
        // apiToken: 'AIzaSyDJclCsIyYc-EJAXWXXIvSMtu6Ct-sOO5Q',
        ...manifest.google_maps,
    };

    const google_analytics = {
        enabled: false,
        ...manifest.google_analytics,
    };

    return {
        codeVersion: manifest.codeVersion || 'latest',
        autoUpdater: {
            reloadOnNavigation: manifest.autoUpdater?.reloadOnNavigation !== false,
        },
        manifest: manifest,
        appEnv: appEnv,
        api: api,
        themeName: manifest.REACT_APP_THEME_NAME || 'poolware',
        themeNameAdmin: manifest.REACT_APP_THEME_NAME_ADMIN || 'poolware-admin',
        appName: manifest.REACT_APP_APP_NAME || 'Poolware',
        appProfile: {
            variant: manifest.appProfile?.variant,
            profile: manifest.appProfile?.profile || 'default',
        },
        rollbar: rollbar,
        zendesk: zendesk,
        hotjar: hotjar,
        google_maps: google_maps,
        google_analytics: google_analytics,
        apps: manifest.apps,
        disabledOrgs: manifest.disabledOrgs || [],
    };
};

const config = loadConfig();

export const getAppConfig = () => config;

const Rollbar = require('rollbar');

const rollbar = new Rollbar({
    enabled: config.rollbar.enabled,
    accessToken: config.rollbar.accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    captureIp: 'anonymize',
    autoInstrument: {
        log: config.appEnv.isProduction,
    },
});

export const getRollbar = (): any => rollbar;
