import gql from 'graphql-tag';
import { MutationHookOptionsEZCDeviceState, useMutationEZCDeviceState } from '@poolware/api';

const MutationLQ = gql`
    mutation EZCDeviceStateMutation($input: EZCDeviceStateMutationInput!) {
        EZCDeviceState(input: $input) {
            EZCDeviceState {
                definitionId
                state
            }
        }
    }
`;

export function useMutationDeviceState(options?: MutationHookOptionsEZCDeviceState) {
    return useMutationEZCDeviceState({
        mutation: MutationLQ,
        awaitRefetchQueries: true,
        ...options,
    });
}
