import * as React from 'react';
import { useMemo } from 'react';
import { Query, QueryResult, useQuery } from 'react-apollo';
import _get from 'lodash/get';
import {
    GenericQueryConnection,
    QueryConnectionConfigProps,
    QueryConnectionResult,
    QueryConnectionState,
} from './types';
import { NodeType } from '../api-types';
import { NetworkStatus } from 'apollo-client';
import { fromEdges } from '../utils';

export interface QueryConnectionProps<T extends NodeType.Node = NodeType.AnyNode> extends GenericQueryConnection<T> {
    variables: any;
    connectionConfig: QueryConnectionConfigProps;
    connectionPath: string;
    postfetchFilter?: (items: T[]) => T[];
}

function getQueryConnectionsResultParser<Item extends NodeType.Node = NodeType.AnyNode>({
    connectionConfig,
    connectionPath,
    postfetchFilter,
    fetchPolicy,
}) {
    return (queryResult: QueryResult): QueryConnectionResult<Item> => {
        const { loading, error, data, refetch, networkStatus, fetchMore } = queryResult;
        // console.log(queryResult);
        // console.log('loading', loading);
        // console.log('networkStatus', networkStatus);

        const pageInfo: NodeType.PageInfo = _get(data, `${connectionPath}.pageInfo`);
        const pageMeta: NodeType.PageMeta = _get(data, `${connectionPath}.pageMeta`);
        const pageCount = pageMeta?.pageCount;
        const totalCount = pageMeta?.totalCount;
        const isPolling = networkStatus === NetworkStatus.poll;
        const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
        const isRefetching = networkStatus === NetworkStatus.refetch;

        // Note: always check for the data at the connectionPath.
        // If there are some data in the cache, it will return the cached data first while a request is in flight.
        // When the request is finished, the data will be updated.
        let connectionData = fromEdges<Item>(_get(data, connectionPath));
        if (postfetchFilter) {
            connectionData = postfetchFilter(connectionData);
        }

        let isInitialLoading = loading && !isPolling && !isFetchingMore && !isRefetching;

        if (fetchPolicy === 'cache-and-network') {
            // Check also for whether `connectionData` is not empty.
            // If there is some data, then it is not the initial loading.
            const hasCachedData = connectionData.length > 0;
            isInitialLoading = isInitialLoading && !hasCachedData;
        }

        const enhancedConnectionState: QueryConnectionState = {
            ...connectionConfig,
            refetch,
            fetchMore,
            loading,
            isInitialLoading,
            isFetchingMore,
            isPolling,
            pageCount,
            totalCount,
            error,
            pageInfo,
            pageMeta,
        };

        const newProps: QueryConnectionResult<Item> = {
            connectionData: connectionData,
            connectionState: enhancedConnectionState,
        };

        return newProps;
    };
}

export const QueryConnection: React.FC<QueryConnectionProps<NodeType.Node>> = (props) => {
    const { query, variables, connectionConfig, connectionPath, postfetchFilter, pollInterval, skip } = props;
    const fetchPolicy = props.fetchPolicy || 'cache-and-network';
    const parser = getQueryConnectionsResultParser({ fetchPolicy, connectionConfig, connectionPath, postfetchFilter });

    return (
        <Query
            skip={skip}
            query={query}
            variables={variables}
            notifyOnNetworkStatusChange={true}
            partialRefetch={false}
            fetchPolicy={fetchPolicy}
            pollInterval={pollInterval}
            errorPolicy={props.errorPolicy || 'all'}
        >
            {(queryResult: QueryResult) => {
                const newProps = parser(queryResult);
                return <>{props.children(newProps)}</>;
            }}
        </Query>
    );
};

// hook
export function useQueryConnection<Item extends NodeType.AnyNode = NodeType.AnyNode>(
    props: Omit<QueryConnectionProps<Item>, 'children'>
) {
    const { query, variables, connectionConfig, connectionPath, postfetchFilter, pollInterval, skip } = props;
    const fetchPolicy = props.fetchPolicy || 'cache-and-network';
    const parser = useMemo(
        () =>
            getQueryConnectionsResultParser<Item>({
                fetchPolicy,
                connectionConfig,
                connectionPath,
                postfetchFilter,
            }),
        [fetchPolicy, connectionConfig, connectionPath, postfetchFilter]
    );

    const res = useQuery(query, {
        skip,
        variables,
        fetchPolicy,
        notifyOnNetworkStatusChange: true,
        pollInterval,
        errorPolicy: props.errorPolicy || 'all',
    });

    return useMemo(
        () => ({
            ...parser(res),
            refetchQuery: {
                query: query,
                variables: variables,
            },
        }),
        [res, parser, query, variables]
    );
}
