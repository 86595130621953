import gql from 'graphql-tag';
import { createUseQueryNodeHook, customerFragment, NodeType } from '@poolware/api';

const QL = gql`
    query CustomerVendLinkQuery($id: ID!) {
        node(id: $id) {
            ... on Customer {
                id
                ...CustomerFragment
                root {
                    franchise {
                        id
                        name
                        vend {
                            id
                        }
                    }
                }
                vendLink {
                    id
                    vendUUID
                    isDeleted
                    vendURL
                    vendCustomer {
                        id
                        firstName
                        lastName
                        companyName
                        email
                        phone
                        mobile
                        fax
                        cursor
                        customerCode
                        createdAt
                        updatedAt
                        deletedAt
                        note
                        customField1
                        customField2
                        customField3
                        customField4
                        doNotEmail
                        balance
                        loyaltyBalance
                        loyaltyEmailSent
                        physicalAddress {
                            streetLine1
                            streetLine2
                            suburb
                            city
                            postCode
                            state
                            countryId
                        }
                        postalAddress {
                            streetLine1
                            streetLine2
                            suburb
                            city
                            postCode
                            state
                            countryId
                        }
                    }
                }
            }
        }
    }
    ${customerFragment}
`;

export const useQueryCustomer = createUseQueryNodeHook<NodeType.Customer>(QL);
