import * as React from 'react';
import { Button, cn, Select, SelectContent, SelectItem, SelectTrigger } from '@ez/components';
import gql from 'graphql-tag';
import { NodeType, useDefaultConnectionState, useQueryConnection } from '@poolware/api';
import { DEFAULT_APPOINTMENT_GROUP_ID } from '../../constants';

const QUERY = gql`
    query AppointmentGroup_Picker {
        viewer {
            appointmentGroups(first: 100) {
                edges {
                    node {
                        id
                        title
                        defaultColor
                        description
                        priority
                        colorPalette
                    }
                }
            }
        }
    }
`;

const useQueryAppointmentGroupsConnection = (props?) => {
    const { variables, connectionState } = useDefaultConnectionState({
        ...props,
    });

    return useQueryConnection({
        query: QUERY,
        // fetchPolicy: 'cache-first',
        connectionPath: 'viewer.appointmentGroups',
        connectionConfig: connectionState,
        variables: variables,
    });
};

const prepareOptions = (agList: NodeType.AppointmentGroup[]) => {
    let options = agList.map((ag) => ({
        key: ag.id,
        text: ag.title,
        value: ag.id,
        color: ag.defaultColor,
        ag: ag,
    }));

    const defaultGroupOption = {
        key: '0',
        text: 'Default Group',
        value: DEFAULT_APPOINTMENT_GROUP_ID,
        color: 'white',
        ag: null,
    };

    return [defaultGroupOption].concat(options);
};

interface AppointmentGroupSingleSelectorProps {
    selectedGroupId?: NodeType.ID;
    onChange: (ag: NodeType.AppointmentGroup) => any;
    readonly?: boolean;
    className?: string;
}

export const AppointmentGroupSingleSelector: React.FC<AppointmentGroupSingleSelectorProps> = ({
    selectedGroupId,
    onChange,
    readonly,
    className,
}) => {
    const { connectionData } = useQueryAppointmentGroupsConnection();

    const options = prepareOptions(connectionData);

    const handleChange = (value) => {
        if (options.length < 2) {
            // do nothing if list contains 0 or 1 item;
            return;
        }
        const option = options.find((o) => o.value === value) || options[0];
        onChange && onChange(option.ag);
    };

    const value = !selectedGroupId ? DEFAULT_APPOINTMENT_GROUP_ID : selectedGroupId;

    const selectedOption = options.find((o) => o.value === value) || options[0];

    const trigger = (
        <Button
            disabled={readonly}
            variant={'secondary'}
            className={cn('min-w-6 ez-btn-base border-input h-input-base', className)}
        >
            <div
                className={'border size-4 rounded-full'}
                style={{
                    backgroundColor: selectedOption.color,
                }}
            />
            <div>{selectedOption.text}</div>
        </Button>
    );

    if (readonly) {
        return trigger;
    }

    return (
        <Select value={selectedOption.value} onValueChange={handleChange}>
            <SelectTrigger asChild={true}>{trigger}</SelectTrigger>
            <SelectContent>
                {options.map((op, index) => {
                    return (
                        <SelectItem key={index} value={op.value}>
                            <div className="flex items-center pr-2 gap-2">
                                <div
                                    className={'border size-5 rounded-full'}
                                    style={{
                                        backgroundColor: op.color,
                                    }}
                                />
                                <div>{op.text}</div>
                            </div>
                        </SelectItem>
                    );
                })}
            </SelectContent>
        </Select>
    );
};
