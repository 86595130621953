import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import _merge from 'lodash/merge';
import moment from 'moment';
// NOTE: it has to be imported from /types file, otherwise it will cause circular dependency
// TODO: need to fix
import { CalResourceMode, CalViewLayoutMode, CalViewMode } from '../Scheduler/types';
import { CalendarStateType, hasActiveFilters, schedulerInitialState } from './reducers-calendar-state';
import { computeDateRange } from './helpers';
import { isScreenWider } from '@ez/tools';

const isWidescreen = isScreenWider(768);

export const deserializeSchedulerState = (schedulerState?: Partial<CalendarStateType>) => {
    if (!schedulerState || _isEmpty(schedulerState)) {
        return _cloneDeep(schedulerInitialState);
    }

    const state: CalendarStateType = _cloneDeep(schedulerInitialState);

    if (schedulerState.showLeftSidebar !== undefined) {
        if (isWidescreen) {
            state.showLeftSidebar = schedulerState.showLeftSidebar;
        } else {
            // don't show sidebar on mobile devices by default
            state.showLeftSidebar = false;
        }
    }

    if (schedulerState.showRightSidebar !== undefined) {
        state.showRightSidebar = schedulerState.showRightSidebar;
    }

    if (schedulerState.filters !== undefined) {
        const filters = _pick<CalendarStateType['filters']>(
            schedulerState.filters,
            'staffIds',
            'teamId',
            'isRecurring',
            'excludeAppointmentGroupsIds',
            'includeStatuses',
            'autoHideEmptyResources'
        );
        state.filters = _merge(state.filters, filters);
        state.filters.hasActiveFilters = hasActiveFilters(state.filters);
    }

    if (schedulerState.viewMode !== undefined) {
        const vm = schedulerState.viewMode;
        const modes = [CalViewMode.MONTH, CalViewMode.DAY, CalViewMode.WEEK, CalViewMode.AGENDA];
        if (modes.includes(vm)) {
            state.viewMode = vm;
        }
    }

    if (schedulerState.activeDate !== undefined) {
        state.activeDate = moment(schedulerState.activeDate).toDate();
    }

    if (schedulerState.previewApptId) {
        state.previewApptId = schedulerState.previewApptId;
    }

    if (schedulerState.zoomFactor !== undefined) {
        state.zoomFactor = schedulerState.zoomFactor;
    }

    if (state.activeDate && state.viewMode) {
        state.displayingDateRange = computeDateRange(state.activeDate, state.viewMode);
    }

    if (schedulerState.layoutMode !== undefined) {
        const vm = schedulerState.layoutMode;
        const modes = [CalViewLayoutMode.GRID, CalViewLayoutMode.LIST];
        if (modes.includes(vm)) {
            state.layoutMode = vm;
        }
    }

    const allowedResourceModes = [CalResourceMode.COMBINED, CalResourceMode.SPLIT];
    if (schedulerState.resourceMode !== undefined) {
        if (allowedResourceModes.includes(schedulerState.resourceMode)) {
            state.resourceMode = schedulerState.resourceMode;
        }
    }

    if (schedulerState.resourceModeWeek !== undefined) {
        if (allowedResourceModes.includes(schedulerState.resourceModeWeek)) {
            state.resourceModeWeek = schedulerState.resourceModeWeek;
        }
    }

    return state;
};

export const serializeSchedulerState = (state: CalendarStateType) => {
    return _pick(state, [
        'showLeftSidebar',
        'viewMode',
        'layoutMode',
        'resourceMode',
        'resourceModeWeek',
        'showRightSidebar',
        'filters',
        'previewApptId',
        'zoomFactor',
        'autoHideEmptyResources',
    ]);
};
