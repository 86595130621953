import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, VariantProps } from 'class-variance-authority';

import { clsxm, cn } from '../../utils';

const buttonBase = cva('ez-btn-base', {
    variants: {
        size: {
            xxs: 'h-5 px-1 text-xs',
            mini: 'h-6 px-1 text-xs', // alias for xxs
            xs: 'h-6 px-2 text-xs',
            tiny: 'h-6 px-2 text-xs', // alias for xs
            sm: 'h-8 px-3 text-sm',
            small: 'h-8 px-3 text-sm', // alias for sm
            md: 'h-button-base px-4 text-base',
            medium: 'h-button-base px-4 text-base', //alias for md
            lg: 'h-11 px-6 py-4 text-lg font-bold',
            large: 'h-11 px-6 py-4 text-lg font-bold', // alias for lg
        },
        segmentPosition: {
            first: 'rounded-r-none border-r-0',
            middle: 'rounded-none border-r-0',
            last: 'rounded-l-none',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});

const buttonVariantPrimary = {
    primary: 'bg-button-primary text-primary-foreground border-button-primary hover:bg-button-primary-hover',
    secondary: 'bg-button-secondary text-secondary-foreground border-button-secondary hover:bg-button-secondary-hover',
    tertiary: 'bg-button-tertiary text-tertiary-foreground border-button-tertiary hover:bg-button-tertiary-hover',
    grey: 'bg-grey text-primary-foreground border-grey',
    red: 'bg-red border-red text-white border-red',
    orange: 'bg-orange text-primary-foreground border-orange',
    green: 'bg-green text-primary-foreground  border-green',
    teal: 'bg-teal text-primary-foreground border-teal',
    blue: 'bg-blue text-primary-foreground border-teal',
    purple: 'bg-purple text-primary-foreground border-purple',
};

const buttonVariantSecondary = {
    primary: 'text-primary border-button-primary hover:bg-button-primary-hover',
    secondary: 'text-tertiary border-button-secondary hover:bg-button-secondary-hover',
    tertiary: 'text-tertiary border-button-tertiary hover:bg-button-tertiary-hover',
    grey: 'border-grey',
    red: 'text-red border-red',
    orange: 'text-orange border-orange',
    green: 'text-green border-green',
    teal: 'text-teal border-teal',
    blue: 'text-blue border-blue',
    purple: 'text-purple border-purple',
};

const buttonVariantTertiary = {
    primary: 'text-primary hover:bg-button-primary-hover',
    secondary: 'text-secondary hover:bg-button-secondary-hover',
    tertiary: 'text-tertiary hover:bg-button-tertiary-hover',
    grey: 'text-grey',
    red: 'text-red',
    orange: 'text-red',
    green: 'text-green',
    teal: 'text-teal',
    blue: 'text-blue',
    purple: 'text-purple',
};

const variantLUT = {
    primary: buttonVariantPrimary,
    secondary: buttonVariantSecondary,
    tertiary: buttonVariantTertiary,
};

type Colors = 'primary' | 'secondary' | 'tertiary' | 'red' | 'orange' | 'green' | 'teal' | 'blue' | 'grey' | 'purple';
type Variants = 'primary' | 'secondary' | 'tertiary';

const getColorClasses = (variant: Variants, color: Colors) => {
    let classNames = variantLUT[variant]?.[color];
    if (!classNames) {
        classNames = buttonVariantSecondary['secondary'];
    }
    return cn(classNames, variant === 'secondary' && 'bg-none', variant === 'tertiary' && 'bg-none border-opacity-0');
};

type ButtonBaseProps = VariantProps<typeof buttonBase>;

export interface ButtonRadixProps
    extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick' | 'color' | 'name'>,
        ButtonBaseProps {
    asChild?: boolean;
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>, data?: ButtonRadixProps) => void | Promise<any> | any;
    color?: Colors;
    variant?: Variants;
    loading?: boolean;
}

const ButtonRadix = React.forwardRef<HTMLButtonElement, ButtonRadixProps>(
    (
        {
            className,
            variant = 'secondary',
            size = 'md',
            color = 'primary',
            asChild = false,
            onClick,
            segmentPosition,
            ...props
        },
        ref
    ) => {
        const Comp = asChild ? Slot : 'button';
        const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            if (props.disabled) {
                e.preventDefault();
                e.stopPropagation();
            }
            onClick?.(e, props);
        };

        const colorClasses = getColorClasses(variant, color);

        return (
            <Comp
                onClick={handleClick}
                data-size={size}
                className={clsxm([buttonBase({ size, segmentPosition }), colorClasses, className])}
                ref={ref}
                {...props}
            />
        );
    }
);
ButtonRadix.displayName = 'Button';

export { ButtonRadix, buttonBase as buttonVariants };
