import * as React from 'react';
import { Label, LabelProps } from '../radix';
import _isString from 'lodash/isString';

// TODO: deprecate FormLabel
export const FormLabel = Label;

function createMarkup(htmlString: string) {
    return { __html: htmlString };
}

export interface FormFieldLabelProps extends LabelProps {
    label?: string | React.ReactNode;
    required?: boolean;
    errorLabel?: React.ReactNode;
    htmlFor?: string;
    disabled?: boolean;
    readOnly?: boolean;
}

export const FormFieldLabel: React.FC<FormFieldLabelProps> = ({
    required,
    errorLabel,
    htmlFor,
    label,
    children,
    ...props
}) => {
    let requiredSymbol = null;
    let errorMessage = null;

    if (required) {
        requiredSymbol = <span className={'pl-1 text-red leading-none'}>*</span>;
    }

    if (errorLabel && _isString(errorLabel)) {
        // TODO: Remove `dangerouslySetInnerHTML` (Search for TOFIX1 tag in the code.)
        errorMessage = <span className={'text-sm text-red ml-2'} dangerouslySetInnerHTML={createMarkup(errorLabel)} />;
    } else if (errorLabel) {
        errorMessage = <span className={'text-sm text-red ml-2'}>{errorLabel}</span>;
    }

    if (!errorMessage && !label && !children) {
        return null;
    }

    return (
        <Label htmlFor={htmlFor} {...props}>
            {label || children}
            {requiredSymbol}
            {errorMessage}
        </Label>
    );
};
