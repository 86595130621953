import _isArray from 'lodash/isArray';
import { NodeType } from '../api-types';

export const toEdges = (items = []) => {
    if (!_isArray(items)) throw new Error('expected array');

    return {
        edges: items.map((item) => ({
            node: item,
        })),
        pageMeta: {
            totalCount: items.length,
        },
    };
};

export const fromEdges = <T extends any>(item: NodeType.Edge<T> | T[] | null = null): T[] => {
    if (!item) return [];
    if (_isArray(item)) return item as T[];
    const edgeItem = item as NodeType.Edge<T>;
    if (!_isArray(edgeItem.edges)) return [];
    return edgeItem.edges.map((edge) => edge.node);
};

function unbase64(i) {
    return new Buffer(i, 'base64').toString('utf8');
}

export const fromGlobalId = (globalId) => {
    let unbasedGlobalId = unbase64(globalId);
    let delimiterPos = unbasedGlobalId.indexOf(':');
    return {
        type: unbasedGlobalId.substring(0, delimiterPos),
        id: unbasedGlobalId.substring(delimiterPos + 1),
    };
};
