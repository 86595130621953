import * as React from 'react';
import { ProductSearchResultsTable } from './ProductSearchResultsTable';
import SearchBar from './SearchSideBar';
import { MenuBar, PageLayout, ScrollableLayout, useStateSidebar } from '@ez/components';

const ProductHome: React.FC = () => {
    const { showSidebar, setShowSidebar } = useStateSidebar('productCatalog.list.sidebar');
    const sideBar = (
        <ScrollableLayout>
            <ScrollableLayout.MenuBar>
                <MenuBar.Section position={'left'}>
                    <MenuBar.HeaderItem color={'grey'} icon={'filter'}>
                        Filters
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item onClick={() => setShowSidebar(false)} icon={'angle double left'} color={'grey'} />
                </MenuBar.Section>
            </ScrollableLayout.MenuBar>
            <ScrollableLayout.BodyScroll>
                <SearchBar />
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );

    const main = (
        <ScrollableLayout block={false}>
            <ScrollableLayout.BodyScroll>
                <ProductSearchResultsTable showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );
    return (
        <PageLayout.ThreeColumnManaged
            leftSidebar={sideBar}
            main={main}
            showLeft={showSidebar}
            onCloseLeft={() => setShowSidebar(false)}
            layoutColumns={'auto'}
        />
    );
};

export default ProductHome;
