import * as React from 'react';
import { compose } from '@ez/tools';
import { IAppNavigatorProps, ModuleLink, ModuleNavLink, withAppNavigator } from '@poolware/react-app-navigator';
import { ConnectionTableDef, DefaultConnectionTable, Icon, PageLayout, StickyMenuBar, VStack } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { QueryProductCompanyConnection } from '../../../queries/QueryProductCompanyConnection';
import { MenuBar } from '@ez/components';

class ListProductCompanies extends React.Component<PageControlProps> {
    onAdd = () => {
        this.props.AppNavigator.navigate('/new-company', { relativeToModule: true, setOrigin: true });
    };

    render() {
        return (
            <PageLayout>
                <StickyMenuBar>
                    <MenuBar.Section>
                        <MenuBar.Item>
                            <ModuleNavLink to={'/'}>All Companies</ModuleNavLink>
                        </MenuBar.Item>
                        <MenuBar.Item>
                            <ModuleNavLink to={'/brands'}>All Brands</ModuleNavLink>
                        </MenuBar.Item>
                    </MenuBar.Section>
                    <MenuBar.Section position={'right'}>
                        <MenuBar.Item icon={'plus'} color={'green'} onClick={this.onAdd} title={'Add Company'} />
                    </MenuBar.Section>
                </StickyMenuBar>

                <QueryProductCompanyConnection>
                    {({ connectionState, connectionData }) => {
                        const tableDef: ConnectionTableDef<NodeType.ProductCompany> = [
                            {
                                header: 'Product Company',
                                cellProps: {
                                    width: 2,
                                },
                                cell: (pd) => <ModuleLink to={`/${pd.id}`}>{pd.name}</ModuleLink>,
                            },
                            {
                                header: 'Brands',
                                cellProps: {
                                    singleLine: false,
                                },
                                cell: (pd) => {
                                    const brands = fromEdges(pd?.brands)
                                        .map((b) => b.name)
                                        .join(', ');
                                    return <>{brands}</>;
                                },
                            },
                            {
                                header: 'Visibility',
                                cellProps: {
                                    width: 2,
                                },
                                cell: (pd) => {
                                    const { franchise } = pd;
                                    if (franchise) {
                                        return (
                                            <div>
                                                <Icon name={'building'} />
                                                {franchise.name}
                                            </div>
                                        );
                                    } else {
                                        return 'Global';
                                    }
                                },
                            },
                        ];

                        return (
                            <DefaultConnectionTable
                                connectionData={connectionData}
                                connectionState={connectionState}
                                tableProps={{ singleLine: false }}
                                tableDef={tableDef}
                            />
                        );
                    }}
                </QueryProductCompanyConnection>
            </PageLayout>
        );
    }
}

export default compose(withAppNavigator())(ListProductCompanies);

export interface PageControlProps extends IAppNavigatorProps {}
