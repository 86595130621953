import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { PanelNavigationStack, PanelNavigationStackProvider } from '../Components/PanelNavigationStack';
import { AppointmentView, AppointmentViewProps } from './AppointmentView';
import { useAppointmentItemQuery } from '../Queries/use-appointment-item-query';
import { AppointmentItemDockViewQuery } from './query-appointment-item-dock-view';
import { useAppointmentViewCtx } from './AppointmentViewContext';
import { useAppNavigator } from '@poolware/react-app-navigator';
import {
    Alert,
    AlertHeader,
    DisplayApolloError,
    Icon,
    MenuBar,
    PageSkeletonLoader,
    ScrollableLayout,
} from '@ez/components';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { NodeType } from '@poolware/api';
import { ApolloError } from 'apollo-client';
import { AppErrorBoundary } from '@poolware/app-shell';

const LoadErrorMessage: React.FC<{ onClose: () => any; error?: ApolloError }> = ({ onClose, error }) => {
    return (
        <ScrollableLayout>
            <ScrollableLayout.MenuBar size={'tiny'}>
                <MenuBar.Section>
                    <MenuBar.Item onClick={onClose} icon={'cancel'} />
                    <MenuBar.HeaderItem>
                        <Icon color={ModuleColorNames.Appointment} name={ModuleIconNames.Appointment} />
                        Appointment
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
            </ScrollableLayout.MenuBar>

            <ScrollableLayout.BodyScroll>
                <div className={'p-2'}>
                    <Alert>
                        <AlertHeader>
                            <Icon name={'frown'} /> Failed to load appointment details.
                        </AlertHeader>
                    </Alert>
                    {error && <DisplayApolloError error={error} />}
                </div>
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );
};

export interface AppointmentViewDockProps {
    appointmentItemId?: NodeType.ID;
    onClose: () => any | Promise<any>;
    onDidDelete?: () => any | Promise<any>;
    onShowInCalendar?: (item: NodeType.AppointmentItem) => any | Promise<any>;
    showServiceJobLink?: boolean;
    allowDockModeOption?: boolean;
    canShowDockModePin?: boolean;
}

export const AppointmentViewDock: React.FC<AppointmentViewDockProps> = (props) => {
    const { params } = useAppNavigator();
    const previewItemCtx = useAppointmentViewCtx();
    const id = props.appointmentItemId || params.appointmentItem;
    const queryRes = useAppointmentItemQuery(id, {
        query: AppointmentItemDockViewQuery,
    });

    useEffect(() => {
        const refetchQueries = [queryRes.refetchQuery];
        previewItemCtx.addRefetchQueries(refetchQueries);
        return () => {
            previewItemCtx.removeRefetchQueries(refetchQueries);
        };
    }, [id]);

    const root = useMemo(() => {
        const _props: AppointmentViewProps = {
            ...props,
            ...queryRes,
        };
        return {
            component: AppointmentView,
            name: 'appointment-view',
            props: _props,
        };
    }, [queryRes, props]);

    if (queryRes.isLoading && !queryRes.appointmentItem) {
        return (
            <div className={'p-2'}>
                <PageSkeletonLoader lineCount={5} />
            </div>
        );
    } else if (!queryRes.isLoading && !queryRes.appointmentItem) {
        return <LoadErrorMessage onClose={props.onClose} error={queryRes.error} />;
    }

    return (
        <AppErrorBoundary>
            <PanelNavigationStackProvider>
                <PanelNavigationStack root={root} stackId={id} />
            </PanelNavigationStackProvider>
        </AppErrorBoundary>
    );
};
