import Page from './Page';
import { compose, mapProps } from '@ez/tools';
import { AuthConnectProps, NodeType, StaffMutatorProps, withStaffMutators } from '@poolware/api';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withApolloLoading } from '@ez/components';
import { IWithPageProps, withPageProps } from '../../../components/withPageProps';

const QL = gql`
    query RolesQuery($franchiseId: ID!) {
        franchise: node(id: $franchiseId) {
            id
            ... on Franchise {
                id
                name
            }
        }
    }
`;

export default compose(
    withPageProps(),
    graphql(QL, {
        options: (props: any) => {
            const franchiseId = props?.query?.franchiseId || props.franchiseId || '';
            return {
                variables: { franchiseId },
            };
        },
    }),
    withApolloLoading(),
    mapProps((props: AuthConnectProps & any) => {
        const franchiseId = props?.query?.franchiseId || props.franchiseId;
        const franchise = franchiseId ? props.data?.franchise : undefined;

        return {
            ...props,
            franchise,
        };
    }),
    withStaffMutators(['StaffDetailsQuery', 'QueryStaffConnection', 'StaffListQuery', 'FranchiseViewQuery'])
)(Page);

export interface PageControlProps extends IWithPageProps, StaffMutatorProps {
    franchise?: NodeType.Franchise;
}
