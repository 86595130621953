import { NavbarMenuSection, NavbarRoot } from './NavbarRoot';
import { NavbarLogo } from './NavbarLogo';
import { NavbarMenuItem, NavbarMenuLabel } from './NavbarMenuItem';
import { NavbarDropdown } from './NavbarDropdownMenu';

export const Navbar = {
    Root: NavbarRoot,
    Logo: NavbarLogo,
    Section: NavbarMenuSection,
    Item: NavbarMenuItem,
    Label: NavbarMenuLabel,
    Dropdown: NavbarDropdown,
};
