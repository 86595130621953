import * as React from 'react';
import { cn } from '../utils';

export const FormField = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
    ({ className, ...props }, ref) => (
        <div
            ref={ref}
            className={cn(
                [
                    'min-w-40 my-2 flex flex-col items-stretch gap-1.5',
                    'group-[.ez-child-grow]:flex-1',
                    'has-[:disabled]:cursor-not-allowed has-[:disabled]:opacity-70 has-[:disabled]:pointer-events-none',
                ],
                className
            )}
            {...props}
        />
    )
);
