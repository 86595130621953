import { DeviceStateType, LifeGuardShadowType, resolveColorForAttributeAlarmStatus } from '../../../common';
import {
    Alert,
    clsxm,
    ConnectionTableDef,
    DefaultConnectionTable,
    DevOnly,
    Icon,
    Popup,
    Switch,
    toastError,
} from '@ez/components';
import * as React from 'react';
import { AttributeModel, AttributeRenderer } from './AttributeRenderer';
import { AttributesSetType, mapDeviceStateToAttributesSet } from '../../../common/lifeguard-attributes-map';
import { DeviceContextProps } from './types';
import { useMutationDeviceState } from './use-mutation-device-state';

const TargetNameContainer: React.FC<{ attributeSet: AttributesSetType }> = ({ attributeSet }) => {
    const color = resolveColorForAttributeAlarmStatus(attributeSet.error?.type);
    if (attributeSet.error) {
        return (
            <Popup
                content={
                    <span>
                        <span className={'text-bold'}>{attributeSet.error?.type}: </span>
                        {attributeSet.error.message}
                    </span>
                }
                trigger={
                    <div
                        className={clsxm(
                            'py-1 px-3 rounded flex flex-row items-baseline justify-between w-full',
                            color?.bg
                        )}
                    >
                        <span>{attributeSet.name || '-'}</span>
                        <Icon name={'warning sign'} />
                    </div>
                }
            />
        );
    }

    return (
        <div className={'py-1 px-3'}>
            <span>{attributeSet.name || '-'}</span>
        </div>
    );
};

export const PanelTargets: React.FC<DeviceContextProps> = ({ device, refetchQueries }) => {
    const [debug, setDebug] = React.useState(false);
    const deviceState = device.deviceState;

    const { update } = useMutationDeviceState({
        refetchQueries: refetchQueries,
    });

    if (!deviceState) {
        return <Alert type={'warning'}>Device state is not available</Alert>;
    }

    const state: DeviceStateType<LifeGuardShadowType> = deviceState.state?.lifeguard || {};
    // const state = lifeguardStateExample;

    const lgAttributes = mapDeviceStateToAttributesSet(state);

    const onChangeAttribute = async (attrModel: AttributeModel, newValue: any) => {
        try {
            console.log('onChangeAttribute', { attrModel, newValue });
            await update({
                device: device.device.id,
                scope: 'lifeguard',
                input: {
                    [attrModel.attributeKey]: newValue,
                },
            });
        } catch (e) {
            console.error('onChangeAttribute', e);
            toastError(e);
        }
    };

    const tableDef: ConnectionTableDef<AttributesSetType> = [
        {
            header: 'Target',
            width: 3,
            cell: (s) => {
                return <TargetNameContainer attributeSet={s} />;
            },
        },
        {
            header: 'Value',
            width: 3,
            cellProps: { textAlign: 'center' },
            cell: (s) => <AttributeRenderer attrModel={s.value} debug={debug} onChange={onChangeAttribute} />,
        },
        {
            header: 'Setpoint',
            width: 3,
            cellProps: { textAlign: 'center' },
            cell: (s) => <AttributeRenderer attrModel={s.setpoint} debug={debug} onChange={onChangeAttribute} />,
        },
        {
            header: 'Connected',
            width: 3,
            cellProps: { textAlign: 'center' },
            cell: (s) => {
                return <AttributeRenderer attrModel={s.isConnected} debug={debug} onChange={onChangeAttribute} />;
            },
        },
    ];

    return (
        <>
            <DefaultConnectionTable tableDef={tableDef} connectionData={lgAttributes} />
            <DevOnly>
                <Switch checked={!!debug} onCheckedChange={setDebug} />
            </DevOnly>
        </>
    );
};
