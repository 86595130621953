import * as React from 'react';
import { ScrollArea } from './ScrollArea';
import {
    Button,
    FormField,
    FormikFormFieldLabel,
    JSONView,
    Popover,
    PopoverContent,
    PopoverTrigger,
    useId,
} from '@ez/components';
import { useField } from 'formik';
import { LifeGuardTimerTimeType } from '../../../../common';
import { formatLGTime } from './DisplayLGTimer';

interface FormikDatePickerProps {
    name: string;
    label?: string;
    required?: boolean;
    id?: string;
}

const ScrollPicker: React.FC<{ items: number[]; value: any; onChange: (value: any) => void }> = ({
    items,
    value,
    onChange,
}) => {
    return (
        <ScrollArea className="h-72 flex flex-col items-stretch">
            {items.map((h) => (
                <Button
                    variant={value === h ? 'primary' : 'tertiary'}
                    size={'sm'}
                    className={'w-full'}
                    key={h}
                    onClick={() => onChange(h)}
                >
                    {h?.toString().padStart(2, '0')}
                </Button>
            ))}
        </ScrollArea>
    );
};

export const FormikTimePicker: React.FC<FormikDatePickerProps> = ({ name, label, required, id, ...props }) => {
    const fieldId = useId(id);
    const [open, setOpen] = React.useState(false);

    const [field, meta, helpers] = useField<LifeGuardTimerTimeType | null>(name);

    const setHour = (hour: number) => {
        helpers.setValue({ ...field.value, hour });
    };
    const setMinute = (minute: number) => {
        helpers.setValue({ ...field.value, minute });
    };
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const minutes = Array.from({ length: 60 }, (_, i) => i);

    const value = field.value;

    const hasError = meta.touched && (meta.error as any);

    const labelError = hasError?.hour || hasError?.minute;

    return (
        <FormField>
            <FormikFormFieldLabel
                errorLabel={labelError}
                htmlFor={fieldId}
                label={label}
                name={name}
                required={required}
            />
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild={true}>
                    <Button
                        aria-invalid={!!hasError}
                        iconLeft={'clock'}
                        variant={'secondary'}
                        iconRight={'chevron down'}
                        className={'border-input h-input-base text-default'}
                        onClick={() => setOpen(true)}
                        content={formatLGTime(field.value)}
                    />
                </PopoverTrigger>
                <PopoverContent sideOffset={8} className={'py-2 px-4'}>
                    <div className={'flex flex-row gap-1 p-2 rounded border'}>
                        <div className={'flex-1 flex flex-col items-stretch gap-1  p-1'}>
                            <label className={'text-center font-bold'}>Hour</label>
                            <ScrollPicker value={value?.hour} items={hours} onChange={setHour} />
                        </div>
                        <div className={'flex-1 flex flex-col items-stretch gap-1 p-1'}>
                            <label className={'text-center font-bold'}>Minute</label>
                            <ScrollPicker value={value?.minute} items={minutes} onChange={setMinute} />
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
            {/*<JSONView data={meta.error} />*/}
        </FormField>
    );
};
