import gql from 'graphql-tag';
import {
    addressFragment,
    NodeType,
    QueryConnectionExternalProps,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';

const QL = gql`
    query QueryEzcDeviceLinkConnection(
        $first: Int
        $page: Int
        $sort: EZCDeviceLinkViewerEzcDeviceLinksSort
        $search: EZCDeviceLinkViewerEzcDeviceLinksSearch
    ) {
        viewer {
            ezcDeviceLinks(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        createdAt
                        deviceName
                        label
                        franchise {
                            id
                            name
                        }
                        device {
                            id
                            title
                            version
                            deviceId
                            definition {
                                definitionId
                                id
                                familyId
                            }
                        }
                        pool {
                            id
                            name
                            volume
                            address {
                                id
                                ...AddressFragment
                            }
                        }
                        owner {
                            id
                            contactName
                            crn
                            user {
                                id
                                firstName
                                lastName
                            }
                            primaryPhone {
                                id
                                data
                                label
                            }
                            primaryEmail {
                                id
                                data
                                label
                            }
                            primaryAddress {
                                id
                                ...AddressFragment
                            }
                        }
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${addressFragment}
`;

export interface QueryEzcDeviceLinkConnectionProps extends QueryConnectionExternalProps<NodeType.EZCDeviceLink> {}

function mapPropsToSearchQuery(
    props?: QueryEzcDeviceLinkConnectionProps
): NodeType.EZCDeviceLinkViewerEzcDeviceLinksSearch {
    if (!props) {
        return undefined;
    }

    const search = {};
    return search;
}

export function useQueryEzcDeviceLinksConnection(
    props?: QueryConnectionExternalProps<NodeType.EZCDeviceLink, NodeType.EZCDeviceLinkViewerEzcDeviceLinksSearch>
) {
    const connectionPath = 'viewer.ezcDeviceLinks';
    const { variables, connectionState } = useDefaultConnectionState<NodeType.EZCDeviceLinkViewerEzcDeviceLinksSearch>({
        defaultPageSize: 100,
        // search: mapPropsToSearchQuery(props),
        ...props,
    });

    return useQueryConnection<NodeType.EZCDeviceLink>({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
