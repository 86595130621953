import { compose, withProps } from '@ez/tools';
import {
    convertToConfigValue,
    NodeType,
    useMutationAppointment,
    useMutationAppointmentItem,
    withAppointmentItemMutator,
    withAppointmentMutator,
} from '@poolware/api';
import invariant from 'invariant';
import _trim from 'lodash/trim';
import {
    sanitizeAppointmentBaseData,
    sanitizeAppointmentStartDate,
    sanitizeRecurrence,
} from './mutation-input-sanitisers';
import { MutationHookOptionsAppointmentItem } from '@poolware/api';

export interface CreateAppointmentInput extends Partial<NodeType.Appointment> {
    recurrence?: NodeType.Recurrence;
    isRecurring?: boolean;
    duration?: number;
    serviceJob?: NodeType.ServiceJob;
    workOrderTemplate?: NodeType.WorkOrderTemplate;
}

export interface UpdateAppointmentItemInput extends Partial<NodeType.AppointmentItem> {
    recurrence?: NodeType.Recurrence;
    isRecurring?: boolean;
    future?: boolean;
    mergeStates?: boolean;
}

export interface UpdateAppointmentItemBaseDataInput {
    staff?: NodeType.NodeOrId<NodeType.Staff>;
    customer?: NodeType.NodeOrId<NodeType.Customer>;
    pool?: NodeType.NodeOrId<NodeType.Pool>;
    address?: NodeType.NodeOrId<NodeType.Address>;
    group?: NodeType.NodeOrId<NodeType.AppointmentGroup>;
    note?: string;
    status?: NodeType.AppointmentStatusEnum;
    completed?: boolean;
    color?: String;
}

export interface UpdateAppointmentItemSingleInput extends UpdateAppointmentItemBaseDataInput {
    date?: Date;
    duration?: number;
}

export interface UpdateAppointmentItemFutureInput extends UpdateAppointmentItemBaseDataInput {
    dateTime?: Date;
    duration?: number;
}

export interface DeleteAppointmentItemInput {
    appointmentItem: NodeType.AppointmentItem;
    future?: boolean;
    all?: boolean;
}

export const createAppointmentMutationConfig = (
    input: CreateAppointmentInput
): NodeType.CreateAppointmentMutationInput => {
    const { isRecurring, startDate, duration, recurrence } = input;

    invariant(startDate, `Expected 'startDate' to be of type Date. Value: ${startDate}.`);

    const durationToSubmit = duration;

    invariant(durationToSubmit > 0, `Expected duration to be positive number, Value: ${durationToSubmit}`);

    let mutationConfig: NodeType.CreateAppointmentMutationInput = {
        startDate: sanitizeAppointmentStartDate(startDate),
        duration: durationToSubmit,
        status: NodeType.AppointmentStatusEnum.NotStarted,
        address: null,
        customer: null,
        note: null,
        staff: null,
        pool: null,
        serviceJob: null,
        workOrder: null,
    };

    mutationConfig.note = convertToConfigValue({ values: input, key: 'note' });
    mutationConfig.customer = convertToConfigValue({ values: input, key: 'customer', isSourceNode: true });
    mutationConfig.pool = convertToConfigValue({ values: input, key: 'pool', isSourceNode: true });
    mutationConfig.staff = convertToConfigValue({ values: input, key: 'staff', isSourceNode: true });
    mutationConfig.address = convertToConfigValue({ values: input, key: 'address', isSourceNode: true });
    mutationConfig.serviceJob = convertToConfigValue({ values: input, key: 'serviceJob', isSourceNode: true });
    mutationConfig.workOrder = convertToConfigValue({ values: input, key: 'workOrder', isSourceNode: true });
    mutationConfig.color = convertToConfigValue({ values: input, key: 'color' });
    mutationConfig.group = convertToConfigValue({ values: input, key: 'group', isSourceNode: true });
    mutationConfig.workOrderTemplate = convertToConfigValue({
        values: input,
        key: 'workOrderTemplate',
        isSourceNode: true,
        isSwitch: true,
    });

    if (mutationConfig.note !== undefined && mutationConfig.note !== null) {
        mutationConfig.note = _trim(mutationConfig.note);
    }

    if (isRecurring && recurrence) {
        mutationConfig.recurrence = sanitizeRecurrence(recurrence);
    } else {
        mutationConfig.recurrence = null;
    }

    return mutationConfig;
};

const updateAppointmentItemMutationConfig = (
    id: NodeType.ID,
    input: UpdateAppointmentItemInput
): NodeType.AppointmentItemUpdateMutationInput => {
    const { startDate, recurrence, duration, future, mergeStates } = input;

    invariant(id, 'AppointmentItem.id is not provided');

    let mutationConfig: NodeType.AppointmentItemUpdateMutationInput = {
        id: id,
    };

    if (startDate) {
        invariant(startDate, `Expected 'startDate' to be of type Date. Value: ${startDate}.`);
        invariant(duration, `Expected 'duration' to be provided`);

        const durationToSubmit = duration;

        invariant(durationToSubmit > 0, `Expected duration to be positive number, Value: ${durationToSubmit}`);

        mutationConfig.startDate = sanitizeAppointmentStartDate(startDate);
        mutationConfig.duration = durationToSubmit;
    }

    mutationConfig.note = convertToConfigValue({ values: input, key: 'note' });
    mutationConfig.customer = convertToConfigValue({ values: input, key: 'customer', isSourceNode: true });
    mutationConfig.pool = convertToConfigValue({ values: input, key: 'pool', isSourceNode: true });
    mutationConfig.staff = convertToConfigValue({ values: input, key: 'staff', isSourceNode: true });
    mutationConfig.address = convertToConfigValue({ values: input, key: 'address', isSourceNode: true });
    mutationConfig.color = convertToConfigValue({ values: input, key: 'color' });
    mutationConfig.group = convertToConfigValue({ values: input, key: 'group', isSourceNode: true });
    mutationConfig.status = convertToConfigValue({ values: input, key: 'status' });

    if (mutationConfig.note !== undefined && mutationConfig.note !== null) {
        mutationConfig.note = _trim(mutationConfig.note);
    }

    mutationConfig.future = future;
    mutationConfig.mergeStates = mergeStates;

    if (recurrence) {
        mutationConfig.recurrence = sanitizeRecurrence(recurrence);
    } else if (recurrence === null) {
        mutationConfig.recurrence = null;
    }

    return mutationConfig;
};

export interface AppointmentMutatorInjected {
    mutateAppointment: ReturnType<typeof useMutationAppointment>;
    mutateAppointmentItem: ReturnType<typeof useMutationAppointmentItem>;
}

const createAppointmentMutator = ({ mutateAppointmentItem, mutateAppointment }: AppointmentMutatorInjected) => {
    const createAppointment = async (values: CreateAppointmentInput) => {
        return mutateAppointment.create(createAppointmentMutationConfig(values));
    };

    const updateAppointmentItem = async (
        appointmentItem: NodeType.NodeOrId<NodeType.AppointmentItem>,
        values: UpdateAppointmentItemInput,
        options?: MutationHookOptionsAppointmentItem
    ) => {
        const conf = updateAppointmentItemMutationConfig(NodeType.extractId(appointmentItem), values);
        return mutateAppointmentItem.update(conf, options);
    };

    const updateAppointmentItemSingle = async (
        appointmentItem: NodeType.NodeOrId<NodeType.AppointmentItem>,
        values: UpdateAppointmentItemSingleInput,
        options?: MutationHookOptionsAppointmentItem
    ) => {
        const id = NodeType.extractId(appointmentItem);
        invariant(id, 'AppointmentItem.id is not provided');

        const { date, duration } = values;

        let mutationConfig: NodeType.AppointmentItemUpdateSingleMutationInput = {
            id: id,
            status: convertToConfigValue({ values: values, key: 'status' }),
            ...sanitizeAppointmentBaseData(values),
        };

        if (date) {
            invariant(date, `Expected 'date' to be of type Date. Value: ${date}.`);
            invariant(duration, `Expected 'duration' to be provided`);
            invariant(duration > 0, `Expected duration to be positive number, Value: ${duration}`);
            mutationConfig.date = sanitizeAppointmentStartDate(date);
            mutationConfig.duration = duration;
        }

        return mutateAppointmentItem.updateSingle(mutationConfig, options);
    };

    const updateAppointmentItemFuture = async (
        appointmentItem: NodeType.NodeOrId<NodeType.AppointmentItem>,
        values: UpdateAppointmentItemFutureInput,
        options?: MutationHookOptionsAppointmentItem
    ) => {
        const id = NodeType.extractId(appointmentItem);
        invariant(id, 'AppointmentItem.id is not provided');

        const { dateTime, duration } = values;

        let mutationConfig: NodeType.AppointmentItemUpdateFutureMutationInput = {
            id: id,
            ...sanitizeAppointmentBaseData(values),
        };

        if (dateTime) {
            invariant(dateTime, `Expected 'date' to be of type Date. Value: ${dateTime}.`);
            invariant(duration, `Expected 'duration' to be provided`);
            invariant(duration > 0, `Expected duration to be positive number, Value: ${duration}`);
            mutationConfig.time = sanitizeAppointmentStartDate(dateTime);
            mutationConfig.duration = duration;
        }

        return mutateAppointmentItem.updateFuture(mutationConfig, options);
    };

    const updateAppointmentItemRecurrence = async (
        appointmentItem: NodeType.NodeOrId<NodeType.AppointmentItem>,
        values: {
            startDate: Date;
            fromDate?: Date;
            endDate?: Date;
            duration?: number;
            recurrence: NodeType.Recurrence;
        }
    ) => {
        const mutationConf: NodeType.AppointmentItemChangeRecurrenceMutationInput = {
            id: NodeType.extractId(appointmentItem),
            stopCurrentAt: values.fromDate,
            startNewFrom: values.startDate,
            recurrence: sanitizeRecurrence(values.recurrence),
            duration: values.duration,
            withinPatternBlockId: true,
        };
        return mutateAppointmentItem.changeRecurrence(mutationConf);
    };

    const changeAppointmentItemStatus = async (
        appointmentItem: NodeType.NodeOrId<NodeType.AppointmentItem>,
        newStatus: NodeType.AppointmentStatusEnum
    ) => {
        return mutateAppointmentItem.updateSingle({ id: NodeType.extractId(appointmentItem), status: newStatus });
    };

    const changeAppointmentItemStaff = async (
        appointmentItem: NodeType.NodeOrId<NodeType.AppointmentItem>,
        newStaff: NodeType.Staff | null,
        future: boolean = false
    ) => {
        if (future) {
            return mutateAppointmentItem.updateFuture({
                id: NodeType.extractId(appointmentItem),
                staff: newStaff?.id || null,
            });
        } else {
            return mutateAppointmentItem.updateSingle({
                id: NodeType.extractId(appointmentItem),
                staff: newStaff?.id || null,
            });
        }
    };

    const changeAppointmentItemGroup = async (
        appointmentItem: NodeType.NodeOrId<NodeType.AppointmentItem>,
        ag: NodeType.AppointmentGroup | null,
        future: boolean = false
    ) => {
        if (future) {
            return mutateAppointmentItem.updateFuture({
                id: NodeType.extractId(appointmentItem),
                group: ag?.id || null,
            });
        } else {
            return mutateAppointmentItem.updateSingle({
                id: NodeType.extractId(appointmentItem),
                group: ag?.id || null,
            });
        }
    };

    const changeAppointmentItemColor = async (
        appointmentItem: NodeType.NodeOrId<NodeType.AppointmentItem>,
        color: string | null,
        future: boolean = false
    ) => {
        return mutateAppointmentItem.update({
            id: NodeType.extractId(appointmentItem),
            color: color ? color : null,
            future: future,
            mergeStates: true,
        });
    };

    const deleteAppointmentItem = async (
        appointmentItem: NodeType.NodeOrId<NodeType.AppointmentItem>,
        future: boolean
    ) => {
        return mutateAppointmentItem.delete({
            id: NodeType.extractId(appointmentItem),
            future: future,
            withinSharedPatternBlockId: true,
        });
    };

    const addServiceJob = async (
        appointmentItem: NodeType.NodeOrId<NodeType.AppointmentItem>,
        serviceJob: NodeType.NodeOrId<NodeType.ServiceJob>
    ) => {
        return mutateAppointmentItem.updateSingle({
            id: NodeType.extractId(appointmentItem),
            serviceJob: NodeType.extractId(serviceJob),
        });
    };

    const addSale = async (appointmentItem: NodeType.NodeOrId<NodeType.AppointmentItem>, input: { note: string }) => {
        return mutateAppointmentItem.addSale({
            id: NodeType.extractId(appointmentItem),
            note: input ? input.note : undefined,
        });
    };

    return {
        AppointmentMutator: {
            createAppointment,
            updateAppointmentItem,
            updateAppointmentItemSingle,
            updateAppointmentItemFuture,
            updateAppointmentItemRecurrence,
            changeAppointmentItemStatus,
            changeAppointmentItemStaff,
            changeAppointmentItemGroup,
            changeAppointmentItemColor,
            deleteAppointmentItem,
            addSale,
            addServiceJob,
        },
    };
};

export const withAppointmentMutators = (refetchQueries) =>
    compose(
        withAppointmentItemMutator(refetchQueries),
        withAppointmentMutator(refetchQueries),
        withProps(createAppointmentMutator)
    );

export interface AppointmentMutatorProps
    extends AppointmentMutatorInjected,
        ReturnType<typeof createAppointmentMutator> {}

export const useAppointmentMutators = (refetchQueries?: any[], awaitRefetchQueries?: boolean) => {
    const mutateAppointmentItem = useMutationAppointmentItem({
        refetchQueries: refetchQueries,
        awaitRefetchQueries: !!awaitRefetchQueries,
    });
    const mutateAppointment = useMutationAppointment({
        refetchQueries: refetchQueries,
        awaitRefetchQueries: !!awaitRefetchQueries,
    });

    return {
        mutateAppointment,
        mutateAppointmentItem,
        ...createAppointmentMutator({ mutateAppointmentItem, mutateAppointment }),
    };
};
