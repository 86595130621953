import * as React from 'react';
import { getIn, useFormikContext } from 'formik';
import { NodeType } from '@poolware/api';
import { FormikCustomerLookupField } from './FormikCustomerLookupField';
import { CustomerAddressList } from './CustomerAddressList';
import { FormFieldLabel } from '@ez/components';
import { FormField } from '@ez/components';

export interface FormikCustomerAndLocationInputFieldsProps {
    readOnly?: boolean;
    readOnlyCustomer?: boolean;
    readOnlyPool?: boolean;
    customerRequired?: boolean;
}

export const FormikCustomerAndLocationInputFields: React.FC<FormikCustomerAndLocationInputFieldsProps> = ({
    customerRequired,
    readOnlyPool,
    readOnlyCustomer,
    readOnly,
}) => {
    const { values, setFieldValue } = useFormikContext();
    const customer = getIn(values, 'customer');
    const pool = getIn(values, 'pool');
    const address = getIn(values, 'address');

    const onCustomerRemove = () => {
        setFieldValue('pool', null);
        setFieldValue('address', null);
    };

    const onAddressSelect = (address: NodeType.Address) => {
        setFieldValue('pool', null);
        setFieldValue('address', address);
    };

    const onPoolSelect = (pool: NodeType.Pool) => {
        setFieldValue('pool', pool);
        if (pool?.address) {
            setFieldValue('address', pool.address);
        } else {
            setFieldValue('address', null);
        }
    };

    return (
        <div className={'flex flex-col'}>
            <FormikCustomerLookupField
                readOnly={readOnlyCustomer || readOnly}
                name={'customer'}
                label={'Customer'}
                onRemove={onCustomerRemove}
                required={customerRequired}
            />
            {customer?.id && (
                <FormField>
                    <FormFieldLabel label={'Address'} />
                    <div className={'pl-2'}>
                        <CustomerAddressList
                            readOnly={readOnlyPool || readOnly}
                            selectedPoolId={pool?.id}
                            // Don't select address if pool is provided
                            selectedAddressId={pool ? undefined : address?.id}
                            customerId={customer?.id}
                            onAddressSelect={onAddressSelect}
                            onPoolSelect={onPoolSelect}
                        />
                    </div>
                </FormField>
            )}
        </div>
    );
};
