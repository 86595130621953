import { ThemeInterface } from './theme.interface';

import { deepMerge, hsl } from './utils';

const HSL_WHITE = 'hsl(0, 0%, 100%)';

export const createThemeByHue = (input: {
    publicPrefix?: string;
    huePrim: number;
    primSat?: number;
    primLum?: number;
    hueSec?: number;
    secSat?: number;
    secLum?: number;
    customisation?: Partial<ThemeInterface>;
    isDark?: boolean;
}): ThemeInterface => {
    const { hueSec, huePrim, publicPrefix, isDark = false, customisation = {} } = input || {};
    const saturation = input.primSat ?? 45;
    const lum = input.primLum ?? 46;

    const _hsl = (hue: number, sat: number, lum: number, alpha?: number) => {
        const calcThemedLum = (l: number, offset: number = 0) => {
            if (isDark) {
                return 100 - l - offset;
            } else {
                return l + offset;
            }
        };
        const _lum = calcThemedLum(lum);
        return hsl(hue, sat, _lum, alpha);
    };

    const sat2 = 77;
    const lum2 = 80;

    const color_primary = _hsl(huePrim, saturation, lum);
    const color_secondary_0 = _hsl(hueSec, sat2, lum2);

    const theme: ThemeInterface = {
        publicPrefix: publicPrefix,
        themeName: 'default',
        logoPath: publicPrefix ? `${publicPrefix}/logo.png` : null,
        iconPath: publicPrefix ? `${publicPrefix}/icon.png` : null,
        isDark: isDark,
        color: {
            primary: color_primary,
            primary_25: _hsl(huePrim, saturation, lum, 0.25),
            secondary: _hsl(hueSec, sat2, lum2),
            secondaryForeground: _hsl(hueSec, sat2, 20),
            tertiary: _hsl(huePrim, 15, 85),
            tertiaryForeground: _hsl(huePrim, 15, 20),
        },
        text: {
            size: {
                base: '14px',
            },
            color: {
                base: isDark ? hsl(huePrim, saturation, 100) : hsl(huePrim, 4, 15, 0.85),
                primary: isDark ? hsl(huePrim, 90, 90) : hsl(huePrim, 50, 25),
                secondary: isDark ? hsl(hueSec, 90, 90) : hsl(hueSec, 50, 30),
                secondaryForeground: isDark ? hsl(hueSec, 90, 90) : hsl(hueSec, 10, 15, 0.85),
                tertiary: isDark ? hsl(huePrim, 20, 90, 0.8) : hsl(huePrim, 4, 35, 0.85),
                primaryForeground: _hsl(huePrim, 15, 99),
                tertiaryForeground: _hsl(huePrim, 4, 15, 0.85),
                strong: _hsl(huePrim, 4, 5),
                accentForeground: _hsl(hueSec, 50, 10, 10),
                link: isDark ? hsl(210, 100, 65) : hsl(210, 83, 51),
                linkHover: isDark ? hsl(210, 100, 70) : hsl(210, 53, 51),
            },
        },
        border: {
            radiusSm: '0.375rem',
            radius: '0.5rem',
            radiusMd: '0.75rem',
            radiusXl: '1.0rem',
            radius2xl: '1.5rem',
            radius3xl: '2rem',
            color: _hsl(huePrim, 20, 90),
            colorStrong: _hsl(huePrim, 20, 80),
        },
        main: {
            // background: _hsl(huePrim, 15, 95),
            backgroundColor: _hsl(huePrim, 15, 90),
            backgroundColorTertiary: _hsl(huePrim, 33, 94),
            backgroundColorAccent: _hsl(huePrim, 67, 42, 0.15),
            backgroundColorNotes: _hsl(60, 50, 98),
        },
        sidebar: {
            backgroundColor: hsl(huePrim, saturation, lum * 0.7),
            menuItem: {
                height: '2rem',
                container: {
                    padding: '0 calc(0.5em - 2px)',
                },
                content: {
                    padding: '0.25rem 0.5rem',
                    backgroundColor: hsl(huePrim, saturation, lum * 0.7),
                    backgroundColorHover: hsl(huePrim, saturation, lum * 0.6),
                    backgroundColorActive: hsl(huePrim, saturation, lum * 0.5),
                    ascentColorActive: hsl(hueSec, sat2, lum2),
                    textColor: '#FFFFFF',
                },
            },
        },
        navbar: {
            backgroundColor: hsl(huePrim, saturation, lum * 0.75),
            menuItem: {
                backgroundColor: hsl(huePrim, saturation, lum * 0.75),
                backgroundColorHover: hsl(huePrim, saturation, lum * 0.6),
                backgroundColorActive: hsl(huePrim, saturation, lum * 0.5),
                textColor: HSL_WHITE,
            },
            logo: {
                backgroundColor: HSL_WHITE,
                textColor: hsl(huePrim, 10, 20),
            },
            separatorColor: color_secondary_0,
        },
        panel: {
            header: {
                backgroundColor: _hsl(huePrim, 10, 96),
                color: _hsl(huePrim, 20, 10),
            },
            body: {
                backgroundColor: _hsl(huePrim, 10, 98),
            },
            section: {
                backgroundColor: _hsl(huePrim, 10, 95),
            },
            item: {
                label: {
                    color: _hsl(huePrim, 4, 11, 0.85),
                },
            },
        },
        components: {
            activeFilterColor: hsl(285, 70, 50),
        },
        button: {
            color: {
                primary: _hsl(huePrim, 45, 42),
                secondary: _hsl(hueSec, 25, 50),
                tertiary: _hsl(huePrim, 15, isDark ? 50 : 85),
                primaryHover: _hsl(huePrim, 25, 80, 0.85),
                secondaryHover: _hsl(hueSec, 25, 80, 0.85),
                tertiaryHover: _hsl(huePrim, 25, 80, 0.85),
            },
        },
        calendar: {
            grid: {
                gapBetweenDayColumn: 2,
                borderColor: hsl(huePrim, 40, 90),
                workingHours: {
                    backgroundColor: hsl(huePrim, 10, 100),
                    borderColor: hsl(huePrim, 10, 96),
                },
                nonWorkingHours: {
                    backgroundColor: hsl(huePrim, 10, 95),
                    borderColor: hsl(huePrim, 10, 91),
                },
            },
        },
        menubar: {
            backgroundColor: _hsl(huePrim, 10, 98),
            menuItem: {
                backgroundColor: _hsl(huePrim, 15, 98, 0.8),
                backgroundColorHover: _hsl(huePrim, 25, 92),
                backgroundColorActive: _hsl(huePrim, 25, 88),
            },
        },
        popover: {
            backgroundColor: hsl(huePrim, 10, 100),
            foregroundColor: hsl(huePrim, 10, 10),
        },
        input: {
            backgroundColor: _hsl(huePrim, 0, 100),
            border: _hsl(huePrim, 15, 85),
        },
    };

    return deepMerge(theme, customisation);
};
