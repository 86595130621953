import { createGlobalStyle } from 'styled-components';
import { ThemeInterface } from '../app-theme';
import { getThemeCssVars } from '../app-theme/theme-css-vars';

const rootTheme = getThemeCssVars();

export const AppGlobalStyle = createGlobalStyle<{ theme: ThemeInterface }>`
    ${rootTheme}

    html {
        //height: unset;
        //background-color: #000000;
        //min-height: 100%;
    }

    body {
        //height: unset;
        //min-height: 100vh;
        font-size: var(--ez-text-size-base, 14px);
        color: var(--ez-text-color-base, hsl(0 20 20));
        background: var(--ez-main-background);
        background-color: var(--ez-main-background-color, hsl(234 20 90));
    }

    #root {
        //min-height: 100%;
        //background-color: transparent;
    }

    @media print {
        body {
            background: white;
            color: black;
        }
    }

    //html {
    //    @media (prefers-color-scheme: dark) {
    //        background-color: #000000;
    //    }
    //    @media (prefers-color-scheme: light) {
    //        background-color: #f9f9f9;
    //    }
    //}


    @media screen and (max-width: 640px) {
        body {
            font-size: 16px;
        }
    }

`;
