import * as React from 'react';
import { useMemo } from 'react';
import { mapServiceJobStageTypeToIconProps, ModuleColorNames, ModuleIconNames } from '../../constants';
import { fromEdges, NodeType } from '@poolware/api';
import { useConnectedQueryServiceJobConnection } from './useConnectedQueryServiceJobConnection';
import { DueDate } from '../../components/DueDate';
import _truncate from 'lodash/truncate';
import { useViewerContext } from '@poolware/app-shell';
import { RECUR_FILTER } from '../../redux/helpers';
import { ConnectionTableDef, DefaultConnectionTable, Display, Icon, IconWithPopup, TruncatedDiv } from '@ez/components';

export interface PageTableProps {
    onView: any;
}

const ServiceJobCell: React.FC<{ serviceJob: NodeType.ServiceJob }> = ({ serviceJob }) => {
    const wos = fromEdges(serviceJob.workOrders).slice(0, 5); // Print first 5 work orders
    const title = _truncate(serviceJob.title || '--', { length: 60 });
    const groupTitle = serviceJob?.group?.title;
    return (
        <div>
            <div
                style={{
                    marginBottom: '0.5em',
                    fontSize: '1em',
                }}
            >
                <Icon {...mapServiceJobStageTypeToIconProps(serviceJob.stage?.type)} />
                <Icon name={ModuleIconNames.ServiceCall} />
                {groupTitle && <span className={'font-bold text-purple-500'}>{groupTitle} - </span>}{' '}
                <span className={'text-base'}>{title}</span>
            </div>
            {!serviceJob.isRecurring && (
                <div className={'mb-1'}>
                    <ul className={'list-none p-0 pl-6 m-0 text-sm'}>
                        {wos.map((wo) => {
                            return (
                                <li key={wo.id}>
                                    <div>
                                        <Icon {...mapServiceJobStageTypeToIconProps(wo.stage?.type)} />
                                        <Icon name={ModuleIconNames.WorkOrder} />
                                        WO - {_truncate(wo.title, { length: 60 })}{' '}
                                        {wo.appointmentItem && (
                                            <IconWithPopup
                                                popup={{
                                                    content: <Display.Date value={wo.appointmentItem.startDate} />,
                                                }}
                                                name={ModuleIconNames.Appointment}
                                            />
                                        )}
                                        {wo.assignedTo && (
                                            <IconWithPopup
                                                name={ModuleIconNames.Staff}
                                                popup={{ content: <Display.Entity value={wo.assignedTo} /> }}
                                            />
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export const ServiceJobsTable: React.FC<PageTableProps> = ({ onView }) => {
    const { connectionState, connectionData } = useConnectedQueryServiceJobConnection(RECUR_FILTER.ONEOFF);
    const { isAdmin } = useViewerContext();

    const tableDef: ConnectionTableDef<NodeType.ServiceJob> = useMemo(
        () => [
            {
                header: 'ID',
                width: 1,
                sortKey: 'serviceJobNumber',
                cell: (serviceJob) => {
                    return (
                        <span>
                            <div>{serviceJob.serviceJobNumber}</div>
                            {serviceJob.isImplicit && <i className={'text-gray-300'}>auto-created</i>}
                        </span>
                    );
                },
            },
            {
                header: 'Case',
                width: 5,
                cell: (serviceJob) => {
                    return <ServiceJobCell serviceJob={serviceJob} />;
                },
            },
            {
                header: 'Customer',
                width: 4,
                cell: (value) => {
                    const { customer, address } = value;
                    return (
                        <TruncatedDiv maxWidth={400}>
                            {customer && (
                                <div>
                                    <Icon name={'user'} />
                                    <code style={{ color: '#2185d0' }}>
                                        <b>{customer.crn}</b>
                                    </code>{' '}
                                    - <Display.Entity value={customer} />
                                </div>
                            )}
                            {customer?.companyName && (
                                <div>
                                    <Icon name={'building'} />
                                    {customer.companyName}
                                </div>
                            )}
                            {address && (
                                <div>
                                    <Icon name={'map marker alternate'} />
                                    <Display.Address value={address} />
                                </div>
                            )}
                        </TruncatedDiv>
                    );
                },
            },
            {
                header: 'Assigned To',
                cell: (value) => {
                    const { staff } = value;
                    return <Display.Entity value={staff} />;
                },
            },
            {
                header: 'Opened',
                sortKey: 'createdAt',
                cellProps: { verticalAlign: 'top' },
                width: 2,
                cell: (sj) => (
                    <div className={'flex flex-col'}>
                        <DueDate dueDate={sj.createdAt} format={'lll'} warnIfOverdue={false} />
                        {sj.dueDate && (
                            <span>
                                <DueDate
                                    format={'ll'}
                                    dueDate={sj.dueDate}
                                    warnIfOverdue={sj.stage?.status === NodeType.ServiceJobStageStatus.Active}
                                />{' '}
                                <span className={'text-gray-400'}>(due date)</span>
                            </span>
                        )}
                    </div>
                ),
            },
            isAdmin && {
                header: 'Franchise',
                cell: (sj) => {
                    return sj?.entity?.franchise?.name;
                },
            },
        ],
        [connectionData, isAdmin]
    );

    return (
        <DefaultConnectionTable
            debug={false}
            onRowClick={onView}
            tableProps={{
                color: ModuleColorNames.ServiceCall,
            }}
            tableCellProps={{
                verticalAlign: 'top',
                style: {
                    borderTop: '1px solid hsl(24, 50%, 85%)',
                },
            }}
            tableDef={tableDef}
            connectionData={connectionData}
            connectionState={connectionState}
        />
    );
};
